<template>
  <div class="sections-element-wrapper">
    <div class="sections-element heart-course">
      <h4 class="sections__title">{{ lesson.title }}</h4>
      <pre class="sections__text" v-if="lesson.description">{{
        parseString(lesson.description)
      }}</pre>
      <p
        class="sections__lessons mt-10"
        v-if="lesson.checklistsId.length !== 0"
      >
        {{ lesson.checklistsId.length }} тестирования
      </p>
      <button class="sections__about red br-red btn-midle" @click="goToLesson">
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseSection",
  props: ["lesson", "trend", "courseId"],
  methods: {
    goToLesson() {
      this.$router.push({
        name: "lessonPage",
        params: { lessonId: this.lesson.id, courseId: this.courseId },
      });
    },
    parseString(str) {
      str = str.replaceAll(".", ".\n");
      str = str.replaceAll("?", "?\n");
      str = str.replaceAll("!", "!\n");
      return str;
    },
  },
};
</script>

<style scoped>
.sections-element-wrapper {
  max-width: 100%;
  width: 100%;
  padding: 10px;
}

.sections-element {
  padding: 20px;
  max-width: 100%;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sections__title {
  font-family: "Muller";
  font-weight: 500;
  font-size: 20px;
  line-height: 1.1;
  margin-bottom: 10px;
}

.sections__lessons {
  font-size: 12px;
  line-height: 1.1;
  color: rgba(16, 12, 64, 0.699999988079071);
}
.sections__text {
  font-family: "Muller";
  white-space: pre-wrap;
  font-size: 12px;
  line-height: 1.1;
  color: rgba(16, 12, 64, 0.699999988079071);
  margin-bottom: 10px;
}

.sections__about {
  font-size: 12px;
  margin-top: auto;
}

@media (max-width: 600px) {
  .sections-element {
    max-width: 100%;
    width: 100%;
    height: unset;
    text-align: center;
    align-items: center;
  }

  .sections__about {
    margin-top: 20px;
  }

  .sections-element-wrapper {
    max-width: 100%;
    width: 100%;
    padding: 5px;
  }
}

/* Modificator */
.head-course {
  border-color: #8357a0;
}

.head-course .sections__about {
  border-color: #8357a0;
  color: #8357a0;
}

.lungs-course {
  border-color: #f7ad3d;
}

.lungs-course .sections__about {
  border-color: #f7ad3d;
  color: #f7ad3d;
}

.heart-course {
  border-color: #ff2031;
}

.heart-course .sections__about {
  border-color: #ff2031;
  color: #ff2031;
}

.brain-course {
  border-color: #f7ad3d;
}

.brain-course .sections__about {
  border-color: #f7ad3d;
  color: #f7ad3d;
}
</style>
