import { createRouter, createWebHistory } from 'vue-router';
import HomePage from "@/pages/landing/HomePage.vue";
import PrivacyPolicy from "@/pages/agreement/PrivacyPolicy";
import TermsOfUse from "@/pages/agreement/TermsOfUse";
import PersonalInformation from "@/pages/agreement/PersonalInformation";
import CodePage from "@/pages/authorization/CodePage.vue";
import AuthorizationPage from "@/pages/authorization/AuthorizationPage.vue";
import RegistrationPage from "@/pages/authorization/RegistrationPage.vue";
import CheckListPage from "@/pages/authorization/CheckListPage.vue";
import DashbordPage from "@/pages/personal_area/DashbordPage.vue";
import FAQPage from "@/pages/personal_area/FAQPage.vue";
import HelpPage from "@/pages/personal_area/HelpPage.vue";
import AboutUsPage from "@/pages/personal_area/AboutUsPage.vue";
import MyCourses from "@/pages/personal_area/MyCourses.vue";
import AllCoursesPage from "@/pages/personal_area/AllCoursesPage.vue";
import CoursePage from "@/pages/personal_area/CoursePage.vue";
import SeparateTestPreviousPage from "@/pages/personal_area/SeparateTestPreviousPage.vue";
import LessonPage from "@/pages/personal_area/LessonPage.vue";
import LibraryPage from "@/pages/personal_area/LibraryPage.vue";
import BlogPage from "@/pages/personal_area/BlogPage.vue";
import TestPage from "@/pages/personal_area/TestPage.vue";
import App from "@/App";
import { canEnterToRegistration, canEnterToUserCabinet, canEnterToUserPortal, } from "@/router/helpers";
import { errorHandler } from "@/lib/errorHandler";


// creating routes to all pages
const routes = [
    {
        path: '/',
        name: 'homePage',
        component: HomePage,
        props: true
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPolicy,
    },
    {
        path: '/terms',
        name: 'terms',
        component: TermsOfUse,
    },
    {
        path: '/personal',
        name: 'personal',
        component: PersonalInformation,
    },
    {
        path: '/code',
        name: 'codePage',
        component: CodePage,
        props: true
    },
    {
        path: '/AboutUsPage',
        name: 'AboutUsPage',
        component: AboutUsPage
    },

    {
        path: '/FAQPage',
        name: 'FAQPage',
        component: FAQPage
    },
    {
        path: '/HelpPage',
        name: 'HelpPage',
        component: HelpPage
    },
    {
        path: '/login',
        name: 'loginPage',
        component: AuthorizationPage,
        beforeEnter: async (to, before, next) => {
            await canEnterToRegistration(function (token) {
                if (token) {
                    next({ name: 'dashboardPage' });
                } else {
                    next();
                }
            })
        }
    },
    {
        path: '/registration',
        name: 'registrationPage',
        component: RegistrationPage
    },

    {
        path: '/main',
        name: 'user',
        component: App,
        children: [
            {
                path: 'dashboard',
                name: 'dashboardPage',
                component: DashbordPage
            },
            {
                path: 'courses',
                name: 'myCoursesPage',
                component: MyCourses
            },
            {
                path: 'all/courses',
                name: 'allCoursesPage',
                props: true,
                component: AllCoursesPage
            },
            {
                path: '/courses/:courseId',
                props: true,
                name: 'coursePage',
                component: CoursePage
            },
            {
                path: '/testing/:testingId',
                name: 'currentTestingPage',
                props: true,
                component: SeparateTestPreviousPage
            },
            {
                path: '/course/:courseId/:lessonId',
                name: 'lessonPage',
                props: true,
                component: LessonPage
            },
            {
                path: 'library',
                name: 'libraryPage',
                component: LibraryPage
            },
            {
                path: '/library/:articleId',
                name: 'currentArticle',
                props: true,
                component: BlogPage
            },
            {
                path: '/testing/passing/:testingId',
                name: 'testingPassingPage',
                props: true,
                component: TestPage
            },
            {
                path: 'help',
                name: 'helpPage',
                component: HelpPage
            },
            {
                path: 'about',
                name: 'aboutPage',
                component: AboutUsPage
            },

        ],
        beforeEnter: async (to, before, next) => {
            await canEnterToUserPortal(function (user) {
                if (user) {
                    if (!user.userParams.passedChecklist) {
                        next({ name: 'checklistPage' })
                    } else {
                        next();
                    }
                } else {
                    next({ name: 'login' });
                }
            })
        }
    },
    {
        path: '/checklist',
        name: 'checklistPage',
        component: CheckListPage,
        beforeEnter: async (to, before, next) => {
            await canEnterToUserCabinet(function (checklistId) {
                if (checklistId) {
                    errorHandler({ name: 'checklist', status: 400, get: true });
                    next({ name: 'dashboardPage' });
                } else {
                    next()
                }
            })
        }
    },

];

// creating Vue-Router instance with normal history mode
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;