<template v-if="user">
  <!-- <div class="body "> добавляешь модификатор при начале обучения -->
  <div class="body" :class="{ 'training-bg-all': tutorial }">
    <PersonalHeader @startTutorialStageTimer="startTutorialStageTimer" :tutorial="tutorial"
      :tutorial-stage="tutorialStage" ref="mobileHeader">
    </PersonalHeader>
    <main class="main">
      <div class="caption flex al-cent">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">Личный кабинет</h1>
          <p class="caption-greetings__text">
            Добро пожаловать,
            {{ user.firstName ? user.firstName : "Пользователь" }}!
          </p>
        </div>
        <!-- <label for="" class="surch ml-auto">
            <img src="../../assets/img/surch.svg" alt="" class="surch__img">
            <input type="text" placeholder="Поиск" class="surch__input">
        </label> -->
        <PersoneInCaption></PersoneInCaption>
      </div>
      <div class="search-wrapper">
        <SearchElement :view-results="viewResults" :findResults="findResults" type="article" style="margin-top: 30px"
          @closeViewResults="viewResults = false" @viewResults="viewResults = true">
        </SearchElement>
      </div>

      <div class="filling mt-30 flex">
        <div class="content flex flex-dir-col">
          <div class="content-element flex carousel carousel-desktop">
            <div class="carousel-info desktop">
              <h2 class="carousel__title">О проекте послевыписки</h2>
              <p class="carousel__text">
                Главный врач ММКЦ "Коммунарка" Денис Проценко - о проекте, о
                команде, о том, как он поможет Вам и Вашим близким
              </p>
            </div>
            <video controls preload="none" poster="@/assets/img/video-prevue-2.webp" class="video">
              <source :src="appConfig.domain + '/files/data/poslevipiski_title.mp4'" type="video/mp4" />
            </video>
          </div>
          <div class="content-element flex carousel carousel-tablet">
            <video controls preload="none" poster="@/assets/img/video-prevue-2.webp" class="video">
              <source :src="appConfig.domain + '/files/data/poslevipiski_title.mp4'" type="video/mp4" />
            </video>
          </div>
          <div class="content-element flex carousel carousel-mobile">
            <div class="carousel-info desktop">
              <h2 class="carousel__title">О проекте послевыписки</h2>
              <p class="carousel__text">
                Главный врач ММКЦ "Коммунарка" Денис Проценко - о проекте, о
                команде, о том, как он поможет Вам и Вашим близким
              </p>
            </div>
            <video controls preload="none" poster="@/assets/img/video-prevue-2.webp" class="video">
              <source :src="appConfig.domain + '/files/data/poslevipiski_title.mp4'" type="video/mp4" />
            </video>
          </div>
          <div class="content-element popular-course" v-if="false">
            <div class="content-element-caption flex al-cent just-space-betwin">
              <h5 class="content-element-caption__title">
                Популярный курсы недели:
              </h5>
              <a href="" class="content-element-caption__more">См. все</a>
            </div>
            <div class="popular-course-filling block mt-20">
              <SliderWrapper></SliderWrapper>
            </div>
          </div>
          <div class="additionally-element additionall-about block tablet">
            <h4 class="additionall-about-title">
              как пользоваться платформой?
            </h4>
            <p class="additionall-about-text mt-20">
              Пройдите пошаговое обучение по полноценному освоению платформы
              ПОСЛЕВЫПИСКИ.РФ
            </p>
            <!-- <p class="additionall-about-time flex al-cent mt-10">
              <span class="additionall-about-time__img"></span> Время
              прохождения 8 минут
            </p> -->
            <button class="additionall-about-btn btn-midle br-red red" @click="startTutorial">
              Пройти обучение
            </button>
          </div>
          <!-- <div class="content-element category-course" v-if="courseCategory">
            <div class="content-element-caption flex al-cent just-space-betwin">
              <h5 class="content-element-caption__title">Курсы по категориям:</h5>
            </div>
            <div class="category-element category-element-first block" v-if="head&&head.length!==0">
              <div class="category-element__caption">
                <img src="../../assets/img/course/head.webp" alt="" class="category-element__caption-img">
                <div class="category-element__caption-tags">
                  <div class="category-tag-wrapper" v-for="item in courseCategory.filter(item=>item.trend==='head')"
                        :key="item.id">
                    <div class="category-tag tag" >{{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <SliderWrapper :courses="head">
              </SliderWrapper>
            </div>
            <div class="category-element block" v-if="lungs&&lungs.length!==0">
              <div class="category-element__caption">
                <img src="../../assets/img/course/lungs.webp" alt="" class="category-element__caption-img">
                <div class="category-element__caption-tags">
                  <div class="category-tag-wrapper" v-for="item in courseCategory.filter(item=>item.trend==='lungs')"
                        :key="item.id">
                    <div class="category-tag tag" >{{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <SliderWrapper :courses="lungs">
              </SliderWrapper>
            </div>
            <div class="category-element block" v-if="heart&&heart.length!==0">
              <div class="category-element__caption">
                <img src="../../assets/img/course/heart.webp" alt="" class="category-element__caption-img">
                <div class="category-element__caption-tags">
                  <div class="category-tag-wrapper" v-for="item in courseCategory.filter(item=>item.trend==='heart')"
                        :key="item.id">
                    <button class="category-tag tag">{{ item.name }}
                    </button>
                  </div>
                </div>
              </div>
              <SliderWrapper :courses="heart">
              </SliderWrapper>
            </div>
            <div class="category-element block" v-if="brain&&brain.length!==0">
              <div class="category-element__caption">
                <img src="../../assets/img/course/brain.webp" alt="" class="category-element__caption-img">
                <div class="category-element__caption-tags">
                  <div class="category-tag-wrapper" v-for="item in courseCategory.filter(item=>item.trend==='brain')"
                        :key="item.id">
                    <button class="category-tag tag" >{{ item.name }}
                    </button>
                  </div>
                </div>
              </div>
              <SliderWrapper :courses="brain">
              </SliderWrapper>
            </div>
          </div> -->
          <div class="hidden-block" id="courses" v-show="tutorial"></div>
          <ContentWrapper v-if="viewResults && findResults && findResults.length" :findResults="findResults">
          </ContentWrapper>

          <div class="filling-element">
            <div class="content-element-caption flex al-cent just-space-betwin">
              <h5 class="content-element-caption__title">Все курсы:</h5>
              <a href="" class="content-element-caption__more" v-if="false">Вид</a>
            </div>
            <div class="content block mt-20">
              <div class="content-filling" v-if="courses">
                <div class="content-filling-element" v-for="(item, index) in courses.sort((a,b)=>a.order-b.order)"
                  :key="item.id">
                  <SeparateSmallCourse :course="item" :tutorial="tutorial" :tutorial-stage="tutorialStage" :index="index"
                    @startTutorialStageTimer="startTutorialStageTimer">
                  </SeparateSmallCourse>
                </div>
              </div>
              <button class="content-more btn-midle br-red m0-auto mt-30 red" v-if="false" @click="loadCourses">
                Еще
              </button>
            </div>
          </div>
        </div>
        <div class="additionally flex flex-dir-col ml-40">
          <div class="additionally-element additionall-about block desctop">
            <h4 class="additionall-about-title">
              как пользоваться платформой?
            </h4>
            <p class="additionall-about-text mt-20">
              Пройдите пошаговое обучение по полноценному освоению платформы
              ПОСЛЕВЫПИСКИ.РФ
            </p>
            <button class="additionall-about-btn btn-midle br-red red" @click="startTutorial">
              Пройти обучение
            </button>
            <!-- <p class="additionall-about-time flex al-cent mt-10">
              <span class="additionall-about-time__img"></span> Время
              прохождения 8 минут
            </p> -->
          </div>
          <div class="additionally-element my-course">
            <h5 class="my-course__title small-title">Мои курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="myCourses">
                <SeparateRecommendedCourse v-for="item in myCourses" :key="item.id" :course="item">
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img src="../../assets/img/dashbord/filling/last-element.svg" alt="" class="slider-block__img" />
              </button>
              <button class="slider-next-block tablet">
                <img src="../../assets/img/dashbord/filling/next-element.svg" alt="" class="slider-block__img" />
              </button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto" @click="goToCourses">
                Все мои курсы
              </button>
            </div>
          </div>
          <div class="additionally-element mt-40 my-course">
            <h5 class="my-course__title small-title">Рекомендуемые курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="recommendedCourse">
                <SeparateRecommendedCourse v-for="item in recommendedCourse" :key="item" :course="item">
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img src="../../assets/img/dashbord/filling/last-element.svg" alt="" class="slider-block__img" />
              </button>
              <button class="slider-next-block tablet">
                <img src="../../assets/img/dashbord/filling/next-element.svg" alt="" class="slider-block__img" />
              </button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
                @click="$router.push({ name: 'allCoursesPage' })">
                Все курсы
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile></PersonalProfile>
    <div class="training-bg mobile" v-if="tutorial && tutorialStage === 3"></div>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SliderWrapper from "../../components/slider/SliderWrapper.vue";
import SeparateRecommendedCourse from "../../components/courses/SeparateRecommendedCourse.vue";
import SearchElement from "../../components/SearchElement.vue";
import ContentWrapper from "../../components/content/ContentWrapper.vue";
import { mapGetters } from "vuex";
import SeparateSmallCourse from "@/components/slider/SeparateSmallCourse";
import { appConfig } from "@/lib/appConfig";

export default {
  computed: {
    ...mapGetters([
      "user",
      "head",
      "heart",
      "lungs",
      "brain",
      "courseCategory",
      "myCourses",
      "recommendedCourse",
      "courses",
      "findResults",
    ]),
  },
  components: {
    PersonalHeader,
    PersonalProfile,
    SliderWrapper,
    SeparateRecommendedCourse,
    PersoneInCaption,
    SearchElement,
    ContentWrapper,
    SeparateSmallCourse,
  },
  name: "DashbordPage",
  data() {
    return {
      viewResults: false,
      appConfig,
      tutorial: false,
      tutorialStage: null,
      tutorialTimer: null,

      isMobile: false,
    };
  },
  beforeMount() {
    scroll(0, 0);
    this.$store.dispatch("fetchAllCourses", {
      page: 1,
      limit: 20,
      trend: "all",
      name: "",
    });

    this.$store.dispatch("fetchMyCourses", { page: 1, limit: 5, trend: "all" });
    this.$store.dispatch("fetchRecommendedCourses");
  },
  mounted() {
    if (window.innerWidth < 950) {
      this.isMobile = true;
    }
  },
  methods: {
    goToCourses() {
      this.$router.push({ name: "myCoursesPage" });
    },
    startTutorial() {
      this.tutorial = true;
      this.tutorialStage = 0;

      if (this.isMobile) {
        this.$refs.mobileHeader.viewMobile = true;
      }
      setTimeout(this.startTutorialStageTimer, 0);
    },
    startTutorialStageTimer(skip) {
      if (skip) {
        if (this.tutorialStage === 3) {
          this.tutorial = false;
          this.tutorialStage = null;
          if (this.isMobile) {
            this.$refs.mobileHeader.viewMobile = false;
          }
          clearInterval(this.tutorialTimer);
          scroll(0, 0);
          return;
        }
        this.tutorialStage++;
        if (this.tutorialStage === 3) {
          document.getElementById("courses").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
      clearInterval(this.tutorialTimer);
      this.tutorialTimer = setInterval(() => {
        if (this.tutorialStage === 3) {
          this.tutorial = false;
          this.tutorialStage = null;
          if (this.isMobile) {
            this.$refs.mobileHeader.viewMobile = false;
          }
          clearInterval(this.tutorialTimer);
          scroll(0, 0);
          return;
        }
        this.tutorialStage++;
        if (this.tutorialStage === 3) {
          document.getElementById("courses").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100000);
    },
  },
};
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

/* Caption */

.caption {
  padding: 40px 5% 30px 0px;
}

.search-wrapper {
  border-bottom: 1px solid rgba(85, 85, 85, 0.2);
  padding-bottom: 30px;
}

.caption-greetings__title {
  font-weight: 500;
  font-size: 32px;
  font-family: "DelaGothicOne";
}

.caption-greetings__text {
  color: rgba(16, 12, 64, 0.6000000238418579);
  margin-top: 6px;
}

.surch {
  position: relative;
}

.surch__img {
  position: absolute;
  left: 0;
  top: -2px;
}

.surch__input {
  padding-left: 31px;
  padding-bottom: 11px;
  border-bottom: 1px solid rgba(16, 12, 64, 0.5);
  font-size: 16px;
  color: rgba(16, 12, 64, 0.5);
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
}

.content {
  max-width: 100%;
  width: 100%;
}

.content-element {
  margin-top: 40px;
  max-width: 100%;
  width: 100%;
}

.filling-element {
  max-width: 100%;
  width: 100%;
  margin-right: 40px;
  margin-top: 30px;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/*content*/
.content {
  padding: 0px 0px 0px 0px;
  width: 100%;
}

.content-filling {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 10px 0;
}

.content-filling-element {
  margin-bottom: 20px;
  padding: 0 10px;
  width: 100%;
}

.separate-curse {
  max-width: 100%;
}

.content-more {
  max-width: 119px;
}

/* carousel */
.carousel {
  margin-top: 0;
  max-width: 100%;
  width: 100%;
  position: relative;
  background: none;
  background-repeat: no-repeat;
  border: none;
  border-radius: 6px;
  box-shadow: none;
  background: #ffffff;

  border: 1px solid rgba(85, 85, 85, 0.2);
}

.carousel-info {
  padding: 28px 63px 28px 28px;
  max-width: 520px;
  width: 100%;
}

.video {
  max-width: 100%;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.carousel__title {
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #100c40;
}

.carousel__text {
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #100c40;
  max-width: 400px;
}

.courusel-img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 10px;
  box-shadow: 0px 40px 140px rgba(0, 0, 0, 0.06);
}

.carousel-tablet {
  display: none;
}

.carousel-mobile {
  display: none;
}

.carousel-block {
  display: block;
}

.slider-next-block {
  position: absolute;
  width: 36px;
  height: 36px;
  right: -10px;
  bottom: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  z-index: 2;
}

.slider-last-block {
  position: absolute;
  width: 36px;
  height: 36px;
  left: -10px;
  bottom: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  z-index: 2;
}

.slider-next-block img {
  width: 36px;
  height: 36px;
}

.slider-last-block img {
  width: 36px;
  height: 36px;
}

.carousel-title {
  font-family: "DelaGothicOne";
  font-size: 64px;
  line-height: 1.2;
  text-transform: uppercase;
  position: relative;
  z-index: 3;
}

.carousel-text {
  max-width: 358px;
  line-height: 1.6;
  color: rgba(33, 33, 33, 0.699999988079071);
  position: relative;
  z-index: 3;
}

.carousel-button {
  margin-top: 55px;
  position: relative;
  z-index: 3;
}

.carousel-nav {
  position: absolute;
  left: calc(40% - 70px);
  display: flex;
  bottom: 14px;
  z-index: 3;
}

.carousel-nav__element {
  width: 5px;
  height: 5px;
  background: rgba(85, 85, 85, 0.4000000059604645);
  border-radius: 100%;
  z-index: 3;
}

.carousel-nav-chosen {
  width: 40px;
  height: 5px;
  background: #ff1d2e;
  border-radius: 100px;
}

.carousel-img {
  position: absolute;
  right: 12px;
  bottom: 1px;
  background: url(../../assets/img/dashbord/filling/man.svg);
  width: 405px;
  height: 382px;
  z-index: 2;
}

@media (max-width: 3100px) {
  .content-filling {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 2500px) {
  .content-filling {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 2150px) {
  .content-filling {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1920px) {
  .content-filling {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1700px) {
  .content-filling {
    grid-template-columns: repeat(2, 1fr);
  }

  .carousel-desktop {
    display: none;
  }

  .carousel-tablet {
    display: flex;
  }
}

@media (max-width: 1100px) {
  .content-filling {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .content-filling {
    grid-template-columns: repeat(1, 1fr);
  }
}

/*category*/

.category-element {
  padding: 10px 10px 20px 10px;
  margin-top: 40px;
}

.category-element__caption {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.category-element__caption-tags {
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 455px;
}

.category-element__caption-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  display: flex;
  flex-shrink: 0;
}

.category-tag-wrapper {
  padding: 5px 10px 5px 0;
}

.category-element-first {
  margin-top: 20px;
}

/*popular-course*/
.popular-course {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

.popular-course-filling {
  padding: 20px 10px;
}

.content-element-caption__title {
  font-weight: 500;
  font-size: 20px;
}

.content-element-caption__more {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #100c40;
}

/* additionally */
.additionall-about {
  padding: 30px 20px;
  margin-bottom: 40px;
}

.additionall-about-title {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.additionall-about-text {
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-btn {
  margin-top: 58px;
}

.additionall-about-time {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-time__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/time.svg);
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/*my cours*/
.my-course__more {
  margin-top: 10px;
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 1880px) {
  .carousel-title {
    max-width: 398px;
  }

  .additionally {
    max-width: 326px;
  }
}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 30px 0px;
    justify-content: space-between;
  }

  .caption-greetings__title {
    font-size: 24px;
  }

  .caption-greetings__text {
    font-size: 14px;
    margin-top: 8px;
  }

  .surch {
    margin-left: 0;
  }

  .surch__input {
    font-size: 14px;
    max-width: 265px;
    width: 100%;
    display: flex;
    flex-shrink: 1;
  }

  /* carousel */
  .carousel-title {
    font-size: 48px;
  }

  .carousel-text {
    font-size: 14px;
    max-width: 213px;
    line-height: 1.4;
  }

  .carousel-button {
    margin-top: 14px;
    max-width: 223px;
    height: 44px;
    font-size: 12px;
  }

  .carousel-nav {
    left: calc(50% - 70px);
  }

  .carousel-img {
    right: 0px;
    bottom: 0px;
    background: url(../../assets/img/dashbord/filling/man.svg);
    background-size: cover;
    width: 274px;
    height: 260px;
  }

  /* additionally */
  .additionall-about {
    padding: 20px 10px 26px 10px;
  }

  .additionall-about-title {
    font-size: 20px;
  }

  .additionall-about-text {
    margin-top: 10px;
    font-size: 14px;
  }

  .additionall-about-btn {
    margin-top: 72px;
    max-width: 195px;
    font-size: 12px;
  }

  .additionall-about-time {
    margin-top: 20px;
  }

  .slider-last-block {
    display: none;
  }

  .carousel-block {
    display: block;
  }

  .carousel {
    position: relative;
  }

  .category-element-first {
    margin-top: 10px;
  }

  .additionall-about {
    margin-top: 0;
  }
}

@media (max-width: 950px) {
  .training-bg {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.20999999344348907);
    backdrop-filter: blur(12px);
    z-index: 10;
  }

  .body {
    flex-direction: column;
  }

  .carousel-tablet {
    display: none;
  }

  .carousel-mobile {
    display: block;
    background: none;
    border-radius: 0;
    border: none;
  }

  .carousel-info {
    padding: 0;
  }

  .carousel__title {
    font-size: 20px;
    max-width: 225px;
  }

  .carousel__text {
    max-width: 100%;
    font-size: 14px;
  }

  .video {
    margin-top: 20px;
  }

  .caption {
    display: none;
  }

  .search-wrapper {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .profile {
    display: none;
  }

  .main {
    margin-right: 0;
    margin-left: 0;
    padding: 0 20px;
  }

  .filling {
    flex-direction: column;
  }

  /* additionall */
  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .additionall-about {
    flex-direction: column;
    padding: 20px 30px;
    text-align: center;
    margin-bottom: 0;
  }

  .additionall-about-title {
    font-size: 16px;
  }

  .additionall-about-text {
    margin-top: 10px;
    font-size: 10px;
    line-height: 1.6;
  }

  .additionall-about-btn {
    max-width: 220px;
    margin: 10px auto 0 auto;
  }

  .additionall-about-time {
    flex-direction: column;
    margin: 20px auto 0 auto;
  }

  .additionall-about-time {
    font-size: 10px;
  }

  .additionall-about-time__img {
    margin-right: 0;
    margin-bottom: 4px;
    background: url(../../assets/img/dashbord/filling/time-red.svg);
    width: 16px;
    height: 16px;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .additionall-about {
    margin-top: 20px;
  }

  .content-element {
    margin-top: 40px;
  }

  .additionally-element {
    margin-top: 40px;
  }

  .additionally {
    margin-left: 0;
  }

  .carousel {
    margin-top: 0;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  .category-tag-wrapper {
    padding: 4px 4px 4px 0;
  }

  .carousel-title {
    max-width: 267px;
    font-size: 32px;
  }

  .carousel-text {
    margin-top: 10px;
    max-width: 267px;
    font-size: 12px;
  }

  .carousel-button {
    margin-top: 199px;
    max-width: 223px;
  }

  .carousel-img {
    width: 298px;
    height: 282px;
    right: calc(50% - 149px);
  }

  /*category*/
  .category-element {
    margin-top: 20px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }
}

.training-bg-all * {
  position: relative;
}

.hidden-block {
  padding-top: 20px;
}
</style>
