<template v-if="user">
  <div class="body">
    <PersonalHeader> </PersonalHeader>
    <main class="main">
      <div class="caption flex al-cent">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">о нас</h1>
        </div>
        <PersoneInCaption v-if="false"> </PersoneInCaption>
      </div>
      <div class="filling">
        <p class="filling__text">
          ПОСЛЕВЫПИСКИ.РФ - это проект в помощь людям, переболевшим
          коронавирусом, а также тем, кто столкнулся со сходными осложнениями
          после перенесенных заболеваний. <br />
          <br />
          ПОСЛЕВЫПИСКИ.РФ поможет преодолеть многообразные проявления
          постковидного синдрома от когнитивных расстройств до нарушений
          дыхания. <br />
          <br />
          Программа физической реабилитации и психологической поддержки
          составлена командой доктора медицинских наук, Героя труда России
          Дениса Проценко. <br />
          <br />
          Это рекомендации лучших практикующих врачей, которые лично помогли
          тысячам пациентов, находясь на передовой борьбы с COVID-19. <br />
          <br />
          Ответив на вопросы стартовой анкеты, вы получите индивидуальную
          программу восстановления, состоящую из видеокурсов, практических
          занятий, проверенных советов и рекомендаций специалистов. <br />
          <br />
          ПОСЛЕВЫПИСКИ.РФ - ваше здоровье в одном приложении.
        </p>
        <div class="filling-info" v-if="false">
          <div class="info-element">
            <h2 class="info__title info__text">Свыше</h2>
            <p class="info__number mt-10 purple">9999</p>
            <p class="info__text mt-10">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
            </p>
          </div>
          <div class="info-element">
            <h2 class="info__title info__text">Свыше</h2>
            <p class="info__number mt-10 red">9999</p>
            <p class="info__text mt-10">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
            </p>
          </div>
          <div class="info-element">
            <h2 class="info__title info__text">Свыше</h2>
            <p class="info__number mt-10 yellow">9999</p>
            <p class="info__text mt-10">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
            </p>
          </div>
        </div>
        <img src="@/assets/img/dashbord/filling/comand-2.webp" alt="" class="filling__img" />
      </div>
    </main>
    <PersonalProfile> </PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "user",
      "head",
      "heart",
      "lungs",
      "brain",
      "courseCategory",
      "myCourses",
      "recommendedCourse",
    ]),
  },
  components: { PersonalHeader, PersonalProfile, PersoneInCaption },
  name: "AboutUsPage",
  beforeMount() {
    scroll(0, 0);
  },
  methods: {
    goToCourses() {
      this.$router.push({ name: "myCoursesPage" });
    },
  },
}; // this.$store.dispatch('fetchHeartCourses');
// this.$store.dispatch('fetchBrainCourses');
// this.$store.dispatch('fetchHeadCourses');
// this.$store.dispatch('fetchLungsCourses');
// this.$store.dispatch('fetchCourseCategory');
// this.$store.dispatch('fetchMyCourses', {page: 1, limit: 5});
// this.$store.dispatch('fetchRecommendedCourses');
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

/* Caption */

.caption {
  padding: 40px 5% 30px 0px;
}

.caption-greetings__title {
  font-weight: 700;
  font-size: 48px;
  font-family: "DelaGothicOne";
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
}

.filling__text {
  max-width: 1120px;
  font-size: 16px;
  width: 100%;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.filling-info {
  margin-top: 40px;
  display: flex;
  max-width: 835px;
  justify-content: space-between;
}

.filling-info :not(:last-child) {
  margin-right: 20px;
}

.info-element {
  max-width: 211px;
}

.info__text {
  font-size: 14px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
  font-family: "Muller", sans-serif;
}

.info__title {
  font-weight: 400;
  line-height: 1;
}

.info__number {
  font-weight: 500;
  font-size: 36px;
}

.filling__img {
  max-width: 100%;
  width: 100%;
  margin-top: 40px;
  border-radius: 10px;
  height: auto;
  padding-right: 20px;
}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 20px 0px;
    justify-content: space-between;
  }

  .caption-greetings__title {
    font-size: 36px;
  }
}

@media (max-width: 950px) {
  .body {
    flex-direction: column;
  }

  .profile {
    display: none;
  }

  .main {
    margin-left: 0;
    margin-right: 0px;
    padding: 0 20px;
  }

  .filling {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px 430px 10px;
    overflow: hidden;
    position: relative;
  }

  /* caption */
  .caption {
    padding: 30px 0 20px 0;
  }

  .caption-greetings {
    width: 100%;
    text-align: center;
  }

  .caption-greetings__title {
    font-size: 32px;
  }

  /* filling */
  .filling {
    text-align: center;
  }

  .filling__text {
    font-size: 12px;
  }

  .filling-info {
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
  }

  .info-element {
    max-width: 230px;
  }

  .info__text {
    font-size: 12px;
  }

  .filling__img-wrapper {
    position: relative;
    height: 400px;
    margin-top: 40px;
    border-radius: 10px;
  }

  .filling__img {
    padding-right: 0;
  }

  .filling-info :not(:last-child) {
    margin-right: 0px;
  }

  .info-element {
    margin-top: 30px;
  }

  .main {
    padding-bottom: 0px;
  }
}</style>
