<template>
  <div class="background">
    <AuthorizationHeader>
    </AuthorizationHeader>

    <main class="authorization authorization-container" v-if="checklist">
      <h1 class="authorization__title">Чек-лист</h1>
      <p class="authorization__text">Ответьте на все вопросы для того чтобы мы смогли сформировать для вас
        индивидуальный курс реабилитации</p>
      <div class="authorization-content br-rad-10 bg-white mt-20">
        <img src="../../assets/img/big-logo.svg" alt="" class="big-logo">
        <p class="number">{{ iter + 1 }}/{{ checklist.questions.length }}</p>
        <h2 class="authorization-content__title">{{ checklist.questions[iter].body }}</h2>
        <p class="authorization-content__text">Выберите несколько вариантов ответов:</p>
        <div class="check-wrapper">
          <label class="check option" v-for="item in checklist.questions[iter].answers" :key="item.id">
            <input type="checkbox" :value="item.id"
              @change="e => $store.commit('saveAnswerChecklist', { questionId: checklist.questions[iter].id, answerId: e.target.value })"
              class="check__input"
              :checked="savedChecklist && savedChecklist.map(item => item.answerId).indexOf(item.id) !== -1">
            <span class="check__box"></span>
            {{ item.body }}
          </label>
        </div>
        <div class="pozition">
          <button class="pozition__last pozition-btn br-red red" @click="prevQuestion">Назад</button>
          <button class="pozition__next pozition-btn white bg-red" @click="nextQuestion">
            {{ iter === checklist.questions.length - 1 ? 'Завершить' : 'Далее' }}
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import AuthorizationHeader from "../../components/authorization/AuthorizationHeader.vue";
import { mapGetters } from "vuex";
import { errorHandler } from "@/lib/errorHandler";

export default {
  computed: {
    ...mapGetters(['checklist', 'savedChecklist'])
  },
  components: { AuthorizationHeader },
  name: 'CheckListPage',
  data() {
    return {
      iter: 0
    }
  },
  beforeMount() {
    scroll(0, 0);
    this.$store.dispatch('fetchChecklist');
  },
  methods: {
    nextQuestion() {
      if (this.iter === this.checklist.questions.length - 1) {
        if (!this.savedChecklist || !this.savedChecklist.length) {
          errorHandler({ status: 300, name: 'checklist' })
          return
        }
        this.$store.dispatch('savePassedChecklist');
      } else {
        this.iter++;
      }
    },
    prevQuestion() {
      if (this.iter !== 0) {
        this.iter--;
      }
    }
  }
}
</script>

<style scoped>
.background {
  background: url(../../assets/img/registration-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 133px;
  background-position: center;
}

.authorization {
  text-align: center;
  margin-top: 44px;
}

.authorization-content {
  color: unset;
  margin-top: 30px;
  padding-bottom: 20px;
}

.authorization-container {
  max-width: 820px;
}

.authorization__title {
  font-family: "DelaGothicOne";
  font-size: 32px;
  text-transform: unset;
  line-height: 1.6;
}

.authorization__text {
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: rgba(16, 12, 64, 0.699999988079071);
  max-width: 407px;
  margin: 20px auto 0 auto;
}

.number {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  margin-top: 40px;
  color: #100c40;
}

.authorization-content__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  text-align: center;
  color: #100c40;
  max-width: 503px;
  font-family: "Muller";
  margin: 20px auto 0 auto;
}

.authorization-content__text {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  color: rgba(16, 12, 64, 0.4000000059604645);
}

.check-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.pozition {
  justify-content: space-between;
  display: flex;
  margin-top: 40px;
}

.pozition-btn {
  max-width: 130px;
  width: 100%;
  height: 44px;
  border-radius: 8px;
}

/* Check-box */
.option {
  margin-top: 40px;
  margin-right: 40px;
  display: flex;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  color: rgba(16, 12, 64, 0.6000000238418579);
  align-items: center;
}

.check {
  cursor: pointer;
  padding-left: 46px;
}

.check__input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.check__box {
  position: absolute;
  margin-left: -46px;
  width: 36px;
  height: 36px;
  background-image: url(../../assets/img/authorization/check_box-off.svg);
}

.check__input:checked+.check__box {
  background-image: url(../../assets/img/authorization/check_box-on.svg);
}

.check__input:checked+.option {
  color: #100c40;
}

@media (max-width: 1200px) {
  .option {
    margin-top: 30px;
    margin-right: 20px;
  }
}

@media (max-width: 600px) {
  .background {
    background: url(../../assets/img/registration-bg-small.webp);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 53px;
    background-position: top;
  }

  .authorization {
    margin-top: 0;
  }

  .authorization__title {
    font-size: 24px;
    line-height: 1;
  }

  .authorization-content {
    margin-top: 20px;
    padding: 30px 10px;
  }

  .authorization-content__title {
    font-size: 16px;
  }

  .authorization__text {
    font-size: 12px;
  }

  .authorization-content__text {
    font-size: 12px;
  }

  .check-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }


  .big-logo {
    width: 80px;
  }

  .pozition {
    margin-top: 30px;
  }


  /* check-box */
  .option {
    margin-right: 0;
    margin-top: 20px;
    font-size: 12px;
  }

  .check {
    padding-left: 34px;
  }

  .check__box {
    margin-left: -34px;
    width: 24px;
    height: 24px;
    background-size: cover;
  }
}

/* Modififcators */
.color-black {
  color: #100c40;
}
</style>
  