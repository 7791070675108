<template>
  <div class="body" v-if="currentCourse">
    <PersonalHeader></PersonalHeader>
    <main class="main">
      <div class="caption flex">
        <PersoneInCaption></PersoneInCaption>
      </div>
      <div class="filling flex">
        <div class="content">
          <div class="content-filling">
            <div class="course-caption block p-20-10 heart-course">
              <div class="course-caption-description flex al-cent">
                <div class="course-description__img" :style="{
                                    backgroundImage: `url('${currentCourse.previewUrl}')`,
                                  }"></div>
                <div class="course-description-info ml-20 mr-10">
                  <h2 class="course-description-info__name">
                    {{ currentCourse.title }}
                  </h2>
                  <div class="course-description-info-tags mt-20 flex">
                    <div class="category-tag-wrapper"
                      v-for="item in currentCourse.tags.filter((item) => item.name !== currentCourse.title)"
                      :key="item.id">
                      <button class="tag">#{{ item.name }}</button>
                    </div>
                  </div>
                </div>
                <div class="course-description-actions flex ml-auto desctop">
                  <button @click="goToLesson" class="actions-start btn-big white bg-red flex-shrink">
                    Начать курс
                  </button>
                  <!-- <button class="actions-settings"></button> -->
                </div>
              </div>
              <div class="course-caption-filling flex mt-40">
                <div class="course-description">
                  <h3 class="course-description__title">Описание:</h3>
                  <pre class="course-description__text">{{
                                        currentCourse.description
                                      }}</pre>
                  <b class="course-description__author" v-if="currentCourse.authorInfo">{{ currentCourse.authorInfo }}</b>
                </div>
                <div class="course-description" v-if="false">
                  <h3 class="course-description__title">Что входит в курс:</h3>
                  <ul class="course-description-list">
                    <li class="course-description-list__element">
                      Пункт чего-то интересного. Это может быть название
                      раздела/урока
                    </li>
                    <li class="course-description-list__element">
                      Пункт чего-то интересного. Это может быть название
                      раздела/урока
                    </li>
                    <li class="course-description-list__element">
                      Пункт чего-то интересного. Это может быть название
                      раздела/урока
                    </li>
                  </ul>
                </div>
              </div>
              <div class="course-description-actions-tablet mt-30">
                <button class="actions-start btn-big white bg-red flex-shrink" @click="goToLesson">
                  Начать курс
                </button>
                <!-- <button class="actions-settings"></button> -->
              </div>
            </div>
            <h5 class="content-filling__title small-title">Уроки курса:</h5>
            <div class="sections block flex flex-wrap p-20-10">
              <CourseSection v-for="item in currentCourse.lessons.sort(
                                (a, b) => a.order - b.order
                              )" :key="item.id" :lesson="item" :trend="currentCourse.tags[0].trend" :course-id="courseId">
              </CourseSection>
            </div>
          </div>
        </div>
        <div class="additionally flex flex-dir-col">
          <div class="author block text-cent">
            <img :src="currentCourse.author.avatarUrl" alt="" class="author__face" />
            <h3 class="author__name mt-20">{{ currentCourse.author.name }}</h3>
            <h3 class="author__description mt-10">Автор курса</h3>
            <button class="author__btn btn-height-48 red br-red" v-if="false">
              Другие курсы автора
            </button>
          </div>
          <div class="additionally-element my-course">
            <h5 class="my-course__title small-title">Мои курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="myCourses">
                <SeparateRecommendedCourse v-for="item in myCourses" :key="item.id" :course="item">
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img src="../../assets/img/dashbord/filling/last-element.svg" alt="" class="slider-block__img" />
              </button>
              <button class="slider-next-block tablet">
                <img src="../../assets/img/dashbord/filling/next-element.svg" alt="" class="slider-block__img" />
              </button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
                @click="$router.push({ name: 'myCoursesPage' })">
                Все мои курсы
              </button>
            </div>
          </div>
          <div class="additionally-element my-course" v-if="false">
            <h5 class="my-course__title small-title">Рекомендуемые курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="recommendedCourse">
                <SeparateRecommendedCourse v-for="item in recommendedCourse" :key="item.id" :course="item">
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img src="../../assets/img/dashbord/filling/last-element.svg" alt="" class="slider-block__img" />
              </button>
              <button class="slider-next-block tablet">
                <img src="../../assets/img/dashbord/filling/next-element.svg" alt="" class="slider-block__img" />
              </button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
                @click="$router.push({ name: 'allCoursesPage' })">
                Все мои курсы
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile></PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import SeparateRecommendedCourse from "../../components/courses/SeparateRecommendedCourse.vue";
import CourseSection from "../../components/personal_area/CourseSection.vue";
import { mapGetters } from "vuex";
import { errorHandler } from "@/lib/errorHandler";

export default {
  components: {
    PersonalHeader,
    SeparateRecommendedCourse,
    PersoneInCaption,
    CourseSection,
    PersonalProfile,
  },
  name: "CoursePage",
  props: ["courseId"],
  computed: {
    ...mapGetters(["currentCourse", "recommendedCourse", "myCourses"]),
  },
  methods: {
    goToCourses() {
      this.$router.push({ name: "myCoursesPage" });
    },
    goToLesson() {
      let index = this.currentCourse.lessons
        .map((item) => item.passed)
        .indexOf(false);
      if (index === -1) {
        errorHandler({ name: "passing", status: 200 });
      } else {
        this.$router.push({
          name: "lessonPage",
          params: {
            lessonId: this.currentCourse.lessons[index].id,
            courseId: this.courseId,
          },
        });
      }
    },
  },
  beforeMount() {
    scroll(0, 0);
    this.$store.commit("changeUserHeader", true);
    this.$store.dispatch("fetchCurrentCourse", this.courseId);
    this.$store.dispatch("fetchMyCourses", { page: 1, limit: 5, trend: "all" });
  },
  beforeUnmount() {
    this.$store.commit("changeUserHeader", false);
  },
};
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 0 56px 0px;
  width: 100%;
}

/* Caption */

.caption {
  padding: 30px 8% 198px 0px;
  background: url(../../assets/img/course/course-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.caption-greetings__title {
  font-family: "DelaGothicOne";
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
  padding: 40px 20px 0 40px;
}

.content-element {
  max-width: 100%;
  width: 100%;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/* course-caption */
.course-caption {
  margin-bottom: 40px;
}

.course-description__img {
  display: flex;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  background: url(../../assets/img/course/head.webp);
  background-size: contain;
}

.course-description-info__name {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
}

.course-description-actions-tablet {
  display: none;
}

.course-description-actions {
  align-items: center;
}

.actions-start {
  width: 201px;
  flex-shrink: 0;
}

.actions-settings {
  width: 20px;
  margin-left: 20px;
  height: 30px;
  background: url(../../assets/img/course/settings.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.course-caption-filling {
  margin-left: 20px;
}

.course-description {
  max-width: 100%;
  width: 100%;
}

.course-description__title {
  font-family: "Muller";
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
}

.course-description__text {
  margin-top: 20px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
  font-family: "Muller";
  white-space: pre-line;
}

.course-description__author {
  margin-top: 20px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
  font-family: "Muller";
  white-space: pre-line;
  display: inherit;
}

.course-description-list {
  margin-top: 20px;
}

.course-description-list__element {
  margin-left: 3px;
  font-size: 16px;
  line-height: 1.6;
}

/* author  */
.author {
  padding: 44px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.author__face {
  width: 200px;
  height: auto;
  object-fit: cover;
  object-position: center;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 20px;
  box-shadow: 0px 40px 140px rgba(0, 0, 0, 0.06);
}

.author__name {
  font-weight: 500;
  font-size: 20px;
}

.author__description {
  font-weight: 400;
  font-size: 14px;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.author__btn {
  max-width: 250px;
  margin-top: 20px;
  font-size: 12px;
}

/*content*/
.content {
  width: 100%;
}

.content-filling {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.separate-curse {
  max-width: 100%;
}

.sections {
  margin-top: 20px;
  display: grid;
  padding: 10px 0px;
  grid-template-columns: repeat(3, 1fr);
}

/* additionally */
.additionall-about {
  padding: 30px 20px;
}

.additionall-about-title {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.additionall-about-text {
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-btn {
  margin-top: 58px;
}

.additionall-about-time {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-time__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/time.svg);
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/*my cours*/
.my-course__more {
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}

.course-description-info-tags {
  flex-wrap: wrap;
}

.category-tag-wrapper {
  padding: 5px 10px 5px 0;
}

.additionally-element {
  margin-bottom: 40px;
}

@media (max-width: 1880px) {
  .additionally {
    max-width: 326px;
  }
}

@media (max-width: 1800px) {
  .sections {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1300px) {
  .filling {
    padding: 20px;
  }

  /* Caption */
  .caption {
    padding: 20px 6% 116px 0px;
    justify-content: space-between;
  }

  .caption-greetings__title {
    font-size: 24px;
  }

  .caption-greetings__text {
    font-size: 14px;
    margin-top: 8px;
  }

  .surch {
    margin-left: 0;
  }

  .surch__input {
    font-size: 14px;
    max-width: 265px;
    width: 100%;
    display: flex;
    flex-shrink: 1;
  }

  /* course-caption */
  .course-description__img {
    width: 80px;
    height: 80px;
  }

  .course-description-actions {
    display: none;
  }

  .course-description-actions-tablet {
    align-items: center;
    display: flex;
  }

  .actions-start {
    width: 164px;
    height: 44px;
    font-size: 12px;
  }

  .course-caption-filling {
    flex-direction: column;
    margin-left: 10px;
    margin-top: 20px;
  }

  .course-description__title {
    font-size: 20px;
  }

  .course-description__text {
    margin-top: 10px;
    font-size: 14px;
  }

  .course-description-list {
    margin-top: 10px;
  }

  /* additionally */
  .additionall-about {
    padding: 20px 10px 26px 10px;
  }

  .additionall-about-title {
    font-size: 20px;
  }

  .additionall-about-text {
    margin-top: 10px;
    font-size: 14px;
  }

  .additionall-about-btn {
    margin-top: 72px;
    max-width: 195px;
    font-size: 12px;
  }
}

@media (max-width: 1300px) {
  .content-filling {
    margin-right: 20px;
  }
}

@media (max-width: 1100px) {
  .sections {
    grid-template-columns: repeat(1, 1fr);
    padding: 5px;
  }
}

@media (max-width: 950px) {
  .author {
    display: none;
  }

  .caption {
    display: none;
  }

  .body {
    flex-direction: column;
  }

  .filling {
    padding: 40px 0 0 0;
    flex-direction: column;
  }

  .persone {
    display: none;
  }

  .caption {
    padding: 40px 0 0 0;
  }

  .caption-greetings {
    margin: 0 auto;
  }

  .caption-greetings__title {
    font-size: 32px;
  }

  .main {
    margin-left: 0;
    padding: 0 20px;
  }

  .profile {
    display: none;
  }

  /* additionall */
  .my-course-group {
    overflow-x: scroll;
  }

  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    margin-top: 20px;
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .separate-curse {
    max-width: 280px;
  }

  .content-filling {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  /* course-caption */
  .course-caption {
    text-align: center;
    position: relative;
  }

  .course-caption-description {
    flex-direction: column;
  }

  .course-description__img {
    width: 130px;
    height: 130px;
  }

  .course-description-info {
    margin: 10px 0 0 0;
  }

  .course-description-info__name {
    font-family: "Muller";
  }

  .course-caption-filling {
    margin: 20px 0 0 0;
  }

  .actions-settings {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
  }

  .course-description__title {
    font-size: 14px;
  }

  .course-description__text {
    font-size: 12px;
    line-height: 1.4;
  }

  li::before {
    display: none;
  }

  .course-description-list__element {
    font-size: 12px;
    line-height: 1.4;
  }

  .course-description-actions-tablet {
    justify-content: center;
  }

  .actions-start {
    width: 220px;
  }

  .sections {
    grid-template-columns: repeat(1, 1fr);
  }

  /*category*/
  .category-element {
    padding: 0;
    margin-top: 20px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }

  .tags {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: 50px;
  }

  .category-tag-wrapper {
    padding: 4px 4px 4px 0;
  }

  .course-description-info-tags {
    justify-content: center;
  }
}

/* Modifiactor for course-caption */
.head-course .course-description__img {
  background: url(../../assets/img/course/head.webp);
  background-size: cover;
}

.head-course .sections-element {
  border-color: #8357a0;
}

.lungs-course .course-description__img {
  background: url(../../assets/img/course/lungs.webp);
  background-size: cover;
}

.lungs-course .sections-element {
  border-color: #f7ad3d;
}

.heart-course .course-description__img {
  background: url(../../assets/img/course/heart.webp);
  background-size: cover;
}

.heart-course .course-description__img {
  border-color: #ff2031;
}

.brain-course .course-description__img {
  background: url(../../assets/img/course/brain.webp);
  background-size: cover;
}

.brain-course .sections-element {
  border-color: #f7ad3d;
}
</style>
