<template>
  <div class="blog br-red">
    <div class="blog-filling">
      <pre class="blog__title">Совет {{ order + 1 }} из {{ checklist.slides.length }}</pre>
      <pre class="blog__text">{{ checklist.slides[order].text.join('\n') }}</pre>
    </div>
    <div class="blog__activity">
      <button class="activity__back" :class="{'blocked':order===0}" @click="order--">
        <svg width="21" height="8" viewBox="0 0 21 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM1 4.5H21V3.5H1V4.5Z"
              fill="#FF2031"/>
        </svg>
      </button>
      <div class="activity__number">{{ order + 1 }}/{{ checklist.slides.length }}</div>
      <button class="activity__next" :class="{'blocked':order===checklist.slides.length-1}" @click="order++">
        <svg width="21" height="8" viewBox="0 0 21 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
              fill="white"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: "SeparateTestBlog",
  props: ['checklist'],
  data() {
    return {
      order: 0,
    }
  },
  beforeMount() {

  }
}
</script>

<style scoped>
.blog {
  max-width: 100%;
  width: 100%;
  padding: 18px 0px 15px 0px;
  border-radius: 10px;
}

.blog-filling {
  max-width: 100%;
  width: 100%;
  padding: 0 20px;
  scrollbar-width: thin;
  height: 579px;
  overflow-y: scroll;
}

.blog__title {
  font-family: "Muller";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.28;
  color: #100c40;
}

.blog__text {
  margin-top: 20px;
  white-space: pre-wrap;
  font-family: "Muller" !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.6;
  color: #100c40;
  max-width: 1008px;
  width: 100%;
}

.blog__activity {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.activity__back {
  width: 100px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff2031;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.1);
}

.activity__number {
  padding: 10px 20px;
  background: #100c40;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
  border-radius: 8px;
}

.activity__next {
  width: 100px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff2031;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.1);
}

@media (max-width: 600px) {
  .blog {
    max-width: 100%;
    width: 100%;
    padding: 0px 0px 0px 0px;
    border-radius: 10px;
  }

  .blog-filling {
    padding: 17px 10px 17px 10px;
    scrollbar-width: thin;
    height: 427px;
    overflow-y: scroll;
  }

  .blog__title {
    font-size: 14px;
  }

  .blog__text {
    font-size: 14px;
  }

  .blog__activity {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 6px 10px 10px;
    border-top: 1px solid rgba(245, 185, 189, 1);
  }

  .activity__back {
    width: 70px;
  }

  .activity__number {
    font-size: 14px;
  }

  .activity__next {
    width: 70px;
  }
}

.blocked {
  pointer-events: none;
  opacity: 0.3;
}
</style>