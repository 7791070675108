<template>
  <div class="body">
    <PersonalHeader> </PersonalHeader>
    <main class="main" v-if="lesson && currentCourse">
      <div class="caption flex">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">
            {{ checklist ? `В помощь пациенту` : lesson.title }}
          </h1>
          <p class="caption-greetings__text">{{ lesson.description }}</p>
        </div>
        <PersoneInCaption> </PersoneInCaption>
      </div>
      <div class="filling mt-40 flex">
        <div class="content flex flex-dir-col">
          <div class="content-element lesson" v-if="!checklist">
            <video
              :src="lesson.fileUrl"
              crossorigin
              controls
              class="lesson__video"
            ></video>
            <div class="lesson-nav flex just-space mt-30">
              <button
                class="lesson-nav__last"
                :class="{ 'blocked-btn': lesson.order === 0 }"
                @click="prevLesson"
              >
                <span class="lesson-nav__last-arrow"></span>Предыдущий урок
              </button>
              <p class="lesson-nav__number">
                {{ lesson.order + 1 }}/{{ currentCourse.lessons.length }}
              </p>
              <button class="lesson-nav__next" ref="next" @click="nextLesson">
                {{
                  lesson.order === currentCourse.lessons.length - 1 &&
                  lesson.passing
                    ? "Перейти к чек-листу"
                    : "Следующий урок"
                }}
                <span class="lesson-nav__next-arrow"></span>
              </button>
            </div>
          </div>
          <div class="content-element blog">
            <SeparateTestBlog :checklist="checklist" v-if="checklist">
            </SeparateTestBlog>
            <div class="lesson-nav flex just-space mt-30" v-if="checklist">
              <button
                class="lesson-nav__last blog-btn"
                @click="checklist = null"
              >
                Вернуться к уроку
              </button>
            </div>
          </div>
          <div class="content-element info block br-rad-10" v-if="false">
            <div class="info-filling p-20">
              <h3 class="test-description__title">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor
              </h3>
              <p class="test-description__text mt-20">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet. Amet minim
                mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                Velit officia consequat duis enim velit mollit. Exercitation
                veniam consequat sunt nostrud amet. Amet minim mollit non
                deserunt ullamco est sit aliqua dolor do amet sint.
              </p>
              <div
                class="test-description-statistics info-statistics al-cent flex white mt-30"
              >
                <img
                  src="../../assets/img/test/pdf.svg"
                  alt=""
                  class="test-description-statistics__img"
                />
                <p class="test-description-statistics__text">
                  PDF-файл: «Название чек-листа»
                </p>
              </div>
              <button
                class="test-description-actions__start info-action btn-big bg-red white mt-30"
              >
                Скачать чек-лист
              </button>
            </div>
          </div>
          <div class="content-element tests" v-if="false">
            <h5 class="my-course__title small-title">
              Тестирования к данному уроку:
            </h5>
            <div
              class="slider-wrapper mt-20 block p-10 slider-wrapper-progress slider"
            >
              <button class="slider-last-block">
                <img
                  src="../../assets/img/dashbord/filling/last-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button class="slider-next-block">
                <img
                  src="../../assets/img/dashbord/filling/next-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <div class="slider-content">
                <div class="slider-filling">
                  <SeparateRecommendedLessonTest
                    v-for="item in lesson.checklists"
                    :key="item.id"
                    :testing="item"
                  >
                  </SeparateRecommendedLessonTest>
                  <div class="tests-closed" v-if="false">
                    <button class="tests-closed__info"></button>
                    <img
                      src="../../assets/img/video/close.svg"
                      alt=""
                      class="tests-closed__img"
                    />
                    <p class="tests-closed__text">
                      Доступ к данному тестированию закрыт
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="additionally flex flex-dir-col">
          <div class="additionally-element my-course">
            <h5 class="my-course__title small-title">Мои курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="myCourses">
                <SeparateRecommendedCourse
                  v-for="item in myCourses"
                  :key="item.id"
                  :course="item"
                >
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/last-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button class="slider-next-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/next-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button
                class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
                @click="goToCourses"
              >
                Все мои курсы
              </button>
            </div>
          </div>
          <div class="additionally-element my-course" v-if="false">
            <h5 class="my-course__title small-title">Рекомендуемые курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="recommendedCourse">
                <SeparateRecommendedCourse
                  v-for="item in recommendedCourse"
                  :key="item.id"
                  :course="item"
                >
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/last-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button class="slider-next-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/next-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button
                class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
              >
                Все рекомендации
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile> </PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SeparateRecommendedCourse from "../../components/courses/SeparateRecommendedCourse.vue";
import SeparateRecommendedLessonTest from "../../components/tests/SeparateRecommendedLessonTest.vue";
import SeparateTestBlog from "../../components/tests/SeparateTestBlog.vue";
import { mapGetters } from "vuex";
import checklists from "@/lib/checklists.JSON";

export default {
  components: {
    PersonalHeader,
    PersonalProfile,
    SeparateRecommendedCourse,
    PersoneInCaption,
    SeparateRecommendedLessonTest,
    SeparateTestBlog,
  },

  computed: {
    ...mapGetters([
      "lesson",
      "currentCourse",
      "recommendedCourse",
      "myCourses",
    ]),
  },
  name: "LessonPage",
  props: ["lessonId", "courseId"],
  data() {
    return {
      checklist: false,
    };
  },
  beforeMount() {
    this.$store.dispatch("fetchCurrentCourse", this.courseId);
    this.$store.dispatch("fetchLesson", {
      lessonId: this.lessonId,
      courseId: this.courseId,
    });
    this.$store.dispatch("fetchMyCourses", { page: 1, limit: 5, trend: "all" });
    this.$store.dispatch("fetchRecommendedCourses");
    scroll(0, 0);
  },
  methods: {
    goToCourses() {
      this.$router.push({ name: "myCoursesPage" });
    },
    async prevLesson() {
      let id = this.currentCourse.lessons[this.lesson.order - 1].id;
      await this.$store.dispatch("fetchLesson", {
        lessonId: id,
        courseId: this.courseId,
      });
      await this.$router.push({
        name: "lessonPage",
        params: { lessonId: id, courseId: this.courseId },
      });
    },
    async nextLesson() {
      if (!this.lesson.passing) {
        await this.$store.dispatch("savePassedLesson", {
          courseId: this.courseId,
          lessonId: this.lessonId,
        });
        if (this.currentCourse.lessons.length === this.lesson.order + 1) {
          await this.$store.dispatch("fetchLesson", {
            lessonId: this.lessonId,
            courseId: this.courseId,
          });
          return;
        }
      }
      if (this.currentCourse.lessons.length === this.lesson.order + 1) {
        let index = checklists.map((item) => item.id).indexOf(this.courseId);
        if (index !== -1) {
          this.checklist = checklists[index];
        }
        return;
      }
      let id = this.currentCourse.lessons[this.lesson.order + 1].id;
      await this.$store.dispatch("fetchLesson", {
        lessonId: id,
        courseId: this.courseId,
      });
      await this.$router.push({
        name: "lessonPage",
        params: { lessonId: id, courseId: this.courseId },
      });
    },
  },
};
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

.blocked-btn {
  opacity: 0.3;
  pointer-events: none;
}

/* Caption */

.caption {
  padding: 40px 5% 0px 0px;
  align-items: flex-start;
}

.caption-greetings__title {
  font-family: "DelaGothicOne";
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
  max-width: 782px;
}

.caption-greetings__text {
  margin-top: 30px;
  max-width: 100%;
  font-size: 16px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
}

.filling-element {
  max-width: 100%;
  width: 100%;
}

.content {
  margin-right: 40px;
}

.content-element {
  max-width: 100%;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/* lesson */
.lesson__video {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #f1f1f1;
}

.lesson-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lesson-nav__last {
  max-width: 293px;
  width: 100%;
  height: 66px;
  justify-content: center;
  border: 1px solid #ff2031;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.1);
  font-size: 16px;
  font-weight: 500;
  color: #ff2031;
  align-items: center;
  display: flex;
}

.lesson-nav__last-arrow {
  background: url(../../assets/img/video/arrow-left.svg);
  width: 35px;
  height: 9px;
  background-size: cover;
  margin-right: 10px;
  display: block;
}

.lesson-nav__number {
  margin: 0 10px;
  padding: 10px 20px;
  background: #100c40;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.lesson-nav__next {
  max-width: 282px;
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff2031;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.1);
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.lesson-nav__next-arrow {
  background: url(../../assets/img/video/arrow-right.svg);
  width: 35px;
  height: 9px;
  background-size: cover;
  margin-left: 10px;
  display: block;
}

/*content*/
.content {
  width: 100%;
}

.test-description {
  max-width: 60%;
  padding: 30px 20px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.test-description__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.28;
}

.test-description__text {
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.test-description-statistics {
  margin-bottom: 20px;
}

.test-description-statistics__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.5);
}

.test-description-actions {
  margin-top: auto;
}

.test-description-actions__remind {
  max-width: 220px;
}

.test-previous {
  max-width: 40%;
  object-fit: cover;
  border-radius: 0 10px 10px 0;
}

/* additionally */
.additionall-about {
  padding: 30px 20px;
}

.additionall-about-title {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.additionall-about-text {
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-btn {
  margin-top: 58px;
}

.additionall-about-time {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-time__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/time.svg);
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Recommended tests */
.slider-wrapper {
  display: flex;
  flex-shrink: 1;
  position: relative;
  overflow: visible;
  max-width: 100%;
  width: 100%;
  height: 259px;
}

.slider-content {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.slider-last-block {
  position: absolute;
  left: -15px;
  bottom: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  width: 36px;
  height: 36px;
  z-index: 2;
}

.slider-next-block {
  position: absolute;
  right: -15px;
  bottom: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  width: 36px;
  height: 36px;
  z-index: 2;
}

.slider-filling {
  display: flex;
  overflow-x: scroll;
  position: absolute;
  background: #fff;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.tests-closed {
  height: 237px;
  width: 260px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border: 1px solid #ff2031;
  border-radius: 10px;
}

.tests-closed__info {
  position: absolute;
  background: url(../../assets/img/video/info.svg);
  width: 24px;
  height: 24px;
  right: 10px;
  top: 10px;
}

.tests-closed__img {
  width: 100px;
  height: 100px;
}

.tests-closed__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  max-width: 200px;
}

/*my cours*/
.my-course__more {
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
}

.additionally-element {
  margin-bottom: 40px;
}

@media (max-width: 1880px) {
  .carousel-title {
    max-width: 398px;
  }

  .additionally {
    max-width: 326px;
  }
}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 30px 0px;
    justify-content: space-between;
  }

  .caption-greetings__title {
    font-size: 24px;
  }

  .caption-greetings__text {
    font-size: 14px;
    margin-top: 8px;
  }

  .surch {
    margin-left: 0;
  }

  .surch__input {
    font-size: 14px;
    max-width: 265px;
    width: 100%;
    display: flex;
    flex-shrink: 1;
  }

  /* carousel */
  .carousel {
    padding: 20px 20px 50px 20px;
    position: relative;
  }

  .carousel-title {
    font-size: 48px;
  }

  .carousel-text {
    font-size: 14px;
    max-width: 213px;
    line-height: 1.4;
  }

  .carousel-button {
    margin-top: 14px;
    max-width: 223px;
    height: 44px;
    font-size: 12px;
  }

  .carousel-nav {
    left: calc(50% - 70px);
  }

  .carousel-img {
    right: 0px;
    bottom: 0px;
    background: url(../../assets/img/dashbord/filling/man.svg);
    background-size: cover;
    width: 274px;
    height: 260px;
  }

  /* additionally */
  .additionall-about {
    padding: 20px 10px 26px 10px;
  }

  .additionall-about-title {
    font-size: 20px;
  }

  .additionall-about-text {
    margin-top: 10px;
    font-size: 14px;
  }

  .additionall-about-btn {
    margin-top: 72px;
    max-width: 195px;
    font-size: 12px;
  }

  .additionall-about-time {
    margin-top: 20px;
  }

  .content {
    margin-right: 20px;
  }
}

@media (max-width: 950px) {
  .content {
    margin-right: 0px;
  }

  .content {
    margin-top: 0;
  }

  .body {
    flex-direction: column;
  }

  .filling {
    flex-direction: column;
  }

  .caption {
    padding: 40px 0 0 0;
  }

  .caption-greetings__title {
    font-size: 32px;
  }

  .main {
    margin-left: 0;
    margin-right: 0;
    padding: 0 20px;
  }

  .profile {
    display: none;
  }

  /* additionall */
  .my-course-group {
    overflow-x: scroll;
  }

  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    margin-top: 20px;
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .separate-curse {
    max-width: 280px;
  }

  /* slider */
  .slider-wrapper {
    margin-top: 20px;
    height: 214px;
  }

  .tests-closed {
    height: 192px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  .filling {
    margin-top: 30px;
  }

  .caption-greetings {
    max-width: 100%;
    width: 100%;
  }

  .caption-greetings__title {
    text-align: center;
    font-size: 24px;
    max-width: 100%;
  }

  .caption-greetings__text {
    max-width: 100%;
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 1.6;
    margin-top: 20px;
  }

  .carousel {
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 20px 20px 45px 20px;
  }

  .carousel * {
    margin: 0 auto;
  }

  .carousel-title {
    max-width: 267px;
    font-size: 32px;
  }

  .carousel-text {
    margin-top: 10px;
    max-width: 267px;
    font-size: 12px;
  }

  .carousel-button {
    margin-top: 199px;
    max-width: 223px;
  }

  .carousel-img {
    width: 298px;
    height: 282px;
    right: calc(50% - 149px);
  }

  .content {
    margin-top: 0;
    padding: 0 0 20px 0;
  }

  /* test */
  .test {
    flex-direction: column;
  }

  .test-previous {
    order: 1;
    display: flex;
    max-width: 100%;
    height: 160px;
  }

  .test-description {
    order: 2;
    text-align: center;
    padding: 30px 10px 20px 10px;
    max-width: 100%;
    align-items: center;
    min-height: unset;
  }

  .test-description__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
  }

  .test-description__text {
    font-size: 12px;
    line-height: 1.6;
  }

  .test-description-statistics-wrapper {
    flex-direction: row;
    margin-top: 20px;
  }

  .test-description-statistics {
    display: flex;
    margin: 0;
    flex-direction: column;
  }

  .test-description-actions {
    margin-top: 30px;
    max-width: 100%;
    width: 100%;
    justify-content: center;
  }

  .test-description-actions__start {
    max-width: 165px;
    height: 44px;
    font-size: 12px;
  }

  .test-description-actions__remind {
    max-width: 165px;
    height: 44px;
    font-size: 12px;
  }

  .info {
    text-align: center;
  }

  .info-filling {
    padding: 20px 10px;
  }

  .info-statistics {
    flex-direction: row;
    margin: 20px 0 0 0;
    justify-content: center;
  }

  .info-action {
    margin: 20px auto 0 auto;
  }

  /*category*/
  .category-element {
    padding: 0;
    margin-top: 20px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /* lesson */
  .lesson-nav {
    margin-top: 20px;
  }

  .lesson-nav__last {
    max-width: 100px;
    width: 100%;
    height: 66px;
    font-size: 0;
    height: 36px;
  }

  .blog-btn {
    font-size: 16px;
    max-width: 200px;
    height: 50px;
  }

  .lesson-nav__last-arrow {
    background: url(../../assets/img/video/arrow-left.svg);
    width: 28px;
    height: 8px;
    margin-left: 0;
    background-size: contain;
  }

  .lesson-nav__number {
    padding: 8px 14px;
    border-radius: 8px;
    font-size: 10px;
  }

  .lesson-nav__next {
    max-width: 120px;
    font-size: 10px;
    height: 36px;
  }
  .lesson-nav__next span {
    display: none;
  }
  .lesson-nav__last {
    max-width: 120px;
    font-size: 10px;
    height: 36px;
  }
  .lesson-nav__last span {
    display: none;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }

  .tests-closed__img {
    width: 70px;
    height: 70px;
  }
}

/*Modificators*/
.btn-not-available {
  opacity: 0.5;
  pointer-events: none;
}
</style>
