<template>
  <div class="background">
    <AuthorizationHeader>
    </AuthorizationHeader>

    <main class="authorization authorization-container">
      <h1 class="authorization__title">Регистрация</h1>
      <div class="authorization-content br-rad-10 bg-white mt-20">
        <div class="caption">
          <img src="../../assets/img/big-logo.svg" alt="" class="big-logo">
          <img src="@/assets/img/authorization/one-rus.svg" alt="" class="rus">
        </div>

        <p class="authorization__text mt-20">Заполните данные
        </p>
        <div class="authorization-filling flex-dir-col flex">
          <div class="fields">

            <label class="authorization-field">
              <div class="authorization-field__img user"></div>
              <input v-model.trim="registrationData.firstName" type="text" placeholder="Ваше имя*"
                     class="authorization-field__input ">
            </label>
            <label class="authorization-field ">
              <div class="authorization-field__img user"></div>
              <input v-model.trim="registrationData.secondName" type="text" placeholder="Ваша фамилия*"
                     class="authorization-field__input">
            </label>
            <label class="authorization-field ">
              <div class="authorization-field__img user"></div>
              <input v-model.trim="registrationData.thirdName" type="text" placeholder="Ваше отчество"
                     class="authorization-field__input">
            </label>
            <label class="authorization-field ">
              <div class="authorization-field__img date"></div>
              <input type="date" v-model="birthMask" :max="dayjs().format('YYYY-MM-DD')" placeholder="Дата рождения*"
                     class="authorization-field__input">
            </label>
            <label class="authorization-field">
              <div class="authorization-field__img field-tel"></div>
              <i-mask-component type="tel" placeholder="+7 (_ _ _) _ _ _   _ _  _ _" v-model="maskedPhone"
                                v-model:unmasked="registrationData.phone" :mask="mask"
                                class="authorization-field__input"/>

            </label>
            <label class="authorization-field ">
              <div class="authorization-field__img email"></div>
              <input v-model.trim="registrationData.email" type="email" placeholder="Email*"
                     class="authorization-field__input">
            </label>
            <div class="authorization-field-wrapper">
              <label class="authorization-field ">
                <div class="authorization-field__img home"></div>
                <input @blur="blurList('viewRegionList')" @focusin="viewRegionList = true" v-model="searchRegionName"
                       type="email" :placeholder="regionName ? regionName : 'Регион проживания*'"
                       class="authorization-field__input">
              </label>
              <div class="pop-up" v-if="viewRegionList&&regionList.length">
                <button @click="registrationData.regionId=item.id" class="authorization-field-element"
                        v-for="item in regionList" :key="item.id">{{
                    item.name
                  }}
                </button>

              </div>
            </div>
            <div class="authorization-field-wrapper">
              <label id="districtList" class="authorization-field ">
                <div class="authorization-field__img home"></div>
                <input @blur="blurList('viewDistrictList')" @focusin="viewDistrictList = true"
                       v-model="searchDistrictName" type="email"
                       :placeholder="districtName ? districtName :'Район проживания*'  "
                       class="authorization-field__input">
              </label>
              <div class="pop-up" v-if="viewDistrictList">
                <button class="authorization-field-element" v-for="item in districtList" :key="item?.id"
                        @click="registrationData.districtId = item?.id">
                  {{ item?.name }}
                </button>

              </div>
            </div>
            <div class="authorization-field-wrapper">
              <label class="authorization-field ">
                <div class="authorization-field__img home"></div>
                <input v-model="registrationData.city" type="text" placeholder="Адрес проживания*"
                       class="authorization-field__input">
              </label>
            </div>
            <label class="authorization-field ">
              <div class="authorization-field__img password"></div>
              <input :type="viewPassword ? 'text' : 'password'" autocomplete="new-password"
                     v-model.trim="registrationData.password" placeholder="Придумайте пароль"
                     class="authorization-field__input">
              <button class="eye" @click="viewPassword = !viewPassword" :class="{'eye-open':viewPassword}">
              </button>
            </label>

          </div>

          <button class="authorization-register br-rad-10 mt-20" @click="confirmRegistration">
            Зарегистрироваться
          </button>
        </div>
        <div class="authorization-end mt-20">
          <p class="authorization-forgot  authorization-forgot__last">Нажимая на кнопку вы соглашаетесь с
            <router-link target="_blank" :to="{ name: 'privacy' }">политикой
              конфиденциальности
            </router-link>
            ,
            <router-link target="_blank" :to="{ name: 'terms' }">пользовательским
              соглашением
            </router-link>
            и
            <router-link target="_blank" :to="{ name: 'personal' }">обработкой
              персональных
              данных
            </router-link>
          </p>
          <p class="authorization-forgot ">* поле, обязательное для заполнения
          </p>
        </div>

      </div>
    </main>
  </div>
</template>

<script>
import AuthorizationHeader from "../../components/authorization/AuthorizationHeader.vue";
import {IMaskComponent} from "vue-imask"
import districts from "@/lib/districts.json"
import {getDistrictNameById, getRegionNameById} from "@/lib/convertTime";
import dayjs from "dayjs";
import {errorHandler} from "@/lib/errorHandler";

export default {
  components: {AuthorizationHeader, IMaskComponent},
  name: 'RegistrationPage',
  computed: {
    regionList() {
      return districts?.filter(item => this.searchRegionName ? item?.name?.toLowerCase().includes(this.searchRegionName.toLowerCase()) : true)
    },
    districtList() {
      if (this.registrationData.regionId) {
        const regionIndex = districts?.map(item => item?.id).indexOf(this.registrationData.regionId);
        if (regionIndex > -1) {
          return districts[regionIndex]?.districts?.filter(item => this.searchDistrictName ? item?.name?.toLowerCase()?.includes(this.searchDistrictName?.toLowerCase()) : true);
        }
      }
      return []
    },
    regionName() {
      return getRegionNameById(this.registrationData.regionId);
    },
    districtName() {
      return getDistrictNameById(this.registrationData.regionId, this.registrationData.districtId);
    },
  },
  watch: {
    "registrationData.regionId"() {
      this.viewRegionList = false;
      this.searchRegionName = null;
      this.registrationData.districtId = null;
      document.getElementById('districtList')?.focus();

    },
    "registrationData.districtId"(val) {
      if (!val) return;
      this.viewDistrictList = false;
      this.searchDistrictName = null;
    },
    "birthMask"(val) {
      const date = dayjs(val);
      this.registrationData.birthDay = date.get('date');
      this.registrationData.birthMonth = date.get('month');
      this.registrationData.birthYear = date.get('year');
    }


  },
  data() {
    return {
      registrationData: {
        firstName: null,
        secondName: null,
        thirdName: null,
        birthDay: null,
        birthMonth: null,
        birthYear: null,
        regionId: null,
        districtId: null,
        city: null,
        email: null,
        phone: null,
        password: null,
      },

      birthMask: null,


      viewPassword: false,

      mask: "+{7}(000)000-00-00",
      maskedPhone: '',

      viewDistrictList: false,
      viewRegionList: false,
      searchRegionName: null,
      searchDistrictName: null
    }
  },
  methods: {
    dayjs,
    confirmRegistration() {
      const emptyFields = [];
      for (const key in this.registrationData) {
        if (this.registrationData[key] == null) {
          emptyFields.push(key);
        }

      }
      if (emptyFields.length) {
        errorHandler({name: 'notAllFields'});
        return
      }
      if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(this.registrationData.email)) {
        errorHandler({name: 'invalidEmail'});
        return;
      }

      this.$store.dispatch('register', this.registrationData);
    },
    blurList(field) {
      setTimeout(() => {
        this[field] = false;
      }, 100)
    }
  }

}
</script>

<style scoped>
.background {
  background: url(../../assets/img/registration-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 133px;
  background-position: center;
}


.authorization-container {
  max-width: 696px;
}

.authorization {
  text-align: center;
  margin-top: 44px;
}

.authorization__title {
  font-family: "DelaGothicOne";
  font-size: 32px;
  text-transform: unset;
  line-height: 1.6;
}

.authorization__text {
  color: #9F9EB3;
  font-size: 12px;
  line-height: 1.6;
}

.authorization__text a {
  text-decoration-line: underline;
  color: #FF2031;
}

.authorization-choice {
  display: flex;
  width: 100%;
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.authorization-way {
  margin: 17px 0 0px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.way__element {
  font-size: 12px;
  line-height: 1.6;
  font-weight: 500;
  color: rgba(16, 12, 64, 0.4000000059604645);
}

.way-chosen {
  color: #FF2031;
  text-decoration: underline;
}

.choice__element {
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: rgba(16, 12, 64, 0.20000000298023224);
}

.fields {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.authorization-field {
  text-align: left;
  align-items: center;
  display: flex;
  padding: 13px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.authorization-field__input {
  color: #100c40;
  margin-left: 10px;
  width: 100%;
  max-width: 100%;
}

.authorization-field__input::placeholder {
  color: rgba(16, 12, 64, 0.4000000059604645);
}

.authorization-field__input input {
  opacity: 1;
}

.authorization-field__img {
  width: 24px;
  height: 24px;
  display: flex;
  flex-shrink: 0;
}

.user {
  background: url(../../assets/img/authorization/user.svg);
}

.email {
  background: url(../../assets/img/authorization/mail.svg);
}

.date {
  background: url(../../assets/img/authorization/date-icon.svg);
}

.home {
  background: url(../../assets/img/authorization/home-icon.svg);
}

.password {
  background: url(../../assets/img/authorization/paswword-icon.svg);
}

.field-tel {
  background: url(../../assets/img/authorization/tel.svg);
}

.authorization-end {
  display: flex;
  justify-content: space-between;
}

.authorization-forgot__last {
  max-width: 357px;
}

.authorization-forgot {
  font-size: 10px;
  line-height: 1.6;
  text-align: left;
  color: rgba(16, 12, 64, 0.4);
}

.authorization-forgot a {
  text-decoration: underline;
}

.authorization-register {
  background: #FF2031;
  width: 100%;
  height: 66px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.authorization-social {
  margin-top: 32px;
  max-width: 180px;
  align-items: center;
}

.google {
  width: 30px;
  height: 30px;
}

.google img {
  width: 30px;
  height: 30px;
}

.gos {
  width: 38px;
  height: 38px;
}

.gos img {
  width: 38px;
  height: 38px;
}

.vk {
  width: 32px;
  height: 32px;
}

.vk img {
  width: 32px;
  height: 32px;
}

.apple {
  width: 36px;
  height: 36px;
}

.apple img {
  width: 36px;
  height: 36px;
}

.caption {
  display: flex;
  justify-content: center;
}

.rus {
  margin-left: 12px;
}

.authorization-field-wrapper {
  position: relative;
}

.pop-up {
  position: absolute;
  max-width: 100%;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  scrollbar-width: thin;
  border-radius: 10px;
  z-index: 10;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.authorization-field-element {
  max-width: 100%;
  width: 100%;
  text-align: left;
  align-items: center;
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  transition: color 0.5s ease-in-out;
  font-size: 14px;
}

.pop-up :first-child {
  border-radius: 10px 10px 0 0;

}

.pop-up :last-child {
  border-radius: 0 0 10px 10px;
  border-bottom: none;
}

.authorization-field-element:hover {
  opacity: 1;
  color: #FF2031;
}

/* modificators */
.choice__element-chosen {
  background: #FF2031;
  color: #fff;
}

@media (max-width: 600px) {
  .background {
    background: url(../../assets/img/registration-bg-small.webp);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 53px;
    background-position: top;
  }

  .authorization {
    margin-top: 0;
  }

  .authorization__title {
    font-size: 24px;
    line-height: 1;
  }

  .authorization-content {
    margin-top: 30px;
    padding: 30px 10px;
  }

  .big-logo {
    width: 80px;
  }

  .authorization__text {
    margin-top: 30px;
    font-size: 12px;
    line-height: 1;
  }

  .authorization-filling {
    font-size: 12px;
    line-height: 1;
  }

  .authorization-choice {
    margin-top: 30px;
    font-size: 12px;
  }

  .authorization-register {
    height: 58px;
    font-size: 14px;
  }

  .authorization-social__element {
    width: 32px;
    height: 32px;
  }

  .authorization-social__element img {
    width: 32px;
    height: 32px;
  }

  .fields {
    display: flex;
    flex-direction: column;
  }

  .authorization-end {
    flex-direction: column;

  }

  .authorization-forgot {
    text-align: center;
  }
}

input {
  line-height: 1;
}

.eye {
  margin-left: 10px;
  display: flex;
  width: 24px;
  height: 24px;
  background: url(../../assets/img/authorization/eye.svg);
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
}

.eye-open {
  background: url(../../assets/img/authorization/eye-open.svg);
  background-repeat: no-repeat;
}
</style>
  