<template>
  <div class="background">
    <AuthorizationHeader>
    </AuthorizationHeader>

    <main class="container">
      <h1>
        СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </h1>
      <p>Настоящим Пользователь, осуществляющий Регистрацию, Аутентификацию и/или Авторизацию на Сайте, предоставляет
        Правообладателю (Оператору) Согласие на обработку персональных данных (далее по тексту – «Согласие»).
      </p>
      <p><b>Цель обработки персональных данных:</b></p>
      <ul>
        <li>- Информирование Пользователя посредством отправки электронных писем;</li>
        <li>- Предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на Сайте.</li>
      </ul>

      <p><b>Перечень персональных данных, на обработку которых дается согласие:</b></p>
      <ul>
        <li>- фамилия, имя, отчество;</li>
        <li>- адрес электронной почты;</li>
        <li>- номер телефонов;</li>
        <li>- фотографии.</li>
      </ul>
      <p><b>Перечень действий с персональными данными, на совершение которых дается согласие, общее описание
          используемых
          оператором способов обработки персональных данных:</b></p>
      <p>Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
        извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление и уничтожение персональных данных.</p>
      <p><b>Обработка вышеуказанных персональных данных</b> будет осуществляться путем осуществления автоматизированной
        обработки персональных данных с получением и/или передачей полученной информации по
        информационно-телекоммуникационным сетям или без таковой.</p>
      <p>Пользователь дает свое согласие на передачу (предоставление) Оператором персональных данных Пользователя
        третьим
        лицам в соответствии с требованиями действующего законодательства Российской Федерации любым возможным способом.
      </p>
      <p><b>Срок, в течение которого действует Согласие субъекта персональных данных, а также способ его отзыва, если
          иное
          не установлено федеральным законом.</b></p>
      <p>Настоящее Согласие на обработку персональных данных действует с момента его представления Оператору и действует
        в
        течение 3 (Трех) лет с момента его предоставления, и может быть отозвано пользователем в любое время путем
        подачи
        Оператору заявления в простой письменной форме.
      </p>
      <p>Персональные данные субъекта подлежат хранению в течение сроков, установленных законодательством Российской
        Федерации. Персональные данные уничтожаются: по достижению целей обработки персональных данных; при ликвидации
        или
        реорганизации Оператора; на основании письменного обращения субъекта персональных данных с требованием о
        прекращении обработки его персональных данных (Оператор прекратит обработку таких персональных данных в течение
        3
        (трех) рабочих дней, о чем будет направлено уведомление субъекту персональных данных в течение 10 (десяти)
        рабочих
        дней).
      </p>
      <p>Термины и понятия, используемые в настоящем Согласии, применяются и трактуются в соответствии с положениями
        Пользовательского соглашения и Политики конфиденциальности Сайта.
      </p>
    </main>
  </div>
</template>

<script>
import AuthorizationHeader from "../../components/authorization/AuthorizationHeader.vue";

export default {
  components: { AuthorizationHeader, },
  name: 'PersonalInformation',
  beforeMount() {
    scroll(0, 0);
  }

}
</script>

<style scoped>
.background {
  background: url(../../assets/img/registration-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 30px;
  background-position: center;
}

main {
  padding: 80px 10px 30px 10px;
}

h1 {
  text-align: center;
  font-size: 28px;
  font-family: "Muller", sans-serif;
  max-width: 693px;
  margin: 0 auto;
  font-weight: 600;
  padding-bottom: 10px;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Muller", sans-serif;
  text-align: center;
  margin-top: 20px;
}

p {
  margin-top: 10px;
  text-indent: 60px;
  text-align: justify;
  font-size: 18px;
  color: #000;
  line-height: normal;
}


li {
  text-indent: 60px;
  text-align: justify;
  font-size: 18px;
  color: #000;
  list-style-type: none;
  line-height: normal;
  margin: 0;
  padding: 0;
}

li::before {
  display: none;
}

a {
  text-decoration: underline;
}


@media (max-width: 600px) {
  .background {
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 53px;
    background-position: top;
  }

  .authorization {
    margin-top: 0;
  }

  main {
    padding: 40px 10px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
    margin-top: 16px;
  }

  p {
    margin-top: 10px;
    text-indent: 40px;
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }

}
</style>
  