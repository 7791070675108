import {
    fastAuthorization, fastLogin,
    fetchUser,
    login,
    register, updateUserData, uploadFiles,
} from "./serverInterationMethods";
import {errorHandler} from "@/lib/errorHandler";


// store with all authenticated user data
export const user = {
    state: {
        // authorization user token for api
        token: null,
        user: null,
        whiteHeader: false
    },
    mutations: {
        // setting token state
        setToken(state, data) {
            state.token = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        changeUserHeader(state, data) {
            state.whiteHeader = data;
        }
    },
    actions: {
        // gets user auth token from cookies if needed
        async loadUserToken({getters, commit}) {
            if (getters.token) return;
            let token = localStorage.getItem('token');
            if (token) {
                commit('setToken', token);
            }
        },
        // default user authorization method. Saves token in cookies if success
        async login({getters, commit}, userData) {
            if (getters.token) return;

            let {data, status} = await login(userData);
            if (status === 200) {
                localStorage.setItem('token', data.token);
                commit('setToken', data.token);
            }
            errorHandler({name: 'login', status});
        },
        // default new user register method
        async register({getters}, data) {
            if (getters.token) return;
            const {status} = await register(data);
            errorHandler({name: 'registration', status});
        },
        async fastAuthorization({getters}, phone) {
            if (getters.token) return;
            const {status} = await fastAuthorization({phone});
            errorHandler({name: 'login', status, meta: {code: true}});
        },
        async fastLogin({getters, commit}, code) {
            if (getters.token) return;
            const {status, data} = await fastLogin(code);
            if (status === 200) {
                localStorage.setItem('token', data.token);
                commit('setToken', data.token);
            }
            errorHandler({name: 'login', status});
        },
        async uploadFile(_, file) {
            let formData = await new FormData();
            await formData.append("file", file);
            const {data} = await uploadFiles(formData);
            return data.url;
        },
        async updateUserData({getters}, user) {
            const {status, data} = await updateUserData(getters.token, user);
            errorHandler({name: 'registration', status, meta: {data}});
            return status;
        },
        // default logout method. Clear auth token from Storage (cookies by default)
        logout({commit}) {
            localStorage.removeItem('token');
            commit('setToken', null);
        },
        async fetchUser({commit, getters}) {
            const {data} = await fetchUser(getters.token);
            commit('setUser', data.user);
        }
    },
    getters: {
        // get default user auth token
        token: (state) => state.token,
        user: state => state.user,
        whiteHeader: state => state.whiteHeader
    }
};