<template>
  <div class="body">
    <PersonalHeader>
    </PersonalHeader>
    <main class="main">
      <div class="caption flex al-cent">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">Название раздела</h1>
        </div>
        <PersoneInCaption v-if="false">
        </PersoneInCaption>
      </div>
      <div class="filling flex gap40">
        <div class="content mt-40 flex flex-dir-col gap40">
          <div class="questions-group flex flex-dir-col gap20">
            <div class="questions-element flex br-red br-rad-20">
                <div class="questions-element-filling">
                    <h4 class="questions-element-filling__title">Вопрос</h4>
                    <p class="questions-element-filling__text">Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat
                        sunt nostrud amet.</p>
                </div>
                <button class="questions-element__btn questions-element__btn-active"></button>
            </div>
            <div class="questions-element flex br-red br-rad-20">
                <div class="questions-element-filling">
                    <h4 class="questions-element-filling__title">Вопрос</h4>
                    <p class="questions-element-filling__text">Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat
                        sunt nostrud amet.</p>
                </div>
                <button class="questions-element__btn"></button>
            </div>
            <div class="questions-element flex br-red br-rad-20">
                <div class="questions-element-filling">
                    <h4 class="questions-element-filling__title">Вопрос</h4>
                    <p class="questions-element-filling__text">Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat
                        sunt nostrud amet.</p>
                </div>
                <button class="questions-element__btn"></button>
            </div>
            <div class="questions-element flex br-red br-rad-20">
                <div class="questions-element-filling">
                    <h4 class="questions-element-filling__title">Вопрос</h4>
                    <p class="questions-element-filling__text">Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat
                        sunt nostrud amet.</p>
                </div>
                <button class="questions-element__btn"></button>
            </div>
            <div class="questions-element flex br-red br-rad-20">
                <div class="questions-element-filling">
                    <h4 class="questions-element-filling__title">Вопрос</h4>
                    <p class="questions-element-filling__text">Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat
                        sunt nostrud amet.</p>
                </div>
                <button class="questions-element__btn"></button>
            </div>
            <div class="questions-element flex br-red br-rad-20">
                <div class="questions-element-filling">
                    <h4 class="questions-element-filling__title">Вопрос</h4>
                    <p class="questions-element-filling__text">Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat
                        sunt nostrud amet.</p>
                </div>
                <button class="questions-element__btn"></button>
            </div>
          </div>
          <button class="questions-more btn-big red br-red">Задать вопрос</button>
        </div>
        <div class="additionally flex flex-dir-col gap40">
          <div class="additionally-element my-course">
            <h5 class="my-course__title small-title">Рекомендуемые курсы:</h5>
            <div class="my-course-group p-20-10 block gap10 flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="recommendedCourse">
                <SeparateRecommendedCourse v-for="item in recommendedCourse" :key="item" :course="item">
                </SeparateRecommendedCourse>

              </div>
              <button class="slider-last-block tablet"><img src="../../assets/img/dashbord/filling/last-element.svg"
                                                            alt="" class="slider-block__img"></button>
              <button class="slider-next-block tablet"><img src="../../assets/img/dashbord/filling/next-element.svg"
                                                            alt="" class="slider-block__img"></button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto">Все рекомендации</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile>
    </PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SeparateRecommendedCourse from "../../components/courses/SeparateRecommendedCourse.vue";
import {mapGetters} from "vuex";


export default {
  computed: {
    ...mapGetters(['articles', 'currentTrend', 'recommendedCourse', 'myCourses'])
  },
  components: {PersonalHeader, PersonalProfile, SeparateRecommendedCourse, PersoneInCaption},
  name: "FAQPage",
  beforeMount() {
    scroll(0, 0);
    this.fetchArticles('all');
    this.$store.dispatch('fetchMyCourses', {page: 1, limit: 5});
    this.$store.dispatch('fetchRecommendedCourses');

  },
  methods: {
    fetchArticles(trend) {
      this.$store.dispatch('fetchArticles', trend);
    },
    goToCourses() {
      this.$router.push({name: 'myCoursesPage'});
    }
  }
}
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

/* Caption */

.caption {
  padding: 40px 5% 0px 0px;
}

.caption-greetings__title {
  font-family: "DelaGothicOne";
  font-weight: 500;
  font-size: 36px;
}

.caption-greetings__text {
  max-width: 520px;
  color: rgba(16, 12, 64, 0.6000000238418579);
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6;
}

/* filling */
.filling {
  margin-top: 40px;
  max-width: 100%;
  width: 100%;
}

.content {
  max-width: 100%;
  width: 100%;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/* questions */
.questions{
  padding-bottom:74px;
}
.questions-filling{
  max-width: 100%;
  width: 100%;
}
.questions-preference__element{
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.04)
}
.questions-group{
  max-width: 100%;
  width: 100%;
}
.questions-preference{
  max-width: 280px;
  width: 100%;
}
.questions-element{
  padding: 30px 20px;
  justify-content: space-between;
align-items: flex-start;
max-width: 100%;
gap: 20px;
}
.questions-element__btn{
background: url(../../assets/img/home/questions/arrow-not-active.svg);
width: 24px;
height: 24px;
background-size: contain;
display: flex;
flex-shrink: 0;
}
.questions-element__btn-active{
  background: url(../../assets/img/home/questions/arrow-active.svg);
}
.questions-element-filling__title{
  font-weight: 500;
font-size: 20px;
line-height: 1;
color: #100c40;
}
.questions-element-filling__text{
  max-width: 580px;
  margin-top: 30px;
  font-weight: 500;
font-size: 16px;
line-height: 1.8;
color: rgba(16, 12, 64, 0.6000000238418579);
}
.questions__more{
  margin-top: 30px;
}
.questions-more{
  max-width: 220px;
  margin: 0 auto;
}

@media (max-width: 1880px) {

  .additionally {
    max-width: 326px;
  }

  .gap40 {
    gap: 20px;
  }
}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 30px 0px;
    justify-content: space-between;
    gap: 10px;
  }

}

@media (max-width: 950px) {
  .body {
    flex-direction: column;
  } 

  /* caption */
  .caption{
    padding: 30px 0 0 0;
  }

  .caption-greetings{
    margin: 0 auto;
  }

  .profile {
    display: none;
  }

  .main {
    margin-left: 0;
    margin-right: 0;
    padding: 0 20px;
  }
  .filling {
    margin-top: 0;
    flex-direction: column;
  }

  .content {
    gap: 30px;
  }

  /* additionall */
  .additionally-element {
    text-align: center;
  }

  .additionally {
    margin-top: 40px;
    max-width: 100%;
  }

  .my-course-group {
    margin-top: 20px;
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
    gap: 10px;
    width: 100%;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  .content {
    margin-top: 30px;
  }

  .caption-greetings__title{
    font-size: 32px;
    text-align: center;
    font-weight: 400;
  }

  .questions-group{
    gap: 10px;
  }
  .questions-element{
    border-radius: 10px;
    padding: 16px 14px;
  }
  .questions-element-filling__title{
    font-size: 14px;
  }
  .questions-element__btn {
    width: 16px;
    height: 16px;
    background-size: cover;
  }
  .questions-element-filling__text{
    font-size: 12px;
    margin-top: 14px;
  }
  .questions-more{
    height: 44px;
    max-width: 180px;
    font-size: 12px;
  }

}
</style>