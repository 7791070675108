import {useToast} from "vue-toastification";
import router from "@/router";
import store from "@/store";

const toast = useToast();

const notification = {
    params: {
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
    }, successNotification(app, msg) {
        toast.success(msg, this.params);
    }, errorNotification(app, msg) {
        toast.error(msg, this.params);
    }, warningNotification(app, msg) {
        toast.warning(msg, this.params);
    }, infoNotification(app, msg) {
        toast.info(msg, this.params);
    }
};

export function errorHandler(event) {
    if (event.status === 401 || event.status === 403) {
        // notification.infoNotification(this, 'Требуется авторизация');
        store.dispatch('logout').then(function () {
            router.push({name: 'loginPage'});
        });

        return;

    }
    if (event.status === 500) {
        notification.errorNotification(this, 'Error');
        return;
    }

    switch (event.name) {
        case 'notAllFields':
            notification.warningNotification(this, 'Не все поля заполнены');
            break;

        case 'invalidEmail':
            notification.warningNotification(this, 'Некорректный email');
            break;


        case 'validation':
            if (event.meta.name === 'phone') {
                notification.infoNotification(this, 'Некорректный номер телефона');
            }
            if (event.meta.name === 'email') {
                notification.infoNotification(this, 'Некорректный email');
            }
            break;


        case 'registration':
            switch (event.status) {
                case 200:
                    // notification.successNotification(this, 'Успешно');
                    router.push({name: 'loginPage'});
                    break;
                case 402:
                    if (event.meta && event.meta.data) {
                        if (event.meta.data.statusCode === 'emailExist') {
                            notification.warningNotification(this, 'Пользователь с таким email уже существует');
                        }
                        if (event.meta.data.statusCode === 'phoneExist') {
                            notification.warningNotification(this, 'Пользователь с таким номером телефона уже существует');
                        }

                    } else {
                        notification.warningNotification(this, 'Пользователь с такой почтой или номером телефона уже существует');
                    }
                    break;
                case 300:
                    notification.warningNotification(this, 'Не все данные заполнены');
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;
        case 'login':
            switch (event.status) {
                case 200:
                    if (event.meta && event.meta.code) {
                        notification.infoNotification(this, 'Код отправлен');
                        router.push({name: 'codePage'});
                    } else {
                        // notification.successNotification(this, 'Успешно');
                        router.push({name: 'dashboardPage'});
                    }
                    break;
                case 402:
                    notification.warningNotification(this, 'Неверный логин/пароль');
                    break;
                case 405:
                    notification.warningNotification(this, 'Неверный код');
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;
        case 'lesson':
            if (event.status === 200) {
                // notification.successNotification(this, 'Урок успешно пройден');
            }
            break;
        case 'testing':
            if (event.status === 200) {
                notification.params.timeout = null;
                notification.infoNotification(this, 'Ваш результат: ' + event.data);
                router.push({
                    name: 'currentTestingPage', params: {testingId: store.getters.savedTesting.testingId}
                });
            }
            if (event.status === 400) {
                notification.warningNotification(this, 'Время прохождения тестирования окончено');
            }
            break;

        case 'checklist':
            if (event.status === 200 && event.post) {
                // notification.successNotification(this, 'Результаты успешно сохранены');
                store.dispatch('fetchUser').then(function () {
                    router.push({name: 'dashboardPage'});
                });
            }
            if (event.status === 300) {
                notification.infoNotification(this, 'Недостаточно ответов');
            }
            if (event.status === 400 && event.get) {
                notification.infoNotification(this, 'Чек-лист уже пройден');
                router.push({name: 'dashboardPage'});
            }
            break;

        case 'passing':
            if (event.status === 200) {
                notification.infoNotification(this, 'Вы прошли все уроки по данному курсу');
            } else {
                notification.errorNotification(this, 'Error');

            }
    }
}