<template>
  <div class="recommended-blog p-10">
    <h4 class="recommended-blog__title">{{ textSymbolLimit(article.title,30) }}</h4>
    <div class="recommended-blog-actions mt-20 flex">
      <router-link :to="{name:'currentArticle',params:{articleId:article.id}}"
                   class="recommended-blog__link btn-midle br-red red">Подробнее
      </router-link>
      <!-- <button class="recommended-blog__settings"></button> -->
    </div>
  </div>
</template>

<script>

import {textSymbolLimit} from "../../lib/textSymbolLimit"

export default {
  name: "RecommendedBlog",
  props: ['article'],
  data(){
    return{
      textSymbolLimit
    }
  },
}
</script>

<style scoped>
.recommended-blog {
  border: 1px solid #ff2031;
  border-radius: 10px;
  margin-bottom: 10px;
}

.recommended-blog__title {
  font-family: "DelaGothicOne";
  font-size: 16px;
  line-height: 1.2;
  text-align: left;
  text-transform: uppercase;
}

.recommended-blog-actions {
  align-items: center;
}

.recommended-blog__settings {
  width: 20px;
  height: 20px;
  background: url(../../assets/img/course/settings.svg);
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 15px;
}


@media (max-width: 950px) {
  .recommended-blog {
    display: flex;
    flex-shrink: 0;
    max-width: 275px;
    width: 100%;
    height: 122px;
    flex-direction: column;
    margin-bottom: 0px;
    margin-right: 10px;
  }

  .recommended-blog-actions {
    margin-top: auto;
  }

  .recommended-blog__link {
    font-size: 12px;
  }

  .recommended-blog__settings {
    margin-left: auto;
  }
}
</style>