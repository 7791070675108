<template>
  <div class="body">
    <PersonalHeader> </PersonalHeader>
    <main class="main">
      <div class="caption flex al-cent">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">Мои курсы</h1>
        </div>
        <PersoneInCaption> </PersoneInCaption>
      </div>
      <SearchElement
        type="my-courses"
        :view-results="viewResults"
        :findResults="findResults"
        @closeViewResults="viewResults = false"
        @viewResults="viewResults = true"
      >
      </SearchElement>
      <div class="description mt-40" v-if="false">
        <div class="description-nav">
          <button class="description-nav-selected">Все</button>
          <div class="description-nav-filling">
            <button
              class="description-nav__element"
              @click="changeMode('all')"
              :class="{ 'description-nav__element-chozen': mode === 'all' }"
            >
              Все
            </button>
            <button
              class="description-nav__element"
              @click="changeMode('heart')"
              :class="{ 'description-nav__element-chozen': mode === 'heart' }"
            >
              Сердце
            </button>
            <button
              class="description-nav__element"
              @click="changeMode('brain')"
              :class="{ 'description-nav__element-chozen': mode === 'brain' }"
            >
              Мозг
            </button>
            <button
              class="description-nav__element"
              @click="changeMode('lungs')"
              :class="{ 'description-nav__element-chozen': mode === 'lungs' }"
            >
              Легкие
            </button>
            <button
              class="description-nav__element"
              @click="changeMode('head')"
              :class="{ 'description-nav__element-chozen': mode === 'head' }"
            >
              Голова
            </button>
          </div>
        </div>
      </div>
      <div class="tags flex mt-60" v-if="false">
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
      </div>
      <div class="filling mt-30">
        <ContentWrapper
          v-if="viewResults && findResults && findResults.length"
          :findResults="findResults"
        >
        </ContentWrapper>
        <div class="filling-element" v-else>
          <div class="content-element-caption flex al-cent just-space-betwin">
            <h5 class="content-element-caption__title">Все мои курсы:</h5>
            <a href="" class="content-element-caption__more" v-if="false"
              >Вид</a
            >
          </div>
          <div class="content block mt-20">
            <div class="content-filling">
              <div
                class="content-filling-element"
                v-for="item in myCourses"
                :key="item.id"
              >
                <SeparateSmallCourse :course="item" :my-courses-page="true">
                </SeparateSmallCourse>
              </div>
            </div>

            <button
              class="content-more btn-midle br-red m0-auto mt-30 red"
              v-if="pagination"
              @click="loadCourses"
            >
              Еще
            </button>
          </div>
        </div>

        <div class="additionally flex flex-dir-col" v-if="false">
          <div class="additionally-element my-course">
            <h5 class="my-course__title small-title">Рекомендуемые курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="recommendedCourse">
                <SeparateRecommendedCourse
                  v-for="item in recommendedCourse"
                  :key="item.id"
                  :course="item"
                >
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/last-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button class="slider-next-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/next-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button
                class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
                @click="$router.push({ name: 'allCoursesPage' })"
              >
                Все мои курсы
              </button>
            </div>
          </div>
          <div class="additionally-element my-course" v-if="false">
            <h5 class="my-course__title small-title">Тестирования:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="myTesting">
                <SeparateRecommendedTest
                  v-for="item in myTesting"
                  :key="item.id"
                  :testing="item"
                >
                </SeparateRecommendedTest>
              </div>
              <button class="slider-last-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/last-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button class="slider-next-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/next-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button
                class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
              >
                Все мои курсы
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile> </PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SeparateSmallCourse from "../../components/slider/SeparateSmallCourse.vue";
import SeparateRecommendedCourse from "../../components/courses/SeparateRecommendedCourse.vue";
import SeparateRecommendedTest from "../../components/tests/SeparateRecommendedTest.vue";
import SearchElement from "../../components/SearchElement.vue";
import ContentWrapper from "../../components/content/ContentWrapper.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PersonalHeader,
    PersonalProfile,
    SeparateSmallCourse,
    SeparateRecommendedCourse,
    SeparateRecommendedTest,
    PersoneInCaption,
    SearchElement,
    ContentWrapper,
  },
  name: "MyCourses",
  computed: {
    ...mapGetters([
      "recommendedCourse",
      "myCourses",
      "myTesting",
      "findResults",
    ]),
  },
  beforeMount() {
    scroll(0, 0);
    this.changeMode("all");
    this.$store.dispatch("fetchMyTesting");
    this.$store.dispatch("fetchRecommendedCourses");
  },
  data() {
    return {
      mode: "all",
      page: 1,
      limit: 20,
      pagination: true,
      name: null,

      viewResults: false,
    };
  },
  methods: {
    changeName(name = "") {
      this.name = name;
      this.page = 1;
      this.pagination = true;
      this.loadCourses();
    },
    loadMoreCourses() {
      this.page++;
      this.loadCourses();
    },
    changeMode(mode = "all") {
      this.mode = mode;
      this.page = 1;
      this.pagination = true;
      this.loadCourses();
    },
    loadCourses() {
      this.$store
        .dispatch("fetchMyCourses", {
          page: this.page,
          limit: this.limit,
          trend: this.mode,
          name: this.name,
        })
        .then((data) => {
          if (!data || data.length < this.limit) {
            this.pagination = false;
          }
        });
    },
  },
};
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

.content-element-caption__title {
  font-family: "Muller";
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
}

.content-element-caption__more {
  font-size: 14px;
  line-height: 1;
  position: relative;
  padding-right: 20px;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.content-element-caption__more::after {
  position: absolute;
  background: url(../../assets/img/list.svg);
  content: "";
  display: block;
  right: 0;
  top: -2px;
  width: 16px;
  height: 16px;
}

/* Caption */

.caption {
  padding: 40px 5% 0px 0px;
}

.caption-greetings__title {
  font-family: "DelaGothicOne";
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
}

.filling-element {
  max-width: 100%;
  width: 100%;
}

.content-element {
  max-width: 100%;
  width: 100%;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/* description */
.description-nav {
  position: relative;
}

.description-nav-selected {
  display: none;
  position: relative;
}

.description-nav-selected::after {
  background: url(../../assets/img/list.svg);
  content: "";
  right: 20px;
  top: calc(50% - 6px);
  width: 16px;
  height: 16px;
  display: block;
}

.description-nav-filling {
  border: 1px solid rgba(16, 12, 64, 0.4);
  display: inline-flex;
  border-radius: 10px;
}

.description-nav__element {
  padding: 20px 30px;
  color: rgba(16, 12, 64, 0.4000000059604645);
  font-size: 14px;
  border-radius: 10px;
}

.description-nav__element-chozen {
  background: #ff2031;
  color: #fff;
}

/* tags */
.tags {
  flex-wrap: wrap;
}

/*content*/
.content {
  padding: 20px 0px 0px 0px;
  width: 100%;
}

.content-filling {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.content-filling-element {
  margin-bottom: 20px;
  padding: 0 10px;
  width: 100%;
}

.separate-curse {
  max-width: 100%;
}

.content-more {
  max-width: 119px;
}

/* additionally */
.additionall-about {
  padding: 30px 20px;
}

.additionall-about-title {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.additionall-about-text {
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-btn {
  margin-top: 58px;
}

.additionall-about-time {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-time__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/time.svg);
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/*my cours*/
.my-course__more {
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}

.filling-element {
  margin-right: 40px;
}

.additionally-element {
  margin-bottom: 40px;
}

@media (max-width: 3100px) {
  .content-filling {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (max-width: 2500px) {
  .content-filling {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 2150px) {
  .content-filling {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1880px) {
  .carousel-title {
    max-width: 398px;
  }

  .additionally {
    max-width: 326px;
  }

  .content-filling {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1600px) {
  .content-filling {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 30px 0px;
    justify-content: space-between;
  }

  .caption-greetings__title {
    font-size: 24px;
  }

  .caption-greetings__text {
    font-size: 14px;
    margin-top: 8px;
  }

  .surch {
    margin-left: 0;
  }

  .surch__input {
    font-size: 14px;
    max-width: 265px;
    width: 100%;
    display: flex;
    flex-shrink: 1;
  }

  /* carousel */
  .carousel {
    padding: 20px 20px 50px 20px;
    position: relative;
  }

  .carousel-title {
    font-size: 48px;
  }

  .carousel-text {
    font-size: 14px;
    max-width: 213px;
    line-height: 1.4;
  }

  .carousel-button {
    margin-top: 14px;
    max-width: 223px;
    height: 44px;
    font-size: 12px;
  }

  .carousel-nav {
    left: calc(50% - 70px);
  }

  .carousel-img {
    right: 0px;
    bottom: 0px;
    background: url(../../assets/img/dashbord/filling/man.svg);
    background-size: cover;
    width: 274px;
    height: 260px;
  }

  /* additionally */
  .additionall-about {
    padding: 20px 10px 26px 10px;
  }

  .additionall-about-title {
    font-size: 20px;
  }

  .additionall-about-text {
    margin-top: 10px;
    font-size: 14px;
  }

  .additionall-about-btn {
    margin-top: 72px;
    max-width: 195px;
    font-size: 12px;
  }

  .additionall-about-time {
    margin-top: 20px;
  }
}

@media (max-width: 950px) {
  .body {
    flex-direction: column;
  }

  .filling {
    flex-direction: column;
  }

  .caption {
    padding: 40px 0 0 0;
  }

  .caption-greetings {
    margin: 0 auto;
  }

  .caption-greetings__title {
    font-size: 32px;
  }

  .main {
    margin-left: 0;
    margin-right: 0;
    padding: 0 20px;
  }

  .profile {
    display: none;
  }

  /* additionall */
  .my-course-group {
    overflow-x: scroll;
  }

  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    margin-top: 20px;
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .separate-curse {
    max-width: 280px;
  }

  .filling-element {
    margin-bottom: 40px;
    margin-right: 0;
  }

  .content-filling {
    grid-template-columns: repeat(2, 1fr);
  }

  .slider-filling-element {
    margin: 0 auto 20px auto;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  .carousel {
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 20px 20px 45px 20px;
  }

  .carousel * {
    margin: 0 auto;
  }

  .carousel-title {
    max-width: 267px;
    font-size: 32px;
  }

  .carousel-text {
    margin-top: 10px;
    max-width: 267px;
    font-size: 12px;
  }

  .carousel-button {
    margin-top: 199px;
    max-width: 223px;
  }

  .carousel-img {
    width: 298px;
    height: 282px;
    right: calc(50% - 149px);
  }

  .content {
    padding: 0 0 00px 0;
  }

  /*category*/
  .category-element {
    padding: 0;
    margin-top: 20px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /* description */
  .description {
    margin-top: 20px;
  }

  .description-nav {
    position: relative;
  }

  .description-nav-selected {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    background: #ffffff;
    border: 1px solid rgba(16, 12, 64, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 40px 140px rgba(0, 0, 0, 0.06);
  }

  .description-nav-selected__text {
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    color: rgba(16, 12, 64, 0.699999988079071);
  }

  .description-nav-filling {
    display: none;
    background: #fff;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    position: absolute;
    z-index: 2;
  }

  .description-nav__element {
    width: 100%;
    text-align: left;
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    color: rgba(16, 12, 64, 0.699999988079071);
  }

  .description-nav__element-chozen {
    background: #fff;
    color: rgba(16, 12, 64, 0.699999988079071);
  }

  .description-nav__element:not(:last-child) {
    border-bottom: 1px solid rgba(16, 12, 64, 0.4);
    border-radius: 0 0 0 0;
  }

  .description-nav__element:first-child {
    border-radius: 10px 10px 0 0;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }

  .tags {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: 50px;
  }

  .content-filling {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 20px 0 0 0;
  }
}
</style>
