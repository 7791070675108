<template>
  <div class="library-wrapper">
    <LibraryWrapperElement
      v-for="item in articles"
      :article="item"
      :key="item.id"
    >
    </LibraryWrapperElement>
  </div>
</template>

<script>
import LibraryWrapperElement from "./LibraryWrapperElement.vue";

export default {
  components: { LibraryWrapperElement },
  props: ["articles"],
  name: "LibraryWrapper",
};
</script>

<style scoped>
.library-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.library-wrapper :nth-child(2n + 1) {
  margin-right: 20px;
}

@media (max-width: 1100px) {
  .library-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .library-wrapper :nth-child(2n + 1) {
    margin-right: 0px;
  }
  .library-element-wrapper {
    padding: 0;
  }
}
@media (max-width: 600px) {
  .library-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .library-wrapper :nth-child(2n + 1) {
    margin-right: 0px;
  }
}
</style>
