import {defaultHttpResHandler} from "@/store/helpers/defaultHttpResHandler";
import {makeGetReq, makePostReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";

export const fetchTesting = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/testing/' + id, {token}));

export const savePassedTesting = async (token, testing) => await defaultHttpResHandler(async () => makePostReq(appConfig.domain + '/testing/passing', testing, {
        token,
        data: true
    })
);

export const fetchChecklist = async (token) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/checklist', {token}));

export const savePassedChecklist = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/checklist', data, {
    token,
    data: true
}));

export const fetchMyTesting = async (token) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/testings/user', {token}));