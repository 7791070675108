<template>
  <div class="background">
    <AuthorizationHeader>
    </AuthorizationHeader>

    <main class="container">
      <h1>
        Политика конфиденциальности
        в отношении обработки персональных данных
        на веб-сайте «Послевыписки.рф»
      </h1>
      <h2>1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
      <p>Настоящая Политика конфиденциальности в отношении обработки персональных данных (далее по тексту – «Политика»)
        составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных»
        (далее - Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению
        безопасности персональных данных, предпринимаемые Автономной некоммерческой организацией «ЦЕНТР РЕАЛИЗАЦИИ
        ПРОГРАММ В СФЕРЕ ЗДРАВООХРАНЕНИЯ «ВРАЧИ И ПАЦИЕНТЫ ЗА КАЧЕСТВЕННУЮ И ДОСТУПНУЮ МЕДИЦИНСКУЮ ПОМОЩЬ» (ИНН
        9721046167, ОГРН 1177700006230, адрес: 109439, ГОРОД МОСКВА, ЕСЕНИНСКИЙ БУЛЬВАР, ДОМ 9, КОРПУС 3, КВАРТИРА 17)
        (далее по тексту – «Оператор»).
      </p>
      <p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод
        человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность
        частной
        жизни, личную и семейную тайну.</p>
      <p>1.2. Настоящая Политика Оператора применяется ко всей информации, которую Оператор может получить о
        пользователях
        веб-сайта «Послевыписки.рф», размещенного по адресу: <a href="https://xn--b1afkajcuheqf1j.xn--p1ai/"
          target="_blank" class="red">https://xn--b1afkajcuheqf1j.xn--p1ai/</a>.
      </p>
      <h2>2. ОСНОВНЫЕ ПОНЯТИЯ, ИСПОЛЬЗУЕМЫЕ В ПОЛИТИКЕ.</h2>
      <p>2.1. Сайт – веб-сайт «Послевыписки.рф», который представляет собой совокупность программных и аппаратных
        средств
        для
        различного рода Устройств, автоматизированную информационную систему, доступную в сети Интернет по сетевому
        адресу
        <a target="_blank" class="red">https://xn--b1afkajcuheqf1j.xn--p1ai/</a>, предоставляющий Пользователю
        справочно-рекомендательную информацию по
        реабилитации осложнений после COVID-19 на безвозмездной основе;
      </p>
      <p>2.2. Пользовательское соглашение – соглашение, устанавливающее правила, условия, порядок пользования
        Сайтом;</p>
      <p>2.3. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому
        Пользователю Сайта;</p>
      <p>2.4. Пользователь (субъект персональных данных) – дееспособное физическое лицо, действующее лично (без участия
        представителя), чьи данные указаны в личном кабинете на Сайте «Послевыписки.рф», намеренное использовать или
        использующее Сайт «Послевыписки.рф»;</p>
      <p>2.5. Персональные данные, разрешенные субъектом персональных данных для распространения - персональные данные,
        доступ
        неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку
        персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном
        настоящим Федеральным законом;</p>
      <p>2.6. Оператор - Автономная некоммерческая организация «ЦЕНТР РЕАЛИЗАЦИИ ПРОГРАММ В СФЕРЕ ЗДРАВООХРАНЕНИЯ «ВРАЧИ
        И
        ПАЦИЕНТЫ ЗА КАЧЕСТВЕННУЮ И ДОСТУПНУЮ МЕДИЦИНСКУЮ ПОМОЩЬ» (ИНН 9721046167, ОГРН 1177700006230, адрес: 109439,
        ГОРОД
        МОСКВА, ЕСЕНИНСКИЙ БУЛЬВАР, ДОМ 9, КОРПУС 3, КВАРТИРА 17), самостоятельно или совместно с другими лицами
        организующая и (или) осуществляющая обработку персональных данных, а также определяющая цели обработки
        персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с
        персональными данными;</p>
      <p>2.7. Обработка персональных данных - любое действие (операция) или совокупность действий (операций),
        совершаемых
        с
        использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор,
        запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
        передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
        персональных данных;
      </p>
      <p>2.8. Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств
        вычислительной
        техники;
      </p>
      <p>2.9. Распространение персональных данных - действия, направленные на раскрытие персональных данных
        неопределенному
        кругу лиц;
      </p>
      <p>2.10. Предоставление персональных данных - действия, направленные на раскрытие персональных данных
        определенному
        лицу
        или определенному кругу лиц;
      </p>
      <p>2.11. Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением
        случаев,
        если обработка необходима для уточнения персональных данных);
      </p>
      <p>2.12. Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить
        содержание
        персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются
        материальные носители персональных данных;
      </p>
      <p>2.13. Обезличивание персональных данных - действия, в результате которых становится невозможным без
        использования
        дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных
        данных;
      </p>
      <p>2.14. Информационная система персональных данных - совокупность содержащихся в базах данных персональных данных
        и
        обеспечивающих их обработку информационных технологий и технических средств;
      </p>
      <p>2.15. Трансграничная передача персональных данных - передача персональных данных на территорию иностранного
        государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому
        лицу.</p>
      <h2>3. ОСНОВНЫЕ ПРАВА И ОБЯЗАННОСТИ ОПЕРАТОРА</h2>
      <p>3.1.Оператор имеет право:
      </p>
      <ul>
        <li>- получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные
          данные;
        </li>
        <li>- в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также,
          направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить
          обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в
          Законе о персональных данных;
        </li>
        <li>- самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения
          обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными
          правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами;
        </li>
        <li>- осуществлять иные права, предусмотренные Законом о персональных данных.
        </li>
      </ul>
      <p>3.2. Оператор обязан:</p>
      <ul>
        <li>- предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его
          персональных
          данных;
        </li>
        <li>- организовывать обработку персональных данных в порядке, установленном действующим законодательством
          Российской Федерации;
        </li>
        <li>- отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии
          с
          требованиями Закона о персональных данных;
        </li>
        <li>- сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа
          необходимую информацию в сроки, установленные действующим законодательством Российской Федерации;
        </li>
        <li>- публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки
          персональных данных;
        </li>
        <li>- принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного
          или
          случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения
          персональных данных, а также от иных неправомерных действий в отношении персональных данных;
        </li>
        <li>- прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и
          уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;
        </li>
        <li>- исполнять иные обязанности, предусмотренные Законом о персональных данных.</li>
      </ul>
      <h2>4. Основные права и обязанности субъекта персональных данных
      </h2>
      <p>4.1. Субъект персональных данных имеют право:
      </p>
      <ul>
        <li>- получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных
          федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в
          них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за
          исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень
          информации и порядок ее получения установлен Законом о персональных данных;
        </li>
        <li>- требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если
          персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются
          необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих
          прав;
        </li>
        <li>- выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке
          товаров, работ и услуг;
        </li>
        <li>- на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении
          обработки персональных данных;
        </li>
        <li>- обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке
          неправомерные действия или бездействие Оператора при обработке его персональных данных;
        </li>
        <li>- на осуществление иных прав, предусмотренных законодательством Российской Федерации.</li>
      </ul>
      <p>4.2. Субъект персональных данных обязан:
      </p>
      <ul>
        <li>- предоставлять Оператору достоверные данные о себе;</li>
        <li>- сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.</li>
      </ul>
      <p>4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных
        данных без согласия последнего, несут ответственность в соответствии с законодательством Российской Федерации.
      </p>
      <h2>5. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </h2>
      <p>5.1. Обработка персональных данных осуществляется на законной и справедливой основе.</p>
      <p>5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных
        целей.
        Не
        допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
      </p>
      <p>5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в
        целях,
        несовместимых между собой.</p>
      <p>5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
      <p>5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не
        допускается
        избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.
      </p>
      <p>5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в
        необходимых
        случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры
        и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.
      </p>
      <p>5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных,
        не
        дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не
        установлен
        федеральным законом, договором, выгодоприобретателем или поручителем по которому является субъект персональных
        данных. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в
        случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
      </p>
      <h2>6. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </h2>
      <p>
        6.1 Цели обработки персональных данных Пользователя:
      </p>
      <p>6.1.1 Информирование Пользователя посредством отправки электронных писем;
      </p>
      <p>6.1.2 Предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на Сайте.
      </p>
      <p>6.2. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору
        соответствующее
        письмо в порядке, предусмотренном Пользовательским соглашением.</p>
      <p>6.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики (в случае их наличия
        на
        Сайте), служат для сбора информации о действиях Пользователей на Сайте, улучшения качества Сайта и его
        содержания.
      </p>
      <h2>7. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p>7.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его
        персональных данных и необходима для:
      </p>
      <ul>
        <li>- достижения целей, предусмотренных международным договором Российской Федерации или законом, для
          осуществления возложенных законодательством Российской Федерации на оператора функций, полномочий и
          обязанностей.
        </li>
        <li>- осуществления правосудия, исполнения судебных актов, актов других органов и/или должностных лиц,
          подлежащих
          исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;
        </li>
        <li>- для осуществления прав и законных интересов Оператора или третьих лиц либо для достижения общественно
          значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.
        </li>
      </ul>
      <p>7.2. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен
        субъектом
        персональных данных либо по его просьбе (далее по тексту – «общедоступные персональные данные»).</p>
      <p>7.3. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в
        соответствии
        с законодательством Российской Федерации.</p>
      <h2>8. ПОРЯДОК СБОРА, ХРАНЕНИЯ, ПЕРЕДАЧИ И ДРУГИХ ВИДОВ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p>8.1. Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации
        правовых,
        организационных и технических мер, необходимых для выполнения в полном объеме требований действующего
        законодательства в области защиты персональных данных.</p>
      <p>8.2. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к
        персональным данным неуполномоченных лиц.
      </p>
      <p>8.3. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за
        исключением
        случаев, связанных с исполнением действующего законодательства Российской Федерации либо в случае, если
        субъектом
        персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по
        гражданско-правовому договору.</p>
      <p>8.4. В случае выявления неточностей в персональных данных Пользователь может актуализировать их самостоятельно,
        путем
        направления Оператору уведомления в порядке, определенном Пользовательским соглашением к Сайту, с пометкой
        «Актуализация персональных данных».</p>
      <p>8.5. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные
        данные,
        если иной срок не предусмотрен соглашением Оператора и Пользователя или действующим законодательством.
        Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору
        уведомление в порядке, определенном Пользовательским соглашением к Сайту.</p>
      <p>8.6. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами
        связи
        и
        другими поставщиками услуг, хранится и обрабатывается указанными лицами (операторами) в соответствии с их
        Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных данных обязан ознакомиться с
        указанными документами. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в
        настоящем пункте поставщиков услуг. Настоящий пункт применяется в случае, если предоставление соответствующих
        услуг предусмотрено функциональными возможностями Сайта.</p>
      <p>8.7. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на
        обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения,
        не
        действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах,
        определенных законодательством Российской Федерации.</p>
      <p>8.8. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.</p>
      <p>8.9. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных
        данных,
        не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не
        установлен федеральным законом, соглашением, выгодоприобретателем или поручителем по которому является субъект
        персональных данных.</p>
      <p>8.10. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных
        данных,
        истечение срока действия согласия субъекта персональных данных, отзыв согласия субъектом персональных данных или
        требование о прекращении обработки персональных данных, а также выявление неправомерной обработки персональных
        данных.
      </p>
      <h2>9. ПЕРЕЧЕНЬ ДЕЙСТВИЙ, ПРОИЗВОДИМЫХ ОПЕРАТОРОМ С ПОЛУЧЕННЫМИ ПЕРСОНАЛЬНЫМИ ДАННЫМИ</h2>
      <p>9.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
        изменение),
        извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление и уничтожение персональных данных.</p>
      <p>9.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей
        полученной
        информации по информационно-телекоммуникационным сетям или без таковой.</p>
      <h2>10. ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p>10.1. Оператор до начала осуществления деятельности по трансграничной передаче персональных данных обязан
        уведомить
        уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную
        передачу персональных данных (такое уведомление направляется отдельно от уведомления о намерении осуществлять
        обработку персональных данных).</p>
      <p>10.2. Оператор до подачи вышеуказанного уведомления, обязан получить от органов власти иностранного
        государства,
        иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная передача
        персональных
        данных, соответствующие сведения.</p>
      <h2>11. КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p>11.1. Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не
        распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено
        федеральным законом.</p>
      <h2>12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
      <p>12.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его
        персональных
        данных, обратившись к Оператору в порядке, предусмотренному Пользовательским соглашением к Сайту.
      </p>
      <p>12.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором.
        Политика
        действует бессрочно до замены ее новой версией.
      </p>
      <p>12.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу Сайта.</p>
      <p>10.4. Настоящая Политика является неотъемлемой частью Пользовательского соглашения. </p>
    </main>
  </div>
</template>

<script>
import AuthorizationHeader from "../../components/authorization/AuthorizationHeader.vue";

export default {
  components: { AuthorizationHeader, },
  name: 'PrivacyPolicy',
  beforeMount() {
    scroll(0, 0);
  }

}
</script>

<style scoped>
.background {
  background: url(../../assets/img/registration-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 30px;
  background-position: center;
}

main {
  padding: 80px 10px 30px 10px;
}

h1 {
  text-align: center;
  font-size: 28px;
  font-family: "Muller", sans-serif;
  max-width: 693px;
  margin: 0 auto;
  font-weight: 600;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Muller", sans-serif;
  text-align: center;
  margin-top: 20px;
}

p {
  margin-top: 10px;
  text-indent: 60px;
  text-align: justify;
  font-size: 18px;
  color: #000;
  line-height: normal;
}


li {
  text-indent: 60px;
  text-align: justify;
  font-size: 18px;
  color: #000;
  list-style-type: none;
  line-height: normal;
  margin: 0;
  padding: 0;
}

li::before {
  display: none;
}

a {
  text-decoration: underline;
}


@media (max-width: 600px) {
  .background {
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 53px;
    background-position: top;
  }

  .authorization {
    margin-top: 0;
  }

  main {
    padding: 40px 10px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
    margin-top: 16px;
  }

  p {
    margin-top: 10px;
    text-indent: 40px;
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }

}
</style>
  