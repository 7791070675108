<template>
  <div class="library-element-wrapper">
    <div class="library-element">
      <div class="library-tags flex tags">
        <div
          class="category-tag-wrapper"
          v-for="item in article.tags"
          :key="item.id"
        >
          <button class="tag">#{{ item.name }}</button>
        </div>
      </div>
      <h3 class="library-title mt-10">{{ article.title }}</h3>
      <div class="library-about mt-20 flex">
        <router-link
          :to="{ name: 'currentArticle', params: { articleId: article.id } }"
          class="library-about__link btn-midle br-red red"
          >Подробнее
        </router-link>
        <!-- <button class="library-about__settings"></button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { textSymbolLimit } from "../../lib/textSymbolLimit";

export default {
  name: "LibraryWrapperElement",
  props: ["article"],
  data() {
    return {
      textSymbolLimit,
    };
  },
};
</script>

<style scoped>
.library-element {
  padding: 20px;
  max-width: 100%;
  width: 100%;
  min-height: 212px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ff2031;
  background: #fff;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  height: calc(100% - 20px);
}
.library-element-wrapper {
  padding: 0 10px;
}

.library-tags {
  margin-top: auto;
  flex-wrap: wrap;
  max-width: 100%;
}

.library-title {
  font-family: "DelaGothicOne";
  font-size: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  margin-left: 0 !important;
}

.library-about {
  align-items: center;
}

.library-about__settings {
  width: 20px;
  height: 20px;
  background: url(../../assets/img/course/settings.svg);
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 20px;
}
.category-tag-wrapper {
  padding: 5px 10px 5px 0;
  margin-right: 0 !important;
}
.tag {
  margin-right: 0 !important;
}
.library-about__link {
  margin-right: 0 !important;
}
@media (max-width: 600px) {
  .library-element {
    text-align: center;
    align-items: center;
    max-width: 100%;
  }
  .library-title {
    font-size: 18px;
  }

  .library-tags {
    justify-content: center;
  }

  .library-about {
    max-width: 100%;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  .library-about__link {
    font-size: 12px;
    max-width: 220px;
  }

  .library-about__settings {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .category-tag-wrapper {
    padding: 4px 4px 4px 4px;
  }
}
</style>
