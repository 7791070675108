import store from '@/store';


// check if user has token - authorized
export const canEnterToUserPortal = async (callback) => {
    await store.dispatch('loadUserToken');
    await store.dispatch('fetchUser');
    callback(store.getters.user);
}

export const canEnterToRegistration = async (callback) => {
    await store.dispatch('loadUserToken');
    callback(store.getters.token);
}

export const canEnterToUserCabinet = async (callback) => {
    await store.dispatch('loadUserToken');
    await store.dispatch('fetchUser');
    callback(store.getters.user.userParams.passedChecklist);

}