// makes post request to server
export async function makePostReq(url, data, params) {
    const requestOptions = {
        method: "POST",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }

    const response = await fetch(url, requestOptions);
    return {data: await response.json(), status: response.status};
}

export async function makeGetReq(url, params) {
    const requestOptions = {
        method: "GET"
    };
    if (params) {
        requestOptions['headers'] = {};
    }
    if (params.token) {
        requestOptions.headers['X-Auth-Token'] = params.token;
    }

    const response = await fetch(url, requestOptions);
    return {data: await response.json(), status: response.status};
}

