import {makeGetReq, makePostReq} from "../helpers/request";
import {defaultHttpResHandler} from "../helpers/defaultHttpResHandler";
import {appConfig} from "@/lib/appConfig";


// http request for login into system
export const login = async (data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(appConfig.domain + '/user/login', data, {token: null, data: true})
    });
    //TODO make request
    //     console.log(data);
    //     return {
    //         status: 200,
    //         data: {
    //             token: 'test'
    //         }
    //     }
    // })
};

// http request for default register process
export const register = async (data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(appConfig.domain + '/user/registration', data, {token: null, data: true});
    });
};

export const fastAuthorization = async (data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(appConfig.domain + '/user/code', data, {token: null, data: true});
    });
};

export const fastLogin = async (data) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(appConfig.domain + '/user/code/' + data, {token: null});
    });
};

export const fetchUser = async (token) => await defaultHttpResHandler(async () => {
    return await makeGetReq(appConfig.domain + '/user', {token})
    //TODO make request
    // return {
    //     status: 200, data: {
    //         user: {
    //             firstName: 'string',
    //             secondName: 'string',
    //             thirdName: 'string',
    //             avatarUrl: null,
    //             birthDay: 11,
    //             birthMonth: 12,
    //             birthYear: 2000,
    //             city: null,
    //             userParams:
    //                 {
    //                     id: 'rset',
    //                     passedChecklist: 'string',
    //                     passingRecommendCourse:
    //                         {
    //                             head: 0,
    //                             lungs: 0,
    //                             heart: 0,
    //                             brain: 0
    //                         }
    //                 }
    //
    //         }
    //     }
    // }
});


export const uploadFiles = async (file) => await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/files/upload', file, {data: false}))

export const updateUserData = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/user', data, {
    token,
    data: true
}))
