<template>
  <div class="body">
    <PersonalHeader>
    </PersonalHeader>
    <main class="main" v-if="currentTesting">
      <div class="caption flex al-cent">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">Тестирование по «{{ currentTesting.title }}»</h1>
          <div class="caption-greetings-info">
            <p class="caption-greetings-info__author">Время прохождения: {{
                !currentTesting.passingTimeSeconds || currentTesting.passingTimeSeconds === 0 ? 'Неограничено' : convert(currentTesting.passingTimeSeconds) + ' минут'
              }}</p>
            <p class="caption-greetings-info__author share">Количество вопросов: {{
                currentTesting.questions.length
              }}</p>
          </div>
        </div>
        <PersoneInCaption>
        </PersoneInCaption>
      </div>
      <div class="filling mt-40 flex gap40">
        <div class="content mt-40 flex flex-dir-col gap40">
          <div class="content-element interview block br-rad-10">
            <div class="interview-line">
              <div class="interview-line__filling" style="width: 100%;"></div>
            </div>
            <div class="interview-filling">
              <div class="interview-caption al-cent flex just-space-betwin">
                <p class="interview-caption__number">Вопрос {{ iter + 1 }} из {{ currentTesting.questions.length }}</p>
                <p class="interview-caption__time"
                   v-if="currentTesting.passingTimeSeconds && currentTesting.passingTimeSeconds !== 0">
                  {{ convert(currentTesting.passingTimeSeconds - passedTimeSeconds) }}</p>
              </div>
              <p class="inquestionw-question mt-20">{{
                  currentTesting.questions[iter].body
                }}</p>
              <div class="inquestionw-answers">
                <SeparateAnswer :answers="currentTesting.questions[iter].answers"
                                :question-type="currentTesting.questions[iter].questionType"
                                :question-id="currentTesting.questions[iter].id"
                                :saved-testing="savedTesting"
                >
                </SeparateAnswer>
              </div>
              <div class="lesson-nav flex just-space">
                <button class="lesson-nav__last" @click="prevQuestion"><span class="lesson-nav__last-arrow"></span>Предыдущий
                  вопрос
                </button>
                <p class="lesson-nav__number">{{ iter + 1 }}/{{ currentTesting.questions.length }}</p>
                <button class="lesson-nav__next" @click="nextQuestion">
                  {{ iter === currentTesting.questions.length - 1 ? 'Завершить тестирование' : 'Следующий вопрос' }}
                  <span
                      class="lesson-nav__next-arrow"></span></button>
              </div>
            </div>
          </div>
          <div class="content-element info block br-rad-10" v-if="false">
            <div class="info-filling p-20">
              <h3 class="test-description__title">Информация:</h3>
              <p class="test-description__text mt-20">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. </p>
              <div class="test-description-statistics info-statistics al-cent flex white mt-30 gap10">
                <img src="../../assets/img/test/pdf.svg" alt="" class="test-description-statistics__img">
                <p class="test-description-statistics__text">PDF-файл: «Название чек-листа»</p>
              </div>
              <button class="test-description-actions__start info-action btn-big bg-red white mt-30">Скачать чек-лист
              </button>
            </div>
          </div>
        </div>
        <div class="additionally flex flex-dir-col gap40">
          <div class="additionally-element my-course">
            <h5 class="my-course__title small-title">Другие доступные тестирования:</h5>
            <div class="my-course-group p-20-10 block gap10 flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="myTesting">
                <SeparateRecommendedTest v-for="item in myTesting" :key="item.id" :testing="item">
                </SeparateRecommendedTest>
              </div>
              <button class="slider-last-block tablet"><img src="../../assets/img/dashbord/filling/last-element.svg"
                                                            alt="" class="slider-block__img"></button>
              <button class="slider-next-block tablet"><img src="../../assets/img/dashbord/filling/next-element.svg"
                                                            alt="" class="slider-block__img"></button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto">Все мои курсы</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile>
    </PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SeparateRecommendedTest from "../../components/tests/SeparateRecommendedTest.vue";
import SeparateAnswer from "../../components/interview/SeparateAnswer.vue";
import {mapGetters} from "vuex";
import {errorHandler} from "@/lib/errorHandler";
import {convertTimeInMinuteAndSeconds} from "@/lib/convertTime";


export default {
  computed: {
    ...mapGetters(['currentTesting', 'savedTesting','myTesting'])
  },
  components: {PersonalHeader, PersonalProfile, SeparateRecommendedTest, PersoneInCaption, SeparateAnswer},
  name: "TestPage",
  props: ['testingId'],
  data() {
    return {
      iter: 0,
      passedTimeSeconds: null,
      timer: null,
      convert: convertTimeInMinuteAndSeconds
    }
  },
  beforeMount() {
    scroll(0, 0);
    if (!this.currentTesting) {
      this.$router.push({name: 'currentTestingPage', params: {testingId: this.testingId}});
    } else {
      if (this.currentTesting.passingTimeSeconds && this.currentTesting.passingTimeSeconds !== 0) {
        this.passedTimeSeconds = 0;
        this.startTimer();
      }
      this.$store.commit('setTestingId', this.testingId);
    }
  },
  methods: {
    async nextQuestion() {
      if (this.savedTesting && this.savedTesting.answers.map(item => item.questionId).indexOf(this.currentTesting.questions[this.iter].id) !== -1) {
        if (this.iter === this.currentTesting.questions.length - 1) {
          clearInterval(this.timer)
          await this.$store.dispatch('savePassedTesting', this.passedTimeSeconds);
        } else {
          this.iter++;
        }
      }
    },
    prevQuestion() {
      if (this.iter !== 0) {
        this.iter--;
      }
    },
    startTimer() {
      this.timer = setInterval(this.iterationTimer, 1000);
    },
    iterationTimer() {
      if (this.passedTimeSeconds >= this.currentTesting.passingTimeSeconds) {
        this.$router.push({name: 'currentTestingPage', params: {testingId: this.testingId}});
        clearInterval(this.timer)
        errorHandler({name: 'testing', status: 400})
      } else {
        this.passedTimeSeconds++;
      }
    },

  },
  beforeUnmount() {
    clearInterval(this.timer)
  }


}
</script>

<style scoped>


.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

/* Caption */

.caption {
  padding: 40px 5% 0px 0px;
  align-items: flex-start;
}

.caption-greetings__title {
  font-family: "DelaGothicOne";
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
  max-width: 782px;
}

.caption-greetings-info {
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  margin-top: 20px;
}

.caption-greetings-info__author {
  font-size: 16px;
  line-height: 1.1;
  color: rgba(16, 12, 64, 0.6000000238418579);
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
}

.filling-element {
  max-width: 100%;
  width: 100%;
}

.content-element {
  max-width: 100%;
  width: 100%;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/*content*/
.content {
  width: 100%;
}

.test-description__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.28;
}

.test-description__text {
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.test-description-statistics {
  margin-bottom: 20px;
}

.test-description-statistics__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.5);
}

/* additionally */
.additionall-about {
  padding: 30px 20px;
}

.additionall-about-title {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.additionall-about-text {
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-btn {
  margin-top: 58px;
}

.additionall-about-time {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-time__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/time.svg);
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/*interview*/
.interview {
  border: 1px solid #FF2031;
  border-radius: 10px;
}

.interview-line {
  height: 6px;
  border-radius: 100px 20px 0px 0px;
  border-bottom: 1px solid rgba(16, 12, 64, 0.4);
}

.interview-line__filling {
  height: 100%;
  border-radius: 100px 100px 10px 0px;
  background: #ff2031;
}

.interview-filling {
  padding: 14px 20px 20px 20px;
}

.interview-caption__number {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.28;
}

.interview-caption__time {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.28;
  color: #100c40;
  opacity: 0.5;
}

.inquestionw-question {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.6;
}

.inquestionw-answers {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/*my cours*/
.my-course__more {
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* nav */
.lesson-nav {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.lesson-nav__last {
  max-width: 293px;
  width: 100%;
  height: 66px;
  justify-content: center;
  border: 1px solid #ff2031;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.1);
  font-size: 16px;
  font-weight: 500;
  color: #ff2031;
  align-items: center;
  display: flex;
}

.lesson-nav__last-arrow {
  background: url(../../assets/img/video/arrow-left.svg);
  width: 35px;
  height: 9px;
  background-size: cover;
  margin-right: 10px;
  display: block;
}

.lesson-nav__number {
  padding: 10px 20px;
  background: #100c40;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.lesson-nav__next {
  max-width: 282px;
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff2031;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.1);
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.lesson-nav__next-arrow {
  background: url(../../assets/img/video/arrow-right.svg);
  width: 35px;
  height: 9px;
  background-size: cover;
  margin-left: 10px;
  display: block;
}

@media (max-width: 1880px) {
  .carousel-title {
    max-width: 398px;
  }

  .additionally {
    max-width: 326px;
  }

  .gap40 {
    gap: 20px;
  }
}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 30px 0px;
    justify-content: space-between;
    gap: 10px;
  }

  .caption-greetings__title {
    font-size: 24px;
  }

  .caption-greetings-info {
    flex-direction: column;
    gap: 20px;
  }

  .surch {
    margin-left: 0;
  }

  .surch__input {
    font-size: 14px;
    max-width: 265px;
    width: 100%;
    display: flex;
    flex-shrink: 1;
  }

  /* carousel */
  .carousel {
    padding: 20px 20px 50px 20px;
    position: relative;
  }

  .carousel-title {
    font-size: 48px;
  }

  .carousel-text {
    font-size: 14px;
    max-width: 213px;
    line-height: 1.4;
  }

  .carousel-button {
    margin-top: 14px;
    max-width: 223px;
    height: 44px;
    font-size: 12px;
  }

  .carousel-nav {
    left: calc(50% - 70px);
  }

  .carousel-img {
    right: 0px;
    bottom: 0px;
    background: url(../../assets/img/dashbord/filling/man.svg);
    background-size: cover;
    width: 274px;
    height: 260px;
  }

  /* additionally */
  .additionall-about {
    padding: 20px 10px 26px 10px;
  }

  .additionall-about-title {
    font-size: 20px;
  }

  .additionall-about-text {
    margin-top: 10px;
    font-size: 14px;
  }

  .additionall-about-btn {
    margin-top: 72px;
    max-width: 195px;
    font-size: 12px;
  }

  .additionall-about-time {
    margin-top: 20px;
  }
}

@media (max-width: 950px) {
  .gap40 {
    gap: 40px;
  }

  .body {
    flex-direction: column;
  }

  .filling {
    flex-direction: column;
  }

  .caption {
    padding: 40px 0 0 0;
  }

  .caption-greetings__title {
    font-size: 32px;
  }

  .main {
    margin-left: 0;
    margin-right: 0;
    padding: 0 20px;
  }

  .profile {
    display: none;
  }

  /* additionall */
  .my-course-group {
    overflow-x: scroll;
  }

  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    margin-top: 20px;
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
    gap: 10px;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .separate-curse {
    max-width: 280px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  .filling {
    margin-top: 30px;
  }

  .caption-greetings {
    text-align: center;
  }

  .caption-greetings__title {
    text-align: center;
    font-size: 24px;
  }

  .caption-greetings-info {
    max-width: 100%;
  }

  .caption-greetings-info__author {
    font-size: 12px;
  }

  .content {
    margin-top: 0;
    padding: 0;
    gap: 20px;
  }

  /* test */
  .test-description__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
  }

  .test-description__text {
    font-size: 12px;
    line-height: 1.6;
  }

  .test-description-statistics-wrapper {
    flex-direction: row;
    gap: 50px;
    margin-top: 20px;
  }

  .test-description-statistics {
    display: flex;
    margin: 0;
    flex-direction: column;
  }

  .test-description-actions {
    margin-top: 30px;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    gap: 10px;
  }

  .test-description-actions__start {
    max-width: 165px;
    height: 44px;
    font-size: 12px;
  }

  .test-description-actions__remind {
    max-width: 165px;
    height: 44px;
    font-size: 12px;
  }

  .info {
    text-align: center;
  }

  .info-filling {
    padding: 20px 10px;
  }

  .info-statistics {
    flex-direction: row;
    margin: 20px 0 0 0;
    justify-content: center;
  }

  .info-action {
    margin: 20px auto 0 auto;
  }

  /*interview*/
  .interview-filling {
    padding: 24px 10px 25px 10px;
  }

  .interview-caption__number {
    font-size: 12px;
  }

  .interview-caption__time {
    font-size: 12px;
  }

  .inquestionw-question {
    font-size: 14px;
  }

  .inquestionw-answers {
    margin-top: 20px;
  }

  /* nav */
  .lesson-nav {
    gap: 25px;
    margin-top: 20px;
  }

  .lesson-nav__last {
    max-width: 100px;
    width: 100%;
    height: 66px;
    font-size: 0;
    height: 36px;
  }

  .lesson-nav__last-arrow {
    background: url(../../assets/img/video/arrow-left.svg);
    width: 28px;
    height: 8px;
    margin-left: 0;
    background-size: contain;
  }

  .lesson-nav__number {
    padding: 8px 14px;
    border-radius: 8px;
    font-size: 10px;
  }

  .lesson-nav__next {
    max-width: 100px;
    font-size: 0;
    height: 36px;
  }

  .lesson-nav__next-arrow {
    background: url(../../assets/img/video/arrow-right.svg);
    width: 28px;
    height: 8px;
    margin-left: 0;
    background-size: contain;
  }

  /*category*/
  .category-element {
    padding: 0;
    margin-top: 20px;
  }

  .category-element__caption-tags {
    gap: 4px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }

  .tags {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: 50px;
  }


}
</style>