<template>
  <div class="background">
    <AuthorizationHeader>
    </AuthorizationHeader>

    <main class="container">
      <h1>
        Пользовательское соглашение на использование веб-сайта
        «Послевыписки.рф»

      </h1>
      <h2>Содержание:</h2>
      <p>1. Термины и определения</p>
      <p>2. Общие положения</p>
      <p>3. Согласия с условиями Соглашения</p>
      <p>4. Предмет Соглашения</p>
      <p>5. Авторизация Пользователя. Учетная запись Пользователя</p>
      <p>6. Контент Пользователя</p>
      <p>7. Контент Правообладателя</p>
      <p>8. Условия использования сайта</p>
      <p>9. Персональные данные и политика конфиденциальности</p>
      <p>10. Ответственность</p>
      <p>11. Заключительные положения</p>
      <h2>1. Термины и определения:</h2>
      <p>1.1. Правообладатель – Автономная некоммерческая организация «ЦЕНТР РЕАЛИЗАЦИИ ПРОГРАММ В СФЕРЕ ЗДРАВООХРАНЕНИЯ
        «ВРАЧИ
        И ПАЦИЕНТЫ ЗА КАЧЕСТВЕННУЮ И ДОСТУПНУЮ МЕДИЦИНСКУЮ ПОМОЩЬ» (ИНН 9721046167, ОГРН 1177700006230, адрес: 109439,
        ГОРОД МОСКВА, ЕСЕНИНСКИЙ БУЛЬВАР, ДОМ 9, КОРПУС 3, КВАРТИРА 17).</p>
      <p>1.2. Пользователь – дееспособное физическое лицо, действующее лично (без участия представителя), чьи данные
        указаны в
        личном кабинете на веб-сайте «Послевыписки.рф», намеренное использовать или использующее веб-сайт
        «Послевыписки.рф».</p>
      <p>1.3. Регистрация – процесс создания личного кабинета Пользователя для получения рекомендаций посредством
        веб-сайта
        «Послевыписки.рф». После входа на веб-сайт и для начала использования функций, ресурсов и информации,
        размещенных
        на веб-сайте «Послевыписки.рф» Пользователю необходимо пройти авторизацию. Авторизация производится путем
        введения
        номера мобильного телефона Пользователя (абонентский номер, выделенный оператором мобильной связи), на который
        Правообладателем отправляется СМС-сообщение с шестизначным кодом для идентификации. В дальнейшем Пользователь
        проходит краткий опрос (чек-лист), состоящий из двух шагов (этапов), и выбирает вид рекомендаций, которые он
        хотел
        бы получить. После указанных действий учетная запись Пользователя считается созданной и Пользователь может
        пользоваться функциями, ресурсами и информацией веб-сайта «Послевыписки.рф».
      </p>
      <p>1.4. Аутентификационные данные – самостоятельно созданный Пользователем код доступа (код доступа любого
        возможного
        вида, который создается в зависимости от функциональности Устройства Пользователя и текущих функциональных
        возможностей веб-сайта «Послевыписки.рф»), используемый для целей идентификации Пользователя при входе в личный
        кабинет Пользователя на веб-сайте «Послевыписки.рф». Создание Аутентификационных данных является обязательным
        для
        Пользователя.
      </p>
      <p>1.5. Авторизация – вход в личный кабинет Пользователя на веб-сайте «Послевыписки.рф» с использованием
        Аутентификационных данных или иным способом, предусмотренным настоящим Соглашением или функциями веб-сайта.
      </p>
      <p>1.6. Электронный документ – документ, в котором информация представлена в электронной форме, то есть в виде,
        пригодном
        для восприятия человеком с использованием Устройства, а также для передачи по информационно-телекоммуникационным
        сетям или обработки в информационных системах.
      </p>
      <p>1.7. Электронный документооборот – обмен Электронными документами через веб-сайт «Послевыписки.рф» между
        Правообладателем и Пользователем.
      </p>
      <p>1.8. Сайт – веб-сайт «Послевыписки.рф», который представляет собой совокупность программных и аппаратных
        средств
        для
        различного рода Устройств, автоматизированную информационную систему, доступную в сети Интернет по сетевому
        адресу
        <a target="_blank" class="red"
          href="https://xn--b1afkajcuheqf1j.xn--p1ai/">https://xn--b1afkajcuheqf1j.xn--p1ai/</a>, предоставляющий
        Пользователю справочно-рекомендательную информацию по
        реабилитации осложнений после COVID-19 на безвозмездной основе. Сайт принадлежит Правообладателю.
      </p>
      <p>1.9. Любая информация, документация, сведения, базы данных, программное обеспечения, программные коды,
        произведения
        дизайна, тексты, любые средства индивидуализации (фирменное наименование, товарные знаки, знаки обслуживания,
        коммерческие обозначения), кроме объектов интеллектуальной собственности, которые были получены Правообладателем
        из источников в сети Интернет, публикующих информацию в свободном доступе или полученных Правообладателем на
        основании соответствующих лицензионных соглашений для Сайта, являются собственностью Правообладателя и не могут
        быть использованы кроме как для личного (некоммерческого использования) без получения на то соответствующего
        письменного согласия Правообладателя.
      </p>
      <p>1.10. Личный кабинет - персональный раздел Пользователя на Сайте.
      </p>
      <p>1.11. Рекомендации – программа физической и психологической реабилитации, состоящей из видео курсов
        постковидной
        реабилитации, включающих в себя практические лекции нейропсихолога, пульмонолога, невролога, психолога,
        кардиолога, врача ЛФК, нутрициолога, сомнолога и ароматерапевта. Правообладатель обеспечивает предоставление
        Рекомендаций Пользователю посредством Сайта. Рекомендации не являются услугами медицинского характера.
        Правообладатель не осуществляет предоставление услуг медицинского характера. </p>
      <p>1.12. Информация – любые сведения, материалы, видеоролики, документы (в том числе электронные документы),
        размещенные
        на Сайте.
      </p>
      <p>1.3. Устройство – электронно-вычислительные машины, смартфоны, мобильные телефоны или любые иные устройства,
        позволяющее использовать Сайт в соответствии с его функциональны назначением.
      </p>
      <h2>2. Общие положения.</h2>
      <p>2.1. Правообладатель предлагает Пользователю сети Интернет использовать Сайт, размещенный в открытом доступе в
        сети Интернет любым незапрещенным законодательством Российской Федерации способом и с обязательным соблюдением
        настоящего Соглашения.</p>
      <p>2.2. Настоящее Пользовательское соглашение (далее по тексту — «Соглашение») согласно статьям 435 и 437
        Гражданского
        кодекса Российской Федерации (далее по тексту – «ГК РФ») является публичной офертой Правообладателя,
        адресованной
        неопределенному кругу лиц и содержащей условия использования Сайта</p>
      <p>2.3. Акцептом настоящей оферты согласно статье 438 ГК РФ являются любые действия Пользователя по использованию
        Сайта,
        в том числе Авторизация, использование функционала Сайта или первое открытие любой страницы Сайта на Устройстве
        Пользователя.
      </p>
      <p>2.4. Соглашение заключено между Правообладателем и любым Пользователем (далее по тексту – «Стороны»).
        Использование
        Сайта и любых его компонентов, в том числе его просмотр, поиск информации, использование любых функциональных
        возможностей Сайта возможно только при условии полного и безоговорочного принятия условий настоящей оферты в
        форме, установленной Соглашением. В случае, если условия Соглашения являются неприемлемыми для Пользователя,
        последний обязан прекратить использование Сайта.
      </p>
      <p>2.5. В случае несогласия Пользователя с какими-либо из положений Соглашения Пользователь обязан прекратить
        использование Сайта. В случае, если Правообладателем были внесены какие-либо изменения в Соглашение в порядке,
        предусмотренном Разделом 7 настоящего Соглашения, с которыми Пользователь не согласен, он обязан прекратить
        использование Сайта.
      </p>
      <p>2.6. Соглашение может быть изменено Правообладателем. Пользователь уведомляется об изменении Соглашения
        посредством
        публикация новой редакции Соглашения в сети Интернет по сетевому адресу Сайта (п.1.8 Соглашения). При этом новая
        редакция Соглашения вступает в силу с момента такой публикации, если иное не предусмотрено новой редакцией
        Соглашения. Пользователь обязуется контролировать при входе на Сайт обновление редакции Соглашения и, продолжая
        пользоваться Сайтом после изменения Соглашения, подтверждает свое согласие с новой редакцией Соглашения.
      </p>
      <p>2.7. Исключительное право на Сайт принадлежит Правообладателю.
      </p>
      <p>2.8. Авторизация и Аутентификация Пользователя на Сайте осуществляется способами и в порядке, предусмотренными
        Разделом 1 настоящего Соглашения.
      </p>
      <p>2.9. Правообладатель и Пользователь настоящим согласовали, что в рамках использования Сайта они взаимно
        признают
        Электронные документы, подписанные простой электронной подписью, равнозначными документам на бумажных носителях,
        подписанным собственноручной подписью (если иное не будет следовать из такого документа).
      </p>
      <p>2.9.1. Подписание Пользователем документов на Сайте осуществляется посредством простой электронной подписи
        Пользователя,
        формируемой одним из следующих способов:</p>
      <ul>
        <li>- проставление Пользователем специального символа (галочки, крестика или иного символа согласно
          функциональным
          возможностям Сайта) напротив соответствующего документа (ссылки на соответствующий документ) при Авторизации
          и/или Аутентификации Пользователя на Сайте;
        </li>
        <li>- введение Пользователем кода из СМС-сообщения, полученного от Правообладателя на указанный Пользователем
          номер мобильного телефона;
        </li>
        <li>- посредством нажатия Пользователем кнопки «Подтвердить», «Оформить», «Оплатить» рядом с соответствующим
          документом (ссылкой на соответствующий документ) после Авторизации Пользователя на Сайте;
        </li>
        <li>- иными действиями при использовании Сайта, которые свидетельствуют о согласии Пользователя с
          соответствующим
          документом (Электронным документом).
        </li>
      </ul>
      <p>2.9.2. Одной простой электронной подписью Пользователя могут быть подписаны несколько связанных между собой
        Электронных
        документов. При подписании одной простой электронной подписью Пользователя нескольких электронных документов
        каждый документ считается пописанным простой электронной подписью Пользователя.</p>
      <p>2.10. Пользователь обязан соблюдать конфиденциальность в отношении своих Аутентификационных данных. Стороны
        согласовали, что все действия и Электронные документы, сделанные и направленные Правообладателю с использованием
        Аутентификационных данных, считаются сделанными и направленными Пользователем.</p>
      <h2>3. Согласие с условиями Соглашения.</h2>
      <p>3.1. Акцептом настоящей оферты согласно пункту 3 статьи 438 ГК РФ являются действия Пользователя,
        предусмотренные
        п.
        2.3 Соглашения.
      </p>
      <p>3.2. Совершая действия по принятию оферты в порядке, определенном Соглашением, Пользователь гарантирует, что
        ознакомлен, соглашается, полностью и безоговорочно принимает все условия Соглашения, обязуется их соблюдать.</p>
      <p>3.3. Соглашение может быть принято исключительно в целом (согласно пункту 1 статьи 428 ГК РФ). После принятия
        Пользователем условий Соглашения оно считается заключенным сторонами, при этом оно не оформляется как бумажный
        документ, подписанный обеими Сторонами.</p>
      <h2>4. Предмет Соглашения.</h2>
      <p>4.1. Правообладатель безвозмездно, на условиях простой (неисключительной) лицензии, предоставляет Пользователю
        непередаваемое право использования Сайта на территории всех стран мира, а именно применять Сайт по прямому
        функциональному назначению с использованием любого рода Устройств. Пользователь вправе использовать Сайт с
        помощью
        неограниченного числа Устройств.</p>
      <p>4.2. Используя функциональные возможности Сайта, Пользователь самостоятельно и по своему усмотрению выбирает
        необходимые Рекомендации. Пользователь может использовать функциональные возможности Сайта для приобретения и
        оплаты услуг, если таковые будут предоставляться Правообладателем.
      </p>
      <p>4.3. Правообладатель не является медицинской организацией либо представителем медицинской организации, не
        оказывает
        какие-либо консультации в соответствии с Федеральным законом «Об основах охраны здоровья граждан в Российской
        Федерации» № 323-ФЗ от 21 ноября 2011 года.</p>
      <p>4.4. На Сайте отсутствует возможность записи на прием к лицам или установления канала связи с лицами, не
        заключившими
        с Правообладателем лицензионный договор об использовании Сайта.
      </p>
      <h2>5. Авторизация Пользователя. Учетная запись Пользователя.</h2>
      <p>5.1. Для того, чтобы получить Рекомендации посредством Сайта, Пользователю необходимо пройти процедуру
        Регистрации и
        Авторизации, в результате которых для Пользователя будет создана учетная запись. Пользователь, самостоятельно
        производит Регистрацию и Авторизацию учетной записи и использует Сайт в пределах, установленных настоящим
        Соглашением и законодательством Российской Федерации.
      </p>
      <p>5.2. Для Авторизации Пользователь обязуется использовать свои Аутентификационные данные. Если Пользователь
        использует
        некорректные Аутентификационные данные или у Правообладателя есть основания полагать, что используемые им
        Аутентификационные данные некорректны (недостоверны), Правообладатель имеет право по своему усмотрению
        заблокировать либо удалить учетную запись Пользователя и отказать Пользователю в использовании Сайта.
      </p>
      <p>5.3. Пользователь осознает и соглашается с тем, что Правообладатель вправе проводить опубликование и дальнейшее
        использование указанного Пользователем в профиле на Сайте имени и фотографии (аватара) в отзывах (комментариях,
        оценках) на Сайте в порядке, предусмотренном п. 6.4–6.6 настоящего Соглашения, а также публиковать отзывы,
        оставленные Пользователем на Сайте.
      </p>
      <p>5.4. Подтверждение учетной записи:
      </p>
      <p>5.4.1. Правообладатель оставляет за собой право в любой момент потребовать от Пользователя подтверждения
        данных,
        указанных при Регистрации.
      </p>
      <p>5.4.2. Для целей проверки данных, указанных при Регистрации, Правообладатель вправе запросить подтверждающие
        документы
        (в частности - документы, удостоверяющие личность, договор с оператором связи), непредставление которых, по
        усмотрению Правообладателя, может быть приравнено к предоставлению недостоверной информации и повлечь
        последствия,
        предусмотренные п. 5.2. Соглашения. В случае если данные Пользователя, указанные в предоставленных им
        документах,
        не соответствуют данным, указанным при Регистрации, Правообладатель вправе отказать Пользователю в доступе к
        учетной записи и использовании Сайта.
      </p>
      <p>5.4.3. Персональная информация Пользователя, содержащаяся в учетной записи Пользователя, а также содержащаяся в
        профиле
        Пользователя на Сайте, хранится и обрабатывается Правообладателем в соответствии с разделом 9 настоящего
        Соглашения.
      </p>
      <p>5.5. Средства для доступа к учетной записи Пользователя.
      </p>
      <p>5.5.1. После прохождения регистрации, введения Аутентификационных данных и Авторизации Пользователя на Сайте
        данные его
        учетной записи автоматически сохраняются на Сайте и не требуют дополнительного ввода Аутентификационных данных
        при
        каждом использовании Сайта в будущем, если иной порядок не будет установлен Правообладателем.
      </p>
      <p>5.6. Пользователь обязан немедленно уведомить Правообладателя о любом случае несанкционированного (не
        разрешенного
        Пользователем) доступа к Сайту Правообладателя с использованием Аутентификационных данных Пользователя и/или о
        любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи путем
        обращения к Правообладателю по его официальному адресу и/или путем направления иного сообщения способами,
        предусмотренными законодательством Российской Федерации и функциональными возможностями Сайта.
      </p>
      <p>5.6.1. В целях обеспечения безопасности Пользователь обязан самостоятельно осуществлять безопасное завершение
        каждой
        сессии работы с Сайтом Правообладателя под своей учетной записью (путем закрытия Сайта). Правообладатель не
        отвечает за возможную потерю или порчу данных Пользователя, а также другие последствия любого характера, которые
        могут произойти из-за нарушения Пользователем положений данного пункта Соглашения.
      </p>
      <p>5.7. Прекращение регистрации.</p>
      <p>5.7.1. Нарушение Пользователем условий Соглашения или неиспользование Сайта более 24 (Двадцати четырех) месяцев
        может
        повлечь последствия, предусмотренные п. 5.2. Соглашения.</p>
      <p>5.7.2. Пользователь вправе в любой момент потребовать от Правообладателя удалить его учетную запись с Сайта
        путем
        обращения к Правообладателю по его официальному адресу и/или путем направления иного сообщения способами,
        предусмотренными законодательством Российской Федерации и функциональными возможностями Сайта.</p>
      <h2>6. Контент Пользователя.</h2>
      <p>6.1. Пользователь самостоятельно несет ответственность за соответствие содержания размещаемых им сведений
        требованиям
        действующего законодательства Российской Федерации, включая ответственность перед третьими лицами в случаях,
        когда
        размещение Пользователем тех или иных сведений или содержание контента нарушает права и законные интересы
        третьих
        лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/или посягает
        на
        принадлежащие им нематериальные блага.</p>
      <p>6.2. Пользователь признает и соглашается с тем, что Правообладатель вправе по своему усмотрению отказать
        Пользователю
        в размещении и/или распространении им сведений и/или контента или удалить любые сведения и/или контент, которые
        доступны на Сайте. Пользователь осознает и согласен с тем, что он должен самостоятельно оценивать все риски,
        связанные с использованием сведений и/или контента, включая оценку их надежности, полноты или полезности.</p>
      <p>6.3. Пользователь осознает и соглашается с тем, что технология работы Сайта может потребовать копирование
        (воспроизведение) контента Пользователя Правообладателем, а также переработки его Правообладателем для
        соответствия техническим требованиям Сайта.
      </p>
      <p>6.4. Пользователь соглашается с тем, что размещенные им на Сайте отзывы (комментарии, оценки) могут быть
        опубликованы
        и использованы Правообладателем в рекламных или маркетинговых материалах, размещаемых на ресурсах
        Правообладателя
        в сети Интернет, а также на иных ресурсах и сайтах в сети Интернет, для привлечения внимания других
        пользователей
        к Сайту в целом или к иным товарам, работам и услугам третьих лиц доступных на Сайте, без указания имени
        Пользователя в отзывах (комментариях, оценках), без обязанности предоставлять Пользователю отчеты об
        использовании
        таких отзывов (комментариев, оценок), без необходимости получения специального разрешения Пользователя и без
        выплаты авторского вознаграждения, на территории всего мира без ограничения срока, с правом Правообладателя
        вносить в отзывы (комментарии, оценки) изменения (сокращения, дополнения), снабжать их иллюстрациями
        (предисловием, послесловием, комментариями или какими бы то ни было пояснениями), использовать совместно с
        результатами интеллектуальной деятельности любых лиц, вне зависимости от художественной ценности таких
        результатов, в любой последовательности, а также с правом Правообладателя предоставить указанные права
        использования таких отзывов (комментариев, оценок) третьим лицам.
      </p>
      <p>6.5. При этом Пользователь признает и соглашается, что Правообладатель не обязан просматривать такие отзывы
        (комментарии, оценки), а их использование указанными выше способами в указанных выше целях может осуществляться
        автоматически посредством Сайта Правообладателя. В случае если Пользователь не имеет намерением предоставить
        Правообладателю право использования какого-либо отзыва (комментария, оценки), он обязан воздержаться от
        размещения
        такого отзыва (комментария, оценки). Пользователь несет ответственность за достоверность сведений и/или
        контента,
        содержащихся в оставленных им отзывах (комментариях, оценках). Действие настоящего пункта распространяется на
        все,
        в том числе ранее оставленные отзывы (комментарии, оценки) на Сайте.
      </p>
      <p>6.6. Пользователь осознаёт и понимает, что, оставляя на Сайте отзывы (комментарии, оценки), Пользователь тем
        самым
        делает их общедоступными.
      </p>
      <p>6.7. Пользователь уведомлен и согласен с тем, что в целях контроля качества Правообладатель вправе проводить
        исследования переданных и полученных Пользователем сведений и/или контента в обезличенном агрегированном виде
        без
        возможности отнести такую информацию прямо или косвенно к определенному или определяемому Пользователю.
      </p>
      <p>6.8. Пользуясь Сайтом, Пользователь дает свое согласие на получение информационных и рекламных сообщений от
        Правообладателя, в том числе в виде СМС-сообщений. Пользователь вправе отказаться от получения рекламных
        сообщений
        путём письменного обращения в адрес Правообладателя посредством использования функциональных возможностей Сайта.
        Пользователь не вправе отказаться от получения информационных сообщений, связанных с Регистрацией.
      </p>
      <p>6.9. Положения настоящего Раздела применяются в случае наличия функциональной возможности Сайта по размещению,
        обработке контента Пользователя. </p>
      <h2>7. Контент Правообладателя.</h2>
      <p>7.1. Правообладатель осуществляет размещение на Сайте Контента, содержание которого не противоречит требованиям
        действующего законодательства Российской Федерации.</p>
      <p>7.2. Правообладатель вправе в одностороннем порядке вносить любые изменения в собственный Контент, равно как и
        в
        контент Пользователя, в том числе удалять отзывы (комментарии, оценки), сведения и/или любой иной контент
        Пользователей по своему собственному усмотрению при возникновении обстоятельств, свидетельствующих о
        недостоверности, либо противоречии контента Пользователя законодательству Российской Федерации, по требованию
        компетентных государственных органов, предъявленных в порядке, установленном законодательством Российской
        Федерации. Правообладатель также вправе в любое время по собственной инициативе удалить с Сайта отзывы
        (комментарии, оценки), сведения и/или контент Пользователей, если имело место нарушение Раздела 8 настоящего
        Соглашения.
      </p>
      <p>7.3. Информация и Рекомендации, размещенные на Сайте, носят исключительно информационно-справочный характер и
        перед их
        использованием (применением) следует проконсультироваться с врачом.
      </p>
      <p>7.4. Сайт предоставляет функциональную возможность доступа к истории действий Пользователя на Сайте. В
        указанном
        случае Пользователю может быть предоставлена возможность ознакомления с информацией о его действиях на Сайте в
        соответствии с функциональными возможностями Сайта.
      </p>
      <p>7.5. Сайт предоставляет Пользователю возможность перехода к различным информационным разделам Сайта.
        Пользователь
        вправе осуществлять использование материалов информационных разделов Сайта на условиях Соглашения.
        Правообладатель
        является собственником (владельцем, правообладателем) всех материалов, доступных в информационных разделах
        Сайта.
      </p>
      <p>7.6. По всем вопросам, связанным с содержанием материалов информационных разделов Сайта, Пользователь может
        обратиться
        в адрес Правообладателя посредствам использования функциональных возможностей Сайта.
      </p>
      <p>7.7. Пользователь настоящим подтверждает свою осведомленность с тем, что Контент Правообладателя является его
        собственностью и может быть использован исключительно в соответствии с требованиями законодательства Российской
        Федерации и условиями настоящего Соглашения. В случае любого нарушения условий использования Контента
        Правообладатель оставляет за собой право заблокировать или ограничить Пользователю доступ к Сайту.
      </p>
      <h2>8. Условия использования Сайта.
      </h2>
      <p>8.1. Сайт предоставляется Пользователю для личного некоммерческого использования.</p>
      <p>8.2. Сайт предоставляет Пользователю функциональную возможность использования Рекомендаций и Информации,
        размещенных
        на Сайте в полном объеме.
      </p>
      <p>8.3. При использовании Сайта Пользователь не вправе:</p>
      <p>8.3.1. загружать, посылать, передавать или любым другим способом размещать и/или распространять контент,
        который
        является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует (или является
        пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или
        дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит
        оскорбления
        в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, детской
        эротики,
        представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных
        услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ или их
        аналогов,
        взрывчатых веществ или иного оружия, представляет собой рекламу любых видов товаров и услуг;
      </p>
      <p>8.3.2. нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вред в любой форме;
      </p>
      <p>8.3.3. выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то
        прав, в
        том числе за сотрудников Правообладателя, за модераторов форумов, за владельца Сайта Правообладателя, а также
        применять любые другие формы и способы незаконного представительства других лиц в сети, а также вводить других
        Пользователей или Правообладателя в заблуждение относительно свойств и характеристик каких-либо субъектов или
        объектов;
      </p>
      <p>8.3.4. загружать, посылать, передавать или любым другим способом размещать и/или распространять контент при
        отсутствии
        прав на такие действия согласно законодательству Российской Федерации или каким-либо договорным отношениям;
      </p>
      <p>8.3.5. загружать, посылать, передавать или любым другим способом размещать и/или распространять какие-либо
        материалы,
        содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения, уничтожения
        либо ограничения функциональности компьютерного или телекоммуникационного оборудования или программ
        Правообладателя, для осуществления несанкционированного доступа, а также серийные номера к коммерческим
        программным продуктам и программы для их генерации, логины, пароли и прочие средства для получения
        несанкционированного доступа к платным ресурсам в Интернете, а также размещения ссылок на вышеуказанную
        информацию;
      </p>
      <p>8.3.6. несанкционированно собирать и хранить персональные данные третьих лиц;
      </p>
      <p>8.3.7. нарушать нормальную работу Сайта Правообладателя;
      </p>
      <p>8.3.8. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением;
      </p>
      <p>8.3.9. проявлять некорректное и неуважительное отношение к Правообладателю;
      </p>
      <p>8.3.10. каким-либо образом нарушать нормы законодательства Российской Федерации, в том числе нормы
        международного
        права.
      </p>
      <p>8.4. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с
        использованием Сайта, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих
        лиц,
        а также за соблюдение законодательства Российской Федерации при использовании Сайта.
      </p>
      <h2>9. Персональные данные и политика конфиденциальности.
      </h2>
      <p>9.1. Для целей надлежащего исполнения условий Соглашения Пользователь в момент прохождения Регистрации,
        Аутентификации
        и Авторизации дает согласие на обработку персональных данных (далее по тексту – «Согласие») в соответствии с
        Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных» и политикой конфиденциальности в
        отношении
        обработки персональных данных Правообладателя (далее по тексту – «Политика»), путем введения шестизначного кода
        идентификации, получаемого в соответствии с условиями Регистрации, либо путем введения своих данных при
        прохождении Аутентификации и Авторизации.
      </p>
      <p>9.2. Персональная информация Пользователя – любая иная информация, предоставленная или сгенерированная
        Пользователем
        при использовании Сайта и его функций, не относящаяся к персональным данным Пользователя.
      </p>
      <p>9.3. Обработка Правообладателем персональных данных и персональной информации Пользователя, загружаемых,
        передаваемых
        и предоставляемых Пользователем при использовании Сайта, осуществляется на условиях настоящего Соглашения и
        Политики, в части, не противоречащей Соглашению.
      </p>
      <p>9.4. Пользователь настоящим уведомлен, понимает и соглашается с тем, что при использовании Сайта и его
        функциональных
        возможностей, Правообладатель в целях улучшения продукта и качества клиентского обслуживания собирает информацию
        о
        действиях Пользователя, совершаемых при использовании Сайта, в обезличенном виде, включая, но не ограничиваясь
        информацией из любых идентификационных файлов, информацию о браузере и устройстве Пользователя.
      </p>
      <p>9.5. При подписании Электронных документов, сформированных, используемых и направляемых Правообладателю
        Пользователем,
        Стороны руководствуются положениями пункта 2.9 Соглашения.
      </p>
      <p>9.6. Правообладатель гарантирует конфиденциальность в отношении персональных данных и Персональной информации
        Пользователя и предоставляет к ним доступ только тем своим сотрудникам, которым эта информация необходима для
        выполнения условий Соглашения, обеспечивая соблюдение ими конфиденциальности персональных данных Пользователя и
        безопасности при их обработке. Правообладатель обязуется сохранять конфиденциальность всех сведений, полученных
        от
        Пользователя в результате использования Пользователем Сайта, независимо от содержания таких сведений.
      </p>
      <h2>10. Ответственность.
      </h2>
      <p>10.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств в
        соответствии
        с
        условиями Соглашения и законодательством Российской Федерации.</p>
      <p>10.2. Сайт предоставляется Правообладателем Пользователю в состоянии как есть, без каких-либо обязательств
        Правообладателя по устранению его недостатков, эксплуатационной поддержке и/или усовершенствованию.
      </p>
      <p>10.3. Правообладатель не несет ответственности:
      </p>
      <p>10.3.1. за коммерческую пригодность Сайта, а также не гарантирует, что программное обеспечение Сайта полностью
        свободно
        от дефектов и ошибок, и должно функционировать бесперебойно и в обязательном порядке;
      </p>
      <p>10.3.2. за правильность введения Пользователем информации и сведений, которые вносятся при использовании Сайта;
      </p>
      <p>10.3.3. за соответствие Сайта конкретным целям и требованиям Пользователя;
      </p>
      <p>10.3.4. за какие-либо прямые или косвенные убытки, причиненные Пользователю в результате использования,
        неиспользования
        или невозможности использования Сайта, в том числе из-за возможных ошибок или сбоев в работе Сайта, за
        исключением
        случаев, прямо предусмотренных действующим законодательством Российской Федерации.
      </p>
      <p>10.4. Ответственность Правообладателя по Соглашению ограничивается суммой документально подтвержденного ущерба,
        причиненного Пользователю, при наличии вступившего в законную силу решения суда, устанавливающего вину
        Правообладателя.
      </p>
      <p>10.5. Пользователь согласен с тем, что Правообладатель не предоставляет никаких гарантий о возможных
        результатах
        использования функциональных возможностей Сайта, в том числе о результатах применения (использования)
        Рекомендаций
        и Информации, содержащихся на Сайте.
      </p>
      <h2>11. Заключительные положения.</h2>
      <p>11.1. Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не
        урегулированные Соглашением и любые претензии или иски, вытекающие из Соглашения или использования Сайта или
        отдельных его компонентов, должны быть поданы и рассмотрены в суде по месту нахождения Правообладателя.
      </p>
      <p>11.2. Действие Соглашения распространяется на все последующие обновления/новые версии Сайта. Соглашаясь с
        обновлениями/новой версией Сайта, Пользователь принимает условия Соглашения для соответствующих обновлений/новой
        версии Сайта, если обновление/новая версия Сайта не сопровождается иным лицензионным /пользовательским
        соглашением.</p>
      <p>11.3. Соглашение действует с момента Авторизации Пользователя на Сайте и в течение срока действия прав
        Правообладателя
        на Сайт.</p>
    </main>
  </div>
</template>

<script>
import AuthorizationHeader from "../../components/authorization/AuthorizationHeader.vue";

export default {
  components: { AuthorizationHeader, },
  name: 'TermsOfUse',
  beforeMount() {
    scroll(0, 0);
  }

}
</script>

<style scoped>
.background {
  background: url(../../assets/img/registration-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 30px;
  background-position: center;
}

main {
  padding: 80px 10px 30px 10px;
}

h1 {
  text-align: center;
  font-size: 28px;
  font-family: "Muller", sans-serif;
  max-width: 693px;
  margin: 0 auto;
  font-weight: 600;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Muller", sans-serif;
  text-align: center;
  margin-top: 20px;
}

p {
  margin-top: 10px;
  text-indent: 60px;
  text-align: justify;
  font-size: 18px;
  color: #000;
  line-height: normal;
}


li {
  text-indent: 60px;
  text-align: justify;
  font-size: 18px;
  color: #000;
  list-style-type: none;
  line-height: normal;
  margin: 0;
  padding: 0;
}

li::before {
  display: none;
}

a {
  text-decoration: underline;
}


@media (max-width: 600px) {
  .background {
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 53px;
    background-position: top;
  }

  .authorization {
    margin-top: 0;
  }

  main {
    padding: 40px 10px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
    margin-top: 16px;
  }

  p {
    margin-top: 10px;
    text-indent: 40px;
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }

}
</style>
  