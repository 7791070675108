import districts from "@/lib/districts.json";

export function convertTimeInMinuteAndSeconds(time) {
    let minute = Math.trunc(time / 60);
    let seconds = time % 60;
    return (minute < 10 ? '0' + minute : minute) + ':' + (seconds < 10 ? '0' + seconds : seconds);
}


export function getRegionNameById(regionId) {
    const regionIndex = districts?.map(item => item.id).indexOf(regionId);
    return regionIndex > -1 ? districts[regionIndex]?.name : null
}

export function getDistrictNameById(regionId, districtId) {
    const regionIndex = districts?.map(item => item.id).indexOf(regionId);
    if (regionIndex > -1) {
        const districtIndex = districts[regionIndex]?.districts?.map(item => item.id).indexOf(districtId)
        return districtIndex > -1 ? districts[regionIndex]?.districts[districtIndex]?.name : null;
    }
    return null

}