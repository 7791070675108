<template>
  <div v-for="item in answers" :key="item.id">
    <div class="radioBtn" v-if="questionType==='one'">
      <label class="selection "
             :class="{'checkbox-active':savedTesting&&savedTesting.answers&&savedTesting.answers.map(item => item.answerId).indexOf(item.id) !== -1}">
        {{ item.body }} <input
          type="radio"
          :value="item.id"
          class="selection__input"
          @click="saveOneAnswer"
      />
        <span class="selection__checkbox"></span>
      </label>
    </div>

    <div class="checkbox" v-if="questionType==='many'">
      <label class="selection"
             :class="{'checkbox-active':savedTesting&&savedTesting.answers&&savedTesting.answers.map(item => item.answerId).indexOf(item.id) !== -1}">
        {{ item.body }}
        <input
            :value="item.id"
            type="checkbox"
            class="selection__input"
            @change="saveManyAnswers"
        />
        <span class="selection__checkbox"></span>
      </label>
    </div>
  </div>

</template>

<script>

export default {
  name: "SeparateAnswer",
  props: ['answers', 'questionType', 'questionId','savedTesting'],
  methods: {
    saveOneAnswer(e) {
      this.$store.commit('saveAnswer', {questionId: this.questionId, answerId: e.target.value, type: 'one'});
    },
    saveManyAnswers(e) {
      this.$store.commit('saveAnswer', {questionId: this.questionId, answerId: e.target.value, type: 'many'});
    }

  }
}
</script>

<style scoped>

.selection {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 1s ease-in-out;
  background: #ffffff;
  border: 1px solid #9f9eb3;
  border-radius: 8px;
  padding: 23px 20px 23px 54px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(16, 12, 64, 0.4000000059604645);
}

.selection:hover {
  border: 1px solid #ff2031;
  color: #ff2031;
}

.selection__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.selection__checkbox {
  position: absolute;
  border: 2px solid #9F9EB3;
  border-radius: 100%;
  top: calc(50% - 11px);
  left: 24px;
  height: 20px;
  width: 20px;
  background-color: #fff;

}

.selection__checkbox::after {
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: none;
  content: "";
  transition: all 1s ease-in-out;
  left: calc(50% - 5px);
  top: calc(50% - 5px);
  position: absolute;
}

.selection__input ~ .selection__checkbox {
  transition: all 1s ease-in-out;
}

.selection__input:hover ~ .selection__checkbox {
  border-color: #ff2031;
}

.selection__input:hover ~ .selection__checkbox::after {
  background: #ff2031;
}

.selection__checkbox::before {
  content: "";
  position: absolute;
  display: none;
}

.selection__input:checked ~ .selection__checkbox::before {
  display: block;
}

@media (max-width: 600px) {
  .selection {
    padding: 10px 23px 10px 44px;
    font-size: 12px;
  }

  .selection__checkbox {
    left: 10px;
  }
}

/* Modificator */
.checkbox-active .selection__checkbox::after {
  background: #ff2031;
}

.checkbox-active .selection__checkbox {
  border-color: #ff2031;
}

.checkbox-active {
  border: 1px solid #ff2031;
  color: #ff2031;
}
</style>