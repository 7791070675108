<template>
  <div class="body">
    <PersonalHeader></PersonalHeader>
    <main class="main">
      <div class="caption flex">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">Вопрос-ответ</h1>
        </div>
        <PersoneInCaption></PersoneInCaption>
      </div>
      <!-- <div class="form form-desctop">
        <h5 class="form__title small-title">Задать вопрос:</h5>
        <div class="form-filling">
          <label for="" class="form-filling__label">
            <span class="form-icon form-name"></span>
            <input placeholder="Ваше имя" type="text" class="form-filling__input">
          </label>
          <label for="" class="form-filling__label">
            <span class="form-icon form-mail"></span>
            <input placeholder="Ваше E-mail" type="text" class="form-filling__input">
          </label>
          <label for="" class="form-filling__label last-label">
            <span class="form-icon form-question"></span>
            <input placeholder="Ваш вопрос" type="text" class="form-filling__input">
          </label>
          <button class="form-send">Отправить</button>
        </div>
      </div> -->
      <div class="filling">
        <div class="content">
          <!-- <div class="content-filling form-tablet">
            <div class="form">
              <h5 class="form__title small-title">Задать вопрос:</h5>
              <div class="form-filling">
                <label for="" class="form-filling__label">
                  <span class="form-icon form-name"></span>
                  <input placeholder="Ваше имя" type="text" class="form-filling__input">
                </label>
                <label for="" class="form-filling__label">
                  <span class="form-icon form-mail"></span>
                  <input placeholder="Ваше E-mail" type="text" class="form-filling__input">
                </label>
                <div class="textarea-container">
                  <span class="textarea-input__span"></span>
                  <textarea placeholder="Ваш вопрос" type="text" class="form-filling__input textarea-input"></textarea>
                </div>
                <button class="form-send">Отправить</button>
              </div>
            </div>
          </div> -->
          <div class="content-filling">
            <h5 class="content-filling__title small-title">
              В этом разделе авторы курсов дали ответы на часто задаваемые вопросы от пациентов
            </h5>
            <div class="sections">
              <div class="questions-filling">
                <div class="questions-preference" ref="slider">
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-red"
                        @click="
                        (currentTheme = 'aromatherapist'), scrollToPoint()
                      "
                        ref="element"
                    >
                      <p class="preference__element-text red">Ароматерапевт</p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-yellow"
                        @click="
                        (currentTheme = 'gastroenterologist'), scrollToPoint()
                      "
                    >
                      <p class="preference__element-text yellow">
                        Гастроэнтеролог
                      </p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-purple"
                        @click="(currentTheme = 'dermatologist'), scrollToPoint()"
                    >
                      <p class="preference__element-text purple">Дерматолог</p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-yellow"
                        @click="(currentTheme = 'cardiologist'), scrollToPoint()"
                    >
                      <p class="preference__element-text yellow">Кардиолог</p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-red"
                        ref="element"
                        @click="(currentTheme = 'exercise'), scrollToPoint()"
                    >
                      <p class="preference__element-text red">ЛФК</p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-yellow"
                        @click="(currentTheme = 'neurologist'), scrollToPoint()"
                    >
                      <p class="preference__element-text yellow">Невролог</p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-purple"
                        @click="
                        (currentTheme = 'neuropsychologist'), scrollToPoint()
                      "
                    >
                      <p class="preference__element-text purple">
                        Нейропсихолог
                      </p>
                    </button>
                  </div>
                  <div
                      class="questions-preference__element-wrapper"
                      @click="(currentTheme = 'nutritional'), scrollToPoint()"
                  >
                    <button class="questions-preference__element br-yellow">
                      <p class="preference__element-text yellow">Нутрициолог</p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-red"
                        ref="element"
                        @click="(currentTheme = 'pulmonologist'), scrollToPoint()"
                    >
                      <p class="preference__element-text red">Пульмонолог</p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-yellow"
                        @click="(currentTheme = 'psychiatrist'), scrollToPoint()"
                    >
                      <p class="preference__element-text yellow">Психиатр</p>
                    </button>
                  </div>
                  <div class="questions-preference__element-wrapper">
                    <button
                        class="questions-preference__element br-purple"
                        @click="(currentTheme = 'somnologist'), scrollToPoint()"
                    >
                      <p class="preference__element-text purple">Сомнолог</p>
                    </button>
                  </div>
                </div>
                <div class="" id="scrollPoint"></div>
                <div
                    v-if="currentTheme === 'aromatherapist'"
                    class="questions-group aromatherapist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Эфирное масло: что это?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Разные части растений: цветы, листы, корни, кора имеют в
                        составе сильнодействующие химические соединения. Они и
                        являются эфирными маслами.<br/>
                        Эти молекулы обладают уникальными свойствами - придают
                        растениям особый аромат, привлекая опылителей, защищают
                        их от насекомых-вредителей и опасных условий окружающей̆
                        среды: грибков, вирусов, бактерий, охлаждения и
                        перегревания, исцеляют при повреждениях.<br/>
                        Если эти природные химические вещества правильным
                        образом извлечены (для этого применяют холодный отжим,
                        паровую дистилляцию и другие методы), то могут быть
                        использованы в повседневной̆ жизни: для поддержания
                        здоровья и эмоционального состояния, восстановления
                        волос, кожи головы, нормализации сна.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Эфирное масло и аромамасло — в чём отличия?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Часто можно заметить, как понятия «эфирное масло» и
                        «аромамасло» подменяют друг друга. Давайте разберёмся:
                        эфирное масло обладает сильным ароматом, благодаря этому
                        свойству его могут называть ароматическим. Но вот
                        назвать ароматическое масло эфирным - будет ошибкой.<br/>
                        Эфир - это исключительно натуральный продукт. Получают
                        его естественным путем из цветов, листьев, коры,
                        корней.<br/>
                        Ароматические же масла разрабатываются в лаборатории.
                        Ароматическую композицию синтезируют химическим путем и
                        смешивают с растительным жиром. Получается масло,
                        которое искусственно получило необходимый аромат.<br/>
                        Чтобы понять, какое вам досталось масло – эфирное или
                        ароматическое, достаточно провести простой тест.<br/>
                        Капните на лист бумаги каплю вещества. Эфир выветрится,
                        не оставив после себя ничего. А вот ароматическое масло
                        сначала долго будет оставаться на поверхности, и,
                        впитавшись, оставит после себя жирное пятно.<br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Какие масла для чего помогают?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Лучше сконцентрироваться на учёбе или рабочих делах
                        помогут эфирные масла цитрусовых, мяты и розмарина.<br/>
                        Во время интенсивной умственной нагрузки хорошо помогает
                        жасмин. Нужно отметить, что он повышает скорость
                        мыслительных процессов на 25%, что например, будет
                        кстати, для студентов или школьников, готовящихся к
                        сдаче экзаменов. Масло лимона также тонизирует и
                        улучшает скорость усвоения новой информации.<br/>
                        Симптомы головной боли и мигрени снимут масла ромашки,
                        розмарина, перечной мяты и эвкалипта. Кроме того,
                        эвкалипт обладает бактерицидными свойствами, полезен при
                        простуде и заложенности носа.<br/>
                        Эфирные масла пихты и кедра используют для профилактики
                        заболеваний, достаточно нанести под носом 1-2 капли
                        экстракта.<br/>
                        Расслабиться и поднять настроение можно с помощью запаха
                        ванили, тимьяна и мелиссы, а лаванда способствует
                        хорошему сну.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как протестировать эфирное масло на переносимость?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Каждое масло имеет свои особенности, аромат, оно
                        элементарно может не подойти человеку, например, дать
                        аллергическую реакцию или вступить в конфликт с
                        эмоциями.<br/>
                        Чтобы это исключить, можно провести обонятельный тест.
                        Капнуть каплю масла на бумагу или кусочек ткани и
                        подышать на расстоянии 30см, не ближе, знакомясь с
                        ароматом, и определив для себя, какие эмоции он
                        вызывает.<br/>
                        Можно провести и накожную пробу.<br/>
                        Разбавьте 1 каплю эфирного масла в половине чайной ложки
                        базового масла-носителя и нанесите на локтевой сгиб.
                        Реакция может появиться не сразу, через час или даже
                        через полдня. Если масло не вызвало никаких изменений:
                        покраснения, зуда, отечности, сыпи, оно для вас
                        безопасно.<br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как правильно растворять эфирные масла при приеме ванны?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Эфирное масло обладает низкой плотностью, поэтому просто
                        растворить его в воде не получится. Оно будет
                        скапливаться каплями на поверхности.<br/>
                        Чтобы равномерно распределить средство в воде, нужно
                        предварительно растворить его в эмульгаторе. Для этого
                        подходят:<br/>
                        · Свежее молоко или сливки, любое базовое растительное
                        масло (миндальное, оливковое, виноградное и т.д.) —
                        возьмите 2 столовые ложки любого из перечисленных
                        продуктов, добавьте нужное количество эфира, тщательно
                        размешайте и добавьте в воду.<br/>
                        · Соль для ванны — например, морская, английская,
                        гималайская. Можно взять даже обычную поваренную.
                        Накапайте эфирное масло на соль, взятую в количестве 1
                        ст. л. с горкой, а затем растворите ее в воде.<br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что будет в случае увеличения дозировок эфирных масел?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        «Чем меньше, тем лучше» придерживайтесь этого
                        принципа.<br/>

                        Компоненты эфирного масла усваиваются организмом в
                        минимальном количестве, больше ему попросту не нужно, а
                        переизбыток ложится «на плечи» органов выводящей
                        системы.<br/>

                        Таким образом, увеличение дозировки эфирного масла не
                        принесет положительного эффекта, и станет дополнительной
                        нагрузкой для печени, почек и других органов.<br/>

                        Необходимо учитывать и то, что регулярные и чрезмерные
                        передозировки могут привести к токсическим поражениям
                        внутренних органов.<br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как хранить эфирные масла?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Эфирные масла и смеси эфирных масел следует хранить
                        плотно закрытыми, в темном, недоступном для детей месте,
                        вдали от открытых источников огня, при температуре от -5
                        до 30ºС.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Можно ли использовать эфирное масло от депрессии или
                        стресса?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Да, у эфирного масла есть удивительное свойство –
                        положительное воздействие на эмоции человека. Наука
                        объясняет это так: после вдоха аромат обрабатывается
                        обонятельной системой, которая отвечает за восприятие
                        запахов, после аромат достигает лимбической системы,
                        хранящей наши эмоции и воспоминания.<br/>
                        Как это работает дальше? Мозг обрабатывает полученный
                        «маячок», и связывает запахи с воспоминаниями из памяти.
                        Так и получается, что правильно подобранные масла
                        вызывают прилив эмоций и настроения.<br/>
                        Масло лаванды, например, содержит элементы, которые
                        делают его эффективным для снятия раздражения кожи,
                        уменьшения беспокойства и ощущения напряжения, создает
                        располагающую атмосферу для спокойного сна.<br/>
                        Конечно, люди абсолютно по-разному реагируют на один и
                        тот же аромат, поэтому подбор масел, помогающих поднять
                        настроение и справиться со стрессом, сугубо
                        индивидуален.<br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Кому подходят эфирные масла?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Эфирные масла уникальны по природе, каждый человек может
                        найти свой вариант масла, который запустит в его
                        организме необходимые процессы. Они подходят взрослым и
                        детям. Дело лишь в правильной концентрации, правильном
                        способе применения для каждого конкретного масла и
                        человека. Рекомендовано учитывать сопутствующие
                        заболевания, вес, возраст, психоэмоциональное
                        состояние.<br/>
                        Общим противопоказанием, которое указывают все
                        производители эфирных масел, является беременность и
                        младенческий возраст.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'gastroenterologist'"
                    class="questions-group gastroenterologist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что такое синдром раздраженного кишечника?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Синдром раздраженного кишечника – один из наиболее
                        распространенных диагнозов в гастроэнтерологии. Для него
                        характерны дискомфорт, боли в животе, метеоризм и
                        нарушение функции кишечника. Этот синдром относится к
                        функциональному заболеванию кишечника, иными словами,
                        при обследовании современными диагностическими методами
                        не удается определить ни язв, ни видимого воспаления.
                        Казалось бы, нет причин для плохого самочувствия, а
                        симптомы есть. Поэтому, из вышесказанного следует, что
                        СРК – это диагноз исключения.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        В чём может быть причина гастрита?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Можно выделить две основные причины гастрита: <br/>
                        · инфицирование бактерией (H. Pylori) через общую с
                        носителем посуду, поцелуи (слюну);<br/>
                        · неправильное питание — фастфуд, копчености,
                        неравномерное питание — длительные перерывы в еде и
                        переедание 1–2 раза в день. <br/>
                        К факторам риска также относят частые стрессы,
                        врожденную предрасположенность, курение.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Молоко оказывает успокаивающее действие на желудок –
                        правда или миф?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Ещё с детства мы привыкли слышать, что молоко полезно.
                        Однако, его польза при заболеваниях желудка
                        преувеличена. Многими исследованиями доказано, что уход
                        боли и нейтрализация соляной кислоты после приёма молока
                        - это временное облегчение, спустя время секреция
                        соляной кислоты в разы увеличивается. Плюс к тому, у тех
                        пациентов, которые придерживаются молочной диеты,
                        процесс рубцевания язв протекает медленнее, чем у
                        пациентов, не употребляющих молоко. <br/>
                        Ещё нужно учитывать непереносимость лактозы.
                        Употребление цельного молока у таких людей приводит к
                        неблагополучию со стороны желудочно-кишечного тракта:
                        метеоризму, вздутию живота, нарушению стула и прочим
                        дискомфортным ситуациям. В этом случае козье молоко
                        безопаснее, чем коровье, оно содержит лактозу на 13%.
                        <br/>
                        Сегодня можно обратиться к современным препаратам,
                        обладающим обволакивающим действием на слизистую
                        оболочку желудка и снижающих избыточную секрецию
                        желудочного сока. Они точно не вызовут лишних проблем со
                        стороны нижележащих отделов желудочно-кишечного тракта.
                        К ним можно отнести антациды, альгинаты, семена льна и
                        овсяные напитки. <br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Когда стоит обратиться к врачу гастроэнтерологу?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Не нужно игнорировать и пускать на самотёк любое из
                        нарушений пищеварительной системы, обратитесь к врачу
                        при:<br/>
                        · изжоге; <br/>
                        · нарушениях стула;<br/>
                        · болях любого характера в области живота, в
                        подреберье;<br/>
                        · тошноте, которая накатывает эпизодами;<br/>
                        · появлении неприятных привкусов во рту;<br/>
                        · пожелтении склер глаз и кожи.<br/>
                        Также порекомендовать консультацию гастроэнтеролога
                        могут и другие врачи.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как стресс влияет на пищеварение?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Стрессовые ситуации действительно могут стать причиной
                        изменений в работе систем организма. И их влияние на
                        работу желудочно-кишечного тракта нельзя недооценивать.
                        В числе последствий стрессовых ситуаций: спазмы
                        пищевода, отрыжка, тошнота, увеличение производства
                        кислоты в желудке, запоры или диареи, синдром
                        раздраженного кишечника и даже такое тяжёлое заболевание
                        как «стрессовая язва». Правильную тактику лечения
                        подберёт для вас врач – это могут быть медикаментозные и
                        немедикаментозные варианты лечений.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что включают в себя «правильные» перекусы, которые
                        советуют врачи?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Под «правильными» подразумевают здоровые перекусы между
                        приемами пищи. Это могут быть бананы, кисломолочные
                        продукты, морковь, немного орехов, сухофрукты. Здесь
                        учитываются индивидуальные предпочтения и особенности
                        уже существующего лечения у пациентов. Например, у
                        диабетиков – это может быть ограничение по фруктам.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как восстановить микрофлору кишечника после приема
                        антибиотиков?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Статистика говорит о том, что у каждого третьего
                        человека при приёме антибиотиков страдает микрофлора
                        кишечника. Происходит это из-за гибели «полезной»
                        собственной флоры, размножения патогенных и
                        условно-патогенных микроорганизмов, в том числе
                        грибковой флоры. Вот как раз она не чувствительна к
                        действию антибиотиков. <br/>
                        Для того, чтобы помочь своему кишечнику и предотвратить
                        нарушения, необходимо параллельно с антибиотикотерапией
                        принимать специализированные препараты для
                        восстановления микрофлоры кишечника. С этой целью можно
                        использовать некоторые пребиотики, пробиотики,
                        содержащие бифидо – и лактобактерии. Также пересмотрите
                        свой образ жизни: следите за психоэмоциональным
                        состоянием, скорректируйте рацион питания, откажитесь от
                        спиртного и курения. Всё в комплексе даст положительный
                        эффект.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Действительно ли йогурты могут исправить микрофлору
                        после антибиотиков?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Йогурты содержат про- и пребиотики, а это необходимое
                        условие для того, чтобы наша микрофлора была в отличном
                        состоянии. Отдавайте предпочтение йогуртам термостатным
                        - они приготовлены специальным образом и содержат
                        большой процент пробиотиков.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Диагноз «дисбактериоз» – это правда или вымысел?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Пациенты часто говорят о «дисбактериозе» как о диагнозе.
                        Но такой диагноз не включён в международную
                        классификацию болезней. Среди врачей также нет единого
                        мнения о том, что же такое дисбактериоз. Дело в том, что
                        существует просветная и пристеночная микрофлора и анализ
                        кала на дисбактериоз оценивает лишь просветную
                        микрофлору! Нужно отметить, что существует миллион
                        различных бактерий, а анализ кала на дисбактериоз
                        оценивает всего лишь пару десятков из них. <br/>
                        Дисбактериозу кишечника обычно приписываются такие
                        симптомы как метеоризм, расстройства стула, запоры или
                        их чередование, периодические схваткообразные боли по
                        ходу толстой кишки, и они в действительности служат
                        проявлением синдрома раздраженного кишечника (реже –
                        скрыто протекающей лактазной недостаточности).
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Вздутие живота после еды: о чем говорит эта проблема?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        У метеоризма и вздутия живота множество причин. К
                        дискомфортному ощущению тяжести, урчанию после приёма
                        пищи чаще всего приводят диетические предпочтения и
                        пищевые привычки. Это всем известные перекусы «на бегу»,
                        употребление большого количества продуктов, содержащих
                        сложные вещества, которые не так-то просто переварить –
                        в эту группу входят бобовые и картофель. К вздутию может
                        привести любовь к хлебобулочным изделиям, жевание
                        жвачки, питье газированных напитков, курение. Нужно
                        учитывать и то, что беременность нередко провоцирует
                        сдавливание кишечника, особенно на поздних сроках.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'dermatologist'"
                    class="questions-group dermatologist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        С какими болезнями кожи чаще всего обращаются к
                        дерматологу?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Если вас беспокоят изменения, касающиеся кожного покрова, эпителия, волос и ногтей, то вам к
                        дерматологу. Насчитывается несколько десятков болезней, которые они диагностируют и лечат. <br>
                        Например, можно выделить сезонные болезни. В зимнее и осеннее время часто обращаются с
                        обострениями атопического дерматита, экзем, псориаза. После летнего сезона нередко
                        диагностируются витилиго, в том числе у детей, когда на загоревшей коже проявляются белые пятна.
                        Угревая болезнь, или акне, изменения ногтевых пластин, осмотры родинок и других новообразований
                        – осложнения вне сезонов. <br>
                        После перенесенной коронавирусной инфекции через 1—2 месяца пациенты обращаются с телогеновым
                        выпадением волос, причем довольно часто. Кроме того, после COVID-19 пациенты жалуются на
                        проявления васкулита, когда нарушается проницаемость сосудов, появляются мелкие кровоизлияния на
                        коже в виде пятнышек, также чаще стали встречаться герпетические инфекции.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Есть ли принципиальные различия между мужской кожей и
                        женской?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        С точки зрения физиологии, кожа у всех одинаковая. Но существуют заболевания, которые чаще
                        встречаются у мужчин, и реже у женщин, и наоборот. Например, угревая болезнь —
                        предрасположенность к этому состоянию есть и у тех, и у других, но тяжелая форма акне чаще
                        диагностируется именно у пациентов мужчин. Это объясняется просто – проявление угрей находится в
                        прямой взаимосвязи с мужскими половыми гормонами. У женщин они присутствуют в меньшем
                        количестве, а значит, и этому заболеванию представительницы прекрасного пола подвержены меньше.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Правда ли, что состояние кожи связано с питанием?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Есть люди, которые не ставят себе запретов в питании, и у них идеальная кожа, это большая удача.
                        Чаще бывает по-другому. Акне, морщины, сосудистая сеточка, тусклый цвет лица — все эти проблемы
                        могут стать последствием употребления тех или иных продуктов питания. И далеко не всегда вред
                        коже наносят только сладости. В списке нездорового питания: фастфуд, соленая, жирная и острая
                        еда. <br>
                        Сравните пищу из своего рациона со списком нежелательных продуктов для кожи: <br>
                        1. Молочные продукты (из коровьего молока) могут усилить проблему высыпаний. Также они могут
                        стать причиной кругов под глазами и отеков. Все из-за того, что в молоке содержатся гормоны.
                        <br>
                        2. Сладкое. Его любят многие, но, к сожалению, не все осознают, что оно является врагом не
                        только для нашей фигуры, но и для кожи. Сахар вызывает процесс гликирования белков, который
                        отвечает за упругость и эластичность кожи. <br>
                        3. Выпечка. В сладких кондитерских изделиях присутствуют сахарозаменители. Выпечка из муки
                        высшего сорта, сливочного масла и сахара является причиной усиления воспалительных процессов и
                        высыпаний. <br>
                        4. Чипсы из картофеля губительны для печени и тем самым являются причиной воспалительных
                        процессов на коже. В состав чипсов также входит много соли и простых углеводов. <br>
                        5. Содержание маргарина в соусах и различных продуктах ускоряет процесс старения и сухости кожи.
                        Он является главным источником «плохих» жиров. <br>
                        6. Соль. Совсем не случайно в народе ее прозвали «белая смерть». Она задерживает воду, что ведёт
                        к сухости кожи, появлению трещин и отекам. Даже если ее мало употреблять в чистом виде,
                        состояние кожи все равно не будет хорошим. Это связано с тем, что соль есть в составе творога,
                        колбасы, полуфабрикатов и многих продуктов. <br>
                        7. Чашка кофе помогает нам пробудиться. Но слишком частое его употребление приводит к
                        обезвоживанию, кожа быстро теряет столь необходимую ей влагу. Появляются шелушение, ухудшается
                        цвет лица, ускоряются процессы старения кожи. Если отказаться от него сложно, лучше вместе с ним
                        пить как можно больше воды.<br>
                        8. Красное мясо. Оно повышает образование фосфора в организме, что вредно для работы почек и
                        опасно ранним старением сосудов.<br>
                        9. Специи. Мы привыкли добавлять в мясо, супы различные пряности, но не задумываемся об их
                        вреде. Они могут сделать вкус еды интереснее, но при этом вызывают расширение сосудов, а от
                        этого могут появиться сосудистая сетка, покраснения и воспаления.<br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        В чем главная причина сильной сухости кожи?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Единственную причину выделить сложно. Чаще всего это нарушения гидролипидной защитной мантии и
                        микробиома кожи. К этому ведут внешние факторы: агрессивный уход, погода, солярий, увлажненность
                        воздуха в квартире и т.д, и внутренние: недостаток потребления жидкости, неправильное питание,
                        авитаминоз, болезни различных органов и систем, прием лекарств, вредные привычки, возраст и
                        наследственность.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что такое гормональные мази?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Гормональные наружные средства (мази, крема, лосьоны) – средства, обладающие мощным
                        противовоспалительным и антиаллергическим эффектами. Важно понимать, что дерматологи их просто
                        так не назначают: к ним обращаются в случае острых и хронических заболеваний, таких как
                        атопический дерматит, себорейный дерматит, псориаз, аллергический дерматит и других. <br>
                        Чтобы понять, нужна или нет вам гормональная мазь, запишитесь на прием, врач–дерматолог проведет
                        осмотр, оценит тяжесть симптомов, особенности кожного процесса, и после этого подберёт
                        соответствующую наружную терапию. Препарат должен подходить по форме своей активности, быть
                        максимально безопасным и эффективным при данном заболевании. Ни в коем случае не стоит выбирать
                        препарат самостоятельно и пользоваться им бесконтрольно. Помните, что побочные эффекты никто не
                        отменял.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Можно ли вылечить псориаз?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Все хронические заболевания, в число которых входит и псориаз, объединяет одно - полностью
                        вылечить их невозможно. <br>
                        Но псориаз можно перевести в стадию ремиссии, при которой зудящие красноватые бляшки на коже не
                        появляются. Например, на течение псориаза благоприятное воздействие оказывает фототерапия. Этот
                        вид аппаратного дерматологического лечения дает видимый результат, не сопровождается побочными
                        эффектами, и даже не требует пребывания в стационаре.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Может ли родинка стать причиной рака?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Да, иногда самая обычная родинка может перерасти в рак кожи, поэтому дерматологи советуют
                        контролировать состояние кожных наростов и обращаться к врачам.<br>
                        Кожные новообразования бывают доброкачественными и злокачественными, способными спровоцировать
                        раковые клетки. Некоторые наросты не всегда приводят к угрозе здоровью, но случается, что они
                        изменяются и перерастают в злокачественные опухоли.<br>
                        Если по какой-то причине вы хотите удалить родинку, обязательно перед этим проконсультируйтесь с
                        врачом-дерматологом и, если необходимо, онкологом - операция может послужить толчком для
                        появления новых, более опасных родинок. Также нельзя удалять их самостоятельно в домашних
                        условиях народными методами и в косметологических кабинетах. Доверяйте свое здоровье только
                        врачам. <br>
                        При каких изменениях необходимо срочно обратиться к врачу:<br>
                        · Асимметрия родимого пятна.<br>
                        · Если заметна кровь, нарывы (язвочки) на самом родимом пятне.<br>
                        · Воспалительный процесс (покраснение).<br>
                        · Окрас пятна (осветление, потемнение).<br>
                        · Значительный рост родимого пятна.<br>
                        · Появились шелушения, корочки, пузыри.<br>
                        · Родимое пятно затвердело или наоборот размякло.<br>
                        · Создает дискомфорт при трении об одежду.<br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Ежедневное принятие водных процедур – вред или польза?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Все хорошее должно быть в меру. Если вы купаетесь дважды в день, только потому, что так
                        положено, эта привычка может принести вам больше вреда, чем пользы. <br>
                        В летнее время мы часто потеем, любые микроорганизмы начинают активнее размножаться, что
                        приводит к неприятному запаху. Могут возникнуть заболевания вроде разноцветного лишая - он
                        возникает из-за избыточного роста грибков, которые в норме живут на коже. Летом однозначно
                        мыться нужно ежедневно, используя щадящие гели для душа — не цветные и не ароматизированные,
                        которые не будут разрушать липидный барьер.<br>
                        Необходимо ли мыться ежедневно зимой — вопрос спорный. У нас вода довольно жесткая, в домах
                        жарко и сухо, на улице холодно и сухо — кожа и так подвергается агрессивному воздействию внешней
                        среды. Кроме того, например, у детей с атопическим дерматитом кожа сухая, она плохо выполняет
                        барьерную функцию. Если часто смывать с нее и без того тонкий липидный слой, который она
                        производит, это может спровоцировать обострение болезни.<br>
                        Следите за гигиеной тела, но не переусердствуйте. И не забывайте после водных процедур наносить
                        питательный лосьон или увлажняющее молочко, чтобы сохранить необходимую влагу в коже.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как защитить ногти от грибка?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Грибок ногтей – весьма неприятное заболевание, которое доставляет существенный дискомфорт. При
                        нем ногтевые пластины изменяют свои цвет, форму, структуру. Чтобы этого не произошло, важно не
                        только знать, но и соблюдать меры профилактики:<br>
                        · после посещения общественного бассейна обязательно примите душ, а после него насухо вытрите
                        ноги; <br>
                        · не ходите в общественном бассейне, сауне или душе босиком;<br>
                        · в фитнес-зал приносите индивидуальный коврик;<br>
                        · на педикюре всегда обращайте внимание на то, как обрабатываются инструменты;<br>
                        · не надевайте чужую обувь.<br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'cardiologist'"
                    class="questions-group cardiologist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как распознать сердечный приступ и чем помочь?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Симптомы инфаркта миокарда должен знать каждый человек.
                        Это боль или неприятное ощущение в грудной клетке,
                        которое отдает в левое плечо, челюсть, спину. Если
                        человек испытывает затрудненное дыхание или нехватку
                        воздуха, тошноту, рвоту, головокружение, теряет сознание
                        и покрывается холодным потом - скорее вызывайте скорую
                        помощь! И обязательно скажите про боли в груди, тогда к
                        вам будет направлена кардиологическая бригада.<br/>
                        В этой ситуации время играет против нас и становится
                        драгоценным: клетки миокарда жизнеспособны в течение
                        40-60 минут. Чем быстрее пациента доставят в больницу,
                        тем быстрее он попадет в рентгеноперационную,
                        закупоренную тромбом артерию откроют и спасут миокард.
                        Если время будет упущено, у пациента могут развиться
                        жизнеугрожающие состояния.<br/>
                        Обратите внимание на то, что при болевом синдроме
                        человеку может быть трудно лежать, он задыхается. Не
                        старайтесь его обязательно уложить. Пусть он займет
                        положение, в котором удобно дожидаться помощи.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Инфаркт миокарда в молодом возрасте. В чём кроется его
                        причина?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Инфаркт миокарда у пациентов от 30 до 40 лет чаще
                        встречается у людей с отягощенной наследственностью -
                        если у кровных родственников пациента были инфаркты или
                        инсульты в возрасте до 60 лет. Однако, генетическая
                        предрасположенность — вовсе не приговор. Артериальная
                        гипертония и атеросклероз не считаются чисто
                        наследственными заболеваниями, это многофакторные
                        заболевания, то есть, несмотря на генетическую
                        предрасположенность, не всякий человек перенесет инфаркт
                        или инсульт, для этого необходим еще ряд неблагоприятных
                        факторов.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Какие сердечные симптомы должны насторожить?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Прежде всего, это боль в левой половине грудной клетки
                        или за грудиной. Острая и жгучая боль в области сердца,
                        которая может отдавать в левую руку или в челюсть,
                        отсутствие облегчения после приема нитроглицерина – всё
                        это повод не просто обратиться к врачу, а оперативно
                        вызвать скорую помощь.<br/>
                        Так же в числе симптомов, на которые нужно обратить
                        внимание:<br/>
                        Давящая или сжимающая боль в области сердца, возникающая
                        на фоне физической нагрузки и проходящая в состоянии
                        покоя.<br/>
                        Перебои в работе сердца: учащение ритма, аритмия.<br/>
                        Еще один важный симптом – одышка или ощущение нехватки
                        воздуха.<br/>
                        Отеки ног – неспецифический симптом – чаще всего они
                        возникают вечером и проходят к утру.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Когда начать заботиться о сердце?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Как показывает практика, лучше – раньше, чем позже.
                        Профилактикой сердечно-сосудистых заболеваний нужно
                        заниматься с самого раннего детства. Желательно, чтобы у
                        детей перед глазами был пример ежедневной физической
                        активности, правильного питания, отсутствия вредных
                        привычек — это лучший вклад в их здоровье.<br/>
                        Если у человека, который ведёт здоровый образ жизни,
                        хорошо себя чувствует, имеется отягощенная
                        наследственность, возможно у близких родственников были
                        инфаркты или инсульты в достаточно молодом возрасте,
                        начиная с 30 лет, стоит ежегодно сдавать анализы на
                        уровень глюкозы, липидный спектр, креатинин (показывает
                        функцию почек), делать ЭКГ. Это «золотой стандарт».<br/>
                        Всем остальным людям, у которых наследственность не
                        отягощена и все в порядке со здоровьем, важно не
                        пренебрегать диспансеризацией. С 40 лет имеет смысл раз
                        в год сдавать анализы и делать кардиограмму.<br/>
                        Учитывая ситуацию с новой коронавирусной инфекцией,
                        нужно отметить, что люди, имеющие хронические
                        заболевания, страдающие ожирением, сахарным диабетом и
                        артериальной гипертонией, попадают в особую группу риска
                        заражения. Но важно понимать: если человек с хроническим
                        заболеванием сердечно-сосудистой системы заболел
                        COVID-19, но выполняет все рекомендации врача, принимает
                        терапию в полном объеме, держит давление в норме, то он
                        легче перенесет заболевание. Это уже доказано опытом в
                        рамках лечения новой коронавирусной инфекции.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Всегда ли слабость и упадок сил свидетельствуют о
                        пониженном давлении?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Упадок сил может возникнуть по разным причинам: на фоне
                        умственных перегрузок, переутомления, стать
                        предвестником простуды, инфекционных заболеваний, Но
                        если такое состояние является для вас нормой, вернуть
                        жизненный тонус поможет увеличение физической активности
                        или прием пищи (что особенно актуально для любителей
                        жестких диет). Когда снижение давления сопровождается
                        предобморочными состояниями, головокружением - требуется
                        срочная помощь специалиста.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        С возрастом болезни сердца неизбежны – правда или миф?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Старение входит в список наиболее известных факторов
                        риска большинства болезней человека, в том числе
                        сердечно-сосудистых заболеваний. Распространенность
                        артериальной гипертонии увеличивается с возрастом и в
                        возрастной группе старше 60 лет достигает 60%.
                        Атеросклероз также расценивается как болезнь старения.
                        Со временем сужается просвет кровеносных сосудов, хуже
                        питаются клетки и ткани организма. Таким образом, чем
                        старше человек становится, тем выше риск развития у него
                        сердечно-сосудистых заболеваний.<br/>
                        И все-таки, какую бы роль ни играл фактор возраста,
                        каким бы ни был природный процесс, если человек своим
                        поведением усугубляет положение – мало двигается,
                        неправильно питается, набирает лишний вес,
                        злоупотребляет курением и алкоголем - инфаркт или
                        инсульт у него может случиться и в 40 лет. Помните,
                        контроль здоровья - в ваших руках.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Инфаркт миокарда у мужчин и женщин: отличаются ли
                        симптомы?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Основные симптомы схожи, но отличия всё же есть. На эту
                        тему в 2016 году Американская ассоциация сердца даже
                        выпустила специальный документ. Из него следует, что у
                        мужчин инфаркт миокарда чаще имеет классическое
                        «кинематографическое» течение: интенсивная боль за
                        грудиной, отдающая в оба плеча, тяжелая одышка.<br/>
                        У женщин чаще встречаются атипичные симптомы: тошнота и
                        рвота, боли в верхней части спины, шее, челюсти,
                        нехватка воздуха, необычная утомляемость и тревожность.
                        Скажем, при таком неспецифическом симптоме, как боль в
                        плече или руке, у женщин вероятность инфаркта миокарда в
                        два раза выше, чем у мужчин.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Стоит ли при болях в сердце пить валокордин, корвалол
                        или валидол?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        В названиях средств валокордин, корвалол отчетливо
                        слышится слово «сor», которое в переводе с латыни
                        означает «сердце», и пожалуй на этом их взаимоотношения
                        с «сердечными» делами заканчиваются. В действительности
                        там нет ни одного компонента, который мог бы иметь
                        отношение к лечению болезней.<br/>
                        В состав этих препаратов входит некоторое количество
                        мяты и валерианы, а также очень старый и очень
                        небезопасный транквилизатор фенобарбитал. При длительном
                        применении он может вызвать зависимость и привести к
                        головокружению, слабости, расстройству сна, агрессии.
                        Поэтому не принимайте его без консультации с лечащим
                        врачом.<br/>
                        Что касается валидола, это просто конфета «холодок» с
                        высоким содержанием ментола. Поэтому, если пациенту
                        помогает валидол, проблема явно не в сердце.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Чего не любит наше сердце?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        В этом списке нездоровое питание (консервы, жирное и
                        жареное, продукты с ароматизаторами и красителями),
                        малоподвижный образ жизни, ожирение или избыточный вес,
                        злоупотребление алкоголем, курение - 5 сигарет в день на
                        40% повышают риск смерти.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Каким должно быть питание, полезное для сердца?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Есть даже специальная диета, направленная на сохранение
                        здоровья и восстановление сердечно-сосудистой системы,
                        она строится на следующих принципах: <br/>
                        · потребление натуральных продуктов, особенно некоторых
                        овощей и фруктов ( это бананы, гранаты, хурма, помидоры,
                        лук, чеснок, тыква);<br/>
                        · соблюдение режима питания;<br/>
                        · ежедневное потребление достаточного количества
                        воды;<br/>
                        · исключение всех вредных продуктов и привычек,
                        алкоголя;<br/>
                        · потребление достаточного количества калия — калиевая
                        диета считается полезной для сердца, оптимизирует его
                        работу и нормализует давление;<br/>
                        · можно перейти на соль с высоким содержанием калия,
                        замещающего в составе натрий. Все эти рекомендации
                        основываются на научных исследованиях.<br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'exercise'"
                    class="questions-group exercise flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Лечебная физическая культура: что входит в структуру
                        занятий?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Занятия включают в себя три этапа: подготовительный,
                        основной и завершающий. На подготовительном этапе обычно
                        происходит «разогрев» -проводится комплекс разминочных
                        упражнений и суставной гимнастики для разогрева тканей,
                        а на завершающем – используются техники релаксации.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Сочетается ли ЛФК с другими физиотерапевтическими
                        методами?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Если добавить к занятиям лечебной физической культуры
                        массаж, рефлексотерапию, физиотерапию можно получить
                        максимальный эффект. Часто ЛФК применяют в комплексе с
                        эрготерапией, трудотерапией и психотерапией, особенно
                        это касается инвалидов и лиц пожилого возраста,
                        перенесших тяжелые заболевания.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Кому показано заниматься ЛФК?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Если вы хотите сохранить красоту тела, здоровье,
                        бодрость духа, и у вас нет противопоказаний от врача,
                        смело занимайтесь ЛФК. Лечебная физкультура ускорит
                        обменные процессы, повысит защитные силы организма,
                        восстановит нормальный ритм работы внутренних органов, и
                        поможет вывести из малоподвижного образа жизни.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Какие противопоказания существуют у ЛФК?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        В некоторых случаях лечебная физкультура все же
                        противопоказана. Она не назначается при обострении
                        хронических заболеваний, кровотечениях, при острых
                        воспалительных процессах в организме, пациентам с
                        онкологическими образованиями. Аневризмы, выраженный
                        тромбоз и тяжелая степень гипертонии также являются
                        противопоказаниями для занятий. Решение во всех
                        остальных случаях доверьте своему лечащему врачу. Даже
                        больным, находящимся в реанимации, могут назначить
                        несложные пассивные упражнения.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Какие рекомендации нужно соблюдать при самостоятельном
                        выполнении занятий ЛФК после COVID-19?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Многие из тех пациентов, кто переболел COVID-19,
                        столкнулись с проблемами различных органов и систем. У
                        них часто возникает ощущение нехватки воздуха из-за
                        того, что легочная ткань утрачивает эластичность и не
                        получает должного обогащения кислородом. Чтобы
                        предотвратить необратимые изменения в легких, нужно
                        постараться максимально вовлечь легочную ткань в процесс
                        дыхания. При этом важно помнить: в активной фазе
                        заболевания легочная ткань становится очень ранимой, ей
                        легко нанести дополнительную травму — баротравму
                        легкого. В этот период нельзя даже прибегать к такому
                        упражнению, как надувание шариков, обращаться к
                        форсированным дыхательным упражнениям. Автором курса
                        «Практики для легких и дыхания» разработан простой
                        комплекс упражнений дыхательной гимнастики, который
                        можно выполнять самостоятельно в домашних условиях во
                        время или после болезни. Делать упражнения нужно
                        аккуратно, не задерживая дыхание надолго, это может
                        усугубить дыхательную недостаточность. Все должно быть
                        направлено на глубокое дыхание по возможности,
                        диафрагмой.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        ЛФК и психоэмоциональное состояние: как взаимосвязаны
                        занятия и наши эмоции?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        ЛФК весьма позитивно влияет на психоэмоциональное
                        состояние. Обусловлено это тем, что активность
                        пробуждает эндокринную систему вырабатывать дофамин и
                        эндорфин – известные как гормоны удовольствия, и,
                        напротив, подавляет выделение гормона стресса –
                        кортизола. Так же физические нагрузки стимулируют работу
                        головного мозга, уменьшают спазмы и зажимы, нормализуют
                        давление, как следствие человек чувствует себя намного
                        бодрее.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Нужны ли человеку, который и так ведёт активный образ
                        жизни, занятия ЛФК?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Между активным образом жизни и ЛФК есть большая разница.
                        Ежедневная физическая активность, к которой мы привыкли:
                        работа по дому или в огороде, вождение автомобиля,
                        забота о маленьких детях чаще несут осложнения для
                        организма, чем помогают. Однообразные движения утомляют
                        одни мышцы и не задействуют другие, в результате чего
                        появляется боль, возникают нарушения тонуса. В этих
                        случаях на помощь приходит ЛФК: позволяет гармонично
                        влиять на все мышечные волокна и уменьшать спазмы. Так
                        что ЛфК для водителей, садоводов-огородников,
                        воспитателей или молодых мам необходима не меньше, чем
                        для тех, кто ведет пассивный образ жизни
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Оказывает ли ЛФК влияние на мозг?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Упражнения ЛФК участвуют в создании новых нейронных
                        связей, поэтому можно с уверенностью говорить, что
                        оказывают. Как следствие, после занятий
                        восстанавливается и улучшаются память, речь,
                        координация, внимание.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Поможет ли ЛФК при межпозвонковых грыжах?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Наличие межпозвонковых грыж наблюдается у пациентов
                        весьма часто. Сложность в том, что межпозвонковые грыжи
                        шейного и грудного отделов практически неоперабельны. В
                        этом случае ЛФК выступает наиболее эффективным методом
                        лечения, нужно лишь правильно подобрать комплекс
                        упражнений, который вернёт человека к привычному образу
                        жизни. Если соблюдать все рекомендации специалиста,
                        положительный эффект вы увидите через месяц активных
                        занятий.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'neurologist'"
                    class="questions-group neurologist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Боли в спине тоже лечит невролог? С какими симптомами к
                        нему обращаться?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Да, на прием к неврологу приходят пациенты с разнообразными симптомами. <br>
                        Неврологи занимаются заболеваниями нервной системы: центральной, то есть головного и спинного
                        мозга, и периферической — нервов, которые отходят к коже, мышцам, внутренним органам. Они лечат
                        головные боли, головокружения, эпилепсию, рассеянный склероз, нейропатии, помогают в лечении
                        болезней Паркинсона, Альцгеймера, Пика. <br>
                        Помимо этого неврологи занимаются инсультами и их последствиями. <br>
                        Поэтому, когда болит голова, есть какие-то нетравматические боли или онемения в конечностях, это
                        повод для визита к неврологу. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Есть ли заболевания, которые совместно лечат неврологи и
                        психиатры?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Случается и такое. Иногда психосоматические расстройства попадают к неврологу, а
                        неврологические, связанные с органическими поражениями центральной нервной системы, к психиатру.
                        <br>
                        Как правило, если расстройство не откровенно психиатрическое — человек не подвержен
                        галлюцинациям, у него нет бреда, выраженной депрессии или тревоги, — стоит сначала посетить
                        невролога, а он при необходимости направит к психиатру.<br>
                        Встречаются ситуации, когда психиатр нужен пациентам с деменцией или болезнью Паркинсона: у них
                        бывают галлюцинации или бред. Или на фоне неврологической болезни развивается тревожное
                        расстройство. Тогда неврологи и работают совместно с психиатрами, но обычно есть четкое
                        разделение между специальностями.<br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Стоит ли посещать невролога для профилактики?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Это просто незачем, если вы не столкнулись с конкретными проблемами или тревожными симптомами.
                        Никакого профилактического скрининга не существует.<br>
                        МРТ или КТ «на всякий случай» так же делать не требуется. Исследование необходимо, в том случае,
                        если пациенту даст направление врач и для этого есть определенные критерии. Например,
                        компьютерная или магнито-резонансная томография может потребоваться при боли в спине, а также,
                        если в анамнезе у пациента есть онкологические заболевания, имеются выраженные признаки
                        воспаления, такие как отек, покраснение, повышение температуры. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Когда с головной болью нужно обратиться к врачу, а когда
                        хватит таблетки?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Сигналом для обращения к специалисту служит абсолютно любая боль, которая ломает привычный уклад
                        жизни, нарушает её качество. Снять симптом с помощью таблетки и забыть о боли, можно в одном
                        случае, если она привычна для человека и возникает не чаще 1—2 раз в месяц.<br>
                        В других случаях - лучше обратиться к врачу. Вот основной список ситуаций, когда нужно
                        проконсультироваться со специалистом:<br>
                        · Продолжительная головная боль, которая беспокоит чаще 4 раз в месяц.<br>
                        · Вновь появившаяся головная боль, отличная от тех приступов, что были ранее.<br>
                        · Слишком ранний или поздний возраст первого проявления головной боли — когда она возникает у
                        подростков или людей старше 55 лет.<br>
                        · Головная боль с неврологической симптоматикой: слабостью и онемением конечностей, утратой
                        зрения.<br>
                        · Внезапная и сильная головная боль, её еще называют громоподобной <br>

                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Какие неврологические симптомы возможны после COVID-19?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Список довольно обширный, часто встречающиеся симптомы и патологические состояния, которыми
                        характеризуется поражение ЦНС во время течения болезни и постковидном периоде: <br>
                        · головные боли и головокружение, ухудшение памяти, нарушение восприятия;<br>
                        · частичное расстройство или полная потеря вкуса и обоняния;<br>
                        · чувство общей и мышечной слабости —онемение ног;<br>
                        · расстройство аппетита, пищеварения, тошнота, диспепсия, нарушения стула;<br>
                        · депрессия, панические атаки, тревожные состояния, слуховые и обонятельные галлюцинации;<br>
                        · нарушение внимания, концентрации, равновесия, проблемы с речью, восприятием информации;<br>
                        · миозиты, миелиты<br>
                        · полинейропатии<br>
                        Если такие симптомы начали беспокоить, обратитесь к неврологу, может потребоваться расширенное
                        обследование, лечение и даже последующая реабилитация.<br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как понять, что случилась паническая атака? У кого они
                        бывают чаще: у мужчин или у женщин?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Чаще панические атаки наблюдаются у женщин, чем у мужчин. В основном они появляются в возрасте
                        от 25 до 64 лет. Паническое расстройство можно считать умеренным, если происходит не более 4
                        приступов тревоги в месяц. Когда они возникают более 4 раз за неделю, говорят о паническом
                        расстройстве тяжелой степени. <br>
                        Характерная картина приступа у женщин и мужчин схожа: человек внезапно ощущает сильное
                        сердцебиение, перебои, боли и дискомфорт в области сердца. Появляется чувство нехватки воздуха.
                        Повышается артериальное давление. Могут добавиться тошнота, жидкий стул, тремор, потливость,
                        похолодание кистей и стоп. Повторяющиеся эпизоды приводят к тому, что пациент начинает мучиться
                        от самого страха ожидания новой панической атаки и старается избегать ситуаций, которые, как ему
                        кажется, приведут к новому приступу. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Обязательна ли компьютерная или магнитно-резонансная
                        томография при болях в голове или спине?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        К компьютерной или магнитно-резонансной томографии при головных болях обращаются не всегда.
                        Только малая их часть вызвана заболеванием, признаки которого видны на КТ или МРТ, — к примеру,
                        опухолью или синуситом. <br>
                        Около 95% головных болей — первичные, возникающие самостоятельно. Поэтому лучше сначала посетить
                        невролога, и уже обследоваться по его назначению. <br>
                        КТ или МРТ при болях в спине порекомендуют сделать, если: <br>
                        · Была травма спины. <br>
                        · Это самая худшая в вашей жизни головная боль. <br>
                        · Когда-то был поставлен онкологический диагноз. <br>
                        · Есть признаки воспаления, например, отек и покраснение около позвоночника. <br>
                        · Боль сопровождается повышением температуры. <br>
                        · Боли возникают ночью или в покое. <br>
                        · Боль, которая сопровождается нарушением зрения/слабостью конечностей/нарушением
                        чувствительности. <br>
                        · Боли впервые появились в детском или пожилом возрасте. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Лечится ли деменция? Нужно ли при этом заболевании
                        посещать врачей?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Деменция входит в число хронических заболеваний, поэтому не излечивается до конца. Но и в этом
                        случае пациенту можно попробовать помочь. Врач может назначить препараты, замедляющие скорость
                        развития болезни. Существует и другая помощь: родственников и пациента могут обучить тому, как
                        жить с этим непростым недугом, существуют психотерапия и аналогичные методики, повышающие
                        качество жизни пожилого человека. <br>
                        Для профилактики деменции необходимо бросить силы на поддержание общего состояния здоровья
                        пациента, сохранение физической активности, социальных контактов. Низкий уровень образования
                        считается одним из факторов риска старческого слабоумия. У людей, которые поддерживают и
                        развивают интеллектуальные функции, напротив, несмотря на возраст, деменция или не развивается
                        вовсе, или проявляется позже и протекает легче. <br>
                        Еще для профилактики деменции важен контроль артериального давления и уровня холестерина, отказ
                        от курения. Деменция бывает разной: есть болезни Альцгеймера и Пика, на которые повлиять сложно,
                        а есть сосудистая деменция. Она развивается из-за того, что нарушается кровоснабжение мозга. Ее
                        можно предупредить теми же методами профилактики, что и другие сердечно-сосудистые заболевания.
                        <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Чем можно помочь человеку при обмороке и чего делать
                        нельзя?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Обморок – это кратковременный приступ потери сознания на фоне шока, переутомления, боли или
                        стрессовых ситуаций. <br>
                        Чтобы помочь человеку при обмороке, придерживайтесь следующих действий, необходимо:
                        · Уложить пострадавшего на ровную поверхность – на землю или пол. <br>
                        · Обеспечить поступление крови к головному мозгу. Если под рукой нет подушки, подложите под ноги
                        валик из одежды, или же удерживайте ноги приподнятыми. <br>
                        · Освободить шею от сдавливания шарфом, галстуком, расстегнуть воротник рубашки, ремень брюк.
                        · Определить пульс и дыхание: пульс прощупывают на сонной артерии или на лучевой артерии на
                        предплечье. Дыхание определяют по движению грудной клетки, по увлажнению зеркала, приложенного к
                        носу и рту пострадавшего, а также по движению ваты, поднесенной к носу. <br>
                        · Обязательно обеспечить приток свежего воздуха: откройте окно, если вы в помещении, либо
                        обмахивайте пострадавшего импровизированным опахалом, журналом или газетой. <br>
                        · Повернуть голову пострадавшего набок во избежание западения языка и в случае рвоты во
                        избежание аспирации (вдыхания) рвотных масс. <br>
                        После первой помощи, вызывайте скорую помощь и не оставляйте больного одного. <br>
                        Еще при желании помочь, очень важно не навредить. Например, нельзя подкладывать валик из одежды
                        или подушку под голову пострадавшего, обливать водой и давать нюхать нашатырный спирт. Он может
                        помочь только в предобморочном состоянии, пока больной в сознании. После - он не сможет
                        отшатнуться или проконтролировать силу вдоха, что приведёт к ожогу слизистой носа парами
                        нашатырного спирта. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'neuropsychologist'"
                    class="questions-group neuropsychologist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Правда ли, что мы используем только 2% своего мозга? На
                        что - в теории - может быть способен наш мозг?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Это очень древний миф, который любят упоминать в кино.
                        Достаточно вспомнить сюжет фильма «Области тьмы». На
                        сегодняшний день ученые благодаря функциональной
                        магнитно-резонансной томографии могут измерять
                        активность мозга во время разной деятельности.
                        Выяснилось, что мозг работает целиком, если, конечно, он
                        не поврежден. Просто если вы двигаетесь, то наибольшая
                        активность будет в моторных областях, если вы говорите,
                        то в речевых, и это не значит, что все остальные -
                        ничего не делают. <br/>
                        Почему же в мозге не включаются одновременно все
                        области?<br/>
                        Представим на секунду, что мы можем возбудить
                        одновременно ВСЕ наши нейроны. Моторные нейроны запустят
                        все движения, на которые мы только способны, речевые
                        будут что-то воспроизводить, эмоции от смеха до слез,
                        огромное количество ярких ощущений, много мыслей
                        одновременно и т.д. Представляете, как выглядит человек
                        с работающим на 100% мозгом. Но, правда в том, что мозг
                        можно постоянно «улучшать» т.е. тренировать, и о
                        пределах его возможностей мы пока не знаем.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Правда ли, что COVID-19 заставляет мозг стареть быстрее?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Действительно уже есть исследования, которые
                        подтверждают информацию о том, что COVID-19 ускоряет
                        старение мозга. Это подтверждает вся симптоматика,
                        которой посвящён «Полный курс для памяти, внимания,
                        концентрации». Но я бы не стала уточнять цифры, на
                        сколько именно - на 10 или 20 лет. Все очень
                        индивидуально. И пока ученые будут изучать эти процессы,
                        наша задача очень простая – нейрореабилитация, т.е.
                        восстановление.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Могут ли тренировки мозга снизить риск развития
                        серьезных заболеваний памяти?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Да, снизить риск могут, гарантировать не могут. Никто не
                        может дать гарантий, что в старости Вас не настигнет
                        Альцгеймер или деменция. Тем не менее, если вы будете
                        все время тренировать свой мозг, не позволять ему долго
                        лениться (а он это любит), вне всякого сомнения, можно
                        во много раз увеличить шанс сберечь мозг.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        К какому специалисту идти, чтобы получить персональную
                        программу тренировок для мозга?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        В целом с целью развития когнитивных функций можно
                        обратиться к нейропсихологу или к педагогу, который
                        работает в этой специализации и сможет провести
                        диагностики и составит программу. Есть специалисты,
                        которые называются майнд-тренеры, они тоже разрабатывают
                        много интересных программ для тренировок. Главное найти
                        «своего» специалиста.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как измерить эффективность работы моего мозга? Есть ли
                        какие-то анализы или исследования, которые я могу
                        сделать?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Измерить эффективность работы головного мозга можно при
                        помощи диагностики, тестирования. А вот обследование
                        головного мозга - это сложная диагностическая задача для
                        любого специалиста. Он надежно спрятан под черепными
                        пластинами, поэтому в большинстве случаев доступен
                        только для неинвазивных методов диагностики. Если есть
                        тревожные симптомы, из-за которых эффективность работы
                        нарушается, то проводят обследования -
                        электроэнцефалограмму головного мозга, она помогает
                        проанализировать работоспособность головного мозга.
                        Процедуру назначают в случае задержки психоречевого
                        развития, при травмах, эпилептических припадках.<br/>
                        УЗДГ (ультразвуковая допплерография) Исследование
                        проводят для оценки кровотока в крупных и средних
                        сосудах головы и шеи. <br/>
                        МРТ (магнитно-резонансная томография) головы и сосудов
                        головного мозга - достаточно сложный, но
                        высокоинформативный метод обследования, основанный на
                        механизме ядерно-магнитного резонанса.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Современный мир, наполненный гаджетами и интернетом,
                        сделает нас глупее?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Люди используют интернет по-разному, кто-то играет в
                        простейшие игры с лопающимися шариками, а кто-то ищет
                        материал для доклада. Всех не сделает точно, но проблема
                        существует, так как, например, мы реже используем
                        память, чтобы что-то запомнить: телефоны, даты рождения.
                        Поисковые системы находят для нас любую информацию,
                        которые мы используем и мгновенно забываем. Для
                        ориентации на местности используем навигатор.<br/>
                        Очевидно и то, что новые технологии значительно
                        облегчают нам жизнь.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Какие есть еще способы из общеизвестных и доступных по
                        улучшению когнитивных функций?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        1.Очень эффективным упражнением для развития памяти
                        является не зубрежка стихов – это скучно, хотя это тоже
                        работает, а пересказ. Т.е. воспроизведение полученной
                        информации. Вот вы прочитали или посмотрели что-то и
                        теперь вам нужно это переработать и воспроизвести уже
                        своими словами, желательно несколько раз. Если у вас нет
                        слушателей, то можно пересказывать самому себе. А еще
                        более интересный метод – пересказывать и записывать на
                        диктофон (сейчас он есть в телефоне), можно на видео.
                        Переслушайте, что получилось. Скорее всего, в процессе
                        прослушивания вы вспомните еще детали, которые с самого
                        начала могли упустить.<br/>
                        2. Изучайте новые языки.<br/>
                        3. Учитесь играть на музыкальных инструментах.<br/>
                        4. Займитесь неизвестным ранее видами рукоделия или
                        искусства – вязание, вышивание, плетение, шитье,
                        пэчворк, роспись по дереву, стеклу, выкладывайте мозаику
                        из любых материалов, создавайте картины нитями. Есть уже
                        готовые наборы для творчества.<br/>
                        5.Танцуйте и учите новые движения.<br/>
                        6. Уменьшайте количество стресса.<br/>
                        7.Поддерживайте здоровье и активный образ жизни.<br/>
                        8. Играйте в игры, которые развивают мозг – шахматы,
                        пентаго, нарды, есть карточные игры, которые тоже
                        заставят Ваш мозг усердно работать. Кстати, многие из
                        этих игр есть в виде приложений, где можно играть с
                        виртуальным соперником.<br/>
                        9. Очень важно высыпаться, так как именно во сне
                        происходит перемещение информации из краткосрочной
                        памяти в долгосрочную.<br/>
                        10. Регулярно выполнять упражнения для тренировки
                        памяти.<br/>
                        Много упражнений вас ждет на нашей программе, но вы
                        можете и самостоятельно придумывать аналогичные
                        упражнения или приобретать готовые книги-тренажеры с
                        заданиями, которые тренируют память.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Стресс влияет на работу когнитивных функций?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Да, когда сильно нервничаешь, сложно вспомнить то, что
                        вообще-то хорошо знаешь. Был эксперимент на крысах, если
                        исследователи заставляют крыс нервничать, те хуже
                        справляются с выученными задачами — им трудно вспомнить
                        правильную последовательность действий. Происходит это
                        потому, что активированная миндалина мешает нормальной
                        работе гиппокампа. В таком случае эффект стресса
                        временный: когда мы успокаиваемся, память перестает
                        подводить и можно снова нормально ею пользоваться (хоть
                        это и слабое утешение для тех, кто боится экзаменов,
                        публичных выступлений и собеседований, где как раз
                        требуется быстро вспоминать то, что сейчас нужно
                        сказать).
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'nutritional'"
                    class="questions-group nutritional flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Здоровое питание – это, правда, дорого?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Это одно из самых больших заблуждений. Между здоровым
                        питанием и дорогостоящими продуктами не стоит равенство.
                        Только вы решаете, что включить в рацион. Всем известные
                        ягоды Годжи, семена чиа – безусловно, обладают высокой
                        пищевой ценностью, но и без их применения можно
                        сформировать ежедневный здоровый рацион из продуктов,
                        доступных в обычном магазине. Сбалансированное питание
                        подразумевает достаточное употребление клетчатки
                        (например, моркови, свеклы, капусты белокочанной),
                        достаточное количество белка (это могут быть сорта мяса
                        птицы и дикой – морской или речной рыбы). Также
                        употребление сезонных продуктов весьма бюджетно и их
                        можно заготовить впрок, например, в виде заморозок.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Можно ли самостоятельно подбирать себе БАДы и витаминные
                        комплексы?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Витамины и минералы играют важную роль для нашего
                        организма, являясь регуляторами метаболизма,
                        энергетического обмена и других физиологических
                        процессов. Покупка БАДов не требует рецепта врача, но
                        без его консультации или сдачи анализов невозможно
                        понять, что необходимо именно вашему организму. Также
                        имейте в виду, сгладить ситуацию после многолетнего
                        безответственного питания несколькими баночками добавок,
                        не получится. Причем, самоназначение витаминных
                        комплексов без контроля специалиста, ситуацию может лишь
                        усугубить. Доверьте право в подборе необходимых
                        витаминов и добавок своему врачу или нутрициологу.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        В чем отличие между диетологом и нутрициологом?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Диетолог – специалист с медицинским образованием,
                        занимается снижением веса пациентов, может назначать
                        лекарственные препараты. <br/>
                        Нутрициолог – консультант по здоровому питанию и
                        формированию пищевых привычек, который не обязан иметь
                        базовое медицинское образование. Он помогает людям,
                        которые стремятся улучшить свое питание. Ещё одно
                        отличие в том, что нутрициолог смотрит на проблему
                        питания шире, и изучает ее более комплексно: от
                        исследования мотивов выбора человеком тех или иных
                        пищевых продуктов до механизмов внутриклеточного
                        питания, свойств продуктов, специй, трав и их влияния на
                        человека. <br/>
                        Консультация нутрициолога поможет вам, если вы привыкли
                        предупреждать болезни, а не копить их и впоследствии
                        лечить. Также она полезна параллельно с посещением
                        врача, если у вас уже есть заболевания, для комплексного
                        подхода к оздоровлению организма.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Помогает ли голодание в снижении веса?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Периодическое голодание практикуется и порой дает
                        видимые результаты. Но у него есть обратная сторона
                        медали: оно имеет множество противопоказаний, применять
                        его можно только под контролем лечащего врача. Обращаясь
                        к этому способу похудения, имейте в виду - это тяжело
                        психологически, может нести за собой «срывы» и набор
                        веса, поэтому предпочтение всегда отдается здоровому
                        регулярному питанию.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Можно ли решить гормональные проблемы с помощью
                        коррекции питания?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        При изменении питания всегда меняется гормональная
                        система. Гормональная система – это сложный механизм,
                        нарушения в работе которого связаны не с одним, а с
                        совокупностью факторов, которые формировались долгие
                        годы или связаны с сильным стрессом. Поэтому не всегда
                        удается решить гормональные проблемы только лишь с
                        помощью питания. Но подобные нарушения однозначно
                        требуют коррекции пищевых привычек. Разработка питания,
                        поддерживающего организм, поможет улучшить общее
                        состояние.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Чем вредно большое количество соли?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Чрезмерное употребление соли способствует задержке
                        жидкости в организме, провоцирует развитие
                        гипертонической болезни. Не просто так существует
                        рекомендация о том, что суточная норма соли не должна
                        превышать 5 гр., что составляет не более 1 чайной ложки.
                        Большинство людей съедают намного больше этого
                        количества, а ведь она считается усилителем вкуса и
                        повышает аппетит. Будьте аккуратны с фаст-фудом, сырами,
                        консервированными, обработанными, маринованными
                        продуктами, копченостями, в их составе содержится
                        скрытая соль. Также постарайтесь не добавлять соль во
                        время приготовления блюд, а немного подсаливать перед
                        употреблением. И конечно, важно обращать внимание на
                        этикетки продуктов, где указано содержание соли.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Правда ли, что после шести вечера не рекомендуется
                        принимать пищу? И что делать тем, кто ложится спать
                        далеко за полночь?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Последний прием пищи рекомендован за 2-3 часа до сна
                        (при условии непродолжительной прогулки после ужина,
                        чтобы пища переварилась), а сам сон рекомендуется
                        начинать не позднее 23 часов.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Действительно ли с возрастом обмен веществ замедляется,
                        и, чтобы сохранить фигуру, надо потреблять меньше
                        калорий?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Действительно существуют факторы, влияющие с возрастом
                        на обмен веществ. Это, в первую очередь, гормональные
                        изменения, при которых легче начинает откладываться
                        жировая ткань. В число негативных факторов входит также
                        снижение физической активности, как следствие снижение
                        мышечной ткани предполагает сокращение пула белка в
                        теле. За счет снижения мышечной ткани и увеличения
                        жировой снижается величина основного обмена, которая
                        уменьшается каждые 10 лет на 2-3%, начиная с 40-летнего
                        возраста, и к старческому возрасту снижается на 15-20%.
                        Акцентируйте своё внимание на калорийности рациона – это
                        позволит предупредить увеличения веса, и отдавайте
                        предпочтение сбалансированному питанию с учетом
                        индивидуальных особенностей и наличия сопутствующих
                        заболеваний.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как остановить периоды переедания?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Если вы признали, что такая проблема у вас есть, значит,
                        первый шаг уже сделан. После чего необходимо определить,
                        в какое время вы наиболее склонны к перееданию. Сделав
                        это, попробуйте следовать следующим советам, чтобы в
                        последующем избежать тяжелых последствий: <br/>
                        Ешьте осознанно. Ведите пищевой дневник, чтобы
                        отслеживать все, что вы едите. Оценка количества
                        потребляемых калорий (на бумаге или в приложении) может
                        помочь вам остановить этот период. <br/>
                        Сократите употребление продуктов, содержащих
                        рафинированный сахар. <br/>
                        Отдайте предпочтение продуктам с высоким содержанием
                        клетчатки, фруктам, овощам, бобам и др. <br/>
                        Не покупайте нездоровую пищу. Сложнее съесть, если этого
                        нет в доме. Вместо этого покупайте ингредиенты для
                        приготовления полезных закусок с различными вкусами.
                        <br/>
                        Когда появляется желание поесть, выпейте стакан воды,
                        например, с мятой. Этого может быть достаточно, чтобы
                        утолить чувство голода. <br/>
                        Если хочется сладкого, взбейте свежий
                        фруктово-йогуртовый смузи. <br/>
                        Ведите здоровый образ жизни. <br/>
                        Стресс может привести к эмоциональному питанию.
                        Гимнастика, практика релаксации, регулярный длительный
                        сон и поддержание позитивного мировоззрения помогут
                        справиться со стрессом. <br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'pulmonologist'"
                    class="questions-group pulmonologist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Полезны ли домашние ингаляции для лечения болезней
                        органов дыхания? Когда они нужны, а когда нет?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        В большинстве случаев, говоря о домашних ингаляциях,
                        подразумевают метод вдыхания лекарственных средств через
                        небулайзер. Он является медицинским аппаратом и
                        продаётся в аптеке. <br/>
                        На самом деле ингаляционная терапия — широкое понятие. В
                        первую очередь её используют при бронхиальной астме или
                        хронической обструктивной болезни легких. Также нужно
                        учитывать то, что в домашние небулайзеры нельзя наливать
                        все растворы подряд. Нужно использовать только
                        препараты, у которых в инструкции написано, что это
                        раствор для ингаляций. <br/>
                        Все остальное — ни в кое случае нельзя: разнообразные
                        отвары трав, спирт, эфирные масла, антибиотики для
                        внутримышечного или внутривенного введения. Системные
                        глюкокортикоиды также не подходят для ингаляций — нужны
                        специальные ингаляционные глюкокортикоидные препараты.
                        <br/>
                        Иногда в небулайзер заливают физраствор, но в таких
                        ингаляциях попросту нет смысла. Сам по себе он
                        бесполезен, его используют в качестве растворителя,
                        чтобы разбавить лекарство до нужного объема. Для
                        увлажнения слизистых намного полезнее будет поддерживать
                        дома нужную влажность воздуха, соблюдать питьевой режим
                        и промывать нос солевым раствором. <br/>
                        При лечении бронхиальной астмы и ХОБЛ используются
                        различные способы доставки лекарственных препаратов:
                        <br/>
                        · Дозированный аэрозольный ингалятор <br/>
                        · Дозированный порошковый ингалятор <br/>
                        · Дозированный аэрозольный ингалятор со спейсером <br/>
                        · Небулайзер <br/>
                        <b>Преимущества ингаляционной терапии -</b> попадание
                        лекарственного препарата непосредственно в дыхательные
                        пути, быстрое начало действия лекарственного средства,
                        возможность использования меньших доз препарата ниже
                        риск развития побочных эффектов (по сравнению с
                        препаратами системного действия)
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Какие симптомы со стороны органов дыхания требуют
                        срочного обращения к врачу?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Существуют ситуации, при которых консультацию
                        специалиста уже нельзя откладывать на потом. В целом они
                        совпадают у детей и у взрослых: <br/>
                        · симптомы дыхательной недостаточности — тахипноэ, то
                        есть учащенное дыхание, одышка. При этом можно увидеть,
                        что в дыхании участвует вспомогательная мускулатура,
                        втягиваются межреберные промежутки, надключичные ямки,
                        человек дышит животом; <br/>
                        · кровохарканье; <br/>
                        · хрипы, свистящее дыхание; <br/>
                        · повторяющиеся пневмонии, особенно в одном и том же
                        сегменте или доле легкого; <br/>
                        · системные симптомы — длительная лихорадка, потеря
                        веса, отеки; <br/>
                        · кашель и температура вместе с затруднением глотания,
                        проблемами с едой или питьем. <br/>
                        · Также сигналом к посещению врача должно стать
                        появление продолжительного кашля у курильщиков с большим
                        стажем или если к кашлю добавились новые симптомы
                        (например, потеря веса, одышка, кровохарканье)
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что значит «управлять» кашлем?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Кашель — защитный рефлекс организма, являющийся
                        симптомом разных заболеваний. Поэтому когда говорят о
                        лечении кашля, подразумевают выявление основной причины
                        случившегося заболевания и её устранение. Существуют
                        понятия “излечение кашля” и “управление кашлем”. Под
                        излечением кашля понимают его купирование. Что
                        невозможно у пациентов с хроническими заболеваниями
                        органов дыхания, в этом случае и осуществляется, по сути
                        дела, управление кашлем – то есть возможность
                        контролировать его и процесс отхождения мокроты.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Всегда ли курение приводит к обструктивной болезни
                        легких?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Курение действительно является одним из основных
                        факторов риска хронической обструктивной болезни легких.
                        Однако, не у всех курящих людей разовьется ХОБЛ, у
                        кого-то на фоне курения возникает хронический бронхит.
                        <br/>
                        При этом риск заболеваний легких у курильщиков
                        действительно высок: часто на этом фоне может возникнуть
                        бронхиальная астма, рак легкого. <br/>
                        В среднем ХОБЛ может появиться у курильщиков со стажем
                        25—30 лет. Болезнь начинается постепенно: сначала
                        кашель, потом одышка при физической нагрузке, которая со
                        временем нарастает, через какое-то время человек
                        начинает задыхаться. Ситуацию нужно не запускать, и
                        обратиться к врачу.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Можно ли вылечить бронхиальную астму?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Бронхиальная астма — хроническое заболевание, которое
                        нельзя вылечить полностью, точно так же, как сахарный
                        диабет или гипертоническую болезнь. У нее есть периоды
                        обострения и периоды ремиссии. <br/>
                        У некоторых пациентов, чаще всего у детей, с возрастом
                        может наступить длительная ремиссия, когда болезнь не
                        проявляется несколько лет, но она может снова вернуться.
                        <br/>
                        Главное при бронхиальной астме — добиться контроля над
                        заболеванием на фоне ингаляционной терапии. Если все
                        хорошо, дозу лекарства постепенно снижают, в какой-то
                        момент препарат можно отменить полностью. Еще одним
                        фактором контроля над астмой является правильное
                        выполнение техники ингаляции при использовании
                        индивидуального дозированного ингалятора. Контролировать
                        свое состояние пациент может самостоятельно при помощи
                        пикфлоуметра. <br/>
                        Опять же в этом случае мы не вылечили астму — мы взяли
                        под контроль ее симптомы за счет правильной терапии и
                        исключения раздражителей - факторов, провоцирующих
                        приступы. Например, пациент перестал контактировать с
                        тем, на что у него аллергия.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что позволит не пропустить пневмонию?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        • У людей с острой респираторной вирусной инфекцией, у
                        которых имеется повышенная температура, не
                        соответствующая тяжести течения ОРВИ (температура тела
                        более 37,5°С), сохраняющаяся более 3-х суток, с кашлем и
                        мокротой. <br/>
                        • Появление болей в грудной клетке при дыхании,
                        симптомов интоксикации (снижение или потеря аппетита,
                        повышенная утомляемость и потливость). <br/>
                        При вышеперечисленных симптомах стоит насторожиться и
                        обратиться к врачу. Врач осмотрит, направит на
                        рентгенологическое исследование или компьютерную
                        томографию.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что делать, если через две недели после COVID-19 одышка
                        не проходит?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Важно оценивать одышку в динамике. Если день ото дня
                        ситуация не ухудшается, одышка становится все менее
                        выраженной – то это напротив хороший знак на пути к
                        выздоровлению. <br/>
                        Но, если несмотря на соблюдение всех рекомендаций по
                        лечению, одышка не проходит или состояние пациента лишь
                        усугубляется, врач скорее всего направит на
                        пульсоксиметрию — измерение сатурации, ведь одышка может
                        быть проявлением недостаточного уровня кислорода в
                        крови. Пациентам, переболевшим COVID-19, следует иметь в
                        виду, что одышка может проявиться даже через несколько
                        месяцев после перенесенной болезни. Среди возможных
                        причин: как повторное инфицирование вирусом, так и
                        множество других заболеваний, не имеющих ничего общего с
                        новой коронавирусной инфекцией.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Нужна ли реабилитация после коронавирусной инфекции с
                        поражением легких?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Если говорить про респираторную реабилитацию после
                        COVID-19, то доказала свою эффективность только
                        дыхательная гимнастика. <br/>
                        Например, это дыхание животом, когда одну руку помещают
                        на грудь, вторую на живот, потом начинают дышать. Причем
                        рука, которая на животе, должна подниматься выше. Не
                        нужно никаких резких движений, только спокойное дыхание:
                        вдохнули — выдохнули. <br/>
                        В рамках курса «Решение для легких и дыхания» вы можете
                        пройти полный комплекс упражнений, который поможет
                        справиться с последствиями удара вируса по дыхательной
                        системе. Чтобы добиться успехов в восстановлении, нужно
                        последовательно выполнять рекомендации специалиста.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Всегда ли при пневмонии нужны антибиотики?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Терапия антибиотиками дает положительный результат
                        только в том случае, если возбудителями воспаления
                        являются бактерии. <br/>
                        При вирусной пневмонии, как и при обычной простуде, они
                        не нужны, за исключением случаев, когда присоединяется
                        вторичная бактериальная инфекция. <br/>
                        При COVID-19 бактериальная инфекция присоединяется не
                        всегда, чаще всего у пациентов, которые уже находятся в
                        стационаре. Поэтому с первых дней болезни заниматься
                        самолечением антибиотиками может быть опасно. Отличить
                        вирусную пневмонию от бактериальной можно с помощью
                        выделения возбудителя болезни и данных обследования,
                        которое назначит врач.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'psychiatrist'"
                    class="questions-group psychiatrist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Когда нужно обратиться к психиатру?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Первое, что нужно сделать, учесть процент времени, который тратится на переживание симптомов
                        расстройства. Если в сутки более 30% времени человек проводит в тревоге, навязчивых мыслях,
                        депрессивных состояниях, его одолевает грусть, это влияет на его качество жизни — стоит
                        проконсультироваться со специалистом. <br>
                        Второй момент, когда состояние человека кардинально меняется. Например, домосед, интроверт,
                        вдруг проснулся с утра с приливом сил и желанием отправиться в долгосрочный кругосветный круиз.
                        Или, наоборот, человек вел активный образ жизни, но вдруг резко замкнулся, потерял интерес к
                        любимым хобби и ко всему вообще. <br>
                        И, конечно, когда на человека наваливаются навязчивые состояния, панические атаки, фобии, его
                        качает на эмоциональных качелях— обязательно нужно записаться на приём. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Если обнаружилось расстройство, то это на всю жизнь?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Далеко не все расстройства – это на всю оставшуюся жизнь, помимо длительных и хронических,
                        бывают и кратковременные. Например, депрессивный эпизод может быть однократным, и закончиться
                        спустя две недели, также генерализованное тревожное расстройство может длиться 6 месяцев, и
                        пройти навсегда. Даже расстройства с галлюцинациями и бредом могут продолжаться месяц, а потом
                        закончиться. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что может спровоцировать появление психических
                        расстройств, каковы факторы риска?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Психические расстройства появляются совершенно по разным причинам, чаще всего к факторам риска
                        относят генетическую предрасположенность. Конечно же, это не говорит о том, что человек уже
                        рождается с расстройством, но заложенные генетические особенности повышают процент его
                        проявления. Помимо генетики существует ещё ряд факторов. Это может быть пережитый опыт – плохие
                        отношения в семье и насилие в школе, психологические особенности личности (когда у человека вся
                        жизнь в черно-белых тонах), еще влияют соматические заболевания, социально-экономические
                        факторы, экология, травмы головы. Если эти обстоятельства как снежный ком накапливаются, то они
                        могут привести к расстройству. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Можно ли считать навязчивые мысли патологией?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Главное отличие навязчивых мыслей от тяжёлой патологии в том, что человек не лишён самокритики.
                        Он вполне понимает, что эти мысли – ненужные, устаёт от них. И искренне хочет, решить эту
                        проблему. <br>
                        Поэтому навязчивые мысли могут быть самостоятельным явлением. Но часто они сигнализируют о
                        нарушении психики, например: <br>
                        ● депрессии; <br>
                        ● обсессивно-компульсивном расстройстве; <br>
                        ● фобии; <br>
                        ● посттравматическом тревожном расстройстве. <br>
                        Каждая мысль - это определённые эмоции и нейронная связь. Возвращаясь к ней, из раза в раз она
                        становится только прочнее. Поэтому чем дольше мы прокручиваем какие-то мысли, ситуации, тем
                        сложнее выкинуть их из головы. Это и приводит к тому, что со временем навязчивые мысли могут
                        перерасти в психическое расстройство, которое потребует длительного лечения. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что делать, если близкий человек не осознает и отрицает
                        признаки расстройства?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        В первую очередь, нужно сесть за стол переговоров: обсудить проблему, выслушать, поддержать и
                        понять, как сильно это на него влияет. Основной критерий, на который надо ориентироваться, —
                        насколько это мешает близкому жить. Если эмоциональное состояние приводит к потере работы,
                        семьи, отношений, то надо обращаться к врачу. Это все, чем можно помочь. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Можно ли лечить психические расстройства без лекарств?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Психические расстройства иногда можно лечить без медикаментов. Например, в этот список попадает
                        большое число тревожных расстройств, фобий – чаще они лечатся только психотерапией. Легкую
                        степень депрессии также стоит лечить с помощью этого метода психологической коррекции. При
                        средней степени лечение выбирают с учетом симптомов, в зависимости от суицидального риска и
                        особенностей человека — хочет он заниматься психотерапией или нет. Но каждая тяжелая степень
                        депрессии — показание к медикаментозному лечению. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Нужно ли принимать лекарства всю жизнь, если уже начал?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Это зависит от заболевания. Так, при депрессивном расстройстве в большинстве случаев достижима
                        ремиссия, которая позволяет отказаться от лекарств с минимальным риском заболеть снова. Однако,
                        надо понимать, что препараты все равно придется принимать долго — от года. <br>
                        В случае тревожных расстройств о пожизненном приеме таблеток речь не идет — такие расстройства
                        хорошо лечатся. В течение года можно пройти курс психотерапии или лечения медикаментами и
                        почувствовать себя хорошо. <br>
                        А при биполярном аффективном расстройстве все зависит от ситуации: препараты принимают
                        длительно, в редких случаях их можно отменить. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Что такое эмоциональные качели? Когда можно помочь себе
                        самостоятельно?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Под эмоциональными качелями понимают резкую смену совершенно разных состояний человека: от
                        веселья и счастья до уныния, злости и даже агрессии. Это симптом вне пола и возраста, он
                        характерен для женщин, мужчин, подростков, пожилых людей. <br>
                        Игнорировать эмоциональную неустойчивость нельзя. И в некоторых случаях можно попробовать
                        стабилизировать её самостоятельно. <br>
                        · Нормализуйте режим сна. Ложиться спать необходимо в 21-23 часа, не позже. <br>
                        · Используйте для расслабления нервной системы ванны с магниевой солью, ароматерапию. <br>
                        · Нельзя недооценивать пользу массажа, который можно делать руками или домашними аппаратами.
                        <br>
                        Разные техники массажа лица - хороший способ сделать кожу свежей, а также снять тревогу и
                        напряжение. Для снятия стресса эффективен и массаж головы. <br>
                        · Дыхательные упражнения позволят справиться со стрессом, паническими атаками, чувством тревоги.
                        <br>
                        · Снимите излишнюю нагрузку с кишечника. Молочные продукты, излишек мяса, жирное, копченое,
                        сладкое – все это способно закислять организм, вызывать воспаления и дисбаланс гормональной
                        системы. Например, избыток в рационе сладостей провоцируют кандидоз — одну из разновидностей
                        грибковой инфекции в кишечнике. Человек с таким диагнозом подвержен эмоциональной
                        нестабильности, бывает агрессивным или, напротив, плаксивым. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Существует ли риск заболеть психическим расстройством с
                        возрастом?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Человек, который достиг пожилого возраста и ни разу не сталкивался с психическими
                        расстройствами, уже вряд ли ими заболеет, исключение составляет деменция. <br>
                        Здесь можно привести в пример шизофрению, в пожилом возрасте она, как правило, впервые не
                        появляется. С депрессией человек, скорее всего, в первый раз столкнется в возрасте от 20 до 30
                        лет, часто она возникает и как постродовой симптом у женщин. Биполярное аффективное расстройство
                        вероятнее проявится еще в детстве или в подростковом возрасте. <br>
                        Бывает и так: случился психоз, переболели, прошел, через несколько лет постучался вновь, но
                        протекал уже тяжелее, это как раз и может случиться в старости. Но на фоне возраста сил бороться
                        с заболеванием без посторонней помощи уже не будет. Именно в этом случае может создаться
                        впечатление, что человек поздно заболел шизофренией, но на самом деле эта болезнь появилась
                        давно, просто никто не замечал это заболевание или скрывал его. <br>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <div
                    v-if="currentTheme === 'somnologist'"
                    class="questions-group somnologist flex flex-dir-col"
                >
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Как понять, высыпаюсь ли я?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        У каждого человека своя потребность во сне.<br/>
                        Большинству взрослых людей достаточно 7-8 часов ночного
                        отдыха. Если вы постоянно поддерживаете режим сна, утром
                        чувствуете себя бодрым и свежим, а в течение дня не
                        испытываете сонливость, то не сомневайтесь – вы
                        высыпаетесь.<br/>
                        Если же вас не отпускает дневная усталость, вы в
                        «разбитом» состоянии, то попробуйте увеличить
                        продолжительность ночного отдыха на 30 минут – 1 час.
                        Если изменение режима не принесло положительных
                        результатов в течение недели, обратитесь к сомнологу для
                        диагностики расстройства сна и выбора тактики лечения.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Вредна ли привычка засыпать под телевизор?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Эту привычку действительно нельзя назвать полезной.<br/>
                        Во-первых, по мере погружения в смысл просмотренного,
                        мозг продолжает перерабатывать увиденное и услышанное,
                        т.е. находится в возбужденном состоянии. В результате вы
                        наверняка заснете позже, чем планировали.<br/>
                        Во-вторых, свет от экрана подавляет выработку гормона
                        сна мелатонина.<br/>
                        В-третьих, когда телевизор продолжает оставаться
                        включённым, громкость и мерцание экрана могут разбудить
                        вас. Это само по себе не слишком хорошо. Также после
                        пробуждения вы можете вновь начать «щелкать» каналы, что
                        еще больше сократит ночной отдых.<br/>
                        И в-четвертых, сон под телевизор может вызвать
                        нежелательную привычку – неспособность заснуть при
                        других предложенных обстоятельствах. Опять же мозг
                        начинает определять спальню, как место для получения
                        информации, а не для отдыха, что может быть чревато
                        бессонницей.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Существуют ли безопасные виды снотворного, которые можно
                        принимать без назначения врача?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Любое снотворное нужно принимать по рекомендации
                        врача.<br/>
                        Вообще сомнологи стараются избегать назначения таких
                        средств: их легко назначить, но непросто «забрать» у
                        пациента.<br/>
                        Единственный препарат, который можно назначать для
                        улучшения сна без опасений – мелатонин (любые препараты
                        на его основе). Это не снотворное, а адаптоген. Он
                        создан на основе гормона сна мелатонина. Помогает при
                        бессоннице, обусловленной циркадными нарушениями: это
                        смена часовых поясов, сменный график работы и т. д.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Каких советов придерживаться, если долго не можешь
                        заснуть?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Каждый хоть раз сталкивался с ситуацией, что чем сильнее
                        стараешься заснуть, тем меньше это получается. Наш мозг
                        усиленно этому сопротивляется. Поэтому при ночных
                        пробуждениях, если вам не удалось заснуть сразу,
                        рекомендуют прибегать к релаксирующим методикам –
                        медитации и спокойному расслабляющему дыханию –
                        подробные примеры мы разобрали в курсе «Здоровый и
                        полезный сон».<br/>
                        Если заснуть мешают нежелательные мысли, и вы провели в
                        кровати уже около 15 минут, встаньте, походите по
                        квартире, отвлекитесь – почитайте книгу или послушайте
                        спокойную музыку. Спальня не должна ассоциироваться у
                        вас с негативом и отсутствием сна. Возвращайтесь в
                        постель только тогда, когда успокоитесь и снова
                        почувствуете сонливость.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Можно ли за один день отоспаться после недели недосыпа?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        «Запасания сна» не существует, мы не можем выспаться на
                        будущее. Зато существует бессонница выходного дня.
                        Человек недосыпает в будни, поэтому в выходные старается
                        выспаться и встает позднее обычного. Из-за этого в ночь
                        на понедельник могут возникнуть трудности с засыпанием.
                        А вставать, как и прежде, в 6-7 утра. Это создает
                        проблемы со сном до конца недели. В следующие выходные
                        человек снова отсыпается, и так по кругу.<br/>
                        В выходные рекомендуется вставать не более чем на 2 часа
                        позже обычного. Это поможет сохранить нормальный режим
                        сна.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Какие последствия влечет за собой нехватка сна?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Постоянное недосыпание накапливается день ото дня, что
                        может стать причиной замедленного мышления,
                        безответственных поступков, раздражительности, проблем с
                        памятью. Последствия такого состояния неприятны не
                        только для самого невысыпающегося, но и для его
                        окружения.<br/>
                        При продолжительном ограничении сна
                        развиваются/обостряются заболевания желудочно-кишечного
                        тракта, сердечно-сосудистая патология, нарушения
                        углеводного обмена и другие имеющиеся проблемы со
                        здоровьем. Кроме того, повышается риск тревожных
                        расстройств и депрессии.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Почему иногда мы видим сны, а иногда нет?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Мы видим сны, когда находимся в стадии быстрого сна – в
                        это время мозг обрабатывает информацию и в виде
                        «побочного явления» создает сны. Если отдых длится около
                        шести часов, то человек успеет увидеть сны в среднем 4
                        раза за ночь (из расчета средней длительности цикла сна
                        1,5 часа). Но большинство из них не запоминаются, если
                        не проснуться в момент прохождения стадии быстрого сна.
                        Существуют люди, которые в принципе не запоминают свои
                        сны. И это норма, которая ни на что не влияет и никак не
                        отражается на здоровье. Если же человек сначала видел
                        сны, а потом вдруг перестал, то вот тут стоит
                        насторожиться. Причины такой перемены могут быть
                        разные:<br/>
                        • Болезни сна<br/>
                        • Недосыпание<br/>
                        • Выраженная физическая усталость<br/>
                        • Депрессия.<br/>
                        Для определения точной причины стоит обратиться к врачу.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Каких полезных привычек придерживаться, чтобы крепче и
                        лучше спать?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        · Ложиться спать и вставать в одно и то же время.<br/>
                        · Регулярно заниматься физическими упражнениями.<br/>
                        · Отказаться от кофеина за 6-8 часов до сна.<br/>
                        · Не курить и не принимать алкоголь за 2-3 часа до
                        сна.<br/>
                        · Избегать пользования гаджетами в течение 1-2 часов до
                        сна.<br/>
                        · Не переедать на ночь (допустим легкий ужин за 3-4 часа
                        до сна)<br/>
                        · Не спать днем.<br/>
                        · Спать в хорошо проветренной спальне, при комфортной
                        температуре, на удобной постели. <br/>
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">
                        Влияет ли на качество сна поза, в которой привык спать
                        человек?
                      </h4>
                      <p class="questions-element-filling__text" hidden>
                        Кому-то нравится спать на боку, свернувшись как эмбрион,
                        кому-то на животе, все спят по-разному. Не существует
                        лучшей и худшей позы для сна. Для здорового человека это
                        не имеет значения. В какой позе вам нравится спать – в
                        такой и спите. Единственное, о чем стоит оговориться – в
                        положении на спине чаще возникает храп.
                      </p>
                    </div>
                    <button
                        class="questions-element__btn"
                        @click="openOrCloseText"
                    ></button>
                  </div>
                </div>
                <!-- <div class="questions-group aromatherapist flex flex-dir-col">
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                  <div class="questions-element flex br-red br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                  <div class="questions-element flex br-yellow br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                  <div class="questions-element flex br-purple br-rad-20">
                    <div class="questions-element-filling">
                      <h4 class="questions-element-filling__title">Вопрос</h4>
                      <p class="questions-element-filling__text" hidden>Ответ на вопрос. Amet minim mollit non deserunt ullamco
                        est sit
                        aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                        consequat
                        sunt nostrud amet.</p>
                    </div>
                    <button class="questions-element__btn" @click="openOrCloseText"></button>
                  </div>
                </div> -->
              </div>
              <!-- <button class="questions__more btn-big m0-auto mt-40 br-red red">Задать вопрос</button> -->
            </div>
          </div>
        </div>
        <div class="additionally flex flex-dir-col">
          <div class="additionally-element my-course" v-if="false">
            <h5 class="my-course__title small-title">Рекомендуемые курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="myCourses">
                <SeparateRecommendedCourse
                    v-for="item in myCourses"
                    :key="item.id"
                    :course="item"
                >
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img
                    src="../../assets/img/dashbord/filling/last-element.svg"
                    alt=""
                    class="slider-block__img"
                />
              </button>
              <button class="slider-next-block tablet">
                <img
                    src="../../assets/img/dashbord/filling/next-element.svg"
                    alt=""
                    class="slider-block__img"
                />
              </button>
              <button
                  class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
              >
                Все мои курсы
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile></PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SeparateRecommendedCourse from "../../components/courses/SeparateRecommendedCourse.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    PersonalHeader,
    SeparateRecommendedCourse,
    PersonalProfile,
    PersoneInCaption,
  },
  name: "HelpPage",
  computed: {
    ...mapGetters(["recommendedCourse", "myCourses"]),
  },
  methods: {
    goToCourses() {
      this.$router.push({name: "myCoursesPage"});
    },
    nextCourses() {
      let weight = this.$refs.element.offsetWidth + 20;
      this.$refs.slider.scrollBy({
        left: weight,
        behavior: "smooth",
      });
    },
    prevCourses() {
      let weight = this.$refs.element.offsetWidth + 20;
      this.$refs.slider.scrollBy({
        left: -weight,
        behavior: "smooth",
      });
    },
    openOrCloseText(e) {
      let el = e.target.previousSibling.childNodes[1];
      el.hidden = !el.hidden;
      if (!el.hidden) {
        e.target.classList.add("questions-element__btn-active");
      } else {
        e.target.classList.remove("questions-element__btn-active");
      }
      //questions-element__btn-active
    },
    scrollToPoint() {
      if (document.getElementById("scrollPoint")) {
        document.getElementById("scrollPoint").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
  data() {
    return {
      helpQuestion: [
        {
          id: "b82cb48c-5c6a-4f17-b005-e6135b7337b8",
          title: "Вопрос 1",
          description: "Описание урока 1",
          order: 1,
          fileUrl: null,
          checklistsId: [],
        },
        {
          id: "b82cb48c-5c6a-4f17-b005-e6135b7337b8",
          title: "Вопрос 2",
          description: "Описание урока 1",
          order: 1,
          fileUrl: null,
          checklistsId: [],
        },
        {
          id: "b82cb48c-5c6a-4f17-b005-e6135b7337b8",
          title: "Вопрос 3",
          description: "Описание урока 1",
          order: 1,
          fileUrl: null,
          checklistsId: [],
        },
        {
          id: "b82cb48c-5c6a-4f17-b005-e6135b7337b8",
          title: "Вопрос 4",
          description: "Описание урока 1",
          order: 1,
          fileUrl: null,
          checklistsId: [],
        },
        {
          id: "b82cb48c-5c6a-4f17-b005-e6135b7337b8",
          title: "Вопрос 5",
          description: "Описание урока 1",
          order: 1,
          fileUrl: null,
          checklistsId: [],
        },
        {
          id: "b82cb48c-5c6a-4f17-b005-e6135b7337b8",
          title: "Вопрос 6",
          description: "Описание урока 1",
          order: 1,
          fileUrl: null,
          checklistsId: [],
        },
      ],
      currentTheme: "aromatherapist",
    };
  },
  beforeMount() {
    scroll(0, 0);
    this.$store.dispatch("fetchMyCourses", {page: 1, limit: 5, trend: "all"});
  },
};
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 0px;
  width: 100%;
}

/*form*/
.form {
  margin: 60px 0px 0 40px;
}

.form__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  color: #100c40;
}

.form-filling {
  max-width: 1120px;
  width: 100%;
  display: flex;
  padding: 10px;
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 10px;
  align-items: center;
}

.form-filling__label {
  display: flex;
  align-items: center;
  height: 40px;
  display: flex;
  align-items: center;
  max-width: 214px;
  width: 100%;
  border-right: 1px solid rgba(85, 85, 85, 0.3);
}

.form-icon {
  width: 24px;
  height: 24px;
  display: flex;
  flex-shrink: 0;
}

.form-filling__input {
  max-width: 100%;
  width: 100%;
  padding-right: 10px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 1;
  color: rgba(16, 12, 64, 0.6000000238418579);
}

.form-name {
  background: url(../../assets/img/help/name.svg);
}

.form-mail {
  background: url(../../assets/img/help/mail.svg);
  margin-left: 20px;
}

.form-question {
  background: url(../../assets/img/help/question.svg);
  margin-left: 20px;
}

.textarea-input {
  position: relative;
}

.form-send {
  max-width: 180px;
  width: 100%;
  height: 60px;
  background: #ff2031;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.17);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
}

.last-label {
  max-width: 100%;
  border-right: none;
}

/* Caption */

.caption {
  margin-left: 40px;
  padding: 40px 5% 0px 0px;
}

.caption-greetings__title {
  font-family: "DelaGothicOne";
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
  padding: 40px 0px 0 40px;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/* questions */
.questions {
  padding-bottom: 74px;
}

.questions-filling {
  margin-top: 20px;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 10px;
  box-shadow: 0px 40px 140px rgba(0, 0, 0, 0.06);
}

.questions-preference__element {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.04);
  font-family: "DelaGothicOne";
  text-transform: uppercase;
}

.preference__element-text {
  font-size: 24px;
  line-height: 1.2;
}

.questions-group {
  max-width: 100%;
  width: 100%;
}

.questions-preference-wrapper {
  max-width: 280px;
  width: 100%;
}

.questions-preference {
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.questions-preference__element-wrapper {
  margin: 0 20px 20px 0;
}

.questions-preference :nth-child(4n) {
  margin-right: 0;
}

.questions-element {
  padding: 30px 20px;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  margin-bottom: 20px;
}

.questions-group :last-child {
  margin-bottom: 0;
}

.questions-element__btn {
  background: url(../../assets/img/home/questions/arrow-not-active.svg);
  width: 24px;
  height: 24px;
  background-size: contain;
  display: flex;
  flex-shrink: 0;
}

.questions-element__btn-active {
  background: url(../../assets/img/home/questions/arrow-active.svg);
}

.questions-element-filling__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  color: #100c40;
}

.questions-element-filling__text {
  max-width: 100%;
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.8;
  color: rgba(16, 12, 64, 0.6000000238418579);
}

.questions__more {
  margin-top: 30px;
}

/* course-caption */
.course-description__img {
  width: 100px;
  height: 100px;
  background: url(../../assets/img/course/head.webp);
  background-size: contain;
}

.course-description-info__name {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
}

.course-description-actions-tablet {
  display: none;
}

.course-description-actions {
  align-items: center;
}

.actions-start {
  width: 201px;
  flex-shrink: 0;
}

.actions-settings {
  width: 20px;
  margin-left: 20px;
  height: 30px;
  background: url(../../assets/img/course/settings.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.course-caption-filling {
  margin-left: 20px;
}

.course-description {
  max-width: 100%;
  width: 100%;
}

.course-description__title {
  font-family: "Muller";
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
}

.course-description__text {
  margin-top: 20px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.course-description-list {
  margin-top: 20px;
}

.course-description-list__element {
  margin-left: 3px;
  font-size: 16px;
  line-height: 1.6;
}

/* author  */
.author {
  padding: 44px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.author__face {
  width: 200px;
  height: 200px;
  object-fit: contain;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 20px;
  box-shadow: 0px 40px 140px rgba(0, 0, 0, 0.06);
}

.author__name {
  font-weight: 500;
  font-size: 20px;
}

.author__description {
  font-weight: 400;
  font-size: 14px;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.author__btn {
  max-width: 250px;
  margin-top: 20px;
  font-size: 12px;
}

/*content*/
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.content-filling {
  margin-top: 0px;
  margin-bottom: 40px;
}

.content :first-child {
  margin-top: 0;
}

.content-filling {
  display: flex;
  flex-direction: column;
}

.separate-curse {
  max-width: 100%;
}

.sections {
  margin-top: 20px;
}

/* additionally */
.additionall-about {
  padding: 30px 20px;
}

.additionall-about-title {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.additionall-about-text {
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-btn {
  margin-top: 58px;
}

.additionall-about-time {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-time__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/time.svg);
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/*my cours*/
.my-course__more {
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}

.form-tablet {
  display: none;
}

@media (max-width: 1700px) {
  .questions-preference {
    grid-template-columns: repeat(3, 1fr);
  }

  .questions-preference :nth-child(4n) {
    margin-right: 20px;
  }

  .questions-preference :nth-child(3n) {
    margin-right: 0px;
  }
}

@media (max-width: 1500px) {
  .questions-preference {
    grid-template-columns: repeat(2, 1fr);
  }

  .questions-preference :nth-child(2n) {
    margin-right: 0px;
  }

  .questions-preference :nth-child(2n + 1) {
    margin-right: 20px;
  }
}

@media (max-width: 1880px) {
  .additionally {
    max-width: 326px;
  }
}

@media (max-width: 1300px) {
  .filling {
    padding: 20px 0 20px 20px;
  }

  /* Caption */
  .caption {
    padding: 20px 6% 0px 0px;
    justify-content: space-between;
    margin-left: 20px;
  }

  .caption-greetings__title {
    font-size: 36px;
  }

  .caption-greetings__text {
    font-size: 14px;
    margin-top: 8px;
  }

  .surch {
    margin-left: 0;
  }

  .surch__input {
    font-size: 14px;
    max-width: 265px;
    width: 100%;
    display: flex;
    flex-shrink: 1;
  }

  /*form*/
  .form-desctop {
    display: none;
  }

  .form-tablet {
    display: flex;
  }

  .form {
    margin: 0;
  }

  .form-filling {
    padding: none;
    margin-top: 20px;
    background: none;
    border: none;
    padding: 0;
    border-radius: none;
    align-items: unset;
    flex-direction: column;
  }

  .form-filling__label {
    display: flex;
    align-items: center;
    height: 52px;
    display: flex;
    max-width: 100%;
    padding: 14px;
    background: #ffffff;
    border: 1px solid rgba(85, 85, 85, 0.2);
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .textarea-container {
    position: relative;
  }

  .textarea-input__span {
    position: absolute;
    background: url(../../assets/img/help/question.svg);
    margin-left: 0px;
    position: absolute;
    left: 14px;
    top: 14px;
    content: "";
    width: 24px;
    height: 24px;
    display: block;
    z-index: 1;
  }

  .form-filling__input {
    max-width: 100%;
    width: 100%;
    padding-right: 10px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 1;
    color: rgba(16, 12, 64, 0.6000000238418579);
  }

  .form-mail {
    margin-left: 0px;
  }

  .form-question {
    margin-left: 0px;
  }

  .form-send {
    margin-top: 20px;
    max-width: 220px;
  }

  .textarea-input {
    padding: 20px 20px 20px 48px;
    position: relative;
    background: #ffffff;
    border: 1px solid rgba(85, 85, 85, 0.2);
    border-radius: 10px;
    margin-left: 0;
    min-height: 190px;
  }

  .last-label {
    max-width: 100%;
    border-right: none;
  }

  /* course-caption */
  .course-description__img {
    width: 80px;
    height: 80px;
  }

  .course-description-actions {
    display: none;
  }

  .course-description-actions-tablet {
    align-items: center;
    display: flex;
  }

  .actions-start {
    width: 164px;
    height: 44px;
    font-size: 12px;
  }

  .course-caption-filling {
    flex-direction: column;
    margin-left: 10px;
    margin-top: 20px;
  }

  .course-description__title {
    font-size: 20px;
  }

  .course-description__text {
    margin-top: 10px;
    font-size: 14px;
  }

  .course-description-list {
    margin-top: 10px;
  }

  .sections {
    grid-template-columns: repeat(3, 1fr);
  }

  /* additionally */
  .additionall-about {
    padding: 20px 10px 26px 10px;
  }

  .additionall-about-title {
    font-size: 20px;
  }

  .additionall-about-text {
    margin-top: 10px;
    font-size: 14px;
  }

  .additionall-about-btn {
    margin-top: 72px;
    max-width: 195px;
    font-size: 12px;
  }

  .additionall-about-time {
    margin-top: 20px;
  }

  /* questions */
  .questions-preference__element-desctop {
    display: none;
  }

  .questions-preference {
    flex-direction: row;
    max-width: 100%;
    overflow-x: scroll;
  }

  .questions-preference:last-child {
    margin-right: 0;
  }

  .questions-preference-wrapper {
    max-width: 100%;
    width: 100%;
    position: relative;
  }

  .preference__element-text {
    font-size: 14px;
  }

  .questions-element {
    text-align: left;
    max-width: 100%;
    width: 100%;
    margin: 0 auto 20px auto;
  }

  .questions-group {
    margin-top: 20px;
    margin-left: 0;
  }

  .slider-last-questions {
    left: -17px;
  }

  .slider-next-questions {
    right: 17px;
  }

  .questions-element-filling__text {
    margin-top: 20px;
  }

  .questions-preference__element {
    height: 90px;
    width: 100%;
    flex-shrink: 0;
  }

  .questions-preference {
    grid-template-columns: repeat(2, 1fr);
  }

  .questions-preference :nth-child(2n + 1) {
    margin-right: 10px;
  }

  .questions-preference__element-wrapper {
    margin-bottom: 10px;
  }
}

@media (max-width: 1100px) {
  .sections {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 950px) {
  .author {
    display: none;
  }

  .body {
    flex-direction: column;
  }

  .filling {
    padding: 40px 0 0 0;
    flex-direction: column;
  }

  .persone {
    display: none;
  }

  .caption {
    padding: 40px 0 0 0;
    margin-left: 0;
  }

  .caption-greetings {
    margin: 0 auto;
  }

  .caption-greetings__title {
    font-size: 32px;
  }

  .main {
    margin-left: 0;
    margin-right: 0;
    padding: 0 10px;
  }

  .profile {
    display: none;
  }

  /* additionall */
  .my-course-group {
    overflow-x: scroll;
  }

  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    margin-top: 20px;
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-last-block-top {
    left: -18px;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block-top {
    right: -18px;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .separate-curse {
    max-width: 280px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  /*form*/
  .form-send {
    margin-top: 10px;
    max-width: 100%;
  }

  .textarea-input {
    min-height: 160px;
  }

  /* course-caption */
  .course-caption {
    text-align: center;
    position: relative;
  }

  .course-caption-description {
    flex-direction: column;
  }

  .course-description__img {
    width: 130px;
    height: 130px;
  }

  .course-description-info {
    margin: 10px 0 0 0;
  }

  .course-description-info__name {
    font-family: "Muller";
  }

  .course-caption-filling {
    margin: 20px 0 0 0;
  }

  .actions-settings {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
  }

  .course-description__title {
    font-size: 14px;
  }

  .course-description__text {
    font-size: 12px;
    line-height: 1.4;
  }

  li::before {
    display: none;
  }

  .course-description-list__element {
    font-size: 12px;
    line-height: 1.4;
  }

  .course-description-actions-tablet {
    justify-content: center;
  }

  .actions-start {
    width: 220px;
  }

  .sections {
    grid-template-columns: repeat(1, 1fr);
  }

  /*category*/
  .category-element {
    padding: 0;
    margin-top: 20px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }

  .tags {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: 50px;
  }

  .questions-element {
    padding: 20px;
  }

  .questions-preference {
    grid-template-columns: repeat(1, 1fr);
  }

  .questions-preference :nth-child(2n + 1) {
    margin-right: 0px;
  }
}

/* Modifiactor for course-caption */
.head-course .course-description__img {
  background: url(../../assets/img/course/head.webp);
  background-size: cover;
}

.head-course .sections-element {
  border-color: #8357a0;
}

.lungs-course .course-description__img {
  background: url(../../assets/img/course/lungs.webp);
  background-size: cover;
}

.lungs-course .sections-element {
  border-color: #f7ad3d;
}

.heart-course .course-description__img {
  background: url(../../assets/img/course/heart.webp);
  background-size: cover;
}

.heart-course .course-description__img {
  border-color: #ff2031;
}

.brain-course .course-description__img {
  background: url(../../assets/img/course/brain.webp);
  background-size: cover;
}

.brain-course .sections-element {
  border-color: #f7ad3d;
}
</style>
