<template>
  <div class="background">
    <AuthorizationHeader>
    </AuthorizationHeader>

    <main class="authorization authorization-container">
      <h1 class="authorization__title">Вход</h1>
      <div class="authorization-content br-rad-10 bg-white mt-20">
        <div class="caption">
          <img src="../../assets/img/big-logo.svg" alt="" class="big-logo">
          <img src="@/assets/img/authorization/one-rus.svg" alt="" class="rus">
        </div>
        <p class="authorization__text mt-20">Введите номер телефона
          <!--          <router-link :to="{name:'registrationPage'}"-->
          <!--                       class="authorization__enter">Зарегистрироваться-->
          <!--          </router-link>-->
          <button v-if="false" class="authorization__enter" style="color: #FF2031"
            @click="mode = 'sms'">Зарегистрироваться</button>
        </p>
        <div class="authorization-social m0-auto flex just-space-betwin" v-if="false">
          <a href="#" class="authorization-social__element google">
            <img src="../../assets/img/authorization/google.svg" alt="" class="social__element-img">
          </a>
          <a href="#" class="authorization-social__element gos">
            <img src="../../assets/img/authorization/gos.webp" alt="" class="social__element-img">
          </a>
          <a href="https://vk.com/poslevypiski" class="authorization-social__element vk">
            <img src="../../assets/img/authorization/vk.svg" alt="" class="social__element-img">
          </a>
          <a href="#" class="authorization-social__element apple">
            <img src="../../assets/img/authorization/apple.svg" alt="" class="social__element-img">
          </a>
        </div>
        <div class="authorization-filling flex-dir-col flex">
          <div class="authorization-choice br-rad-10 mt-20" v-if="false">
            <button class="choice__element choice__element-chosen br-rad-10" v-if="false">Пациент</button>
            <button class="choice__element br-rad-10" v-if="false">Администратор</button>
          </div>
          <div class="authorization-way">
            <button @click="(mode = 'sms', login = '', password = null, phone = '')" class="way__element "
              :class="{ 'way-chosen': mode === 'sms' }">Быстрый вход
            </button>
            <button ref="phone" class="way__element ml-10"
              @click="(mode = 'phone', login = '', password = null, phone = '')"
              :class="{ 'way-chosen': mode === 'phone' }">По паролю
            </button>
          </div>
          <label class="authorization-field mt-20">
            <div class="authorization-field__img " :class="{ 'field-tel': mode, 'field-email': !mode }"></div>
            <i-mask-component type="tel" v-if="mode === 'phone' || mode === 'sms'"
              placeholder="+7 (_ _ _) _ _ _   _ _  _ _" @keydown.enter="authorization" v-model="phone"
              v-model:unmasked="login" :mask="mask" class="authorization-field__input" />
            <input type="text" @keydown.enter="authorization" v-if="mode === 'email'" v-model.trim="login"
              placeholder="test@test.ru" class="authorization-field__input">
          </label>
          <label class="authorization-field mt-20" v-if="mode==='phone'">
            <div class="authorization-field__img field-password"></div>
            <input @keydown.enter="authorization" :type="viewPassword ? 'text' : 'password'" v-model.trim="password"
              placeholder="Введите пароль" class="authorization-field__input">
            <button class="eye " :class="{ 'eye-open': !viewPassword }" @click="viewPassword = !viewPassword"></button>
          </label>
          <button class="authorization-register br-rad-10 mt-20" @click="authorization">
            Войти
          </button>
        </div>
        <p class="authorization-forgot mt-20">Нажимая на кнопку вы соглашаетесь с <router-link target="_blank"
            :to="{ name: 'privacy' }">политикой
            конфиденциальности</router-link>, <router-link target="_blank" :to="{ name: 'terms' }">пользовательским
            соглашением</router-link> и <router-link target="_blank" :to="{ name: 'personal' }">обработкой
            персональных
            данных</router-link>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import AuthorizationHeader from "../../components/authorization/AuthorizationHeader.vue";
import { IMaskComponent } from "vue-imask"

export default {
  components: { AuthorizationHeader, IMaskComponent },
  name: 'AuthorizationPage',
  data() {
    return {
      mode: 'sms',
      login: '',
      password: null,
      viewPassword: false,
      phone: null,
      mask: "+{7}(000)000-00-00",
    }
  },
  methods: {
    authorization() {
      let login = this.login
      if (this.mode === 'email' || this.mode === 'phone') {
        this.$store.dispatch('login', { login, password: this.password });
      }
      if (this.mode === 'sms') {
        this.$store.dispatch('fastAuthorization', login)
      }
    },

  },
  beforeMount() {
    scroll(0, 0);
  }

}
</script>

<style scoped>
.background {
  background: url(../../assets/img/registration-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 133px;
  background-position: center;
}

.authorization {
  text-align: center;
  margin-top: 44px;
}

.authorization__title {
  font-family: "DelaGothicOne";
  font-size: 32px;
  text-transform: unset;
  line-height: 1.6;
}

.authorization__text {
  color: #9F9EB3;
  font-size: 12px;
  line-height: 1.6;
}

.authorization__text a {
  text-decoration-line: underline;
  color: #FF2031;
}

.authorization-choice {
  display: flex;
  width: 100%;
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.authorization-way {
  margin: 17px 0 0px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.way__element {
  font-size: 12px;
  line-height: 1.6;
  font-weight: 500;
  color: rgba(16, 12, 64, 0.4000000059604645);
}

.way-chosen {
  color: #FF2031;
  text-decoration: underline;
}

.choice__element {
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: rgba(16, 12, 64, 0.20000000298023224);
}


.authorization-field {
  height: 52px;
  text-align: left;
  align-items: center;
  display: flex;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;

}

.authorization-field__input {
  color: #100c40;
  margin-left: 10px;
  width: 100%;
  max-width: 100%;
}

.authorization-field__input::placeholder {
  color: rgba(16, 12, 64, 0.4000000059604645);
}

.authorization-field__input input {
  opacity: 1;
}

.authorization-field__img {
  width: 24px;
  height: 24px;
  display: flex;
  flex-shrink: 0;
}

.field-tel {
  background: url(../../assets/img/authorization/tel.svg);
}

.field-email {
  background: url(../../assets/img/authorization/mail.svg);
}

.field-password {
  background: url(../../assets/img/authorization/password.svg);
}

.authorization-forgot {
  text-align: center;
  font-size: 12px;
  line-height: 1.6;

  color: rgba(16, 12, 64, 0.4);
}

.authorization-forgot a {
  text-decoration: underline;
}

.authorization-register {
  background: #FF2031;
  width: 100%;
  height: 66px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.authorization-social {
  margin-top: 32px;
  max-width: 180px;
  align-items: center;
}

.google {
  width: 30px;
  height: 30px;
}

.google img {
  width: 30px;
  height: 30px;
}

.gos {
  width: 38px;
  height: 38px;
}

.gos img {
  width: 38px;
  height: 38px;
}

.vk {
  width: 32px;
  height: 32px;
}

.vk img {
  width: 32px;
  height: 32px;
}

.apple {
  width: 36px;
  height: 36px;
}

.apple img {
  width: 36px;
  height: 36px;
}

.caption {
  display: flex;
  justify-content: center;
}

.rus {
  margin-left: 12px;
}

/* modificators */
.choice__element-chosen {
  background: #FF2031;
  color: #fff;
}

@media (max-width: 600px) {
  .background {
    background: url(../../assets/img/registration-bg-small.webp);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 53px;
    background-position: top;
  }

  .authorization {
    margin-top: 0;
  }

  .authorization__title {
    font-size: 24px;
    line-height: 1;
  }

  .authorization-content {
    margin-top: 30px;
    padding: 30px 10px;
  }

  .big-logo {
    width: 80px;
  }

  .authorization__text {
    margin-top: 30px;
    font-size: 12px;
    line-height: 1;
  }

  .authorization-filling {
    font-size: 12px;
    line-height: 1;
  }

  .authorization-choice {
    margin-top: 30px;
    font-size: 12px;
  }

  .authorization-register {
    height: 58px;
    font-size: 14px;
  }

  .authorization-social__element {
    width: 32px;
    height: 32px;
  }

  .authorization-social__element img {
    width: 32px;
    height: 32px;
  }
}

.eye {
  display: flex;
  width: 24px;
  height: 24px;
  background: url(../../assets/img/authorization/eye.svg);
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
}

.eye-open {
  background: url(../../assets/img/authorization/eye-open.svg);
  background-repeat: no-repeat;
}
</style>
  