<template>
  <div class="slider-wrapper slider-wrapper-progress slider">
    <button class="slider-last-block" @mousedown="prevCourses"><img
        src="../../assets/img/dashbord/filling/last-element.svg" alt=""
        class="slider-block__img"></button>
    <button class="slider-next-block" @mousedown="nextCourses"><img
        src="../../assets/img/dashbord/filling/next-element.svg" alt=""
        class="slider-block__img"></button>
    <div class="slider-content" id="slider">
      <div class="slider-filling" ref="slider">
        <div class="slider-filling-element" :id="'course'+index" v-for="(item,index) in courses"  :key="item.id">
          <SeparateSmallCourse  :course="item">
          </SeparateSmallCourse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeparateSmallCourse from "./SeparateSmallCourse.vue";

export default {
  components: {SeparateSmallCourse},
  name: "SliderWrapper",
  props: ['courses'],
  methods: {
    nextCourses() {
      if (document.getElementById('course0') != null) {
        let weight = (document.getElementById('course0').offsetWidth) + 20;
        this.$refs.slider.scrollBy({
          left: weight,
          behavior: 'smooth'
        });
      }
    },
    prevCourses(){
      if (document.getElementById('course0') != null) {
        let weight = document.getElementById('course0').offsetWidth;
        this.$refs.slider.scrollBy({
          left: -weight,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<style scoped>
.slider-wrapper {
  display: flex;
  flex-shrink: 1;
  position: relative;
  overflow: visible;
  max-width: 100%;
  width: 100%;
  height: 394px;
}

.slider-content {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.slider-last-block {
  position: absolute;
  left: -15px;
  bottom: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  width: 36px;
  height: 36px;
  z-index: 2;
}

.slider-next-block {
  position: absolute;
  right: -15px;
  bottom: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  width: 36px;
  height: 36px;
  z-index: 2;
}

.slider-filling {
  display: flex;
  overflow-x: scroll;
  position: absolute;
  max-width: 100%;
  width: 100%;
}
.slider-filling-element{
  display: flex;
  flex-shrink: 0;
  margin-right: 20px;
  width: 260px;
}

/* Modificators */
.slider-wrapper-progress {
  height: 443px;
}

@media (max-width: 600px) {
  .slider-wrapper {
    height: 501px;
  }

  .slider-filling {
    margin: 10px 0px 0px 0px;
  }
  .slider-filling-element{
    margin-right: 10px;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .slider-next-block {
    width: 24px;
    height: 24px;
    right: -10px;
  }

  .slider-last-block {
    width: 24px;
    height: 24px;
    left: -10px;
  }

  /* Modificators */
  .slider-wrapper-progress {
    height: 541px;
  }
}

</style>