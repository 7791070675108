import {fetchArticles} from "@/store/articles/serverInterationMethods";
import {fetchAllCourses, fetchMyCourses} from "@/store/courses/serverInterationMethods";


export const application = {
    state: {
        iterationInfo: null
    },
    mutations: {

    },
    actions: {

        async fetchSearchingData({getters}, {type, page, limit, name}) {
            switch (type) {
                case 'article':
                    return await fetchArticles(getters.token, 'all', page, limit, name);
                case 'courses':
                    return await fetchAllCourses(getters.token, page, limit, 'all', name);
                case 'my-courses':
                    return await fetchMyCourses(getters.token, page, limit, 'all', name);
            }
        }
    },
    getters: {
        iterationInfo: (state) => state.iterationInfo
    }
};