<template>
  <div class="profile" :class="{ 'profile-open': view }">
    <button @click="(view = !view)" class="active-profile " :class="{ 'active-profile-open': view }">
    </button>
    <div class="profile-wrapper" v-if="view">
      <div class="profile-caption flex al-cent">
        <h2 class="profile-caption__title">Профиль</h2>
        <button class="profile-caption__notifications ml-auto"></button>
        <button class="profile-caption__settings ml-20"></button>
      </div>
      <div class="profile-persone text-cent flex flex-dir-col m0-auto">
        <button class="authorization-field field-photo" v-if="change" @click="$refs.avatar.click()">
          <img :src="newUserData.avatarUrl" alt=" " class="field-photo__img "
               :class="{ 'field-photo__img-active': avatar }">
          <input ref="avatar" hidden type="file" @change="changeUserAvatar">
        </button>
        <img v-else :src="user.avatarUrl ? user.avatarUrl : require('@/assets/img/profile/user-not-avatar.webp')" alt=""
             class="profile-persone__face">
        <label class="authorization-field" v-if="change">
          <input type="text" placeholder="Фамилия" v-model="newUserData.secondName" class="authorization-field__input">
        </label>
        <label class="authorization-field" v-if="change">
          <input type="text" placeholder="Имя" v-model="newUserData.firstName" class="authorization-field__input">
        </label>
        <label class="authorization-field" v-if="change">
          <input type="text" placeholder="Отчество" v-model="newUserData.thirdName" class="authorization-field__input">
        </label>
        <label class="authorization-field" v-if="change">
          <i-mask-component placeholder="Номер телефона" v-model="newUserData.phone" v-model:unmasked="phone"
                            :mask="mask"
                            class="authorization-field__input"/>
        </label>
        <label class="authorization-field" v-if="change">
          <input type="text" placeholder="Email" v-model="newUserData.email" class="authorization-field__input">
        </label>
        <div class="authorization-field-wrapper" v-if="change">
          <label class="authorization-field ">
            <div class="authorization-field__img home"></div>
            <input @blur="blurList('viewRegionList')" @focusin="viewRegionList = true" v-model="searchRegionName"
                   type="email" :placeholder="regionName ? regionName : 'Регион проживания*'"
                   class="authorization-field__input">
          </label>
          <div class="pop-up" v-if="viewRegionList&&regionList.length">
            <button @click="newUserData.regionId=item.id" class="authorization-field-element"
                    v-for="item in regionList" :key="item.id">{{
                item.name
              }}
            </button>

          </div>
        </div>

        <div class="authorization-field-wrapper" v-if="change">
          <label id="districtList" class="authorization-field ">
            <div class="authorization-field__img home"></div>
            <input @blur="blurList('viewDistrictList')" @focusin="viewDistrictList = true"
                   v-model="searchDistrictName" type="email"
                   :placeholder="districtName ? districtName :'Район проживания*'  " class="authorization-field__input">
          </label>
          <div class="pop-up" v-if="viewDistrictList">
            <button class="authorization-field-element" v-for="item in districtList" :key="item?.id"
                    @click="newUserData.districtId = item?.id">
              {{ item?.name }}
            </button>

          </div>
        </div>

        <h3 class="profile-persone__name mt-20" v-else>
          {{ user.secondName }} {{ user.firstName }} {{ user.thirdName }}</h3>
        <p class="profile-persone__id mt-10" v-if="false">ID пациента: {{ user.userParams.id }}<span
            class="profile-persone__id-img"></span></p>
        <button @click="changeUserData" class="profile-persone__change m0-auto btn-height-48 br-black mt-20">
          {{ change ? 'Сохранить' : 'Редактировать информацию' }}
        </button>
      </div>
      <div class="profile-progress mt-60 m0-auto">
        <h3 class="profile-progress__title">Прогресс реабилитации пациента:</h3>
        <div class="profile-progress__group mt-20">
          <div class="progress-element" v-if="user.userParams.passingRecommendCourses.head != null">
            <img src="../../assets/img/course/head.webp" alt="" class="progress-element__img">
            <div class="progress-element__statistics">
              <p class="progress-element__statistics-text">Пройдено {{ user.userParams.passingRecommendCourses.head }}
                %</p>
              <div class="progress-element__statistics-line progress-element__statistics-line-purple">
                <div class="statistics-line__filling bg-purple"
                     :style="{ width: user.userParams.passingRecommendCourses.head + '%' }"></div>
              </div>
            </div>
          </div>
          <div class="progress-element" v-if="user.userParams.passingRecommendCourses.lungs != null">
            <img src="../../assets/img/course/lungs.webp" alt="" class="progress-element__img">
            <div class="progress-element__statistics">
              <p class="progress-element__statistics-text">Пройдено {{ user.userParams.passingRecommendCourses.lungs }}
                %</p>
              <div class="progress-element__statistics-line progress-element__statistics-line-yellow">
                <div class="statistics-line__filling bg-yellow"
                     :style="{ width: user.userParams.passingRecommendCourses.lungs + '%' }"></div>
              </div>
            </div>
          </div>
          <div class="progress-element" v-if="user.userParams.passingRecommendCourses.heart != null">
            <img src="../../assets/img/course/heart.webp" alt="" class="progress-element__img">
            <div class="progress-element__statistics">
              <p class="progress-element__statistics-text">Пройдено {{ user.userParams.passingRecommendCourses.heart }}
                %</p>
              <div class="progress-element__statistics-line progress-element__statistics-line-red">
                <div class="statistics-line__filling bg-red"
                     :style="{ width: user.userParams.passingRecommendCourses.heart + '%' }"></div>
              </div>
            </div>
          </div>
          <div class="progress-element" v-if="user.userParams.passingRecommendCourses.brain != null">
            <img src="../../assets/img/course/brain.webp" alt="" class="progress-element__img">
            <div class="progress-element__statistics">
              <p class="progress-element__statistics-text">Пройдено {{ user.userParams.passingRecommendCourses.brain }}
                %</p>
              <div class="progress-element__statistics-line progress-element__statistics-line-yellow">
                <div class="statistics-line__filling bg-yellow"
                     :style="{ width: user.userParams.passingRecommendCourses.brain + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
        <router-link :to="{ name: 'myCoursesPage' }"
                     class="profile-progress__start-course bg-red btn-height-48 mt-30 m0-auto">Перейти к курсам
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {errorHandler} from "@/lib/errorHandler";
import {IMaskComponent} from "vue-imask";
import districts from "@/lib/districts.json";
import {getDistrictNameById, getRegionNameById} from "@/lib/convertTime";

export default {
  watch: {
    "registrationData.regionId"() {
      this.viewRegionList = false;
      this.searchRegionName = null;
      this.newUserData.districtId = null;
      document.getElementById('districtList')?.focus();

    },
    "registrationData.districtId"(val) {
      if (!val) return;
      this.viewDistrictList = false;
      this.searchDistrictName = null;
    },


  },
  computed: {
    ...mapGetters(['user']),
    regionList() {
      return districts?.filter(item => this.searchRegionName ? item?.name?.toLowerCase().includes(this.searchRegionName.toLowerCase()) : true)
    },
    districtList() {
      if (this.newUserData?.regionId) {
        const regionIndex = districts?.map(item => item?.id).indexOf(this.newUserData?.regionId);
        if (regionIndex > -1) {
          return districts[regionIndex]?.districts?.filter(item => this.searchDistrictName ? item?.name?.toLowerCase()?.includes(this.searchDistrictName?.toLowerCase()) : true);
        }
      }
      return []
    },
    regionName() {
      return getRegionNameById(this.newUserData?.regionId);
    },
    districtName() {
      return getDistrictNameById(this.newUserData?.regionId, this.newUserData?.districtId);
    },
  },
  components: {
    IMaskComponent
  },
  name: 'PersonalProfile',
  data() {
    return {
      view: false,
      change: true,
      name: null,
      file: null,
      avatar: null,
      newUserData: null,

      mask: "+{7}(000)000-00-00",
      phone: '',


      viewDistrictList: false,
      viewRegionList: false,
      searchRegionName: null,
      searchDistrictName: null
    }
  },
  methods: {
    blurList(field) {
      setTimeout(() => {
        this[field] = false;
      }, 100)
    },
    async changeUserData() {
      if (this.change) {
        try {
          if (this.file) {
            await this.$store.dispatch('uploadFile', this.file).then(url => this.newUserData.avatarUrl = url);
          }
        } catch (e) {
          errorHandler({name: 'registration', status: 300})
          return null;
        }
        if (this.phone.length < 11) {
          errorHandler({name: 'validation', meta: {name: 'phone'}})
          return;
        }
        if (this.newUserData.email && !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(this.newUserData.email)) {
          errorHandler({name: 'validation', meta: {name: 'email'}},)
          return;
        }
        await this.$store.dispatch('updateUserData', {
          firstName: this.newUserData.firstName,
          secondName: this.newUserData.secondName,
          thirdName: this.newUserData.thirdName,
          avatarUrl: this.newUserData.avatarUrl,
          email: this.newUserData.email,
          phone: this.phone,
          regionId: this.newUserData.regionId,
          districtId: this.newUserData.districtId,

        }).then(async (status) => {
          if (status === 200) {
            await this.$store.dispatch('fetchUser').then(() => {
              this.newUserData = {
                firstName: this.user.firstName,
                secondName: this.user.secondName,
                thirdName: this.user.thirdName,
                avatarUrl: this.user.avatarUrl,
                email: this.user.email,
                phone: this.user.phone,
                regionId: this.user.regionId,
                districtId: this.user.districtId,
              }
            })
          }
        })
      }
    },
    changeUserAvatar() {
      this.file = this.$refs.avatar.files[this.$refs.avatar.files.length - 1];
      this.newUserData.avatarUrl = URL.createObjectURL(this.file);
    }
  },
  beforeMount() {
    this.newUserData = {
      firstName: this.user.firstName,
      secondName: this.user.secondName,
      thirdName: this.user.thirdName,
      avatarUrl: this.user.avatarUrl,
      email: this.user.email,
      phone: this.user.phone,
      regionId: this.user.regionId,
      districtId: this.user.districtId,

    }
  }

}
</script>

<style scoped>
.authorization-field {
  margin-top: 20px;
  height: 52px;
  text-align: left;
  align-items: center;
  display: flex;
  padding: 20px 10px 20px 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative !important;

}

.authorization-field__input {
  color: rgba(16, 12, 64, 0.4);
  margin-left: 10px;
}

.authorization-field-wrapper {
  position: relative;
}

.pop-up {
  position: absolute;
  max-width: 100%;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  scrollbar-width: thin;
  border-radius: 10px;
  z-index: 10;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.authorization-field-element {
  max-width: 100%;
  width: 100%;
  text-align: left;
  align-items: center;
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  transition: color 0.5s ease-in-out;
  font-size: 14px;
}

.pop-up :first-child {
  border-radius: 10px 10px 0 0;

}

.pop-up :last-child {
  border-radius: 0 0 10px 10px;
  border-bottom: none;
}

.authorization-field-element:hover {
  opacity: 1;
  color: #FF2031;
}

.authorization-field__input input {
  opacity: 1;
}

.authorization-field__status {
  width: 24px;
  height: 24px;
  display: flex;
  flex-shrink: 0;
}


.field-photo__text {
  color: #585579;
  font-size: 14px;
  margin-top: 12px;
}

.field-photo__img {
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


.profile {
  max-width: 420px;
  width: 100%;
  height: 100vh;
  position: fixed !important;
  right: 0;
  top: 0;
  transition: all 1s ease-in-out;
  border-left: 1px solid #DBDBDB;
  transform: translate(400px);
  z-index: 1;
}

/* Modificator */
.profile-open {
  width: 100%;
  background: #fff;
  transform: translate(0px);
}

.profile-wrapper {
  border-left: 1px solid rgba(85, 85, 85, 0.3);
  padding: 52px 20px 40px 28px;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
}


.active-profile {
  padding-left: 0;
  background: url(../../assets/img/profile/profile-btn-not-active.svg);
  width: 29px;
  height: 29px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 51px;
  left: -15px;
  border-radius: 100%;
  transition: all 1s ease-in-out;
}

/* Modificator */
.active-profile-open {
  background: url(../../assets/img/profile/profile-btn-active.svg);
  z-index: 20;
}

.profile-caption {
  margin-left: 12px;
  display: none;
}

.profile-caption__title {
  font-weight: 500;
  font-size: 24px;
}

.profile-caption__notifications {
  background: url(../../assets/img/profile/notification.svg);
  width: 24px;
  height: 24px;
}

.profile-caption__settings {
  background: url(../../assets/img/profile/settings.svg);
  width: 24px;
  height: 24px;
}

.profile-persone {
  max-width: 250px;
}

.profile-persone__face {
  width: 200px;
  height: 200px;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 10px;
  box-shadow: 0px 40px 140px rgba(0, 0, 0, 0.06);
  object-fit: cover;
  margin: 0 auto;
  background: rgba(33, 33, 33, 0.3000000238418579);;
}

.profile-persone__change {
  max-width: 100%;
  font-size: 12px;
  color: #100c40;
}

.profile-persone__id {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6000000238418579);
}

.profile-persone__id-img {
  display: flex;
  flex-shrink: 0;
  margin-left: 5px;
  background: url(../../assets/img/profile/id.svg);
  width: 16px;
  height: 16px;
}

.profile-progress__group {
  display: flex;
  flex-direction: column;
}

.profile-progress__title {
  font-size: 16px;
  font-weight: 500;
}

.progress-element {
  display: flex;
  align-items: center;
}

.progress-element__img {
  width: 50px;
  height: 50px;
  object-position: center;
}

.progress-element__statistics {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 8px;
}

.progress-element__statistics-text {
  font-size: 12px;
  font-weight: 500;
  color: #100c40;
  text-align: left;
}

.progress-element__statistics-line {
  max-width: 100%;
  width: 100%;
  height: 6px;

  border-radius: 69px;
  margin-top: 7px;
}

.progress-element__statistics-line-yellow {
  background: rgba(247, 173, 61, 0.20000000298023224);
}

.progress-element__statistics-line-purple {
  background: rgba(131, 87, 160, 0.20000000298023224);
}

.progress-element__statistics-line-red {
  background: rgba(255, 32, 49, 0.20000000298023224);
}

.statistics-line__filling {
  height: 100%;
  border-radius: 69px;
}

.profile-progress__start-course {
  color: #fff;
}

.field-photo {
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: url(../../assets/img/profile/no-photo-bg.svg);
  background-size: cover;
  background-position: center;
  padding: 0 !important;
}

.field-photo img {
  border-radius: 10px;
}

@media (max-width: 600px) {
  .profile {
    display: none;
  }
}
</style>
