<template>
  <div class="search">
    <div class="search-wrapper">
      <label class="search-filling">
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.4129 18.5062L11.616 12.7312C11.1931 13.0958 10.69 13.3802 10.1066 13.5844C9.52331 13.7885 8.90352 13.8906 8.24727 13.8906C6.6431 13.8906 5.2832 13.3365 4.16758 12.2281C3.05195 11.1198 2.49414 9.77812 2.49414 8.20312C2.49414 6.62813 3.05195 5.28646 4.16758 4.17813C5.2832 3.06979 6.62852 2.51562 8.20352 2.51562C9.77852 2.51562 11.1202 3.06979 12.2285 4.17813C13.3368 5.28646 13.891 6.62813 13.891 8.20312C13.891 8.83021 13.7889 9.43542 13.5848 10.0187C13.3806 10.6021 13.0816 11.149 12.6879 11.6594L18.5066 17.4125L17.4129 18.5062ZM8.22539 12.4031C9.37747 12.4031 10.3582 11.9911 11.1676 11.1672C11.977 10.3432 12.3816 9.35521 12.3816 8.20312C12.3816 7.05104 11.977 6.06302 11.1676 5.23906C10.3582 4.4151 9.37747 4.00312 8.22539 4.00312C7.04414 4.00312 6.04518 4.4151 5.22852 5.23906C4.41185 6.06302 4.00352 7.05104 4.00352 8.20312C4.00352 9.35521 4.41185 10.3432 5.22852 11.1672C6.04518 11.9911 7.04414 12.4031 8.22539 12.4031Z"
            fill="#100C40"
            fill-opacity="0.6"
          />
        </svg>
        <input
          type="text"
          v-model="word"
          @input="searchByName"
          placeholder="Поиск"
          class="search__field"
        />
        <div class="search-list" v-if="findResults && !viewResults">
          <button
            class="search-list__element"
            @click="goToPage(item)"
            v-for="item in findResults"
            :key="item.id"
          >
            {{
              item.title +
              ` (${
                item.type === "article"
                  ? "Статья"
                  : item.type === "course"
                  ? "Курс"
                  : item.type === "lesson"
                  ? "Урок"
                  : ""
              })`
            }}
          </button>
        </div>
      </label>
      <button class="search-button" @click="$emit('viewResults')">Найти</button>
    </div>
    <div class="search-settings" style="display: none">
      <button class="search-settings__btn">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6504 21.1501V15.3751H12.2754V17.4501H21.1004V19.1001H12.2754V21.1501H10.6504ZM2.90039 19.1001V17.4501H9.20039V19.1001H2.90039ZM7.57539 14.8751V12.8251H2.90039V11.1751H7.57539V9.0751H9.20039V14.8751H7.57539ZM10.6504 12.8251V11.1751H21.1004V12.8251H10.6504ZM14.8004 8.6251V2.8501H16.4254V4.9001H21.1004V6.5501H16.4254V8.6251H14.8004ZM2.90039 6.5501V4.9001H13.3504V6.5501H2.90039Z"
            fill="#100C40"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchElement",
  props: ["findResults", "viewResults"],
  data() {
    return {
      word: null,
      timer: null,
    };
  },
  methods: {
    searchByName() {
      if (
        this.word.indexOf(":") !== -1 ||
        this.word.indexOf("#") !== -1 ||
        this.word.indexOf("?") !== -1 ||
        this.word.indexOf("/") !== -1 ||
        this.word.indexOf("\\") !== -1 ||
        this.word.indexOf("\\") !== -1 ||
        this.word.indexOf("%") !== -1
      ) {
        return false;
      }
      clearTimeout(this.findTimer);
      if (this.word && this.word.length >= 4) {
        this.$emit("closeViewResults");
        this.findTimer = setTimeout(
          this.$store.dispatch("findArticlesAndCourses", {
            page: 1,
            limit: 20,
            word: this.word,
          }),
          1000
        );
      } else {
        this.$store.commit("setFindResults", []);
      }
    },
    goToPage(item) {
      switch (item.type) {
        case "lesson":
          this.$router.push({
            name: "lessonPage",
            params: { courseId: item.courseId, lessonId: item.id },
          });
          break;
        case "course":
          this.$router.push({
            name: "coursePage",
            params: { courseId: item.id },
          });
          break;
        case "article":
          this.$router.push({
            name: "currentArticle",
            params: { articleId: item.id },
          });
          break;
      }
    },
  },
  beforeUnmount() {
    this.$store.commit("setFindResults", []);
  },
};
</script>

<style scoped>
.search {
  display: flex;
  margin-top: 60px;
  font-family: "Muller";
}

.search-wrapper {
  max-width: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.search-button {
  margin-left: 20px;
  padding: 11px 40px;
  background: #ff2031;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.1);
  font-family: "Muller";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
}

.search-filling {
  display: flex;
  align-items: flex-start;
  padding-bottom: 9px;
  cursor: text;
  position: relative;
  border-bottom: 1px solid rgba(16, 12, 64, 0.6);
  max-width: 100%;
  width: 100%;
}

.search-filling svg {
  display: flex;
  flex-shrink: 0;
}

.search__field {
  padding-top: 3px;
  padding-left: 16px;
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: rgba(16, 12, 64, 0.6000000238418579);
  max-width: 100%;
  width: 100%;
}

.search-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #fff;
  max-width: 100%;
  width: 100%;
  z-index: 10000;
  border-radius: 6px;
  max-height: 200px;

  scrollbar-width: thin;
  top: 36px;
  overflow-y: scroll;
}

.search-list:hover {
  overflow-y: scroll;
}

.search-list :last-child {
  border-radius: 0 0 6px 6px;
}

.search-list__element {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: rgba(16, 12, 64, 0.6000000238418579);
}

.search-settings__btn {
  margin-left: 10px;
}

@media (max-width: 600px) {
  .search {
    margin-top: 50px;
  }

  .search-wrapper {
    max-width: 100%;
  }

  .search-filling {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .search-wrapper {
    flex-direction: column;
  }

  .search-button {
    margin: 20px 0 0;
    padding: 11px 65px;
  }
}
</style>
