<template>
  <div class="separate-curse separate-curse-line heart-course">
    <div class="curse-caption">
      <div
          class="curse-caption__img"
          :style="{ backgroundImage: `url('${course.previewUrl}')` }"
      ></div>
      <div class="curse-caption-description">
        <p class="curse-caption-description__name">{{ course.title }}</p>
        <div class="category-element__caption-tags">
          <div
              class="category-tag-wrapper"
              v-for="item in course.tags.filter((item) => item.name !== course.title)"
              :key="item.id"
          >
            <button class="category-tag tag">#{{ item.name }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="curse-progress">
      <p class="curse-progress__number">
        Пройдено {{ !course.passed ? 0 : course.passed }} %
      </p>
      <div class="curse-progress-line">
        <div
            class="curse-progress-line__filling"
            :style="{ width: (!course.passed ? 0 : course.passed) + '%' }"
        ></div>
      </div>
    </div>
    <p class="curse-text mt-10">{{ course.description }}</p>
    <button class="curse-start__about mt-20 btn-midle" @click="goToCourse">
      Подробнее
    </button>
    <!-- <button class="curse-start__settiongs"></button> -->
  </div>
</template>

<script>
export default {
  name: "SeparateRecommendedCourse",
  props: ["course"],
  methods: {
    goToCourse() {
      this.$router.push({
        name: "coursePage",
        params: {courseId: this.course.id},
      });
    },
  },
};
</script>

<style scoped>
.separate-curse {
  padding: 10px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
}

.curse-caption {
  display: flex;
}

.curse-caption__img {
  width: 60px;
  height: 60px;
  display: flex;
  flex-shrink: 0;
}

.curse-caption-description {
  margin-left: 14px;
}

.curse-caption-description__status {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: rgba(16, 12, 64, 0.6000000238418579);
}

.curse-caption-description__name {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #100c40;
}

.category-element__caption-tags {
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
}

.curse-start__about {
  font-size: 12px;
  color: #ffffff;
}

.curse-progress {
  display: none;
  margin-top: 15px;
}

.curse-progress__number {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #100c40;
}

.curse-progress-line {
  margin-top: 6px;
  max-width: 80%;
  width: 100%;
  height: 6px;
  border-radius: 69px;
}

.curse-progress-line__filling {
  height: 100%;
  border-radius: 69px;
}

.curse-text {
  display: none;
  font-size: 12px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.curse-start__settiongs {
  width: 20px;
  height: 20px;
  background: url(../../assets/img/course/settings.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  top: 10px;
}

.category-tag-wrapper {
  padding: 5px 10px 5px 0;
}

/*Modificators*/
.separate-curse-line .curse-progress {
  display: block;
}

.separate-curse-text .curse-text {
  display: inline;
}

.head-course {
  border-color: #8357a0;
}

.head-course .curse-caption__img {
  background: url(../../assets/img/course/head.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.head-course .curse-start__about {
  background: #8357a0;
}

.head-course .curse-progress-line {
  background: rgba(16, 12, 64, 0.20000000298023224);
}

.head-course .curse-progress-line__filling {
  background: #100c40;
}

.lungs-course {
  border-color: #f7ad3d;
}

.lungs-course .curse-caption__img {
  background: url(../../assets/img/course/lungs.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.lungs-course .curse-start__about {
  background: #f7ad3d;
}

.lungs-course .curse-progress-line {
  background: rgba(247, 173, 61, 0.20000000298023224);
}

.lungs-course .curse-progress-line__filling {
  background: #f7ad3d;
}

.heart-course {
  border-color: #ff2031;
}

.heart-course .curse-caption__img {
  background: url(../../assets/img/course/heart.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.heart-course .curse-start__about {
  background: #ff2031;
}

.heart-course .curse-progress-line {
  background: rgba(255, 32, 49, 0.20000000298023224);
}

.heart-course .curse-progress-line__filling {
  background: #ff2031;
}

.brain-course {
  border-color: #f7ad3d;
}

.brain-course .curse-caption__img {
  background: url(../../assets/img/course/brain.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.brain-course .curse-start__about {
  background: #f7ad3d;
}

.brain-course .curse-progress-line {
  background: rgba(247, 173, 61, 0.20000000298023224);
}

.brain-course .curse-progress-line__filling {
  background: #f7ad3d;
}

@media (max-width: 950px) {
  .separate-curse {
    padding: 0 10px 10px 10px;
    max-width: 280px;
    width: 100%;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .curse-caption {
    flex-direction: column;
  }

  .curse-caption__img {
    margin: 0 auto;
    width: 70px;
    height: 70px;
    background-size: 58px !important;
    background-position-x: center !important;
    background-position-y: center !important;
  }

  .curse-caption-description {
    margin-left: 0;
    text-align: center;
  }

  .curse-caption-description__status {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: rgba(16, 12, 64, 0.6000000238418579);
  }

  .curse-caption-description__name {
    margin-top: 2px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #100c40;
  }

  .category-element__caption-tags {
    margin-top: 12px;
    justify-content: center;
  }

  .curse-start__about {
    font-size: 12px;
    color: #ffffff;
  }

  .curse-progress {
    margin-top: 20px;
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .curse-text {
    margin-top: 20px;
  }

  .curse-progress-line {
    margin-top: 10px;
    max-width: 100%;
  }

  .curse-progress-line__filling {
    height: 100%;
    border-radius: 69px;
  }

  .curse-start__about {
    margin-top: 30px;
    max-width: 220px;
  }

  .curse-start__settiongs {
    width: 20px;
    height: 20px;
    background: url(../../assets/img/course/settings.svg);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .category-tag-wrapper {
    padding: 5px;
  }
}
</style>
