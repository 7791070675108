<template>
  <div class="body">
    <PersonalHeader>
    </PersonalHeader>
    <main class="main" v-if="currentTesting">
      <div class="caption flex al-cent">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">Тестирование по «{{ currentTesting.title }}»</h1>
        </div>
        <PersoneInCaption>
        </PersoneInCaption>
      </div>
      <div class="filling mt-40 flex gap40">
        <div class="content mt-40 flex flex-dir-col gap40">
          <div class="content-element test block br-red br-rad-10 flex">
            <div class="test-description">
              <h3 class="test-description__title" v-if="false">Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                sed do
                eiusmod tempor </h3>
              <p class="test-description__text mt-20">{{ currentTesting.description }} </p>
              <div class="test-description-statistics-wrapper flex flex-dir-col">
                <div class="test-description-statistics mt-20 al-cent flex gap10">
                  <img src="../../assets/img/test/time.svg" alt="" class="test-description-statistics__img">
                  <p class="test-description-statistics__text"> {{
                    !currentTesting.passingTimeSeconds || currentTesting.passingTimeSeconds === 0 ? 'Неограничено' :
                    convert(currentTesting.passingTimeSeconds) + ' минут'
                  }} </p>
                </div>
                <div class="test-description-statistics al-cent flex gap10">
                  <img src="../../assets/img/test/questions.svg" alt="" class="test-description-statistics__img">
                  <p class="test-description-statistics__text">{{ currentTesting.questions.length }} вопросов</p>
                </div>
              </div>
              <div class="test-description-actions flex gap20">
                <button class="test-description-actions__start btn-big bg-red white" @click="goToTesting">Начать
                  тестирование
                </button>
                <button v-if="false" class="test-description-actions__remind btn-big br-red red">Напомнить позже
                </button>
              </div>
            </div>
            <img :src="currentTesting.previewUrl ? currentTesting.previewUrl : require('@/assets/img/test/test-bg.webp')"
              alt="" class="test-previous">
          </div>
          <div class="content-element info block br-rad-10" v-if="false">
            <div class="info-filling p-20">
              <h3 class="test-description__title">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor </h3>
              <p class="test-description__text mt-20">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. </p>
              <div class="test-description-statistics info-statistics al-cent flex white mt-30 gap10">
                <img src="../../assets/img/test/pdf.svg" alt="" class="test-description-statistics__img">
                <p class="test-description-statistics__text">PDF-файл: «Название чек-листа»</p>
              </div>
              <button class="test-description-actions__start info-action btn-big bg-red white mt-30">Скачать чек-лист
              </button>
            </div>
          </div>
        </div>
        <div class="additionally flex flex-dir-col gap40">
          <div class="additionally-element my-course" v-if="false">
            <h5 class="my-course__title small-title">Тестирования:</h5>
            <div class="my-course-group p-20-10 block gap10 flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="myTesting">
                <SeparateRecommendedTest v-for="item in myTesting" :key="item.id" :testing="item">
                </SeparateRecommendedTest>
              </div>
              <button class="slider-last-block tablet"><img src="../../assets/img/dashbord/filling/last-element.svg"
                  alt="" class="slider-block__img"></button>
              <button class="slider-next-block tablet"><img src="../../assets/img/dashbord/filling/next-element.svg"
                  alt="" class="slider-block__img"></button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto">Все мои курсы</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile>
    </PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SeparateRecommendedTest from "../../components/tests/SeparateRecommendedTest.vue";
import { mapGetters } from "vuex";
import { convertTimeInMinuteAndSeconds } from "@/lib/convertTime";


export default {
  computed: {
    ...mapGetters(['currentTesting', 'myTesting'])
  },
  components: { PersonalHeader, PersonalProfile, SeparateRecommendedTest, PersoneInCaption },
  name: "SeparateTestPreviousPage",
  props: ['testingId'],
  beforeMount() {
    this.$store.dispatch('fetchTesting', this.testingId);
    this.$store.dispatch('fetchMyTesting');
  },
  data() {
    return {
      convert: convertTimeInMinuteAndSeconds
    }
  },
  methods: {
    goToTesting() {
      this.$router.push({ name: 'testingPassingPage', params: { testingId: this.testingId } });
    }
  }
}
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

/* Caption */

.caption {
  padding: 40px 5% 0px 0px;
}

.caption-greetings__title {
  font-family: "DelaGothicOne";
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
  max-width: 782px;
}


/* filling */
.filling {
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
}

.filling-element {
  max-width: 100%;
  width: 100%;
}

.content-element {
  max-width: 100%;
  width: 100%;

}

.additionally {
  max-width: 400px;
  width: 100%;
}

/*content*/
.content {
  width: 100%;
}

.test-description {
  max-width: 100%;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
}

.test-description__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.28;
}

.test-description__text {
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.test-description-statistics {
  margin-bottom: 20px;
}

.test-description-statistics__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.5);
}

.test-description-actions {
  margin-top: auto;
}

.test-description-actions__remind {
  max-width: 220px;
}

.test-previous {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0 10px 10px 0;
  max-height: 600px;
}

/* additionally */
.additionall-about {
  padding: 30px 20px;
}

.additionall-about-title {
  font-family: "DelaGothicOne";
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.additionall-about-text {
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-btn {
  margin-top: 58px;
}

.additionall-about-time {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.additionall-about-time__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/time.svg);
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/*my cours*/
.my-course__more {
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 1880px) {
  .carousel-title {
    max-width: 398px;
  }

  .additionally {
    max-width: 326px;
  }

  .gap40 {
    gap: 20px;
  }
}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 30px 0px;
    justify-content: space-between;
    gap: 10px;
  }

  .caption-greetings__title {
    font-size: 24px;
  }

  .caption-greetings__text {
    font-size: 14px;
    margin-top: 8px;
  }

  .surch {
    margin-left: 0;
  }

  .surch__input {
    font-size: 14px;
    max-width: 265px;
    width: 100%;
    display: flex;
    flex-shrink: 1;
  }

  /* carousel */
  .carousel {
    padding: 20px 20px 50px 20px;
    position: relative;
  }

  .carousel-title {
    font-size: 48px;
  }

  .carousel-text {
    font-size: 14px;
    max-width: 213px;
    line-height: 1.4;
  }

  .carousel-button {
    margin-top: 14px;
    max-width: 223px;
    height: 44px;
    font-size: 12px;
  }

  .carousel-nav {
    left: calc(50% - 70px);
  }

  .carousel-img {
    right: 0px;
    bottom: 0px;
    background: url(../../assets/img/dashbord/filling/man.svg);
    background-size: cover;
    width: 274px;
    height: 260px;
  }

  /* additionally */
  .additionall-about {
    padding: 20px 10px 26px 10px;
  }

  .additionall-about-title {
    font-size: 20px;
  }

  .additionall-about-text {
    margin-top: 10px;
    font-size: 14px;
  }

  .additionall-about-btn {
    margin-top: 72px;
    max-width: 195px;
    font-size: 12px;
  }

  .additionall-about-time {
    margin-top: 20px;
  }
}

@media (max-width: 950px) {
  .gap40 {
    gap: 40px;
  }

  .body {
    flex-direction: column;
  }

  .filling {
    flex-direction: column;
  }

  .caption {
    padding: 40px 0 0 0;
  }

  .caption-greetings {
    margin: 0 auto;
  }

  .caption-greetings__title {
    font-size: 32px;
  }

  .main {
    margin-left: 0;
    margin-right: 0;
    padding: 0 20px;
  }

  .profile {
    display: none;
  }

  /* additionall */
  .my-course-group {
    overflow-x: scroll;
  }

  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    margin-top: 20px;
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
    gap: 10px;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .separate-curse {
    max-width: 280px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  .caption-greetings__title {
    text-align: center;
    font-size: 24px;
  }

  .carousel {
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 20px 20px 45px 20px;
  }

  .carousel * {
    margin: 0 auto;
  }

  .carousel-title {
    max-width: 267px;
    font-size: 32px;
  }

  .carousel-text {
    margin-top: 10px;
    max-width: 267px;
    font-size: 12px;
  }

  .carousel-button {
    margin-top: 199px;
    max-width: 223px;
  }

  .carousel-img {
    width: 298px;
    height: 282px;
    right: calc(50% - 149px);
  }

  .content {
    margin-top: 0;
    padding: 0 0 20px 0;
  }

  /* test */
  .test {
    flex-direction: column;
  }

  .test-previous {
    order: 1;
    display: flex;
    max-width: 100%;
    height: 160px;
  }

  .test-description {
    order: 2;
    text-align: center;
    padding: 30px 10px 20px 10px;
    max-width: 100%;
    align-items: center;
    min-height: unset;
  }

  .test-description__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
  }

  .test-description__text {
    font-size: 12px;
    line-height: 1.6;
  }

  .test-description-statistics-wrapper {
    flex-direction: row;
    gap: 50px;
    margin-top: 20px;
  }

  .test-description-statistics {
    display: flex;
    margin: 0;
    flex-direction: column;
  }

  .test-description-actions {
    margin-top: 30px;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    gap: 10px;
  }

  .test-description-actions__start {
    max-width: 165px;
    height: 44px;
    font-size: 12px;
  }

  .test-description-actions__remind {
    max-width: 165px;
    height: 44px;
    font-size: 12px;
  }

  .info {
    text-align: center;
  }

  .info-filling {
    padding: 20px 10px;
  }

  .info-statistics {
    flex-direction: row;
    margin: 20px 0 0 0;
    justify-content: center;
  }

  .info-action {
    margin: 20px auto 0 auto;
  }

  /*category*/
  .category-element {
    padding: 0;
    margin-top: 20px;
  }

  .category-element__caption-tags {
    gap: 4px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }

  .tags {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: 50px;
  }


}
</style>