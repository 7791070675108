<template>
  <div class="body" v-if="currentArticle">
    <PersonalHeader>
    </PersonalHeader>
    <main class="main">
      <div class="caption flex al-cent">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">{{ currentArticle.title }}</h1>
          <div class="caption-greetings-info">
            <p class="caption-greetings-info__author">Автор: {{ currentArticle.author.firstName }}
              {{ currentArticle.author.secondName }} </p>
            <!-- <p class="caption-greetings-info__author share"> Поделиться</p> -->
          </div>
        </div>
        <PersoneInCaption>
        </PersoneInCaption>
      </div>
      <div class="filling flex mt-20">
        <div class="blog p-20 mt-40 block br-rad-10" style="max-width: 100%;width: 100%;">
          <div class="blog-description" v-if="false">
            <h3 class="blog-description__title blog-title">Описание:</h3>
            <p class="blog-description__text blog-text">Описание курса Amet minim mollit non deserunt ullamco est sit
              aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat
              sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
              consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non
              deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua
              dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt
              nostrud amet.</p>
          </div>
          <div class="blog-main">
            <div class="article-body"
                 v-for="item in (currentArticle.body?currentArticle.body.sort((a,b)=>a.order-b.order):nullBodyArticle)"
                 :key="item.body">
              <div class="article-body-title" v-if="item.typeBlock==='title'">
                <h3 class="blog-description__title blog-title">{{ item.body }}</h3>
              </div>
              <div class="article-body-text" v-if="item.typeBlock==='text'">
                <pre class="blog-text">{{ item.body }}</pre>
              </div>
              <div class="article-body-image" v-if="item.typeBlock==='image'">
                <img :src="item.body" alt="" class="blog-img">
              </div>
              <div class="article-body-image" v-if="item.typeBlock==='link'">
                <a :href="item.body" target="_blank" class="blog-text link">{{ item.body }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="additionally flex flex-dir-col">
          <div class="additionally-element my-course">
            <h5 class="my-course__title small-title">Другое:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="similarArticles">
                <RecommendedBlog v-for="item in similarArticles" :key="item.id" :article="item">
                </RecommendedBlog>
              </div>
              <button class="slider-last-block tablet"><img src="../../assets/img/dashbord/filling/last-element.svg"
                                                            alt="" class="slider-block__img"></button>
              <button class="slider-next-block tablet"><img src="../../assets/img/dashbord/filling/next-element.svg"
                                                            alt="" class="slider-block__img"></button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto" @click="goToAllBlogs">Все мои
                курсы
              </button>
            </div>
          </div>
          <div class="additionally-element my-course" v-if="false">
            <h5 class="my-course__title small-title">Рекомендуемые курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="recommendedCourse">
                <SeparateRecommendedCourse v-for="item in recommendedCourse" :key="item.id" :course="item">
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet"><img src="../../assets/img/dashbord/filling/last-element.svg"
                                                            alt="" class="slider-block__img"></button>
              <button class="slider-next-block tablet"><img src="../../assets/img/dashbord/filling/next-element.svg"
                                                            alt="" class="slider-block__img"></button>
              <button class="my-course__more btn-midle red br-red desctop mt-10 m0-auto">Все рекомендации</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile>
    </PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SeparateRecommendedCourse from "../../components/courses/SeparateRecommendedCourse.vue";
import RecommendedBlog from "../../components/blog/RecommendedBlog.vue";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['currentArticle', 'similarArticles', 'recommendedCourse'])
  },
  components: {PersonalHeader, PersonalProfile, SeparateRecommendedCourse, PersoneInCaption, RecommendedBlog},
  name: "BlogPage",
  props: ['articleId'],
  data() {
    return {
      nullBodyArticle: [
        {
          typeBlock: 'title',
          body: 'Нет содержания статьи',
          order: 1
        }
      ]
    }
  },
  beforeMount() {
    scroll(0, 0);
    this.$store.dispatch('fetchCurrentArticle', this.articleId);
    this.$store.dispatch('fetchSimilarArticles', this.articleId);
    this.$store.dispatch('fetchMyCourses', {page: 1, limit: 5, trend: 'all'})
  },
  methods: {
    goToAllBlogs() {
      this.$router.push({name: 'libraryPage'});
    }
  }
}
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

/* Caption */

.caption {
  padding: 40px 5% 0px 0px;
  align-items: flex-start;
}

.caption-greetings__title {
  max-width: 600px;
  font-family: "DelaGothicOne";
  font-weight: 500;
  font-size: 32px;
}

.caption-greetings-info {
  display: flex;
  max-width: 400px;
  justify-content: space-between;
  margin-top: 20px;
}

.caption-greetings-info__author {
  font-size: 16px;
  line-height: 1.1;
  color: rgba(16, 12, 64, 0.6000000238418579);
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
}

.content {
  max-width: 100%;
  width: 100%;
}

.content-element {
  max-width: 100%;
  width: 100%;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/* blog */
.blog {
  margin-right: 40px;
}

.blog-description {
  display: flex;
  flex-direction: column;
}

.blog-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  color: #100c40;
  margin-bottom: 20px;
}

.blog-text {
  font-family: "Muller";
  font-size: 16px;
  line-height: 1.6;
  white-space: pre-line;
  color: rgba(16, 12, 64, 0.699999988079071);
  margin-bottom: 20px;
}

.blog-main {
  display: flex;
  flex-direction: column;
}

.blog-img {
  height: 340px;
  object-fit: cover;
  border: 1px solid rgba(85, 85, 85, 0.2);
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
}

.link {
  cursor: pointer;
}

/*category*/

.category-element {
  padding: 10px 10px 20px 10px;
  margin-top: 40px;
}

.category-element__caption {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.category-element__caption-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.category-element-first {
  margin-top: 20px;
}

/*my cours*/
.my-course__more {
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
}

.additionally-element {
  margin-bottom: 40px;
}

@media (max-width: 1880px) {
  .additionally {
    max-width: 326px;
  }

}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  .filling {
    margin-top: 0px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 30px 0px;
    justify-content: space-between;
  }

  .caption-greetings__title {
    font-size: 24px;
  }

  .caption-greetings-info {
    flex-direction: column;
  }

  .slider-last-block {
    display: none;
  }

  .slider-next-block {
    display: none;
  }

  .content {
    margin-right: 20px;
  }
}

@media (max-width: 950px) {
  .body {
    flex-direction: column;
  }

  .profile {
    display: none;
  }

  .main {
    margin-left: 0;
    margin-right: 0;
    padding: 0 20px;
  }

  .filling {
    flex-direction: column;
  }

  .blog {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }

  /* additionall */
  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .content {
    margin-right: 0px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }


  .caption-greetings {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .caption-greetings__title {
    max-width: 100%;
  }

  .caption-greetings-info {
    max-width: 100%;
  }

  .caption-greetings-info__author {
    font-size: 12px;
  }

  /*category*/
  .category-element {
    padding: 0;
    margin-top: 20px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }

  /* blog */
  .blog {
    text-align: center;
    padding: 23px 10px;
  }

  .blog-title {
    font-size: 16px;
    line-height: 1.2;
  }

  .blog-text {
    font-size: 12px;
  }

  .blog-main {
    margin-top: 0px;
  }
}
</style>