import {
    fetchAllCourses,
    fetchAllLessons,
    fetchCourseCategory,
    fetchMyCourses,
    fetchRecommendedCourses,
    findArticlesAndCourses
} from "./serverInterationMethods";
import {fetchCurrentCourse} from "@/store/courses/serverInterationMethods";

export const courses = {
    actions: {
        async findArticlesAndCourses({getters, commit}, {word, page, limit}) {
            const {data} = await findArticlesAndCourses(getters.token, word, page, limit);
            commit('setFindResults', data);
        },
        async fetchAllCourses({getters, commit}, payload) {
            const {data} = await fetchAllCourses(getters.token, payload.page, payload.limit, payload.trend, payload.name);
            if (payload.page === 1) {
                commit('setAllCourses', data);
            } else {
                commit('addAllCourses', data);
            }
            return data;
        },
        async fetchAllLessons({getters, commit}, {page, limit, name}) {
            const {data} = await fetchAllLessons(getters.token, page, limit, name);
            if (page === 1) {
                commit('setAllCourses', data);
            } else {
                commit('addAllCourses', data);
            }
            return data;
        },
        async fetchHeadCourses({commit, getters}) {
            const {data} = await fetchAllCourses(getters.token, getters.headPage, getters.coursesLimit, 'head');
            commit('setHeadCourses', data);
        },
        async fetchLungsCourses({commit, getters}) {
            const {data} = await fetchAllCourses(getters.token, getters.lungsPage, getters.coursesLimit, 'lungs');
            commit('setLungsCourses', data);
        },
        async fetchBrainCourses({commit, getters}) {
            const {data} = await fetchAllCourses(getters.token, getters.brainPage, getters.coursesLimit, 'brain');
            commit('setBrainCourses', data);
        },
        async fetchHeartCourses({commit, getters}) {
            const {data} = await fetchAllCourses(getters.token, getters.heartPage, getters.coursesLimit, 'heart');
            commit('setHeartCourses', data);
        },
        async fetchCourseCategory({commit, getters}) {
            const {data} = await fetchCourseCategory(getters.token);
            commit('setCourseCategory', data);
        },
        async fetchCurrentCourse({commit, getters}, id) {
            const {data} = await fetchCurrentCourse(getters.token, id);
            commit('setCurrentCourse', data.course);
            commit('setCourseAuthor', id)
        },
        async fetchMyCourses({getters, commit}, payload) {
            const {data} = await fetchMyCourses(getters.token, payload.page, payload.limit, payload.trend, payload.name);
            if (payload.page === 1) {
                commit('setMyCourses', data);
            } else {
                commit('addMyCourses', data);
            }
            return data;
        },
        async fetchRecommendedCourses({getters, commit}) {
            const {data} = await fetchRecommendedCourses(getters.token);
            commit('setRecommendedCourses', data);
        }
    },
    mutations: {
        setAllCourses(state, data) {
            state.courses = data;
        },
        addAllCourses(state, data) {
            state.courses = state.courses.concat(data);
        },
        setRecommendedCourses(state, data) {
            state.recommendedCourse = data;
        },
        setMyCourses(state, data) {
            state.myCourses = data;
        },
        setHeadCourses(state, data) {
            state.head = data;
        },
        setLungsCourses(state, data) {
            state.lungs = data;
        },
        setBrainCourses(state, data) {
            state.brain = data;
        },
        setHeartCourses(state, data) {
            state.heart = data;
        },
        setCourseCategory(state, data) {
            state.courseCategory = data;
        },
        setCurrentCourse(state, data) {
            state.currentCourse = data;
        },
        setFindResults(state, data) {
            state.findResults = data;
        },
        setCourseAuthor(state, id) {
            switch (id) {
                case '6a79e890-ef9a-4313-ace5-12d894a42460':
                    state.currentCourse.author.name = 'Андрей Евгеньевич Ирисов';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/andrey_irisov.webp');
                    break;
                case '21a3f78a-51a1-44cd-8f98-dae9ceb2a75c':
                    state.currentCourse.author.name = 'Евгений Леонидович Никонов';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/evgeny_nikonov.webp');
                    break;
                case 'b1e7a636-aaed-4e16-85ca-b520b5aef642':
                    state.currentCourse.author.name = 'Николай Николаевич Потекаев';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/nikolay_potekaev.webp');
                    break;
                case 'dfe2be75-9f61-420d-ae70-86ab36ee5a67':
                    state.currentCourse.author.name = 'Анастасия Юрьевна Лебедева';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/anastasia_lebedeva.webp');
                    break;
                case '394a3354-8b3c-4c12-9168-eaf62bf05cf7':
                    state.currentCourse.author.name = 'Ойбек Эркинович Тургунхужаев';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/oybek_turgunhujaev.webp');
                    break;
                case '8dad05bd-178c-4761-aabb-6460dd0eaec1':
                    state.currentCourse.author.name = 'Вероника Хуснутдинова';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/veronika_husnutdinova.webp');
                    break;
                case '3dc055a6-f9f7-455d-8a95-b72241b8bee9':
                    state.currentCourse.author.name = 'Наталья Юрьевна Кравченко';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/nataliya_kravchenko.webp');
                    break;
                case '9570e089-83f7-4317-a0a4-917ddcbd641f':
                    state.currentCourse.author.name = 'Антон Валерьевич Масякин';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/anton_masyakin.webp');
                    break;
                case '7f2434c2-0835-470e-ab4c-0d4f41911193':
                    state.currentCourse.author.name = 'Роман Вячеславович Бузунов';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/roman_buzunov.webp');
                    break;
                case '225a69b1-69f9-4f58-881e-b14d3532d2d5':
                    state.currentCourse.author.name = 'Мила Эйнуллаевна Иманова';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/mila_imanova.webp');
                    break;
                case 'b639e50b-eca2-4c9b-9f2f-fe5bbd205c6c':
                    state.currentCourse.author.name = 'Эльдар Юрьевич Султанов';
                    state.currentCourse.author.avatarUrl = require('@/assets/img/doctors/eldar_sultanov.webp');
                    break;
            }
        }

    },
    state: {
        headPage: 0,
        lungsPage: 0,
        heartPage: 0,
        brainPage: 0,
        coursesLimit: 5,
        heart: null,
        lungs: null,
        brain: null,
        head: null,
        courseCategory: null,
        currentCourse: null,
        myCourses: null,
        recommendedCourse: null,
        courses: null,
        findResults: null
    },
    getters: {
        head: state => state.head,
        lungs: state => state.lungs,
        brain: state => state.brain,
        heart: state => state.heart,
        coursesLimit: state => state.coursesLimit,
        headPage: state => state.headPage,
        lungsPage: state => state.lungsPage,
        heartPage: state => state.heartPage,
        brainPage: state => state.brainPage,
        courseCategory: state => state.courseCategory,
        currentCourse: state => state.currentCourse,
        myCourses: state => state.myCourses,
        recommendedCourse: state => state.recommendedCourse,
        courses: state => state.courses,
        findResults: state => state.findResults
    }
}