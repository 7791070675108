<template v-if="user">
  <div class="persone ml-auto flex al-cent" :class="{ 'persone-white': whiteHeader }">
    <!-- <button class="persone-nitification">
    </button> -->
    <img :src="user.avatarUrl ? user.avatarUrl : require('@/assets/img/profile/user-not-avatar.webp')" alt=""
      class="persone__face ml-30">
    <div class="persone-description ml-20">
      <p class="persone-name">{{ user.secondName }} {{ user.firstName }} {{ user.thirdName }}
        <!-- <button class="persone-name__list"></button> -->
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PersoneInCaption',
  computed: {
    ...mapGetters(['user', 'whiteHeader'])
  }


}
</script>

<style scoped>
.persone-nitification {
  width: 24px;
  height: 24px;
  background: url(../../assets/img/profile/notification.svg);
}

.persone__face {
  display: flex;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 10px;
}

.persone-name {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #100c40;
  font-size: 20px;
}

.persone-name__list {

  background: url(../../assets/img/list.svg);
  width: 18px;
  height: 18px;
  margin-left: 6px;
  display: flex;
  flex-shrink: 0;
  background-size: cover;
}

.persone-id {
  color: rgba(33, 33, 33, 0.30000001192092896);
  margin-top: 6px;
}

@media (max-width: 1300px) {
  .persone {
    margin-left: 0;
  }

  .persone__face {
    margin-left: 20px;
  }

  .persone-description {
    margin-left: 2px;
  }

  .persone-name {
    font-size: 16px;
  }

  .persone-name__list {
    width: 14px;
    height: 14px;
  }

  .persone-id {
    font-size: 14px;
  }
}

@media (max-width: 950px) {
  .persone {
    display: none;
  }
}

/* Modification */
.persone-white .persone-nitification {
  background: url(../../assets/img/profile/notification-white.svg);
}

.persone-white .persone-name {
  color: #ffffff;
}

.persone-white .persone-id {
  color: rgba(255, 255, 255, 0.5899999737739563);
}

.persone-white .persone-name__list {
  background: url(../../assets/img/list-white.svg);
}

@media (max-width: 1300px) {
  .persone-white {
    margin-left: auto;
  }
}
</style>
