<template>
  <div
    class="separate-curse-wrapper separate-curse-progress heart-course"
    v-if="course.tags.length !== 0"
    :class="{ 'course-in-my-courses': myCoursesPage != null }"
  >
    <div class="separate-curse">
      <div class="curse-caption">
        <div
          class="curse-caption__img"
          :style="{ backgroundImage: `url('${course.previewUrl}')` }"
        ></div>
        <div class="curse-caption-description">
          <p class="curse-caption-description__status" v-if="!course.isLesson">
            Статус: {{ course.passed === 100 ? "Пройден" : "Не пройденный" }}
          </p>
          <p class="curse-caption-description__name">
            {{ course.isLesson ? `Урок: ${course.title}` : course.title }}
          </p>
        </div>
      </div>
      <div
        class="curse__tags flex head-tags flex-wrap"
        :class="{
          'training-tags': tutorial && tutorialStage === 3 && index === 0,
        }"
      >
        <div
          class="trind-card"
          v-if="tutorial && tutorialStage === 3 && index === 0"
        >
          <img src="@/assets/img/cards/arrow.svg" alt="" class="card-arrow" />
          <button
            class="card__close"
            @click="$emit('startTutorialStageTimer', true)"
          >
            <img
              src="@/assets/img/cards/close.svg"
              alt="Закрыть"
              class="card__close-img"
            />
          </button>
          <div class="card-cation">
            <img
              src="@/assets/img/cards/tags.svg"
              alt=""
              class="card-cation__icon"
            />
            <p class="card-cation__text">Удобные теги поиска</p>
          </div>
          <p class="card__text">
            Ключевые слова для быстрого поиска необходимых курсов.
          </p>
        </div>
        <div
          class="category-tag-wrapper"
          v-for="item in course.tags.filter(
            (item) => item.name !== course.title
          )"
          :key="item.id"
        >
          <button class="curse__tags-element tag">#{{ item.name }}</button>
        </div>
      </div>
      <h5 class="curse-description__title">Что входит в курс:</h5>
      <ul class="curse-description-list mt-10" v-if="course.shortDescription">
        <li
          class="description-list__element"
          v-for="(item, index) in course.shortDescription.split('||')"
          :key="item + index"
        >
          {{ item }}
        </li>
      </ul>
      <ul class="curse-description-list mt-10" v-if="false">
        <li
          class="description-list__element"
          v-for="item in course.lessons"
          :key="item.id"
        >
          {{ item.title }}
        </li>
      </ul>
      <div class="curse-start flex al-cent">
        <button class="curse-start__about btn-midle" @click="goToCourse">
          Подробнее
        </button>
      </div>
      <!-- <button class="curse-start__settiongs"></button> -->
    </div>
    <div class="progress p-10" v-if="!course.isLesson">
      <p class="progress__text">
        Пройдено {{ course.passed ? course.passed : 0 }}%
      </p>
      <div class="progress-line">
        <div
          class="progress-line-filling"
          :style="{ width: (course.passed ? course.passed : 0) + '%' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
  name: "SeparateSmallCourse",
  props: ["course", "myCoursesPage", "tutorial", "index", "tutorialStage"],
  data() {
    return {
      textSymbolLimit,
    };
  },
  methods: {
    async goToCourse() {
      if (this.course.isLesson) {
        this.$router.push({
          name: "lessonPage",
          params: { courseId: this.course.courseId, lessonId: this.course.id },
        });
      } else {
        this.$router.push({
          name: "coursePage",
          params: { courseId: this.course.id },
        });
      }
    },
  },
};
</script>

<style scoped>
.separate-curse-wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  height: calc(100% - 50px);
}

.course-in-my-courses {
  max-width: 100%;
  width: 100%;
}

.separate-curse {
  padding: 0 10px 10px 0;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
}

.curse-caption {
  display: flex;
  align-items: center;
}

.curse-caption__img {
  display: flex;
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 4px 0 0 4px;
}

.curse-caption-description {
  margin-left: 14px;
}

.curse-caption-description__status {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: rgba(16, 12, 64, 0.6000000238418579);
}

.curse-caption-description__name {
  font-weight: 500;
  line-height: 1.1;
  color: #100c40;
  margin-top: 6px;
}

.curse__tags {
  margin-left: 10px;
  margin-top: 13px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.curse-description__title {
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: #100c40;
  margin-left: 10px;
}

.curse-description-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.description-list__element {
  font-size: 12px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
  margin-left: 10px;
  padding-left: 20px;
  position: relative;
  font-family: "Muller";
}

.description-list__element::before {
  position: absolute;
  left: 5px;
  top: 9px;
  content: "";
  width: 2px;
  height: 2px;
  display: block;
  border-radius: 100%;
  background: #100c40;
}

.curse-start {
  margin-top: auto;
  justify-content: space-between;
  margin-left: 10px;
}

.curse-start__about {
  font-size: 12px;
  color: #ffffff;
}

.curse-start__settiongs {
  width: 20px;
  height: 44px;
  background: url(../../assets/img/course/settings.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

/* progress */
.progress {
  display: none;
  border: 1px solid #100c40;
  border-radius: 10px;
  flex-direction: column;
  text-align: center;
  flex-shrink: 0;
  padding: 10px !important;
}

.progress-line {
  width: 100%;
  height: 6px;
  margin-top: 10px;
  border-radius: 69px;
}

.progress-line-filling {
  border-radius: 69px;
  height: 100%;
}

.progress__text {
  font-weight: 500;
  font-size: 12px;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.category-tag-wrapper {
  padding: 5px 10px 5px 0;
}

.training-tags {
  position: relative;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
  z-index: 100;
}

.trind-card {
  position: absolute;
  left: 0;
  top: -159px;
  background: #ffffff;
  border-radius: 10px;
  padding: 12px 35px 20px 12px;
  width: 265px;
  pointer-events: all;
  cursor: auto;
  z-index: 100;
}

.card-arrow {
  position: absolute;
  bottom: -9px;
  left: 17px;
  transform: rotate(270deg);
}

.card__close {
  position: absolute;
  right: 0px;
  top: 0px;
}

.card-cation {
  display: flex;
  align-items: center;
}

.card-cation__text {
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #100c40;
}

.card__text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.card__italic-text {
  margin-top: 10px;
  text-decoration-line: underline;
  font-style: italic;
  font-weight: 700;
}

@media (max-width: 600px) {
  .trind-card {
    position: absolute;
    left: -100px;
  }

  .card-arrow {
    left: 140px;
  }

  .separate-curse-wrapper {
    width: 100%;
  }

  .course-in-my-courses {
    max-width: 100%;
    width: 100%;
  }

  .separate-curse {
    padding: 0 10px 10px 10px;
    justify-content: center;
    text-align: center;
  }

  .curse-caption {
    flex-direction: column;
    justify-content: center;
  }

  .curse-caption__img {
    width: 98px;
    height: 98px;
  }

  .curse-caption-description {
    margin-left: 0;
  }

  .curse-caption-description__status {
    font-size: 12px;
    line-height: 12px;
    margin-top: 7px;
  }

  .curse-caption-description__name {
    margin-top: 10px;
    font-size: 24px;
    line-height: 1.1;
  }

  .curse__tags {
    max-width: 200px;
    margin: 10px auto 0 auto;
    justify-content: center;
  }

  .curse-description__title {
    font-size: 14px;
    line-height: 1.4;
    margin: 15px 0 0 0;
  }

  .description-list__element {
    font-size: 12px;
    line-height: 1.4;
    margin-left: 0;
    padding-left: 0px;
  }

  .description-list__element::before {
    left: -5px;
    top: 6px;
  }

  .curse-start {
    margin: auto 0 0 0;
  }
  .curse-description-list {
    text-align: left;
  }

  .curse-start__about {
    max-width: 100%;
  }

  .curse-start__settiongs {
    top: 10px;
    bottom: unset;
    height: 20px;
    right: 10px;
  }

  .category-tag-wrapper {
    padding: 5px;
  }
}

/*Modificators*/
.separate-curse-progress .progress {
  display: flex;
}

.head-course .separate-curse {
  border-color: #8357a0;
}

.head-course .progress {
  border-color: #8357a0;
}

.head-course .curse-caption__img {
  background-size: cover;
  background-repeat: no-repeat;
}

.head-course .curse-start__about {
  background: #8357a0;
}

.head-course .progress-line {
  background: rgba(131, 87, 160, 0.20000000298023224);
}

.head-course .progress-line-filling {
  background: #8357a0;
}

.lungs-course .separate-curse {
  border-color: #f7ad3d;
}

.lungs-course .progress {
  border-color: #f7ad3d;
}

.lungs-course .curse-caption__img {
  background-size: cover;
  background-repeat: no-repeat;
}

.lungs-course .curse-start__about {
  background: #f7ad3d;
}

.lungs-course .progress-line {
  background: rgba(247, 173, 61, 0.20000000298023224);
}

.lungs-course .progress-line-filling {
  background: #f7ad3d;
}

.heart-course .separate-curse {
  border-color: #ff2031;
}

.heart-course .progress {
  border-color: #ff2031;
}

.heart-course .curse-caption__img {
  background-size: cover;
  background-repeat: no-repeat;
}

.heart-course .curse-start__about {
  background: #ff2031;
}

.heart-course .progress-line {
  background: rgba(255, 32, 49, 0.20000000298023224);
}

.heart-course .progress-line-filling {
  background: #ff2031;
}

.brain-course .separate-curse {
  border-color: #f7ad3d;
}

.brain-course .progress {
  border-color: #f7ad3d;
}

.brain-course .curse-caption__img {
  background-size: cover;
  background-repeat: no-repeat;
}

.brain-course .curse-start__about {
  background: #f7ad3d;
}

.brain-course .progress-line {
  background: rgba(247, 173, 61, 0.20000000298023224);
}

.brain-course .progress-line-filling {
  background: #f7ad3d;
}
</style>
