<template>
  <header class="header">
    <div class="header-iri">
        <p class="header-iri__text">При поддержке</p>
      <img src="@/assets/img/iri-black.svg" alt="" class="header-iri__img">
    </div>
    <div class="header-filling container flex al-cent">
      <img src="../../assets/img/logo-black.webp" alt="" @click="$router.push({ name: 'homePage' })"
           style="cursor: pointer" class="logo"/>
      <nav class="nav flex header-desctop">
        <a href="#aboutApp" class="nav__link almost-grey">О нас</a>
        <a href="#courses" class="nav__link almost-grey">Курсы</a>
        <a href="#appDownload" class="nav__link almost-grey">Скачайте приложение</a>
        <a href="#questions" class="nav__link almost-grey" v-if="false">Частые вопросы</a>
      </nav>
      <div class="header-authorization header-desctop al-cent">
        <router-link :to="{ name: 'registrationPage' }"
                     class="header-authorization__login almost-grey registration">Зарегистрироваться
        </router-link>
        <router-link :to="{ name: 'loginPage' }"
                     class="header-authorization__registration br-rad-10 bg-red white text-cent flex">Войти
        </router-link>
      </div>
      <div class="menu header-mobile">
        <button @click="viewMobile = !viewMobile" class="burger header-mobile"></button>
        <div class="menu-filling" v-if="viewMobile">
          <nav class="nav flex">
            <a href="#aboutApp" class="nav__link almost-grey">О нас</a>
            <a href="#courses" class="nav__link almost-grey">Курсы</a>
            <a href="#appDownload" class="nav__link almost-grey">Скачайте приложение</a>
            <a href="#questions" class="nav__link almost-grey" v-if="false">Частые вопросы</a>
          </nav>
          <div class="authorization">
            <router-link :to="{ name: 'loginPage' }" class="authorization__login almost-grey">Вход
            </router-link>
            <router-link :to="{ name: 'registrationPage' }" class=" authorization__login almost-grey registration">
              Зарегистрироваться
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>

  <main class="main">
    <section class="section performance">
      <div class="performance-filling container">
        <div class="performance-left">
          <div class="performance__caprion">
            <!-- <a href="#" class="performance-after">ПОСЛЕВЫПИСКИ.РФ.рф</a> -->
            <h1 class="performance__caprion-title">
              Ваше здоровье в одном <br/>
              приложении
            </h1>
          </div>
          <p class="performance__text">
            Онлайн-платформа для эффективного восстановления после COVID-19
          </p>
          <div class="performance-mobile">
            <video controls preload="none" poster="@/assets/img/video-prevue-2.webp" class="performance__video">
              <source :src="appConfig.domain + '/files/data/poslevipiski_title.mp4'" type="video/mp4"/>
            </video>
          </div>
          <div class="performance-btns mt-40 flex">
            <router-link :to="{ name: 'allCoursesPage' }"
                         class="performance-btns__element performance-btns-first bg-red white btn-big">Начать
              реабилитацию
            </router-link>
            <router-link v-if="false" :to="{ name: 'dashboardPage' }"
                         class="performance-btns__element performance-btns-second red bg-white br-red btn-big">Узнать
              подробнее
            </router-link>
          </div>
          <div class="performance-apps flex mt-40">
            <a target="_blank"
               href="https://apps.apple.com/ru/app/%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B2%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B8/id6450714477"
               class="apps__aplle">
              <img class="performance-apps__elment" src="../../assets/img/apple.webp" alt=""/></a>
            <a target="_blank" href="https://apps.rustore.ru/app/vrt.covid.project" class="apps__aplle margin-0-20"><img
                class="performance-apps__elment" src="../../assets/img/ru-store.svg" alt=""/></a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=vrt.covid.project"
               class="apps__google ml-20"><img class="performance-apps__elment" src="../../assets/img/google.svg"
                                               alt=""/></a>
          </div>
          <div class="performance-social desctop flex flex-wrap" v-if="false">
            <a href="#" class="performance-social__element" v-if="false"><img src="../../assets/img/fb.svg" alt=""/></a>
            <a href="https://vk.com/poslevypiski" class="performance-social__element"><img src="../../assets/img/vk.svg"
                                                                                           alt=""/></a>
            <a href="#" class="performance-social__element" v-if="false"><img src="../../assets/img/inst.svg"
                                                                              alt=""/></a>
            <a href="https://ok.ru/group/70000003636748" class="performance-social__element"><img
                src="../../assets/img/ok.svg" alt=""/></a>
            <a href="https://dzen.ru/poslevypiskiofficial" class="performance-social__element"><img
                src="../../assets/img/dsen.svg" alt=""/></a>
            <a href="#" class="performance-social__element" v-if="false"><img src="../../assets/img/youtube.svg"
                                                                              alt=""/></a>
          </div>
        </div>
        <div class="performance-right performance-desktop">
          <video controls preload="none" poster="@/assets/img/video-prevue-2.webp" class="performance__video">
            <source :src="appConfig.domain + '/files/data/poslevipiski_title.mp4'" type="video/mp4"/>
          </video>
        </div>
      </div>
    </section>

    <section class="section help container">
      <div class="help__filling">
        <div class="help__block"></div>
        <div class="help__info">
          <p class="help__title help__title--desktop">Проект помог<br> уже
            <span class="red">5 миллионам</span> россиян! </p>
          <p class="help__title help__title--mob">Проект помог уже
            <span class="red">5 миллионам</span> <br> россиян! </p>
          <p class="help__text">Спасибо за вашу поддержку!</p>
        </div>
        <img src="@/assets/img/home/hearts.png" alt="" class="help__hearts">
      </div>
    </section>

    <section class="section demonstration container" id="aboutApp">
      <div class="demonstration__caption flex">
        <img src="../../assets/img/big-logo.svg" alt="" class="small-logo"/>
        <img src="@/assets/img/home/united-rf.svg" alt="" class="united">
        <p class="demonstration__support">При поддержке комиссии <br> по здравоохранению</p>
      </div>
      <h2 class="section__title mt-30 after-discharge">
        Что такое <br/>
        ПОСЛЕВЫПИСКИ.РФ?
      </h2>
      <p class="section__text fs-16 mt-30">
        ПОСЛЕВЫПИСКИ.РФ - это проект в помощь людям, переболевшим новой
        коронавирусной инфекцией, а также тем, кто столкнулся со сходными
        осложнениями после перенесенных заболеваний.
        <br/>
        ПОСЛЕВЫПИСКИ.РФ поможет преодолеть многообразные проявления
        постковидного синдрома от когнитивных расстройств до нарушений дыхания.
        <br/>
        Программа физической реабилитации и психологической поддержки составлена
        командой доктора медицинских наук, Героя труда России Дениса Проценко.
        <br/>
        Это рекомендации лучших практикующих врачей, которые лично помогли
        тысячам пациентов, находясь на передовой борьбы с COVID-19.
        <br/>
        Ответив на вопросы стартовой анкеты, вы получите индивидуальную
        программу восстановления, состоящую из видеокурсов, практических
        занятий, проверенных советов и рекомендаций специалистов.
        <br/>
        ПОСЛЕВЫПИСКИ.РФ - ваше здоровье в одном приложении.
      </p>
      <div class="performance-apps mt-70 flex mt-40">
        <a target="_blank"
           href="https://apps.apple.com/ru/app/%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B2%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B8/id6450714477"
           class="apps__aplle ">
          <img class="performance-apps__elment" src="../../assets/img/apple.webp" alt=""/></a>
        <a href="https://apps.rustore.ru/app/vrt.covid.project" target="_blank" class="apps__aplle margin-0-20">
          <img class="performance-apps__elment" src="../../assets/img/ru-store.svg" alt=""/></a>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=vrt.covid.project"
           class="apps__google ml-20"><img class="performance-apps__elment" src="../../assets/img/google.svg"
                                           alt=""/></a>
      </div>
      <img src="@/assets/img/home/demonstration/desctop.webp" alt=""
           class="demonstration__img mt-60 br-rad-10 br-red desctop"/>
      <img src="../../assets/img/home/demonstration/mobile.webp" alt="" class="demonstration__img mt-60 mobile"/>
      <div class="demonstration-statistics flex flex-wrap just-space-betwin" v-if="false">
        <div class="demonstration-statistics-element mt-0 flex flex-dir-col">
          <p class="statistics__subtitle mt-10 grey">Свыше</p>
          <h5 class="statistics__title mt-10 red">9999</h5>
          <p class="statistics__text mt-10 mw300px">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit.
          </p>
        </div>
        <div class="demonstration-statistics-element flex flex-dir-col">
          <p class="statistics__subtitle mt-10 grey">Более</p>
          <h5 class="statistics__title mt-10 purple">9999</h5>
          <p class="statistics__text mt-10 mw300px">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit.
          </p>
        </div>
        <div class="demonstration-statistics-element flex flex-dir-col">
          <p class="statistics__subtitle mt-10 grey">Около</p>
          <h5 class="statistics__title mt-10 yellow">9999</h5>
          <p class="statistics__text mt-10 mw300px">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit.
          </p>
        </div>
      </div>
    </section>

    <section class="section peculiarities container" id="courses">
      <img src="../../assets/img/big-logo.svg" alt="" class="small-logo"/>
      <h2 class="section__title mt-30">Направления курсов</h2>
      <div class="peculiarities-group">
        <div class="peculiarities-group__element mr-10 br-rad-20 heart p-20 br-red">
          <div class="peculiarities-element-caption al-start flex">
            <img src="../../assets/img/course-icons/1_Монтажнаяобласть1.webp" alt=""
                 class="peculiarities-caption__img heart-img"/>
            <p class="peculiarities-caption__title">
              Курс «Решения для сердца и сосудов»
            </p>
          </div>
          <div class="peculiarities-filling-description mt-30">
            <h5 class="peculiarities-description__title">Что входит в курс:</h5>
            <ul class="peculiarities-description-list mt-10">
              <li class="description-list__element">
                Как вирусы могут угрожать нашей сердечно-сосудистой системы?
                Чего стоит бояться, а какие страхи преувеличены?
              </li>
              <li class="description-list__element">
                Вся правда о миокардите, тромбах, сердечной недостаточности,
                учащенном сердцебиении и других беспокоящих пациентов симптомах
                и состояниях.
              </li>
              <li class="description-list__element">
                Как влияют на работу сердца наши вредные и полезные привычки? С
                чем согласна медицина в вопросе правильного кардиологического
                питания?
              </li>
            </ul>
            <button @click="goToCourses('heart')" class="peculiarities-filling-link btn-midle br-red red">
              Попробовать
            </button>
          </div>
        </div>
        <div class="peculiarities-group__element ml-10 br-rad-20 brain p-20 br-red">
          <div class="peculiarities-element-caption flex al-start">
            <img src="../../assets/img/course-icons/1-10.webp" alt="" class="peculiarities-caption__img brain-img"/>
            <p class="peculiarities-caption__title">
              Курс «Здоровые желудок, кишечник, печень»
            </p>
          </div>
          <div class="peculiarities-filling-description mt-30">
            <h5 class="peculiarities-description__title">Что входит в курс:</h5>
            <ul class="peculiarities-description-list mt-10">
              <li class="description-list__element">
                Как лечение коронавирусной инфекции может отразиться на ЖКТ? Кто
                из пациентов и по каким причинам переносит заболевания тяжелее?
              </li>
              <li class="description-list__element">
                Антибиотики. В каких случаях их назначают при коронавирусной
                инфекции, каковы побочные эффекты от их применения и в чем
                кроются реальные угрозы самолечения антибиотиками и другими
                препаратами?
              </li>
              <li class="description-list__element">
                Как восстановить ЖКТ после коронавирусной инфекции? Какую роль в
                этом играет правильное рациональное питание, какие продукты
                ввести в рацион, а каким сразу сказать «нет».
              </li>
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
            </ul>
            <button @click="goToCourses('brain')" class="peculiarities-filling-link btn-midle br-red red">
              Попробовать
            </button>
          </div>
        </div>
        <div class="peculiarities-group__element mt-20 mr-10 br-rad-20 lungs p-20 br-red">
          <div class="peculiarities-element-caption flex al-start">
            <img src="../../assets/img/course-icons/1-04.webp" alt="" class="peculiarities-caption__img lungs-img"/>
            <p class="peculiarities-caption__title">
              Курс «Помогаем коже и волосам»
            </p>
          </div>
          <div class="peculiarities-filling-description mt-30">
            <h5 class="peculiarities-description__title">Что входит в курс:</h5>
            <ul class="peculiarities-description-list mt-10">
              <li class="description-list__element">
                Какие заболевания кожи чаще всего возникают на фоне COVID-19? И
                как они проявляют себя?
              </li>
              <li class="description-list__element">
                В чем коварство коронавирусной инфекции в отношении волос?
              </li>
              <li class="description-list__element">
                Какие витамины помогут справиться с дерматологическими
                проблемами, и что включить в рацион, чтобы восполнить нехватку
                необходимых микроэлементов?
              </li>
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
            </ul>
            <button @click="goToCourses('lungs')" class="peculiarities-filling-link btn-midle br-red red">
              Попробовать
            </button>
          </div>
        </div>
        <div class="peculiarities-group__element mt-20 ml-10 br-rad-20 head p-20 br-red">
          <div class="peculiarities-element-caption flex al-start">
            <img src="../../assets/img/course-icons/1-06.webp" alt="" class="peculiarities-caption__img head-img"/>
            <p class="peculiarities-caption__title">
              Курс «Решения для легких и дыхания»
            </p>
          </div>
          <div class="peculiarities-filling-description mt-30">
            <h5 class="peculiarities-description__title">Что входит в курс:</h5>
            <ul class="peculiarities-description-list mt-10">
              <li class="description-list__element">
                Как COVID-19 влияет на легкие?
              </li>
              <li class="description-list__element">
                Симптомы, течение и последствия болезней органов дыхания. На что
                важно обратить внимание?
              </li>
              <li class="description-list__element">
                Восстановление легких после вирусной инфекции. Какие
                рекомендации учитывать и в какой момент реабилитации можно
                начать практические занятия?
              </li>
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
              <!--                  <li class="description-list__element">Пункт чего-то интересного</li>-->
            </ul>
            <button @click="goToCourses('head')" class="peculiarities-filling-link btn-midle br-red red">
              Попробовать
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="section app" id="appDownload">
      <div class="app-filling container">
        <div class="app-left">
          <img src="../../assets/img/big-logo.svg" alt="" class="small-logo mobile"/>
          <h4 class="app__title">
            Скачайте <br/>
            приложение <br/>
            Послевыписки.рф
          </h4>
          <img src="@/assets/img/home/demonstration/mobile-2.webp" alt="Приложение" class="app__img mobile"/>
          <div class="app-links flex mt-70 flex mt-40">
            <a target="_blank"
               href="https://apps.apple.com/ru/app/%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B2%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B8/id6450714477"
               class="apps__aplle">
              <img class="performance-apps__elment" src="../../assets/img/apple.webp" alt=""/></a>
            <a href="https://apps.rustore.ru/app/vrt.covid.project" target="_blank" class="apps__aplle margin-0-20">
              <img class="performance-apps__elment" src="../../assets/img/ru-store.svg" alt=""/></a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=vrt.covid.project"
               class="apps__google "><img class="performance-apps__elment" src="../../assets/img/google.svg"
                                          alt=""/></a>
          </div>
          <div class="app-advantages">
            <div class="advantages">
              <div class="advantages-group">
                <div class="app-advantages-element advantages-left">
                  <img src="@/assets/img/home/touch.svg" alt="" class="app-advantages__icon"/>
                  <p class="app-advantages__title">
                    Бесплатная и быстрая регистрация
                  </p>
                  <p class="app-advantages__text">
                    Мы разработали для вас удобное приложение с простой формой
                    регистрации пользователей, понятным и симпатичным
                    интерфейсом.
                  </p>
                </div>
                <div class="app-advantages-element advantages-right">
                  <img src="@/assets/img/home/shield.svg" alt="" class="app-advantages__icon"/>
                  <p class="app-advantages__title">Безопасность</p>
                  <p class="app-advantages__text">
                    Ваши персональные данные защищены от посторонних глаз.
                  </p>
                </div>
              </div>
              <div class="advantages-group">
                <div class="app-advantages-element advantages-left">
                  <img src="@/assets/img/home/play.svg" alt="" class="app-advantages__icon"/>
                  <p class="app-advantages__title">Информативность</p>
                  <p class="app-advantages__text">
                    В приложении вы получаете доступ к 11 видеокурсам с
                    практиками от лучших специалистов, чек-листам и библиотеке
                    знаний.
                  </p>
                </div>
                <div class="app-advantages-element advantages-right">
                  <img src="@/assets/img/home/notification.svg" alt="" class="app-advantages__icon"/>
                  <p class="app-advantages__title">Уведомления</p>
                  <p class="app-advantages__text">
                    Благодаря оповещениям, вы точно не пропустите просмотр
                    лекций и практические занятия в рамках курсов.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="app-right desctop">
          <img src="@/assets/img/home/demonstration/mobile-2.webp" alt="Приложение" class="app__img"/>
        </div>
      </div>
    </section>

    <section class="section questions container" id="questions" v-if="false">
      <img src="../../assets/img/big-logo.svg" alt="" class="small-logo"/>
      <h2 class="section__title mt-30">частые вопросы</h2>
      <div class="questions-filling mt-40 flex">
        <div class="questions-preference flex-dir-col flex">
          <div class="questions-preference__element br-red">
            <p class="preference__element-text red">Сердце</p>
          </div>
          <div class="questions-preference__element questions-preference__element-second mt-20 br-yellow">
            <p class="preference__element-text yellow">Легкие</p>
          </div>
          <div class="questions-preference__element mt-20 questions-preference__element-desctop br-purple">
            <p class="preference__element-text purple">Голова</p>
          </div>
          <div class="questions-preference__element mt-20 questions-preference__element-desctop br-yellow">
            <p class="preference__element-text yellow">Мозг</p>
          </div>
        </div>
        <div class="questions-group ml-20 flex flex-dir-col">
          <div class="questions-element flex br-red br-rad-20">
            <div class="questions-element-filling">
              <h4 class="questions-element-filling__title">Вопрос</h4>
              <p class="questions-element-filling__text">
                Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim
                velit mollit. Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
            <button class="questions-element__btn questions-element__btn-active"></button>
          </div>
          <div class="questions-element mt-20 flex br-yellow br-rad-20">
            <div class="questions-element-filling">
              <h4 class="questions-element-filling__title">Вопрос</h4>
              <p class="questions-element-filling__text">
                Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim
                velit mollit. Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
            <button class="questions-element__btn"></button>
          </div>
          <div class="questions-element mt-20 flex br-purple br-rad-20">
            <div class="questions-element-filling">
              <h4 class="questions-element-filling__title">Вопрос</h4>
              <p class="questions-element-filling__text">
                Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim
                velit mollit. Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
            <button class="questions-element__btn"></button>
          </div>
          <div class="questions-element mt-20 flex br-red br-rad-20">
            <div class="questions-element-filling">
              <h4 class="questions-element-filling__title">Вопрос</h4>
              <p class="questions-element-filling__text">
                Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim
                velit mollit. Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
            <button class="questions-element__btn"></button>
          </div>
          <div class="questions-element mt-20 flex br-yellow br-rad-20">
            <div class="questions-element-filling">
              <h4 class="questions-element-filling__title">Вопрос</h4>
              <p class="questions-element-filling__text">
                Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim
                velit mollit. Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
            <button class="questions-element__btn"></button>
          </div>
          <div class="questions-element mt-20 flex br-purple br-rad-20">
            <div class="questions-element-filling">
              <h4 class="questions-element-filling__title">Вопрос</h4>
              <p class="questions-element-filling__text">
                Ответ на вопрос. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim
                velit mollit. Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
            <button class="questions-element__btn"></button>
          </div>
        </div>
      </div>
      <button class="questions__more btn-big m0-auto mt-40 br-red red">
        Задать вопрос
      </button>
    </section>
  </main>

  <footer class="footer">
    <div class="footer-bg">
      <div class="footer-content">
        <div class="footer-filling container">
          <div class="footer-caption">
            <div class="footer-caption-filling br-red br-rad-20 flex p-30 container">
              <div class="footer-caption-left">
                <h4 class="footer-caption-left__title">
                  Начните реабилитацию прямо сейчас
                </h4>
                <p class="footer-caption-left__text mt-20">
                  В приложении собрано более 80 видеоуроков под разные
                  осложнения, пройдите бесплатную регистрацию
                </p>
              </div>
              <router-link :to="{ name: 'allCoursesPage' }"
                           class="footer-caption-filling__btn btn-big white bg-red">Начать реабилитацию
              </router-link>
            </div>
          </div>
          <div class="footer-group flex">
            <div class="footer-group-left ">
              <div class="footer-group-top flex">
                <div class="footer-group-links">
                  <img src="../../assets/img/logo.webp" alt="" @click="$router.push({ name: 'homePage' })"
                       class="footer-logo"/>
                  <div class="footer-group-links-social mt-30 flex">
                    <a href="" class="footer-social__element" v-if="false">
                      <img src="../../assets/img/home/footer/fb.svg" alt="" class="footer-social__element-img"/>
                    </a>
                    <a href="https://vk.com/poslevypiski" class="footer-social__element">
                      <img src="../../assets/img/home/footer/vk.svg" alt="" class="footer-social__element-img"/>
                    </a>
                    <a href="" class="footer-social__element" v-if="false">
                      <img src="../../assets/img/home/footer/inst.svg" alt="" class="footer-social__element-img"/>
                    </a>
                    <a href="https://ok.ru/group/70000003636748" class="footer-social__element">
                      <img src="../../assets/img/home/footer/ok.svg" alt="" class="footer-social__element-img"/>
                    </a>
                    <a href="https://dzen.ru/poslevypiskiofficial" class="footer-social__element">
                      <img src="../../assets/img/home/footer/dsen.svg" alt="" class="footer-social__element-img"/>
                    </a>

                    <a href="" class="footer-social__element" v-if="false">
                      <img src="../../assets/img/home/footer/youtube.svg" alt="" class="footer-social__element-img"/>
                    </a>
                  </div>

                </div>
                <div class="footer-group-about flex flex-dir-col">
                  <a href="#aboutApp" class="footer-group-about__link">О нас</a>
                  <a href="#courses" class="footer-group-about__link">Курсы</a>
                  <a href="#appDownload" class="footer-group-about__link">Скачайте приложение</a>
                </div>
                <div class="footer-group-agreements flex flex-dir-col">
                  <router-link :to="{ name: 'terms' }" class="footer-group-agreements__link">Пользовательское соглашение
                  </router-link>
                  <router-link :to="{ name: 'privacy' }" class="footer-group-agreements__link">Политика
                    конфиденциальности
                  </router-link>
                </div>
              </div>
              <div class="footer-group-bottom footer-info">
                <p class="footer-info-text">
                  АНО «ЦЕНТР РЕАЛИЗАЦИИ ПРОГРАММ В СФЕРЕ ЗДРАВОХРАНЕНИЯ «ВРАЧИ И ПАЦИЕНТЫ ЗА КАЧЕСТВЕННУЮ И ДОСТУПНУЮ
                  МЕДЕЦИНСКУЮ ПОМОЩЬ» <br>
                  ИНН: 9721046167 / КПП: 772101001 <br>
                  ОГРН: 1177700006230 <br>
                  Юридический адрес: 109439, г. Москва, бульвар Есенинский, дом 9, корп. 3, кв.17 <br>
                  Почтовый адрес: 109439, г. Москва, бульвар Есенинский, дом 9, корп. 3, кв.17<br>
                </p>
                <p class="footer-info-text">
                  Реквизиты счета:
                  ПАО СБЕРБАНК
                  Р/с: 40703810838000008649
                  К/с: 30101810400000000225
                  БИК: 044525225
                </p>
              </div>
            </div>
            <div class="performance-apps footer-apps flex flex-dir-col mt-40">
              <a target="_blank"
                 href="https://apps.apple.com/ru/app/%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B2%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B8/id6450714477"
                 class="apps__aplle apps__aplle-footer">
                <img class="performance-apps__elment" src="../../assets/img/apple.webp" alt=""/></a>

              <a href="https://apps.rustore.ru/app/vrt.covid.project" target="_blank"
                 class="apps__aplle margin-0-20 apps__google-footer ">
                <img class="performance-apps__elment" src="../../assets/img/ru-store.svg" alt=""/></a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=vrt.covid.project"
                 class="apps__google apps__google-footer ml-20-20"><img class="performance-apps__elment"
                                                                        src="../../assets/img/google.svg" alt=""/></a>
            </div>
          </div>
          <p class="footer-group-links__scratch">
            ©Все права защищены. 2023. ПОСЛЕВЫПИСКИ.РФ
          </p>
        </div>
      </div>
    </div>
  </footer>
<!--loader--end вешай через 1.5s после загрузки страницы-->
  <div class="loader " :class="{'loader--end':startLoader}">
    <img src="@/assets/img/iri-white.svg" alt="ИРИ" class="loader__img">
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {appConfig} from "@/lib/appConfig";

export default {
  name: "HomePage",
  props: ["link"],
  data() {
    return {
      viewMobile: false,
      startLoader: false,
      appConfig,
    };
  },
  methods: {
    goToCourses(trend) {
      Cookies.set("trend", trend);
      this.$router.push({name: "allCoursesPage"});
    },
  },
  mounted() {
    if (this.$route.hash) {
      document
          .getElementById(this.$route.hash.replace("#", ""))
          .scrollIntoView();
    }

    setTimeout(() => {
      this.startLoader = true;
    }, 1500);
  },

};
</script>

<style scoped>
html {
  background: #fff;
}

body {
  background: #f8f8f8;
}

/* Loader */
.loader{
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background: #000000;
  transition: opacity 0.5s ease;
  top: 0;
  left: 0;
}
.loader--end{
  opacity: 0;
  pointer-events: none;
}
.loader__img{
  width: 548px;
  margin: auto;
}
.header-iri{
  display: flex;
  padding: 15px 16px;
  max-width: 100%;
  width: 100%;
  background: #F4F7FB;
justify-content: center;
    gap:15px;
    align-items: center;
}
.header-iri__text{
    font-family: "Muller", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #000000;
}
.header-iri__img{
  width: 269px;
}
/* Header */
.header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 1000;
}

.header-filling {
  padding: 20px 20px;
}

.logo {
  width: 260px;
  object-fit: contain;
  display: flex;
  flex-shrink: 0;
  position: relative;
}

.nav {
  margin-left: auto;
  font-size: 18px;
  position: relative;
}

.nav__link {
  margin-right: 40px;
}

.nav__link-right-0 {
  margin-right: 0px;
}

.header-desctop {
  display: flex;
}

.header-mobile {
  display: none;
}

.header-authorization {
  font-weight: 500;
  font-size: 14px;
  margin-left: 105px;
}

.header-authorization__login {
  margin-right: 20px;
}

.header-authorization__registration {
  width: 150px;
  height: 48px;
  align-items: center;
  justify-content: center;
}

.registration {
  display: flex;
  align-items: center;
  height: 48px;
}

@media (max-width: 1200px) {
  .logo {
    width: 240px;
  }

  .nav {
    font-size: 16px;
    margin-left: auto;
  }

  .nav :last-child {
    margin-right: 0;
  }

  .header-authorization {
    margin-left: 20px;
  }
}

@media (max-width: 950px) {
  .header {
    padding: 0;
  }

  .menu {
    margin-left: auto;
  }

  .header-filling {
    padding: 10px 10px 10px 10px;
  }

  .header-desctop {
    display: none;
  }

  .header-mobile {
    display: flex;
  }

  .burger {
    display: flex;
    background: url(../../assets/img/burger.svg);
    width: 36px;
    height: 36px;
    flex-shrink: 0;
  }

  .menu-filling {
    margin-top: 46px;
    transition: all 1s ease;
    position: fixed;
    width: 100%;
    height: 100vh;
    right: 0;
    top: 0;
    background: #fff;
    flex-direction: column;
  }

  .nav {
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding-top: 30px;
  }

  .authorization {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
  }

  .header-authorization__registration {
    margin: 20px auto 0 auto;
  }

  .nav__link {
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 18px;
  }

  .nav__link-right-0 {
    margin-bottom: 0px;
  }

  .header-authorization__login {
    margin-bottom: 20px;
  }

  .authorization__login {
    font-size: 18px;
    margin-top: 20px;
    height: auto;
    justify-content: center;
  }

  .registration {
    margin-top: 20px;
    height: auto;
  }
}

/* /Header */
body {
  margin-bottom: 0;
  position: relative;
  background: #ffffff !important;
}

main {
  position: relative;
  background: #fff;
}

main::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  width: 100%;
  height: 1060px;
  right: 0;
  top: 18px;
  display: block;
  background: url(../../assets/img/home-section-1-bg.webp);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  z-index: 10;
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  max-width: 100%;
  background-position: right;
  pointer-events: none;
}

.section {
  padding-top: 97px;
}

.performance {
  width: 100%;
  padding-top: 198px;
  padding-bottom: 0px;
  background-size: cover;
  position: relative;
}

.performance-filling {
  display: flex;
}

.performance-left {
  max-width: 534px;
  width: 100%;
}

.performance-right {
  max-width: 100%;
  width: 100%;
  margin-left: 32px;
  border-radius: 4px;
  margin-top: 10px;
}

.performance__video {
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  max-height: 356px;
  object-position: center;
  object-fit: cover;
  position: relative;
  z-index: 100;
}

.performance-social {
  margin: 60px 0 0 0;
}

.performance-mobile {
  display: none;
}

.performance-btns-second {
  margin-left: 20px;
}

.performance__caprion {
  position: relative;
}

.performance-social__element {
  margin-right: 10px;
}

.performance__caprion-title {
  font-family: "DelaGothicOne";
  font-weight: 400;
  font-size: 44px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #100c40;
}

.performance-after {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #ff2031;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 53px;
  border: 1px solid #ff2031;
  border-radius: 10px;
  position: absolute;
  left: 450px;
  top: 108px;
}

.performance__text {
  position: relative;
  z-index: 2;
  max-width: 478px;
  margin-top: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.performance-btns {
  margin-top: 24px;
}

.performance-btns__element {
  max-width: 250px;
  height: 60px;
}

.performance-apps {
  margin-top: 24px;
}

.apps__aplle {
  height: 50px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.08);
}

.margin-0-20 {
  margin: 0 20px;
}

.apps__google {
  height: 50px;
  box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.08);
}

.performance-apps__elment {
  height: 50px;
  object-fit: contain;
}

/* Help */
.help {
  padding-top: 60px;
  position: relative;
  z-index: 11;
}

.help__filling {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #F4F7FB;
  background-image: url("@/assets/img/home/help-bg.png");
  background-size: cover;

}
.help__title--mob{
  display: none;
}
.help__block {
  border-radius: 20px 0 0 20px;
  max-width: 404px;
  width: 100%;
}

.help__info {
  padding: 10px 0;
  margin-left: 61px;
}

.help__title {
  color: #000;
  font-family: "Muller", sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.68px;
}

.help__text {
  margin-top: 8px;
  color: #000;
  font-family: "Muller", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.help__hearts {
  margin-left: auto;
  margin-right: 81px;
}

/* Demonstration */
.small-logo {
  width: 54px;
}

.section__title {
  font-size: 40px;
  line-height: 1.2;
  text-transform: uppercase;
}

.peculiarities-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 40px;
}

.section__text {
  max-width: 100%;
}

.section__ul li {
  margin-left: 0;
  font-size: 16px;
  line-height: 1.8;
}

.section__ul li::before {
  width: 3px;
  height: 3px;
  top: 12px;
}

.demonstration {
  padding-top: 67px;
  padding-bottom: 221px;
}
.demonstration__caption{
  align-items: center;
}
.united{
  margin-left: 20px;
}
.demonstration__support{
  margin-left: 24px;
  color: #100C40;
  font-family: "Muller", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
}
.demonstration__img {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.demonstration-statistics {
  margin-top: 90px;
}

.statistics__subtitle {
  font-size: 16px;
  line-height: 1;
  color: rgba(16, 12, 64, 0.4000000059604645);
}

.statistics__title {
  font-weight: 500;
  font-size: 48px;
  line-height: 1;
}

.statistics__text {
  font-size: 16px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.699999988079071);
}

/* Peculiarities */
.peculiarities {
  padding-bottom: 85px;
}

.peculiarities-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.peculiarities-group__element {
  box-shadow: 0px 20px 60px rgba(50, 50, 50, 0.08);
  min-height: 347px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.peculiarities-element-caption {
  align-items: center;
}

.peculiarities-caption__img {
  display: flex;
  flex-shrink: 0;
  object-fit: contain;
  width: 100px;
  height: 100px;
}

.peculiarities-caption__title {
  margin-left: 10px;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.1;
  color: #100c40;
  max-width: 100%;
}

.peculiarities-filling-description {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.heart-tags {
  max-width: 149px;
}

.brain-tags {
  max-width: 153px;
}

.lungs-tags {
  max-width: 177px;
}

.head-tags {
  max-width: 148px;
}

.peculiarities-description__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: #100c40;
}

.description-list__element {
  font-size: 16px;
  line-height: 1.4;
  color: #100c40;
  margin-left: 10px;
  padding-left: 15px;
  position: relative;
}

.peculiarities-description-list {
  margin-bottom: 20px;
  text-align: left;
}

.description-list__element::before {
  position: absolute;
  left: 0;
  top: 9px;
  content: "";
  width: 2px;
  height: 2px;
  display: block;
  border-radius: 100%;
  background: #100c40;
}

.peculiarities-filling-link {
  margin-top: auto;
}

/* app */
.app {
  padding-bottom: 90px;
}

.app-filling {
  display: flex;
  align-items: flex-end;
}

.app-left {
  max-width: 700px;
  width: 100%;
  margin-right: 10px;
}

.app__title {
  font-weight: 400;
  font-size: 40px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #100c40;
  font-family: "DelaGothicOne";
}

.app-links {
  margin-top: 24px;
}

.advantages {
  margin-top: 24px;
}

.advantages :last-child {
  margin-bottom: 0;
}

.advantages-group {
  display: flex;
  margin-bottom: 10px;
}

.app-advantages-element {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  padding: 16px;
}

.advantages-left {
  max-width: 400px;
  width: 100%;
}

.app-advantages__title {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.6;
  color: #100c40;
}

.app-advantages__text {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.advantages-right {
  max-width: 289px;
  margin-left: 10px;
}

.app-right {
  margin-left: auto;
}

.app__img {
  max-width: 320px;
  width: 100%;
}

/* /app */

/* questions */
.questions {
  padding-bottom: 74px;
}

.questions-filling {
  max-width: 100%;
  width: 100%;
}

.questions-preference__element {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.04);
  font-family: "DelaGothicOne";
  text-transform: uppercase;
}

.preference__element-text {
  font-size: 24px;
  line-height: 1.2;
}

.questions-group {
  max-width: 100%;
  width: 100%;
}

.questions-preference {
  max-width: 280px;
  width: 100%;
}

.questions-element {
  padding: 30px 20px;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
}

.questions-element__btn {
  background: url(../../assets/img/home/questions/arrow-not-active.svg);
  width: 24px;
  height: 24px;
  background-size: contain;
  display: flex;
  flex-shrink: 0;
}

.questions-element__btn-active {
  background: url(../../assets/img/home/questions/arrow-active.svg);
}

.questions-element-filling__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  color: #100c40;
}

.questions-element-filling__text {
  max-width: 580px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.8;
  color: rgba(16, 12, 64, 0.6000000238418579);
}

.questions__more {
  margin-top: 30px;
}

/* footer */
.footer {
  overflow-x: hidden;
  overflow-y: visible;
  background: #fff;
}

.footer-content {
  margin: 10px;
}

.footer-logo {
  cursor: pointer;
  width: 310px;
  height: 36px;
  object-fit: contain;
}

.footer-social__element {
  margin-right: 10px;
}

.footer-bg {
  background: #110d40;
  margin: 20px;
  padding-top: 234px;
  border: 1px solid #110d40;
  border-radius: 20px;
  position: relative;
  margin-top: 160px;
  padding-bottom: 20px;
}

.footer-caption {
  position: absolute;
  top: -350px;
  width: 100%;
  left: 0;
}

.footer-caption-filling {
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.footer-caption-filling::before {
  position: absolute;
  content: "";
  left: -20px;
  top: -20px;
  width: 44px;
  height: 44px;
  box-shadow: 0px 10px 30px rgba(50, 50, 50, 0.17);
  background: url(../../assets/img/home/footer/heart-after.svg);
  border-radius: 100%;
  background-repeat: no-repeat;
  display: block;
}

.footer-filling {
  position: relative;
}

.footer-caption-left__title {
  font-family: "DelaGothicOne";
  max-width: 350px;
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #100c40;
}

.footer-caption-left__text {
  max-width: 410px;
  font-size: 16px;
  line-height: 1.6;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.footer-group-links__scratch {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.4000000059604645);
}

.footer-group {
  gap: 30px;
}

.footer-group-top {
  max-width: 100%;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.footer-info {
  margin-top: 20px;
  display: flex;
  max-width: 100%;
  width: 100%;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.footer-info-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4000000059604645);
  line-height: 140%;
  max-width: 500px;

}

.footer-group-about__link {
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 20px;
}

.footer-group-agreements {
  padding-top: 40px;
}

.footer-group-agreements__link {
  font-size: 16px;
  line-height: 1;
  text-decoration-line: underline;
  color: #ffffff;
  margin-bottom: 10px;
}

.footer-apps {
  margin-top: 0;
}

.apps__google-footer {
  margin-top: 20px;
  margin-left: 0;
}

.footer-mobile {
  display: none;
}

.mobile {
  display: none;
}
@media (max-width: 1100px) {
  .help__title{
    font-size: 28px;
    line-height: 100%;
  }
  .help__text{
    font-size: 18px;
  }
}
@media (max-width: 950px) {
  main::after {
    background: url(../../assets/img/home-section-1-bg-mob.svg);
    width: 675px;
    height: 545px;
    background-size: contain;
    top: 135px;
    left: -55px;
    background-repeat: no-repeat;
  }

  .loader__img{
    width: 268px;
  }
  .header-iri{
    padding: 18px 10px;
      gap: 10px;
  }
    .header-iri__text{
        font-size: 12px;
    }
  .header-iri__img{
    width: 210px;
  }

  .mobile {
    display: block;
  }

  .container {
    max-width: 520px;
  }

  .section {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  .main {
    text-align: center;
  }

  .btn-big {
    max-width: 400px;
    height: 56px;
    font-size: 14px;
  }

  .mt-30 {
    margin-top: 20px;
  }

  .mt-40 {
    margin-top: 20px;
  }

  /* performance */
  .performance {
    padding-top: 86px;
  }

  .performance-btns-second {
    margin-left: 0px;
    margin-top: 20px;
  }

  .performance__caprion {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .performance-after {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 180px;
    height: 34px;
    border-radius: 6px;
    font-size: 10px;
  }

  .performance__caprion-title {
    font-size: 26px;
    line-height: 1.2;
    margin-top: 20px;
  }

  .performance__text {
    font-size: 14px;
    line-height: 1.6;
    margin: 16px auto 0 auto;
    max-width: 286px;
  }

  .performance-btns {
    flex-direction: column;
    max-width: 400px;
    margin: 120px auto 0 auto;
  }

  .performance-mobile {
    margin-top: 24px;
    max-width: 100%;
    display: flex;
  }

  .performance-desktop {
    display: none;
  }

  .performance__video {
    max-height: 281px;
  }

  .performance-btns {
    margin-top: 20px;
  }

  .performance-apps {
    max-width: 500px;
    margin: 20px auto 0 auto;
  }

  .performance-apps {
    justify-content: center;
  }

  .performance-social {
    margin: 20px auto 0 auto;
    max-width: 500px;
    justify-content: space-between;
  }
  /* help */
  .help{
    padding-top: 0;
  }
  .help__block{
    display: none;
  }
  .help__title--desktop{
    display: none;
  }
  .help__title--mob{
    display: inherit;
  }
  .help__filling{
    min-height:221px;
    background-color: #F4F7FB;
    background-image: url("@/assets/img/home/help-mob.png");
    background-repeat: no-repeat;
    text-align: left;
    padding: 26px 16px;
    display: flex;
    position: relative;
  }
  .help__info{
      max-width: 178px;
  }
  .help__title{
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 120%;
    line-height: 100%;
  }
  .help__text{
    margin-top: 14px;
    font-size: 16px;
    line-height: 100%;
  }
  .help__hearts{
    width: 94.147px;
    position: absolute;
    top: 7px;
    right: 22px;
    margin-right: 0;
  }
  .help__info{
    margin-left: auto;
    margin-top: auto;
  }
  /* demonstration */
  .demonstration {
    padding-bottom: 74px;
  }

  .demonstration__caption{
    align-items: center;
  }
  .united{
    margin-left: 20px;
  }
  .demonstration__support{
    margin-left: 16px;
    font-size: 12px;
    line-height: 10px; /* 83.333% */
    letter-spacing: 0.24px;
    text-align: left;
  }
  .united{
    width: 107.998px;
    margin-left: 14px;
  }

  .section__title {
    font-size: 20px;
  }

  .after-discharge {
    font-size: 19px;
  }

  .section__ul li::before {
    display: none;
  }

  .section__ul li {
    margin-left: 0;
    padding-left: 0;
  }

  .section__text {
    font-size: 16px;
    max-width: 500px;
    margin: 20px auto 0 auto;
    text-align: left;
  }

  .demonstration__img {
    background: url(../../assets/img/home/demonstration/mobile.webp);
    background-size: contain;
    max-width: 268px;
    margin: 30px auto 0 auto;
    height: 566px;
    box-shadow: -19px 5px 41px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
  }

  .demonstration-statistics {
    margin-top: 40px;
    justify-content: center;
  }

  .demonstration-statistics-element {
    margin-top: 40px;
  }

  .statistics__subtitle {
    font-size: 14px;
  }

  .statistics__title {
    font-size: 36px;
  }

  .statistics__text {
    margin-top: 10px;
    font-size: 14px;
  }

  /* peculiarities */
  .peculiarities {
    padding-bottom: 57px;
  }

  .peculiarities-group {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 20px auto 0 auto;
  }

  .peculiarities-element-caption {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .peculiarities-caption__img {
    margin-right: 0;
  }

  .peculiarities-caption__tags {
    margin-left: 0;
    margin-top: 10px;
    max-width: 100%;
    justify-content: center;
  }

  .peculiarities-caption__tags-element {
    margin-top: 0;
    margin-right: 10px;
  }

  .mr-0-last {
    margin-right: 0;
  }

  .peculiarities-caption__title {
    margin-left: unset;
    max-width: 100%;
  }

  .peculiarities-element-filling {
    flex-direction: column;
    width: 100%;
  }

  .peculiarities-filling-description {
    width: 100%;
  }

  .peculiarities-description__title {
    font-size: 20px;
  }

  .description-list__element {
    margin-left: 0;
    padding-left: 0;
    font-size: 14px;
    line-height: 1.6;
    color: rgba(16, 12, 64, 0.699999988079071);
  }

  .description-list__element::before {
    display: none;
  }

  .peculiarities-filling-link {
    margin: 30px auto 0 auto;
  }

  /* app */
  .app {
    padding-bottom: 30px;
  }

  .app-left {
    max-width: 100%;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .app__title {
    margin-top: 20px;
    font-size: 20px;
  }

  .app-links {
    margin-top: 16px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .advantages {
    margin-top: 20px;
  }

  .advantages :last-child {
    margin-bottom: 0;
  }

  .advantages-group {
    flex-direction: column;
  }

  .app-advantages-element {
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 16px;
    align-items: flex-start;
    margin: 0 0 10px 0;
    max-width: 100%;
    text-align: left;
  }

  .app-advantages__title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.6;
    color: #100c40;
  }

  .app-advantages__text {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(16, 12, 64, 0.699999988079071);
  }

  .app__img {
    margin: 16px auto 0 auto;
    max-width: 100%;
    width: 100%;
  }

  /* /app */
  /* questions */
  .questions-preference__element-desctop {
    display: none;
  }

  .questions-preference__element-second {
    margin-left: 20px;
    margin-top: 0;
  }

  .questions-filling {
    flex-direction: column;
  }

  .questions-group {
    margin-top: 20px;
    margin-left: 0;
  }

  .questions-preference {
    flex-direction: row;
    max-width: 100%;
  }

  .questions-preference__element {
    height: 90px;
  }

  .preference__element-text {
    font-size: 14px;
  }

  .questions-element {
    text-align: left;
    max-width: 500px;
    margin: 20px auto 0 auto;
  }

  .questions-element-filling__text {
    margin-top: 20px;
  }

  /* footer */
  .footer-bg {
    margin: 175px 0 0 0;
    border-radius: 0;
  }

  .footer-filling {
    max-width: 500px;
    margin: 0 auto;
    padding: 0;
  }

  .footer-caption-filling {
    padding: 20px 10px;
  }

  .footer-caption-filling {
    flex-direction: column;
    text-align: center;
  }

  .footer-caption-left__title {
    font-size: 20px;
    line-height: 1.2;
  }

  .footer-caption-left__text {
    font-size: 14px;
    line-height: 1.6;
    max-width: 215px;
    margin: 10px auto 0 auto;
  }

  .footer-caption-filling__btn {
    margin-top: 70px;
  }

  .footer-caption-filling::before {
    width: 30px;
    height: 30px;
    left: -10px;
    top: -10px;
    background-size: contain;
  }

  .footer-caption::before {
    left: 7px;
    top: -27px;
    width: 38px;
    height: 38px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 20;
    rotate: 81deg;
  }

  .footer-caption::after {
    width: 58px;
    height: 52px;
    top: -10px;
    right: 0;
    rotate: -81deg;
    z-index: 10;
  }

  .footer-group {
    flex-direction: column;
    justify-content: center;
  }

  .footer-group-top {
    flex-direction: column;
  }

  .footer-info {
    flex-direction: column;
  }

  .footer-info-text {
    text-align: center;
  }

  .footer-mobile {
    display: flex;
  }

  .footer-group-links {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    width: 239px;
  }

  .footer-social__element-img {
    width: 32px;
  }

  .footer-group-about {
    text-align: center;
  }

  .footer-group-agreements {
    padding-top: 0;
    text-align: center;
  }

  .performance-apps {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer-group-links__scratch {
    margin-top: 30px;
    justify-content: center;
    text-align: center;
  }

  .footer-group-links-social {
    margin-top: 30px;
  }

  .footer-group-about {
    margin-top: 30px;
  }

  .footer-group-agreements {
    margin-top: 20px;
  }

  .peculiarities-group-right {
    margin-left: 0;
  }

  .peculiarities-group__element {
    margin: 0 0 20px 0;
  }

  .mt-0 {
    margin-top: 0;
  }

  .apps__google-footer {
    margin-top: 0px;
    margin-right: 0;
    margin-left: 20px;
  }
}

@media (max-width: 470px) {
  .apps__google {
    margin-top: 20px;
  }
}
@media (max-width: 370px) {
  .help__filling{
    padding: 10px 6px;
    background-position-x: -40px;
  }
}
@media (max-width: 340px) {
  .performance-apps__elment {
    height: 40px;
    width: 109px;
  }

  .apps__aplle {
    height: 40px;
  }

  .apps__google {
    height: 40px;
  }
}
</style>
