<template>
  <div class="separate-test ">
    <h5 class="test-name">Тестирование по <br> «{{ testing.title }}»</h5>
    <div class="test-options">
      <div class="options-time flex">
        <span class="options-time__img"></span>
        {{
          !testing.passingTimeSeconds || testing.passingTimeSeconds === 0 ? 'Неограничено' : convert(testing.passingTimeSeconds)
        }} минут
      </div>
      <div class="options-questions flex">
        <span class="options-questions__img"></span>
        {{ testing.questionsCount }} вопросов
      </div>
    </div>
    <button class="test-start__about  btn-midle" @click="goToTesting">Начать тестирование</button>
    <button class="test-start__settings"></button>
  </div>
</template>

<script>
import {convertTimeInMinuteAndSeconds} from "@/lib/convertTime";

export default {
  name: "SeparateRecommendedTest",
  props: ['testing'],
  data() {
    return {
      convert: convertTimeInMinuteAndSeconds
    }
  },
  methods: {
    goToTesting() {
      this.$router.push({name: 'currentTestingPage', params: {testingId: this.testing.id}});
    }
  }
}
</script>

<style scoped>
.separate-test {
  padding: 14px 10px;
  background: #ffffff;
  border: 1px solid #FF2031;
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
}

.test-start__about {
  margin-top: 15px;
  font-size: 12px;
  color: #ffff;
  background: #FF2031;
  max-width: 217px;
}

.test-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
}

.test-options {
  margin-top: 10px;
  gap: 50px;
  display: flex;
}

.options-time {
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.5);
}

.options-time__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/time-red.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.options-questions {
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: rgba(16, 12, 64, 0.5);
}

.options-questions__img {
  display: block;
  background: url(../../assets/img/dashbord/filling/questions.svg);
  width: 24px;
  height: 24px;
}

.test-start__settings {
  width: 20px;
  height: 20px;
  background: url(../../assets/img/course/settings.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (max-width: 950px) {
  .separate-test {
    padding: 14px 28px;
    max-width: 280px;
    width: 100%;
    flex-shrink: 0;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .options-time {
    gap: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    flex-direction: column;
  }

  .options-time__img {
    width: 20px;
    height: 20px;
  }

  .options-questions {
    gap: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    flex-direction: column;
  }

  .options-questions__img {
    background-size: contain;
    width: 20px;
    height: 20px;
  }

  .test-start__about {
    margin-top: 20px;
    max-width: 220px;
  }

  .test-start__settings {
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }

}
</style>