<template>
  <header class="header" :class="{ 'header-training': tutorial }" id="header">
    <div class="header-filling">
      <router-link :to="{ name: 'dashboardPage' }">
        <img src="../../assets/img/logo-black.webp" alt="" @click="$router.push({ name: 'dashboardPage' })"
          class="header-filling__logo" />
      </router-link>
      <button class="header-filling__burger" @click="viewMobile = !viewMobile"></button>
    </div>
    <nav class="menu none" :style="{ display: viewMobile ? 'flex' : null }">
      <div class="menu-logo" @click="$router.push({ name: 'dashboardPage' })" style="cursor: pointer"></div>
      <div class="menu-filling big-text">
        <router-link :to="{ name: 'dashboardPage' }" class="menu-element home" :class="{
                    'menu-element-selected': $route.path === '/main/dashboard',
                  }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Главная</p>
        </router-link>
        <!-- menu-element-train -->

        <router-link v-show="!tutorial" :to="{ name: 'myCoursesPage' }" class="menu-element courses" :class="{
                    'menu-element-selected': $route.path === '/main/courses',
                    'menu-element-train': tutorial && tutorialStage === 0,
                  }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Мои курсы</p>
        </router-link>
        <div class="hidden-block" id="myCourses" v-show="tutorial"></div>
        <div v-show="tutorial" class="menu-element courses" :class="{
                    'menu-element-selected': $route.path === '/main/courses',
                    'menu-element-train': tutorial && tutorialStage === 0,
                  }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Мои курсы</p>
          <div class="trind-card">
            <img src="@/assets/img/cards/arrow.svg" alt="" class="card-arrow" />
            <button class="card__close" @click="$emit('startTutorialStageTimer', true)">
              <img src="@/assets/img/cards/close.svg" alt="Закрыть" class="card__close-img" />
            </button>
            <div class="card-cation">
              <img src="@/assets/img/cards/heart.svg" alt="" class="card-cation__icon" />
              <p class="card-cation__text">Мои курсы</p>
            </div>
            <p class="card__text">
              Ваши индивидуальные курсы, здесь можно отследить прогресс
              реабилитации.
            </p>
          </div>
        </div>

        <router-link v-show="!tutorial" :to="{ name: 'allCoursesPage' }" class="menu-element all-courses" :class="{
                    'menu-element-selected': $route.path === '/main/all/courses',
                    'menu-element-train': tutorial && tutorialStage === 1,
                  }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Все курсы</p>
        </router-link>
        <div class="hidden-block" id="allCourses" v-show="tutorial"></div>
        <div v-show="tutorial" class="menu-element all-courses" :class="{
                    'menu-element-selected': $route.path === '/main/all/courses',
                    'menu-element-train': tutorial && tutorialStage === 1,
                  }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Все курсы</p>
          <div class="trind-card">
            <img src="@/assets/img/cards/arrow.svg" alt="" class="card-arrow" />
            <button class="card__close" @click="$emit('startTutorialStageTimer', true)">
              <img src="@/assets/img/cards/close.svg" alt="Закрыть" class="card__close-img" />
            </button>
            <div class="card-cation">
              <img src="@/assets/img/cards/heart.svg" alt="" class="card-cation__icon" />
              <p class="card-cation__text">Все курсы</p>
            </div>
            <p class="card__text">
              Подборка всех доступных для пациентов курсов.
            </p>
          </div>
        </div>

        <router-link v-if="false" :to="{ name: 'testingPage' }" class="menu-element tests">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Тестирования</p>
        </router-link>
        <a href="" class="menu-element recomendation" v-if="false">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Рекомендации</p>
        </a>

        <router-link v-show="!tutorial" :to="{ name: 'libraryPage' }" class="menu-element library"
          :class="{ 'menu-element-selected': $route.path === '/main/library' }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Библиотека знаний</p>
        </router-link>
        <div class="hidden-block" id="library" v-show="tutorial"></div>
        <div v-show="tutorial" class="menu-element library" :class="{
                    'menu-element-selected': $route.path === '/main/library',
                    'menu-element-train': tutorial && tutorialStage === 2,
                  }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Библиотека знаний</p>
          <div class="trind-card">
            <img src="@/assets/img/cards/arrow.svg" alt="" class="card-arrow" />
            <button class="card__close" @click="$emit('startTutorialStageTimer', true)">
              <img src="@/assets/img/cards/close.svg" alt="Закрыть" class="card__close-img" />
            </button>
            <div class="card-cation">
              <img src="@/assets/img/cards/heart.svg" alt="" class="card-cation__icon" />
              <p class="card-cation__text">Библиотека знаний</p>
            </div>
            <p class="card__italic-text">Это полезно знать!</p>
            <p class="card__text">
              Подборка материалов о постковидной реабилитации от авторов курсов.
            </p>
          </div>
        </div>

        <router-link :to="{ name: 'aboutPage' }" class="menu-element about"
          :class="{ 'menu-element-selected': $route.path === '/main/about' }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">О нас</p>
        </router-link>
        <a href="" class="menu-element favorites" v-if="false">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Избранное</p>
        </a>
        <router-link :to="{ name: 'helpPage' }" href="" class="menu-element help"
          :class="{ 'menu-element-selected': $route.path === '/main/help' }">
          <div class="menu-element__img"></div>
          <p class="menu-element__text">Вопрос-ответ</p>
        </router-link>
        <div class="training-bg" v-if="tutorial"></div>
      </div>
      <div class="menu__exit-wrapper">
        <button @click="logout" class="menu__exit flex al-cent">
          <img src="../../assets/img/menu/exit.svg" alt="" class="menu__exit-img" />
          <p class="menu__exit-text ml-10 red">Выйти из аккаунта</p>
        </button>
        <p class="menu__politics mt-20">
          ©Все права защищены. 2023. ПОСЛЕВЫПИСКИ.РФ
        </p>
        <p class="menu__politics mt-20">Cкачать приложение</p>
        <div class="menu-app desctop">
          <a target="_blank"
            href="https://apps.apple.com/ru/app/%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B2%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B8/id6450714477"
            class="app-link">
            <img src="@/assets/img/menu/apple.svg" alt="" class="app-link__img app-link__img-apple" />
          </a>
          <a href="https://apps.rustore.ru/app/vrt.covid.project" target="_blank" class="app-link">
            <img src="@/assets/img/ru-store.svg" alt="" class="app-link__img app-link__img-apple" />
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=vrt.covid.project" class="app-link">
            <img src="@/assets/img/menu/google.svg" alt="" class="app-link__img app-link__img-google" />
          </a>
        </div>
        <div class="menu-app menu-app-tablet">
          <a target="_blank"
            href="https://apps.apple.com/ru/app/%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B2%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B8/id6450714477"
            class="app-link">
            <img src="@/assets/img/menu/apple-tablet.svg" alt="" class="app-link__img app-link__img-apple" />
          </a>
          <a href="https://apps.rustore.ru/app/vrt.covid.project" target="_blank" class="app-link">
            <img src="@/assets/img/menu/ru-store-tablet.svg" alt="" class="app-link__img app-link__img-apple" />
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=vrt.covid.project" class="app-link">
            <img src="@/assets/img/menu/google-tablet.svg" alt="" class="app-link__img app-link__img-google" />
          </a>
        </div>
        <p class="menu__politics menu__share mt-20">
          Поделиться этим материалом в социальных сетях
        </p>
        <div class="social" v-if="false">
          <a href="#" class="social-link">
            <img src="@/assets/img/menu/tg.svg" alt="" class="social-link__img" />
          </a>
          <a href="https://vk.com/poslevypiski" class="social-link">
            <img src="@/assets/img/menu/vk.svg" alt="" class="social-link__img" />
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "PersonalHeader",
  data() {
    return {
      viewMobile: false,
    };
  },
  props: ["tutorial", "tutorialStage"],
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      await this.$router.push({ name: "homePage" });
    },
  },
  watch: {
    tutorialStage(val) {
      switch (val) {
        case 0:
          setTimeout(() => {
            document.getElementById("myCourses").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
          break;
        case 1:
          document.getElementById("allCourses").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          break;
        case 2:
          document.getElementById("library").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          break;
      }
    },
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 400vh;
}

.training-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.20999999344348907);
  backdrop-filter: blur(12px);
}

.header-filling__logo {
  width: 210px;
  display: flex;
  flex-shrink: 0;
}

/* menu */
.header {
  position: sticky;
  left: 0;
  top: 0;
  max-width: 300px;
  background: #fff;
  scrollbar-width: auto thin none 10px;
  padding: 30px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  border-right: 1px solid rgba(85, 85, 85, 0.3);
  box-shadow: 10px 4px 80px rgba(0, 0, 0, 0.07);
  flex-shrink: 0;
  scrollbar-width: thin;
  overflow-x: visible;
  overflow-y: auto;
}

.header-filling {
  display: none;
  border-bottom: 1px solid rgba(85, 85, 85, 0.2);
  padding-bottom: 20px;
}

.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu-logo {
  background: url(../../assets/img/logo-black.webp);
  background-size: contain;
  width: 250px;
  height: 23px;
  margin-left: 10px;
  display: flex;
  flex-shrink: 0;
  background-repeat: no-repeat;
}

.menu-filling {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.menu-element {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 224px;
  height: 50px;
  padding-left: 20px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #100c40;
  transition: all 0.2s ease;
  background: #fff;
}

.hidden-block {
  padding-top: 20px;
}

.menu-element .menu-element__img {
  width: 24px;
  height: 24px;
}

.menu-element__text {
  margin-left: 10px;
  color: inherit;
}

.menu-element:hover {
  opacity: 1;
  color: #fff;
  background: #ff2031;
}

.home .menu-element__img {
  background: url(../../assets/img/menu/home.svg);
}

.menu-element.home:hover .menu-element__img {
  background: url(../../assets/img/menu/home-active.svg);
}

.courses .menu-element__img {
  background: url(../../assets/img/menu/courses.svg);
}

.menu-element.courses:hover .menu-element__img {
  background: url(../../assets/img/menu/courses-active.svg);
}

.all-courses .menu-element__img {
  background: url(../../assets/img/menu/all-courses.svg);
}

.menu-element.all-courses:hover .menu-element__img {
  background: url(../../assets/img/menu/all-courses-active.svg);
}

.tests .menu-element__img {
  background: url(../../assets/img/menu/tests.svg);
}

.menu-element.tests:hover .menu-element__img {
  background: url(../../assets/img/menu/tests-active.svg);
}

.recomendation .menu-element__img {
  background: url(../../assets/img/menu/recomendation.svg);
}

.menu-element.recomendation:hover .menu-element__img {
  background: url(../../assets/img/menu/recomendation-active.svg);
}

.library .menu-element__img {
  background: url(../../assets/img/menu/library.svg);
}

.menu-element.library:hover .menu-element__img {
  background: url(../../assets/img/menu/library-active.svg);
}

.about .menu-element__img {
  background: url(../../assets/img/menu/about.svg);
}

.menu-element.about:hover .menu-element__img {
  background: url(../../assets/img/menu/about-active.svg);
}

.favorites .menu-element__img {
  background: url(../../assets/img/menu/favorites.svg);
}

.menu-element.favorites:hover .menu-element__img {
  background: url(../../assets/img/menu/favorites-active.svg);
}

.help .menu-element__img {
  background: url(../../assets/img/menu/help.svg);
}

.menu-element.help:hover .menu-element__img {
  background: url(../../assets/img/menu/help-active.svg);
}

/* train */

.menu-element-train.menu-element.home:hover .menu-element__img {
  background: url(../../assets/img/menu/home.svg);
}

.menu-element-train.menu-element.courses:hover .menu-element__img {
  background: url(../../assets/img/menu/courses.svg);
}

.menu-element-train.menu-element.all-courses:hover .menu-element__img {
  background: url(../../assets/img/menu/all-courses.svg);
}

.menu-element-train.menu-element.tests:hover .menu-element__img {
  background: url(../../assets/img/menu/tests.svg);
}

.menu-element-train.menu-element.recomendation:hover .menu-element__img {
  background: url(../../assets/img/menu/recomendation.svg);
}

.menu-element-train.menu-element.library:hover .menu-element__img {
  background: url(../../assets/img/menu/library.svg);
}

.menu-element-train.menu-element.about:hover .menu-element__img {
  background: url(../../assets/img/menu/about.svg);
}

.menu-element-train.menu-element.favorites:hover .menu-element__img {
  background: url(../../assets/img/menu/favorites.svg);
}

.menu-element-train.menu-element.help:hover .menu-element__img {
  background: url(../../assets/img/menu/help.svg);
}

.menu-element-train.menu-element:hover {
  background: #fff;
  color: #100c40;
}

.menu-element .trind-card {
  display: none;
}

.menu-element-train .trind-card {
  display: block;
}

.menu-element-train {
  position: relative !important;
  z-index: 100;
  pointer-events: none;
}

.trind-card {
  position: absolute;
  right: -285px;
  background: #ffffff;
  border-radius: 10px;
  padding: 12px 35px 20px 12px;
  width: 265px;
  top: 0px;
  pointer-events: all;
  cursor: auto;
}

.card-arrow {
  position: absolute;
  left: -8px;
  top: 7px;
}

.card__close {
  position: absolute;
  right: 0px;
  top: 0px;
}

.card-cation {
  display: flex;
  align-items: center;
}

.card-cation__text {
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #100c40;
}

.card__text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: rgba(16, 12, 64, 0.699999988079071);
}

.card__italic-text {
  margin-top: 10px;
  text-decoration-line: underline;
  font-style: italic;
  font-weight: 700;
}

/* /train */
.menu__exit-wrapper {
  margin-top: auto;
}

.menu__exit {
  margin-left: 20px;
}

.menu__exit-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
}

.menu__politics {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.6;
  margin-left: 20px;
  max-width: 181px;
}

.menu__exit-wrapper {
  padding-bottom: 20px;
}

.menu-app {
  margin: 15px 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu-app-tablet {
  display: none;
}

.menu__share {
  line-height: 1.3;
}

.app-link {
  width: 181px;
  height: 66px;
  display: block;
}

.app-link__img {
  width: 100%;
  height: 100%;
}

.social {
  margin: 15px 0 0 20px;
  display: flex;
  gap: 10px;
}

.social-link {
  display: block;
  width: 31px;
  height: 31px;
}

.social-link__img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1300px) {
  .header {
    max-width: 84px;
    padding: 24px 20px 0px 20px;
    flex-shrink: 0;
  }

  .menu-logo {
    background: url(../../assets/img/big-logo.svg);
    width: 44px;
    height: 24px;
    background-size: cover;
    margin-left: 0;
  }

  .menu-filling {
    margin-top: 45px;
  }

  .menu-element {
    margin-top: 23px;
    width: 44px;
    height: 44px;
    padding-left: 10px;
  }

  .menu-element__text {
    display: none;
  }

  .menu__exit {
    margin: auto 0 0 11px;
  }

  .menu__exit-text {
    display: none;
  }

  .menu__politics {
    display: none;
  }

  .menu-app {
    display: none;
    margin-top: 25px;
  }

  .menu-app-tablet {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .app-link {
    width: 44px;
    height: 44px;
    display: block;
  }

  .social {
    margin: 15px 0 0 0px;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 950px) {
  .header {
    max-width: 100%;
    height: unset;
    background: #f8f8f8;
    box-shadow: none;
    z-index: 100;
    padding: 20px 10px 0 10px;
    border-right: none;
  }

  .header-filling__logo {
    width: 240px;
  }

  .header-filling {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-filling__burger {
    background: url(../../assets/img/burger.svg);
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .menu {
    display: none;
    height: calc(100vh - 77px);
    flex-direction: column;
    overflow-y: scroll;
  }

  .menu-element__img {
    display: none;
  }

  .menu-logo {
    display: none;
  }

  .menu-filling {
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  }

  .menu-element__text {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  .menu-element {
    max-width: 300px;
    width: 100%;
  }

  .menu-element__text {
    display: inline;
  }

  .menu__exit-text {
    display: inline;
  }

  .menu__exit-wrapper {
    padding-bottom: 20px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .menu__exit {
    max-width: 300px;
    margin: 0;
    width: 100%;
    justify-content: center;
  }

  .menu__politics {
    display: inline;
    max-width: 300px;
    margin-left: 0;
    text-align: center;
  }

  .trind-card {
    right: 0;
    width: 100%;
    top: 63px;
    z-index: 101;
  }

  .card-arrow {
    left: calc(50% - 6px);
    top: -8px;
    transform: rotate(90deg);
  }

  .menu-app {
    display: flex;
    margin-left: 0;
    margin-top: 15px;
  }

  .app-link {
    max-width: 130px;
    width: 100%;
    height: 50px;
  }

  .menu-app-tablet {
    display: none;
  }

  .social {
    flex-direction: row;
    margin-left: 0;
  }

  .menu {
    padding-bottom: 30px;
  }
}

/* Modificators */
.menu-element-selected {
  background: #ff2031;
  color: #fff;
  cursor: unset;
}

.menu-element-selected.home .menu-element__img {
  background: url(../../assets/img/menu/home-active.svg);
}

.menu-element-selected.courses .menu-element__img {
  background: url(../../assets/img/menu/courses-active.svg);
}

.menu-element-selected.all-courses .menu-element__img {
  background: url(../../assets/img/menu/all-courses-active.svg);
}

.menu-element-selected.tests .menu-element__img {
  background: url(../../assets/img/menu/tests-active.svg);
}

.menu-element-selected.recomendation .menu-element__img {
  background: url(../../assets/img/menu/recomendation-active.svg);
}

.menu-element-selected.library .menu-element__img {
  background: url(../../assets/img/menu/library-active.svg);
}

.menu-element-selected.about .menu-element__img {
  background: url(../../assets/img/menu/about-active.svg);
}

.menu-element-selected.favorites .menu-element__img {
  background: url(../../assets/img/menu/favorites-active.svg);
}

.menu-element-selected.help .menu-element__img {
  background: url(../../assets/img/menu/help-active.svg);
}

/* header */
.header-open {
  height: 100vh;
}

.header-training {
  overflow: visible;
}
</style>
