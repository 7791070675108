<template>
  <div class="body">
    <PersonalHeader> </PersonalHeader>
    <main class="main">
      <div class="caption flex al-cent">
        <div class="caption-greetings">
          <h1 class="caption-greetings__title">Библиотека знаний</h1>
          <p class="caption-greetings__text">
            В данном разделе собран полезный материал от лучших специалистов по
            постковидной реабилитации.
          </p>
        </div>
        <PersoneInCaption> </PersoneInCaption>
      </div>
      <SearchElement
        type="article"
        :view-results="viewResults"
        :findResults="findResults"
        @closeViewResults="viewResults = false"
        @viewResults="viewResults = true"
      >
      </SearchElement>
      <div class="description mt-40 desctop">
        <div class="description-nav">
          <button
            class="description-nav-selected"
            @click="fetchArticles('all')"
          >
            Все
          </button>
          <div class="description-nav-filling">
            <button
              class="description-nav__element"
              :class="{ 'description-nav__element-chozen': trend === 'all' }"
              @click="changeTrend('all')"
            >
              Все
            </button>
            <button
              class="description-nav__element"
              :class="{ 'description-nav__element-chozen': trend === 'heart' }"
              @click="changeTrend('heart')"
            >
              Сердце
            </button>
            <button
              class="description-nav__element"
              :class="{ 'description-nav__element-chozen': trend === 'brain' }"
              @click="changeTrend('brain')"
            >
              Мозг
            </button>
            <button
              class="description-nav__element"
              :class="{ 'description-nav__element-chozen': trend === 'lungs' }"
              @click="changeTrend('lungs')"
            >
              Легкие
            </button>
            <button
              class="description-nav__element"
              :class="{ 'description-nav__element-chozen': trend === 'head' }"
              @click="changeTrend('head')"
            >
              Голова
            </button>
          </div>
        </div>
      </div>
      <div class="tags flex mt-60" v-if="false">
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
        <button class="tag">Сердце</button>
      </div>
      <div class="filling flex">
        <div class="content flex flex-dir-col">
          <div class="content-element block carousel" v-if="false">
            <h4 class="carousel-title">Дышите легко</h4>
            <p class="carousel-text mt-20">
              Пройдите индивидуальный курс реабилитации после COVID-19 в
              приложении
            </p>
            <button class="carousel-button btn-big white bg-red">
              Начать реабилитацию
            </button>
            <div class="carousel-nav">
              <button
                class="carousel-nav__element carousel-nav-chosen"
              ></button>
              <button class="carousel-nav__element"></button>
              <button class="carousel-nav__element"></button>
              <button class="carousel-nav__element"></button>
              <button class="carousel-nav__element"></button>
            </div>
            <div class="carousel-img"></div>
          </div>
          <div class="content-element">
            <ContentWrapper
              v-if="viewResults && findResults && findResults.length"
              :findResults="findResults"
            >
            </ContentWrapper>
            <LibraryWrapper v-else :articles="articles"> </LibraryWrapper>
          </div>
          <button
            class="content-more btn-midle br-red m0-auto mt-30 red"
            v-if="false"
            @click="loadArticles"
          >
            Еще
          </button>
        </div>
        <div class="additionally flex flex-dir-col">
          <div class="additionally-element my-course">
            <h5 class="my-course__title small-title">Мои курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="myCourses">
                <SeparateRecommendedCourse
                  v-for="item in myCourses"
                  :key="item"
                  :course="item"
                >
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/last-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button class="slider-next-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/next-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button
                class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
                @click="goToCourses"
              >
                Все мои курсы
              </button>
            </div>
          </div>
          <div class="additionally-element my-course" v-if="false">
            <h5 class="my-course__title small-title">Рекомендуемые курсы:</h5>
            <div class="my-course-group p-20-10 block flex flex-dir-col mt-20">
              <div class="my-course-group-wrapper" v-if="recommendedCourse">
                <SeparateRecommendedCourse
                  v-for="item in recommendedCourse"
                  :key="item"
                  :course="item"
                >
                </SeparateRecommendedCourse>
              </div>
              <button class="slider-last-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/last-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button class="slider-next-block tablet">
                <img
                  src="../../assets/img/dashbord/filling/next-element.svg"
                  alt=""
                  class="slider-block__img"
                />
              </button>
              <button
                class="my-course__more btn-midle red br-red desctop mt-10 m0-auto"
                @click="$router.push({ name: 'allCoursesPage' })"
              >
                Все рекомендации
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <PersonalProfile> </PersonalProfile>
  </div>
</template>

<script>
import PersonalHeader from "../../components/personal_area/PersonalHeader.vue";
import PersonalProfile from "../../components/personal_area/PersonalProfile.vue";
import PersoneInCaption from "../../components/personal_area/PersoneInCaption.vue";
import SeparateRecommendedCourse from "../../components/courses/SeparateRecommendedCourse.vue";
import SearchElement from "../../components/SearchElement.vue";
import LibraryWrapper from "../../components/library/LibraryWrapper.vue";
import ContentWrapper from "../../components/content/ContentWrapper.vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "articles",
      "currentTrend",
      "recommendedCourse",
      "myCourses",
      "findResults",
    ]),
  },
  components: {
    PersonalHeader,
    PersonalProfile,
    SeparateRecommendedCourse,
    PersoneInCaption,
    LibraryWrapper,
    ContentWrapper,
    SearchElement,
  },
  name: "LibraryPage",
  data() {
    return {
      page: 1,
      limit: 10,
      pagination: true,
      trend: "all",
      name: null,

      viewResults: false,
    };
  },
  beforeMount() {
    scroll(0, 0);
    this.fetchArticles();
    this.$store.dispatch("fetchMyCourses", { page: 1, limit: 5, trend: "all" });
    this.$store.dispatch("fetchRecommendedCourses");
  },
  methods: {
    fetchArticles() {
      this.$store
        .dispatch("fetchArticles", {
          trend: this.trend,
          page: this.page,
          limit: this.limit,
          name: this.name,
        })
        .then((res) => {
          if (!res || res.length < this.limit) {
            this.pagination = false;
          }
        });
    },
    changeTrend(trend = "all") {
      this.trend = trend;
      this.reloadPagination();
      this.fetchArticles();
    },
    changeName(name = "") {
      this.name = name;
      this.reloadPagination();
      this.fetchArticles();
    },
    loadArticles() {
      this.page++;
      this.fetchArticles();
    },
    reloadPagination() {
      this.page = 1;
      this.pagination = true;
    },
    goToCourses() {
      this.$router.push({ name: "myCoursesPage" });
    },
  },
};
</script>

<style scoped>
.body {
  display: flex;
}

.main {
  margin: 0 20px 56px 40px;
  width: 100%;
}

/* Caption */

.caption {
  padding: 40px 5% 0px 0px;
}

.content-more {
  max-width: 119px;
}

.caption-greetings__title {
  font-family: "DelaGothicOne";
  font-weight: 500;
  font-size: 32px;
}

.caption-greetings__text {
  max-width: 520px;
  color: rgba(16, 12, 64, 0.8000000238418579);
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6;
}

/* description */
.description-nav {
  position: relative;
}

.description-nav-selected {
  display: none;
  position: relative;
}

.description-nav-selected::after {
  background: url(../../assets/img/list.svg);
  content: "";
  right: 20px;
  top: calc(50% - 6px);
  width: 16px;
  height: 16px;
  display: block;
}

.description-nav-filling {
  border: 1px solid rgba(16, 12, 64, 0.4);
  display: inline-flex;
  border-radius: 10px;
}

.description-nav__element {
  padding: 20px 30px;
  color: rgba(16, 12, 64, 0.8000000059604645);
  font-size: 14px;
  border-radius: 10px;
}

.description-nav__element-chozen {
  background: #ff2031;
  color: #fff;
}

/* filling */
.filling {
  max-width: 100%;
  width: 100%;
}

.content {
  max-width: 100%;
  width: 100%;
  margin-top: 40px;
  margin-right: 40px;
}

.content-element {
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
}

.additionally {
  max-width: 400px;
  width: 100%;
}

/* carousel */
.carousel {
  padding: 30px 30px 40px 30px;
  position: relative;
}

.carousel-title {
  font-family: "DelaGothicOne";
  font-size: 64px;
  line-height: 1.2;
  text-transform: uppercase;
  position: relative;
  z-index: 3;
}

.carousel-text {
  max-width: 358px;
  line-height: 1.6;
  color: rgba(33, 33, 33, 0.699999988079071);
  position: relative;
  z-index: 3;
}

.carousel-button {
  margin-top: 55px;
  position: relative;
  z-index: 3;
}

.carousel-nav {
  position: absolute;
  left: calc(40% - 70px);
  display: flex;
  bottom: 14px;
  z-index: 3;
}

.carousel-nav :not(:last-child) {
  margin-right: 20px;
}

.carousel-nav__element {
  width: 5px;
  height: 5px;
  background: rgba(85, 85, 85, 0.4000000059604645);
  border-radius: 100%;
  z-index: 3;
}

.carousel-nav-chosen {
  width: 40px;
  height: 5px;
  background: #ff1d2e;
  border-radius: 100px;
}

.carousel-img {
  position: absolute;
  right: 12px;
  bottom: 0px;
  background: url(../../assets/img/dashbord/filling/man.svg);
  width: 405px;
  height: 382px;
  z-index: 2;
}

/*category*/

.category-element {
  padding: 10px 10px 20px 10px;
  margin-top: 40px;
}

.category-element__caption {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.category-element__caption-tags {
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 455px;
}

.category-element__caption-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.category-element-first {
  margin-top: 20px;
}

/*my cours*/
.my-course__more {
  max-width: 179px;
  font-size: 12px;
}

.my-course-group-wrapper {
  display: flex;
  flex-direction: column;
}

.additionally-element {
  margin-bottom: 40px;
}

@media (max-width: 1880px) {
  .carousel-title {
    max-width: 398px;
  }

  .additionally {
    max-width: 326px;
  }
}

@media (max-width: 1300px) {
  .main {
    margin-left: 20px;
  }

  /* Caption */
  .caption {
    padding: 40px 3% 30px 0px;
    justify-content: space-between;
  }

  .caption-greetings__title {
    font-size: 24px;
  }

  .caption-greetings__text {
    font-size: 14px;
    margin-top: 8px;
  }

  .surch {
    margin-left: 0;
  }

  .surch__input {
    font-size: 14px;
    max-width: 265px;
    width: 100%;
    display: flex;
    flex-shrink: 1;
  }

  /* carousel */
  .carousel {
    padding: 20px 20px 50px 20px;
    position: relative;
  }

  .carousel-title {
    font-size: 48px;
  }

  .carousel-text {
    font-size: 14px;
    max-width: 213px;
    line-height: 1.4;
  }

  .carousel-button {
    margin-top: 14px;
    max-width: 223px;
    height: 44px;
    font-size: 12px;
  }

  .carousel-nav {
    left: calc(50% - 70px);
  }

  .carousel-img {
    right: 0px;
    bottom: 0px;
    background: url(../../assets/img/dashbord/filling/man.svg);
    background-size: cover;
    width: 274px;
    height: 260px;
  }

  .slider-last-block {
    display: none;
  }

  .slider-next-block {
    display: none;
  }

  .content {
    margin-right: 20px;
  }
}

@media (max-width: 950px) {
  .body {
    flex-direction: column;
  }

  .caption {
    display: none;
  }

  .profile {
    display: none;
  }

  .main {
    margin-left: 0;
    margin-right: 0;
    padding: 0 20px;
  }

  .tags {
    overflow-x: scroll;
  }

  .filling {
    flex-direction: column;
  }

  /* additionall */
  .additionally-element {
    text-align: center;
  }

  .additionally {
    max-width: 100%;
  }

  .my-course-group {
    flex-direction: row;
    overflow-x: visible;
    padding: 10px;
    position: relative;
  }

  .slider-last-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .my-course-group-wrapper {
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
  }

  .slider-next-block {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -10px;
    bottom: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    z-index: 2;
  }

  .slider-next-block img {
    width: 24px;
    height: 24px;
  }

  .slider-last-block img {
    width: 24px;
    height: 24px;
  }

  .content {
    margin-right: 0;
  }

  .carousel-nav__element {
    background: #fff;
  }

  .carousel-nav-chosen {
    background: #ff2031;
  }

  .content {
    margin-top: 40px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 10px;
  }

  .carousel {
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 20px 20px 45px 20px;
  }

  .carousel * {
    margin: 0 auto;
  }

  .carousel-title {
    max-width: 267px;
    font-size: 32px;
  }

  .carousel-text {
    margin-top: 10px;
    max-width: 267px;
    font-size: 12px;
  }

  .carousel-button {
    margin-top: 199px;
    max-width: 223px;
  }

  .carousel-img {
    width: 298px;
    height: 282px;
    right: calc(50% - 149px);
  }

  /*category*/
  .category-element {
    padding: 0;
    margin-top: 20px;
  }

  .category-element__caption {
    margin-bottom: 0;
  }

  /*popular-course*/
  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }

  .popular-course-filling {
    padding: 20px 0;
  }

  /* description */
  .description {
    margin-top: 20px;
  }

  .description-nav {
    position: relative;
  }

  .description-nav-selected {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    background: #ffffff;
    border: 1px solid rgba(16, 12, 64, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 40px 140px rgba(0, 0, 0, 0.06);
  }

  .description-nav-selected__text {
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    color: rgba(16, 12, 64, 0.699999988079071);
  }

  .description-nav-filling {
    display: none;
    background: #fff;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    position: absolute;
    z-index: 2;
  }

  .description-nav__element {
    width: 100%;
    text-align: left;
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    color: rgba(16, 12, 64, 0.699999988079071);
  }

  .description-nav__element-chozen {
    background: #fff;
    color: rgba(16, 12, 64, 0.699999988079071);
  }

  .description-nav__element:not(:last-child) {
    border-bottom: 1px solid rgba(16, 12, 64, 0.4);
    border-radius: 0 0 0 0;
  }

  .description-nav__element:first-child {
    border-radius: 10px 10px 0 0;
  }
}
</style>
