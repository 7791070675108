import {defaultHttpResHandler} from "@/store/helpers/defaultHttpResHandler";
import {makeGetReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";

export const fetchAllCourses = async (token, page, limit, trend, name) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/courses/trend?page=' + page + '&limit=' + limit + '&trend=' + trend + '&name=' + (name ? name : ''), {token}));
export const fetchAllLessons = async (token, page, limit, name) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/lessons?page=' + page + '&limit=' + limit + '&name=' + (name ? name : ''), {token}));
export const fetchCourseCategory = async (token) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/courses/tags', {token}));
export const fetchCurrentCourse = async (token, id) => await defaultHttpResHandler(async () => makeGetReq(appConfig.domain + '/course/' + id + '?page=0&limit=0', {token}));
export const fetchMyCourses = async (token, page, limit, trend, name) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/courses/user?page=' + page + '&limit=' + limit + '&trend=' + trend + '&name=' + (name ? name : ''), {token}));
export const fetchRecommendedCourses = async (token) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/courses/recommended', {token}));
export const findArticlesAndCourses = async (token, word, page, limit) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/search/' + word + '?page=' + page + '&limit=' + limit, {token}));
