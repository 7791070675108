import {fetchLesson, savePassedLesson} from "@/store/lesson/serverInterationMethods";
import {errorHandler} from "@/lib/errorHandler";

export const lesson = {
    actions: {
        async fetchLesson({getters, commit}, payload) {
            const {data} = await fetchLesson(getters.token, payload.courseId, payload.lessonId);
            commit('setLesson', data);
        },
        async savePassedLesson({getters, commit}, lesson) {
            const {status, data} = await savePassedLesson(getters.token, lesson);
            commit('setUser', data);
            errorHandler({name: 'lesson', status})
        }
    },
    mutations: {
        setLesson(state, data) {
            state.lesson = data
        }
    },
    state: {
        lesson: null
    },
    getters: {
        lesson: state => state.lesson
    }
}