<template>
  <header class="header container flex al-cent just-space-betwin">
    <img src="../../assets/img/logo-black.webp" alt="" style="cursor: pointer" @click="$router.push({ name: 'homePage' })"
      class="logo" />
    <nav class="nav flex gap30 header-desctop">
      <router-link :to="{ name: 'homePage' }" class="nav__link">Главная</router-link>
      <router-link :to="{ name: 'homePage', hash: '#aboutApp' }" class="nav__link">О нас</router-link>
      <router-link :to="{ name: 'homePage', hash: '#courses' }" class="nav__link">Курсы</router-link>
      <router-link :to="{ name: 'homePage', hash: '#appDownload' }" class="nav__link">Скачать приложение</router-link>
    </nav>
    <div class="header-authorization header-desctop al-cent">
      <router-link :to="{ name: 'registrationPage' }"
        class="header-authorization__registration br-rad-10 bg-red white text-cent flex"
        v-if="$route.name === 'loginPage'">Зарегистрироваться
      </router-link>
      <router-link :to="{ name: 'loginPage' }" v-if="$route.name === 'registrationPage'"
        class="header-authorization__registration br-rad-10 bg-red white text-cent flex">Войти
      </router-link>
    </div>
    <div class="menu header-mobile">
      <button class="burger header-mobile" @click="viewMobile = !viewMobile"></button>
      <div class="menu-filling" v-if="viewMobile">
        <nav class="nav flex gap30">
          <router-link :to="{ name: 'homePage' }" class="nav__link">Главная</router-link>
          <router-link :to="{ name: 'homePage', hash: '#aboutApp' }" class="nav__link">О нас</router-link>
          <router-link :to="{ name: 'homePage', hash: '#courses' }" class="nav__link">Курсы</router-link>
          <router-link :to="{ name: 'homePage', hash: '#appDownload' }" class="nav__link">Скачать приложение</router-link>
        </nav>
        <div class="authorization">
          <router-link v-if="$route.name === 'registrationPage'" :to="{ name: 'loginPage' }"
            class="authorization__login">Войти
          </router-link>
          <router-link v-if="$route.name === 'loginPage'" :to="{ name: 'registrationPage' }"
            class=" authorization__login registration">
            Зарегистрироваться
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AuthorizationHeader",
  data() {
    return {
      viewMobile: false,
    };
  },
};
</script>

<style scoped>
/* Header */
.header {
  padding: 20px 20px;
  font-family: "Muller";
}

.logo {
  display: flex;
  flex-shrink: 0;
  width: 260px;
  object-fit: contain;
}

.nav {
  margin-left: 18px;
  font-size: 18px;
}

.nav__link {
  margin-right: 0;
  font-weight: 500;
  color: #100c40;
  margin-right: 30px;
}

.header-desctop {
  display: flex;
}

.header-mobile {
  display: none;
}

.header-authorization {
  font-weight: 500;
  font-size: 12px;
}

.authorization__login {
  color: #100c40;
  font-size: 18px;
  height: auto;
  justify-content: center;
}

.header-authorization__registration {
  margin-left: 20px;
  width: 180px;
  height: 48px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .logo {
    width: 140px;
  }

  .nav {
    font-size: 12px;
    margin-left: auto;
    margin-right: 40px;
  }
}

@media (max-width: 900px) {
  .header {
    padding: 44px 10px;
    font-family: "Muller";
  }

  .logo {
    width: 160px;
  }

  .header-desctop {
    display: none;
  }

  .header-mobile {
    display: flex;
  }

  .burger {
    display: flex;
    flex-shrink: 0;
    background: url(../../assets/img/burger.svg);
    width: 36px;
    height: 36px;
    z-index: 0;
  }

  .menu-filling {
    transition: all 1s ease;
    position: fixed;
    width: 100%;
    height: 100vh;
    right: 0;
    z-index: 10000;
    top: 0;
    background: #fff;
    flex-direction: column;
    margin-top: 124px;
    background: #fafafa;
  }

  .nav {
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding-top: 30px;
  }

  .authorization {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
  }

  .header-authorization__registration {
    margin: 0 auto;
  }

  .nav__link {
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 18px;
  }

  .logo {
    width: 240px;
  }
}

.margin-0 {
  margin: 0;
}
</style>
