<template>
  <div class="content-wrapper">
    <div class="filling-element">
      <div class="content-element-caption flex al-cent just-space-betwin">
        <h5 class="content-element-caption__title">Все курсы:</h5>
        <a href="" class="content-element-caption__more" v-if="false">Вид</a>
      </div>
      <div class="content block mt-20">
        <div class="content-filling ">
          <div class="content-filling-element"
               v-for="item in courses" :key="item.id">
            <SeparateSmallCourse :course="item">
            </SeparateSmallCourse>
          </div>
        </div>
        <button class="content-more btn-midle br-red m0-auto mt-30 red" @click="loadCourses" v-if="false">Еще
        </button>
      </div>
    </div>
    <div class="filling-element">
      <div class="content-element-caption flex al-cent just-space-betwin">
        <h5 class="content-element-caption__title">Все статьи:</h5>
        <a href="" class="content-element-caption__more" v-if="false">Вид</a>
      </div>
      <div class="content block mt-20 library-content-wrapper">
        <div class=" content-filling library-content">
          <LibraryWrapperElement v-for="item in articles" :article="item"
                                 :key="item.id">
          </LibraryWrapperElement>
        </div>
        <!--        <button class="content-more btn-midle br-red m0-auto mt-30 red" v-if="false" @click="loadCourses">Еще-->
        <!--        </button>-->
      </div>
    </div>
  </div>
</template>

<script>
import LibraryWrapperElement from "../library/LibraryWrapperElement.vue";
import SeparateSmallCourse from "../slider/SeparateSmallCourse.vue";

export default {
  components: {LibraryWrapperElement, SeparateSmallCourse},
  props: ['findResults'],
  name: "ContentWrapper",
  data() {
    return {
      courses: null,
      articles: null
    }
  },
  beforeMount() {
    this.articles = this.findResults.filter(item => item.type === 'article');
    this.courses = this.findResults.filter(item => item.type === 'course' || item.type === 'lesson');

  },
  watch: {
    findResults: function (val, oldVal) {
      console.log(val, oldVal)

    }
  }
}
</script>

<style scoped>
.content {
  max-width: 100%;
  width: 100%;
}

.filling-element {
  max-width: 100%;
  width: 100%;
  margin-right: 40px;
  margin-top: 40px;
}

/*content*/
.content {
  padding: 20px 0px 0px 0px;
  width: 100%;
}

.content-filling {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.content-filling-element {
  margin-bottom: 20px;
  width: 100%;
  padding: 0 10px;
}

.content-more {
  max-width: 119px;
}

.library-content-wrapper {
  padding-bottom: 0;
}

@media (max-width: 3100px) {
  .content-filling {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 2500px) {
  .content-filling {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 2150px) {
  .content-filling {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1920px) {

  .content-filling {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1700px) {
  .content-filling {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1100px) {
  .content-filling {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 950px) {

  .library-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .content-filling {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 550px) {
  .content-filling {
    grid-template-columns: repeat(1, 1fr);
  }

  .content-filling-element {
    margin-right: 0;
  }
}


.content-element-caption__title {
  font-weight: 500;
  font-size: 20px;
}

.content-element-caption__more {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #100c40;
}

@media (max-width: 650px) {
  .library-content {
    width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 600px) {

  .content-element-caption__title {
    font-size: 20px;
    margin: 0 auto;
  }

  .content-element-caption__more {
    display: none;
  }
}
</style>