import {fetchArticles, fetchCurrentArticle, fetchSimilarArticles} from "@/store/articles/serverInterationMethods";

export const articles = {
    actions: {
        async fetchArticles({getters, commit}, {trend, name, page, limit}) {
            const {data} = await fetchArticles(getters.token, trend, page, limit, name);
            if (page > 1) {
                commit('addArticles', data);
            } else {
                commit('setArticles', {data, trend});
            }
            return data
        },
        async fetchCurrentArticle({getters, commit}, id) {
            const {data} = await fetchCurrentArticle(getters.token, id);
            commit('setCurrentArticle', data.article)
        },
        async fetchSimilarArticles({getters, commit}, id) {
            const {data} = await fetchSimilarArticles(getters.token, id);
            commit('setSimilarArticles', data);
        }
    },
    mutations: {
        setArticles(state, payload) {
            state.articles = payload.data;
            state.currentTrend = payload.trend;
        },
        setCurrentArticle(state, data) {
            state.currentArticle = data;
        },
        setSimilarArticles(state, data) {
            state.similarArticles = data;
        },
        addArticles(state, data) {
            state.articles = state.articles.concat(data);
        }
    },
    state: {
        currentTrend: null,
        articles: null,
        articlesPage: 1,
        articlesLimit: 5,
        currentArticle: null,
        similarArticles: null
    },
    getters: {
        currentTrend: state => state.currentTrend,
        articles: state => state.articles,
        articlesPage: state => state.articlesPage,
        articlesLimit: state => state.articlesLimit,
        currentArticle: state => state.currentArticle,
        similarArticles: state => state.similarArticles

    }
}