import {
    fetchChecklist, fetchMyTesting,
    fetchTesting, savePassedChecklist,
    savePassedTesting
} from "@/store/testing/serverInterationMethods";
import {errorHandler} from "@/lib/errorHandler";

export const testing = {
    actions: {
        async fetchTesting({commit, getters}, id) {
            const {data} = await fetchTesting(getters.token, id);
            commit('setCurrentTesting', data.testing);
        },
        async savePassedTesting({getters, commit}, time) {
            commit('updatePassingTime', time);
            const {status, data} = await savePassedTesting(getters.token, getters.savedTesting);
            errorHandler({name: 'testing', status, data: data.correct + ' из ' + data.all});
        },
        async fetchChecklist({getters, commit}) {
            const {data} = await fetchChecklist(getters.token);
            commit('setChecklist', data.checklist);
        },
        async savePassedChecklist({getters, commit}) {
            const {status} = await savePassedChecklist(getters.token, {answers: getters.savedChecklist});
            errorHandler({name: 'checklist', status, post: true});
            if (status === 200) {
                commit('setChecklist', null)
            }
        },
        async fetchMyTesting({getters, commit}) {
            const {data} = await fetchMyTesting(getters.token);
            commit('setMyTesting', data);
        }
    },
    mutations: {
        setCurrentTesting(state, data) {
            state.currentTesting = data;
        },
        saveAnswer(state, data) {
            if (!state.savedTesting) {
                state.savedTesting = {
                    testingId: state.testingId,
                    passedTimeSeconds: null,
                    answers: []
                }
            }
            if (state.savedTesting.answers.length !== 0) {
                if (data.type === 'many') {
                    if (state.savedTesting.answers.map(item => item.answerId).indexOf(data.answerId) !== -1) {
                        state.savedTesting.answers = state.savedTesting.answers.filter(item => item.answerId !== data.answerId);
                    } else {
                        state.savedTesting.answers.push({questionId: data.questionId, answerId: data.answerId});
                    }
                }
                if (data.type === 'one') {
                    if (state.savedTesting.answers.map(item => item.questionId).indexOf(data.questionId) !== -1) {
                        state.savedTesting.answers[state.savedTesting.answers.map(item => item.questionId).indexOf(data.questionId)].answerId = data.answerId;
                    } else {
                        state.savedTesting.answers.push({questionId: data.questionId, answerId: data.answerId});
                    }
                }
            } else {
                state.savedTesting.answers.push({questionId: data.questionId, answerId: data.answerId});
            }
        },
        setTestingId(state, id) {
            state.testingId = id;
        },
        updatePassingTime(state, time) {
            state.savedTesting.passedTimeSeconds = time;
        },
        setChecklist(state, data) {
            state.checklist = data;
        },
        saveAnswerChecklist(state, payload) {
            if (!state.saveAnswersChecklist) {
                state.saveAnswersChecklist = [];
            }
            if (state.saveAnswersChecklist.length !== 0) {
                if (state.saveAnswersChecklist.map(item => item.answerId).indexOf(payload.answerId) !== -1) {
                    state.saveAnswersChecklist = state.saveAnswersChecklist.filter(item => item.answerId !== payload.answerId);
                } else {
                    state.saveAnswersChecklist.push({questionId: payload.questionId, answerId: payload.answerId});
                }
            } else {
                state.saveAnswersChecklist.push({questionId: payload.questionId, answerId: payload.answerId})
            }
        },
        setMyTesting(state, data) {
            state.myTesting = data;
        }
    },
    state: {
        testingId: null,
        currentTesting: null,
        savedTesting: null,
        checklist: null,
        saveAnswersChecklist: null,
        myTesting: null
    },
    getters: {
        currentTesting: state => state.currentTesting,
        savedTesting: state => state.savedTesting,
        checklist: state => state.checklist,
        savedChecklist: state => state.saveAnswersChecklist,
        myTesting: state => state.myTesting

    }
}