import { createStore } from 'vuex'
import {user} from "./user";
import {application} from "./application";
import {courses} from "@/store/courses";
import {lesson} from "@/store/lesson";
import {testing} from "@/store/testing";
import {articles} from "@/store/articles";


// creating Vuex store instance
const store = createStore({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		user, application,courses,lesson,testing,articles
	}
});

//store.dispatch('loadUserToken');

export default store;