<template>
  <div class="background">
    <AuthorizationHeader></AuthorizationHeader>

    <main class="authorization authorization-container">
      <div class="authorization-content br-rad-10 bg-white mt-20">
        <h1 class="authorization__title">Введите код</h1>
        <p class="authorization__text">
          Для подтверждения авторизации мы отправили 6 значный проверочный код
        </p>
        <div class="fields">
          <input v-for="(n, index) in 6" :key="index" maxlength="1" inputmode="numeric" pattern="\d [0-9]"
            :id="'input_' + index" v-model="code[index]" @input="handleInput" @keydown.delete="handleDelete"
            @paste="onPaste" class="authorization-field__input" />
        </div>
        <time class="time" v-if="false">00:34</time>
        <div class="repeat">
          Не получили код?
          <button class="repeat__btn">Отправить заново</button>
        </div>
        <div class="end flex">
          <button class="end__btn red br-red" @click="$router.go(-1)">
            Назад
          </button>
          <button class="end__btn bg-red white" @click="fastLogin">
            Далее
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import AuthorizationHeader from "../../components/authorization/AuthorizationHeader.vue";

export default {
  components: { AuthorizationHeader },
  name: "CodePage",
  data() {
    return {
      code: ["", "", "", "", "", ""],
      dataFromPaste: '',
      timer: null
    };
  },
  watch: {
    'code': {
      handler(val) {
        for (let i = 0; i < val.length; i++) {
          if (val[i] === '') {
            return;
          }
        }
        this.timer = setTimeout(this.fastLogin, 500);
      },
      deep: true
    }
  },
  methods: {
    handleInput(event) {
      const inputType = (event).inputType;
      let currentActiveElement = event.target;
      if (inputType === "insertText")
        (currentActiveElement.nextElementSibling)?.focus();
      if (inputType === "insertFromPaste" && this.dataFromPaste) {
        for (const num of this.dataFromPaste) {
          let id = parseInt(currentActiveElement.id.split("_")[1]);
          currentActiveElement.value = num;
          this.code[id] = num;
          if (currentActiveElement.nextElementSibling) {
            currentActiveElement =
              currentActiveElement.nextElementSibling;
            (currentActiveElement.nextElementSibling)?.focus();
            this.dataFromPaste = '';
          }
        }
      }
    },

    handleDelete(event) {
      //keydown event = move to previous element then only delete number
      let value = (event.target).value;
      let currentActiveElement = event.target;
      if (!value)
        (currentActiveElement.previousElementSibling)?.focus();
    },

    onPaste(event) {
      this.dataFromPaste = (event).clipboardData
        ?.getData("text")
        .trim()
        .split("");
    },

    fastLogin() {
      clearTimeout(this.timer);
      if (this.code.indexOf("") === -1) {
        this.$store.dispatch("fastLogin", this.code.join("")).then(() => {
          this.code = ["", "", "", "", "", ""];
          document.getElementById('input_0').focus();
        });
      }
    },

  },
  beforeMount() {
    scroll(0, 0);
  }
};
</script>

<style scoped>
.mt-14 {
  margin-top: 14px;
}

.background {
  background: url(../../assets/img/registration-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 133px;
  background-position: center;
}

.authorization {
  text-align: center;
  margin-top: 44px;
}

.authorization__title {
  font-family: "DelaGothicOne";
  font-size: 32px;
  text-transform: unset;
  color: #000;
  line-height: 1.6;
}

.authorization__text {
  max-width: 250px;
  margin: 14px auto 0 auto;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  line-height: 1.6;
}

.fields {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.authorization-field__input {
  max-width: 30px;
  border-bottom: 2px solid #ff2031;
  color: #ff2031;
  text-align: center;
  font-size: 20px;
  padding-bottom: 5px;
  border-radius: 0 !important;
}

.authorization-field__input::placeholder {
  opacity: 0.5;
}

.time {
  display: block;
  margin-top: 30px;
}

.repeat {
  margin-top: 20px;
}

.repeat__btn {
  color: #ff2031;
}

.end {
  margin-top: 40px;
  justify-content: space-around;
}

.end__btn {
  width: 120px;
  width: 130px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .background {
    background: url(../../assets/img/registration-bg-small.webp);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 53px;
    background-position: top;
  }

  .authorization {
    margin-top: 0;
  }

  .authorization__title {
    font-size: 24px;
    line-height: 1;
  }

  .authorization-content {
    margin-top: 30px;
    padding: 30px 10px;
  }

  .authorization__text {
    margin-top: 30px;
    font-size: 14px;
    line-height: 1;
  }

  .fields {
    margin-top: 40px;
  }

  .authorization-field__input {
    max-width: 20px;
    border-bottom: 2px solid #ff2031;
    font-size: 16px;
    padding-bottom: 2px;
  }

  .time {
    display: block;
    margin-top: 30px;
  }

  .repeat {
    margin-top: 20px;
    font-size: 14px;
  }

  .end {
    justify-content: space-between;
  }

  .end__btn {
    width: 120px;
    height: 44px;
    font-size: 12px;
  }
}
</style>
